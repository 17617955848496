<template>
  <div>
    <toggle-button v-model="isChecked" :labels="{checked: 'Gebruik eigen weeknummers', unchecked: 'Gebruik geen eigen weeknummers'}" :fontSize="12" :width="240" />
    <div class="form-group optional form-group-valid" v-if="isChecked">
      <label for="custom_number">Eigen weeknummer</label>
            
      <select name="week_plan[custom_week_number]" class="form-control this-is-valid select optional">
        <option v-for="week in allWeeks" :value="week" :selected="(parseInt(week) == parseInt(custom_week_number))">{{week}}</option> 
      </select>

      
    </div>
    <input type="hidden" name="week_plan[use_custom_week_number]" :value="isChecked">
  </div>
</template>

<script>
export default {

  name: 'customWeekNumberSelector.vue',
  props: ["use_custom", "custom_week_number"] ,
  data () {
    return {
      isChecked: false,
    }
  },
  computed: {
    allWeeks(){
      return Array(52).fill(1).map((x, y) => x + y)
    }
  },
  created(){
    this.isChecked = this.use_custom
  }

}
</script>

<style lang="css" scoped>
</style>