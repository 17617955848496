<template>
  <div class="row align-self-center w-100 mx-0 px-0" style="max-height: calc(100vh - 80px); overflow: auto;">    
    <i class="fas fa-arrow-circle-left arrow arrow-left text-info" @click="prevDay" v-if="prevday"></i>
    <div class="day-block mx-auto" v-bind:class="{'col-6' : !isWide, 'col-12' : isWide, 'day-ma': weekplanday.short_name == 'ma', 'day-di': weekplanday.short_name == 'di', 'day-wo': weekplanday.short_name == 'wo', 'day-do': weekplanday.short_name == 'do', 'day-vr': weekplanday.short_name == 'vr' }">
      <div class="card shadow-sm">
        <div class="card-header pb-2 border-0 text-center bg-success" style="position: relative;">
          <h3 class="m-0">{{weekplanday.name}} <small class="ml-3 text-white">{{ weekplanday.date}}</small></h3>
        </div>
        <div class="card-body p-0">
          <div class="bg-danger text-center" style="position: relative;" v-if="!onLine">
            <div class="fadeInUp py-3 text-white font-weight-bold" style="font-size: 16pt;">Je bent nu offline ☹️</div>
          </div>

          <div style="height: 60px; background: #fff; position: relative;" v-if="weekplanday.birthday">
            <div class="balloon animated fadeInUp">{{student.first_name}} jarig</div>
          </div>
          <filesView v-if="weekplanday.id == 'files'" :files="files"></filesView>
          <chatView v-if="weekplanday.id == 'chat'" :use_fileupload="use_fileupload" :loading="loading" :scope="scope"></chatView>
          <div v-if="weekplanday.id == 'evaluation'">
            <evaluationItem 
              v-for="item in weekplanday.items" 
              :item="item" 
              :student-week-plan-key="studentWeekPlanKey"
              :student-week-plan-id="studentWeekPlanId"
              :key="`evaluation-item-${item.id}`" 
            />
          </div>
          <table class="table my-0" v-if="weekplanday.id == 'comments'">
            <tr class="py-2 bg-light" style="background: none;" :key="`comment-${comment.id}`" v-for="comment in weekplanday.comments">
              <td style="vertical-align: middle;" class="pl-3 pr-0" v-linkified v-html="comment.body">
              </td>
            </tr>
          </table>
          <table class="table my-0" v-if="weekplanday.is_active == false">
            <tr class="py-2 bg-white" style="background: none;">
              <td style="vertical-align: middle;" class="pl-3 pr-0">
                {{weekplanday.reason_for_inactive}}
              </td>
            </tr>
          </table>
          <table class="table mt-0 bg-warning mb-0" v-if="showNormalDay && weekplanday.planned_notes && weekplanday.planned_notes.length > 0">
            <tbody>
              <tr v-for="planned_note in weekplanday.planned_notes" :key="'planned_note_td_' + planned_note.id">
                <td width="36px"></td>
                <td width="65.6px" style="vertical-align: middle;"><i :class="`${ planned_note.icon }`"></i></td>
                <td width="24px">
                </td>
                <td width="25%" style="vertical-align: middle;" class="course">
                  <p class="my-0">
                    <b>{{ planned_note.category }}</b></p>
                </td>

                <td style="vertical-align: middle;" class="task">
                  <div><span style="font-size: 14px">
                    <i class="far fa-clock"></i> {{ planned_note.time }}
                  </span></div>
                  <div v-html="planned_note.body" v-linkified></div>
                </td>
              </tr>
            </tbody>
          </table>

          <table class="table my-0" v-if="showNormalDay">
            <thead>
              <tr>
                <th v-if="weekplanday.id != 'autonomous'"></th>
                <th></th>
                <th v-if="weekplanday.id != 'autonomous'"></th>
                <th></th> 
                <th>Les</th>
                <th>Taak</th>
                <th v-if="showGoals && weekplanday.id != 'extra'">Doel</th>
                <th v-if="weekplanday.id == 'autonomous'" class="text-right">Wanneer?</th>
                <th v-if="weekplanday.id != 'autonomous'"></th>
              </tr>
            </thead>


            <tbody>
              <studentweekplanitem :show-goals="showGoals" :scope="scope" :today="today" :only_check_today="only_check_today" :weekplanday="weekplanday" :feedback-result="feedbackResult" :feedback-feeling="feedbackFeeling" :lessonlevel="lesson_level" :key="lesson_level.id" v-for="lesson_level in weekplanday.lesson_levels" />
              <studentweekplanitem  :show-goals="showGoals" :scope="scope" :today="today" :only_check_today="only_check_today" v-if="lesson_level.student_lesson.week_plan_day_id == weekplanday.id && weekplanday.id != 'autonomous' && weekplanday.id != 'extra'"  :hideplanner="false" :feedback-result="feedbackResult" :feedback-feeling="feedbackFeeling" :lessonlevel="lesson_level" :weekplanday="weekplanday" :key="lesson_level.id" v-for="lesson_level in autonomous_lesson_levels" />
              </tbody>
          </table>

        </div>
      </div>       
    </div>
    <i class="fas fa-arrow-circle-right arrow arrow-right text-info" @click="nextDay" v-if="nextday"></i>
  </div>
</template>

<script>

import chatView from '../../components/digital/chatView.vue'
import evaluationItem from '../../components/digital/evaluationItem.vue'
import filesView from '../../components/digital/filesView.vue'
export default {

  props: ["weekplanday", "prevday", "nextday", "scope", "loading", "student", "only_check_today", "today", "use_fileupload", "files", "studentWeekPlanKey", "studentWeekPlanId", "showGoals", "onLine", "feedbackFeeling", "feedbackResult"],
  data () {
    return {

    }
  },
  components: {chatView, filesView, evaluationItem},
  computed:{
    isWide() {
      if (this.weekplanday.id == 'chat') {
        return false 
      } else {
        return true
      }
    },
    weekPlan() {
      return window.store.getters.digitalWeekPlan
    },
    autonomous_lesson_levels(){
      return window.store.getters.digitalWeekPlan.autonomous.lesson_levels
    },
    showNormalDay(){
      // return (this.weekplanday.id != 'comments') && 
      if (this.weekplanday.id == 'comments'){
        return false
      } else if (this.weekplanday.id == 'chat'){
        return false
      } else if (this.weekplanday.id == 'files'){
        return false
      } else if (this.weekplanday.id == 'evaluation'){
        return false
      } else if (this.weekplanday.is_active == false ){
        return false
      } else {
        return true
      }
    }
  },
  methods: {
    prevDay() {
      this.$emit('clicked', 'prev')
    },
    nextDay() {
      this.$emit('clicked', 'next')
    },
    handleKeyDown(event){
      // console.log(`Key pressed: ${event.keyCode}`)
      if (event.keyCode == 37 && this.prevday){
        this.prevDay()
      } else if  (event.keyCode == 39 && this.nextday) {
        this.nextDay()
      } else if (event.keyCode == 27){
        this.$emit('escape')   
      }
    }

  },
  mounted() {
    window.addEventListener("keyup", this.handleKeyDown);
  },
  destroyed () {
    window.removeEventListener("keyup", this.handleKeyDown)
  }
}
</script>

<style lang="css" scoped>
.arrow{
  font-size: 48px;
  position: absolute;
  top: 50%;
  cursor: pointer;
  z-index: 50;

}
.arrow-left{
  left: 20px;
}
.arrow-right{
 right: 20px; 
}
.day-block{
  position: relative;
}

.balloon{
  font-family: 'JI Balloon Caps';
  font-size: 80px;
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: -30px;
}

</style>