import { render, staticRenderFns } from "./userPreferences.vue?vue&type=template&id=384b37db&scoped=true&"
import script from "./userPreferences.vue?vue&type=script&lang=js&"
export * from "./userPreferences.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "384b37db",
  null
  
)

export default component.exports