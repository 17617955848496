<template>
  <div>
    <div class="progress mt-1 main-progress" @click="toggleTable">
      <div class="progress-bar bg-success" role="progressbar" :style="`width: ${progressToShow}%;`">{{progressToShow}}%</div>
    </div>      
    <div v-if="showTable">
      <div class='text-center' v-if="loading">
        <i class="fa fa-spinner fa-spin"></i>
      </div>
      <div v-if="!loading && progress">
        <div v-for="day in progress.days" v-if="day.lessons" class="bg-light rounded p-2 my-1">
          <div class="d-flex justify-content-between">
            <h6>{{ day.name }}</h6>
            <span class="text-muted">{{ day.completed }}/{{ day.total }}</span>
          </div>
          <div v-for="lesson in day.lessons" class="small d-flex justify-content-between">
            <span>
              <i class="text-success fa fa-check" v-if="lesson.completed"></i>
              <i class="text-danger fa fa-times" v-if="!lesson.completed"></i>
              {{ lesson.course}} <span class='text-muted' v-if="lesson.required">(verplicht)</span>
            </span>
            <span>
              <span v-if="lesson.feedback_feeling != null" v-html="feedbackIconFeeling(lesson.feedback_feeling)"></span>
              <span v-if="lesson.feedback_result != null" v-html="feedbackIconResult(lesson.feedback_result)"></span>
            </span>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {

  name: 'studentWeekPlanProgress',
  props: ['studentweekplan'],
  data () {
    return {
      loading: true,
      showTable: false,
      progress: null,
      progressToShow: 0
    }
  },
  created() {
    this.progressToShow = this.studentweekplan.progress
    this.fetchCurrentData()
  },
  
  methods: {
    feedbackIconFeeling(feeling){
      switch(feeling) {
        case 0:
          return "<i class='text-danger far fa-frown'></i>"
        case 1:
          return "<i class='text-warning far fa-meh'></i>"
        case 2:
          return "<i class='text-success far fa-smile'></i>"
        default:
          return ""
      }
      
    },
    feedbackIconResult(result){
      switch(result) {
        case 0:
          return "<i class='text-danger far fa-thumbs-down'></i>"
        case 1:
          return "<i class='text-success far fa-thumbs-up'></i>"
        default:
          return ""
      }
      
    },
    toggleTable() {
      if (this.showTable) {
        this.showTable = false
      } else {
        this.showTable  = true
        this.loading    = true
        this.progress   = null
        this.fetchCurrentData()
      }
    },
    fetchCurrentData() {
      console.log(`fetching data for student ${this.studentweekplan.id}`)
      return axios.get(`/student_week_plans/${this.studentweekplan.id}/progress.json`)
        .then(response => {
          this.loading  = false
          this.progressToShow = response.data.total
          this.progress = response.data
        })

    }
  }
}
</script>

<style lang="css" scoped>
.main-progress {
  cursor: help;
}
</style>