<template>
  <div>
    <div class="row">
      
      <div class="col py-3 px-3">
        <h3>Verstuur feedback of stel een vraag</h3>
        <div class="row">
          <div class="col text-center" v-if="sent">
            <i class="fa-4x fas fa-clipboard-check text-success mb-3"></i>
            <p class="lead">Je feedback is verstuurd. Als het nodig is, neemt iemand van Weektaak.com contact met je op.</p>
          </div>
          <div class="col" v-if="!sent">
            <p>Loop je vast of heb je suggesties om Weektaak.com beter te maken? Vul het onderstaande feedbackformulier in.</p>
            <form>
              <div class="form-group">
                <input ref="subject" type="text" class="form-control" v-model="subject" id="subject" aria-describedby="emailHelp" placeholder="Onderwerp">
              </div>
              <div class="form-group">
                <textarea class="form-control" v-model="body" rows="6" placeholder="Bericht"></textarea>
              </div>
              <button type="submit" @click="submitForm" :disabled="formIsDisabled" class="btn btn-primary">Verstuur</button>
            </form>
          </div>
        </div>
      </div>


      <div class="col bg-light py-3 px-3">
        <h3>Kennisbank</h3>
        <p>In onze <a href="/handleiding" target="_blank">handleiding</a> proberen we veelgestelde vragen te beantwoorden. Mischien staat het antwoord op je vraag hier ook wel tussen.</p>


        <h4>Mogelijke antwoorden</h4>
        <div class="bg-secondary text-light p-3 rounded" v-if="noResults">Helaas hebben we geen antwoorden op je vraag in onze kennisbank kunnen vinden. We helpen je graag persoonlijk verder. Door het formulier verder in te vullen en op verzenden te klikken worden wij van je vraag op de hoogte gebracht en sturen we je binnen een werkdag een reactie per e-mail.</div>

        <div class="list-group">
          <a :href="guide.url" :key="guide.slug" :guide="guide" target="_blank" v-for="guide in filteredList.slice(0, 5)" class="list-group-item list-group-item-action d-flex justify-content-between">
            <span>{{ guide.title }}</span>
            <i class="fas fa-chevron-right align-self-center"></i>
          </a>
          
        </div>
      </div>

    </div>
  </div>

</template>

<script>

import Vue from 'vue/dist/vue.esm'
import Weektaak from '../../packs/weektaak'


export default {
  components: {
  },
  props: ["url", "userid", "useragent", "guides"],

  data () {
    return {
      subject: '',
      body: '',
      sent: false
    }
  },
  computed: {
    formIsDisabled () {
      if (this.subject == '' || this.body == '') {
        return true
      } else {
        return false
      }
    },
    filteredList() {
      if (this.subject == '') {
        return this.guides.guides
      } else {
        return this.guides.guides.filter((guide) => {
          return guide.search_terms.toLowerCase().includes(this.subject.toLowerCase())
        })  
      }
      
    },
    noResults() {
      return this.filteredList.length == 0 && this.subject.length > 0
    },
  },

  methods: {
    submitForm () {
      this.sent = true
      // submitting...
      Weektaak.sendFeedback({user_id: this.userid, url: this.url, user_agent: this.useragent, subject: this.subject, body: this.body})
    },
  },
  mounted() {
    setTimeout(function () {
      this.$refs.subject.focus()
    }.bind(this), 500)
    
  }
}
</script>

<style scoped>
  h3, h4{
    font-weight: 700;
    font-size: 16pt;
  }
  h4{ font-size: 12pt; }
</style>
