<template>
  <div>
    <div class="container">

      <div v-if="courses.length == 0">
        <div class="row">
          <div class="col-6 mx-auto bg-light blank-slate rounded py-3 text-muted">
            Wanneer je lessen hebt ingepland op je weekplanning, krijg je op deze pagina de ingeplande vakken te zien. Je kunt dan per vak bepalen of je deze wel of niet gedifferentieerd wilt aanbieden.
          </div>
        </div>
      </div>

      <div class="row course-toggles" v-if="this.group_courses != undefined">
        <div v-for="course in courses" class="mr-3">
          <toggle-button @change="toggleCourse(course.id)" :value="courseIsActive(course.id)" :sync="true" :fontSize="14" :labels="{checked: course.name, unchecked: course.name}" :width="200" />
        </div>
      </div>
    </div>


    
    <div v-if="courses && courses.length > 0" class="container py-5 my-3 bg-white rounded shadow-sm">
      <!-- course headers -->
      <div class="row no-gutters">
        <div class="col-3">
          <div class="item bg-white course-name border-0 "><br></div>
          <div class="" v-for="student in students">
            <div class="item">{{ student.name }}</div>
          </div>
        </div>
        <div class="col-9" style="overflow-x: auto;">
          
          <div class="row no-gutters row-flex flex-nowrap" :style="`min-height: ${groups.length * 50}px;`">

              <div v-if="coursesOnPlanInclude(course.id)" class="float-left" :style="`min-width: ${studentLevelWidth}px`" v-for="course in coursesWithOptions" >
                <div class="item text-center border-0 course-name">{{course.name}}</div>
                <div v-for="student in students">
                  <div v-for="studentCourse in student.courses">
                    <studentLevelPicker  
                      v-if="studentCourse.course_id == course.id" 
                      @showHelp="showHelp"
                      class="item picker-item" 
                      :student="student" 
                      :student-course="studentCourse"
                      :course="studentCourse" 
                      :key="`slp-${studentCourse.id}`" 
                      :studentLevelId="studentCourse.id" 
                      :groups="groups"
                      :group="group"
                      :show-group-select="showGroupSelect"
                      :levelId="studentCourse.level_id" />
                  </div>
                </div>
                <div class="item text-center border-0 course-name">{{course.name}}</div>
              </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

import Weektaak from '../../packs/weektaak'
import StudentCourseLevelList from './studentCourseLevelList.vue'
import studentLevelPicker from './studentLevelPicker.vue'

export default {
  data () {
    return {
      selectedCourses: []
    }
    
  },
  props: ["weekplanid", "groupid", "groups", "group", "showGroupSelect", "courses"],
  components: {
    StudentCourseLevelList,
    studentLevelPicker
  },
  methods: {
    showHelp() {
      this.$emit('showHelp')
    },
    coursesOnPlanInclude(course_id){
      return this.courses.map(item => item.id).includes(course_id)
    },
    courseIsActive (id) {
      if (this.group_courses == undefined) {
        return false
      } else {
        return this.group_courses.includes(id)
      }
    },
    toggleCourse (id) {
      console.log("toggeling course " + id + " for group " + this.groupid)
      if (this.selectedCourses.includes(id)){
        var index = this.selectedCourses.indexOf(id)
        if (index > -1) {
          this.selectedCourses.splice(index, 1)
        }
      } else {
        this.selectedCourses.push(id)  
      }
      window.store.dispatch('updateGroup', {id: this.groupid, week_plan_id: this.weekplanid, group: {course_ids: this.group_courses}})
      // window.store.dispatch('fetchCourses', { id: this.weekplanid})  
    },
  },

  computed: {
    group_courses (){
      let list = this.weekPlan.group_courses
      for (let group_list of list) {
        if (parseInt(group_list.group_id) == parseInt(this.groupid)){
          return group_list.group_courses //.map((num) => parseInt(num))
        }
      }
    },
    levels (){
      return window.store.getters.levels
    },
    studentLevelWidth (){
      if (this.showGroupSelect) {
        return (window.store.getters.levels.length * 26) + 60
      } else {
        return window.store.getters.levels.length * 26
      }
    },
    weekPlan (){
      return window.store.getters.weekPlan
    },
    weekPlanCourses () {
      return window.store.getters.weekPlanCourses
    },
    coursesWithOptions (){
      return window.store.getters.coursesWithLevels
    },
    students (){
      return window.store.getters.students
    }
  },
  created () {
    // fetch courses
  },
  watch: {
    group_courses: function visible(newValue, oldValue) {
      this.selectedCourses = this.group_courses
    }
  }
  
}
</script>

<style scoped>

.item{
  border-bottom: 1px solid #dedede;
  height: 30px;
  margin-bottom: 2px;
}
.student-level{
  
  border-left: 1px solid #dee2e6;
  text-align: center;
  /*min-width: 120px;*/
}
.course-name{
  font-size: 10pt;
  height: 50px;
}
.picker-item{
  border-left: 1px solid #dedede;
}

</style>
