import axios from 'axios'

export default {
  // {user_id: this.userid, url: this.url, user_agent: this.useragent, subject: this.subject, body: this.body}

  arrayRemove(arr, value) {

     return arr.filter(function(ele){
         return ele != value;
     });
  },
  getChatMessagesForStudent(uuid, student_id) {
    console.log('getting chat messages for student')
    return axios.get(`/user-chats/${uuid}/student/${student_id}.json`)
      .then(response => {
        return response.data
      })
  },
  getChatMessages(uuid, user_chat_id) {
    console.log('getting chat messages')
    return axios.get(`/user-chats/${uuid}/${user_chat_id}.json`)
      .then(response => {
        return response.data
      })
  },

  getChats(uuid) {
    return axios.get(`/user-chats/${uuid}.json`)
      .then(response => {
        return response.data
      })
  },


  sendFeedback(feedback){
    return axios.post("/feedbacks.json", {feedback: feedback})
      .then(response => {
        return response.data
      })
  },
  createStudent(groupId, student){
    return axios.post("/groups/"+groupId+"/students.json", {student: student})
      .then(response => {
        return response.data
      })
  },
  saveUserPreference(pref){
    // console.log(pref)
    return axios.put("/user-preference.json", pref)
      .then(response => {
        // window.store.dispatch('setCourseToSchoolCourses', response.data)
        // return response.data
      })
  },
  addSettingsCourse(course){
    // console.log("saving course:")
    // console.log(course)
    return axios.post("/courses.json", {course: course, settings_page: true})
      .then(response => {
        window.store.dispatch('setCourseToSchoolCourses', response.data)
        return response.data
      })
  },
  deleteCourse(courseId){
  return axios.delete("/courses/" + courseId + ".json")
      .then(response => {
        return response.data
      })
  },
  updateCourse(course_id, course){
    return axios.put("/courses/" + course_id + ".json", {course: course})
      .then(response => {
        return response.data
      })
  },
  updateCourseWithGroup(course_id, course, group_id){
    return axios.put("/courses/" + course_id + ".json", {course: course, group_id: group_id})
      .then(response => {
        return response.data
      })
  },
  saveLessonLinks(lesson_id, linked_lesson_ids){
    // for( var i = 0; i < linked_lesson_ids.length; i++){ 
    //   // console.log(linked_lesson_ids[i])
    //  if ( linked_lesson_ids[i] == lesson_id) {
    //    var ids = linked_lesson_ids.splice(i, 1); 
    //    window.store.dispatch('addToLoadingLessons', ids)
    //  }
    // }
    
    return axios.put("/lessons/" + lesson_id + ".json", {lesson: {linked_lesson_ids: linked_lesson_ids}})
      .then(response => {
        return response.data
      })
  },
  saveLessonLinksPerCourse(lesson_id, options){
    return axios.put("/lessons/" + lesson_id + ".json", {response_type: 'per_course', clockless: options.clockless, lesson: {linked_lesson_ids: options.linked_lesson_ids}})
      .then(response => {
        return response.data
      })
  },
  deletePlanPicto(planPictoId, weekPlanId){
    return axios.delete("/week_plans/" + weekPlanId + "/plan_pictos/" + planPictoId +".json")
      .then(response => {
        return response.data
      })
  },
  deleteCombination(id){
    return axios.delete("/combinations/" + id + ".json")
      .then(response => {
        return response.data
      })
  },
  saveCombination(newCombi){
    return axios.post("/combinations.json", {combination: newCombi})
      .then(response => {
        return response.data
      })
  },
  deleteExtraTask(week_plan_id, id){
    return axios.delete("/week_plans/" + week_plan_id + "/extra_tasks/" +  id +".json")
      .then(response => {
        return response.data
      })
  },
  copyExtraTask(week_plan_id, id, group_id){
    return axios.put("/week_plans/" + week_plan_id + "/extra_tasks/" +  id +"/copy.json", {group_id: group_id})
      .then(response => {
        return response.data
      })
  },
  updateExtraTaskLevel(options){
    return axios.put("/week_plans/" + options.week_plan_id + "/extra_task_levels/" +  options.id +".json", {extra_task_level: options.extra_task_level})
      .then(response => {
        return response.data
      })
  },
  getExtraTasks(week_plan_id){
    return axios.get("/week_plans/" + week_plan_id + "/extra_tasks.json")
      .then(response => {
        return response.data
      })
  },
  addExtraTask(week_plan_id, course_id){
    return axios.post("/week_plans/" + week_plan_id + "/extra_tasks.json", {extra_task: {course_id: course_id}})
      .then(response => {
        return response.data
      })
  },
  addBookBlock(id){
    return axios.get("/books/" + id + "/book_blocks/new.json")
      .then(response => {
        return response.data
      })
  },
  fetchBook(id){
    return axios.get("/books/" + id + ".json")
      .then(response => {
        return response.data
      })
  },
  createPlanPicto(weekPlanId, listId, pictoId, index){
    return axios.post("/week_plans/" + weekPlanId + "/plan_pictos.json", {picto_list_id: listId, picto_id: pictoId, index: index})
      .then(response => {
        return response.data
      })
  },
  updatePictoPlan(id, payload){
    return axios.put("/week_plans/" + id + "/plan_pictos.json", payload)
      .then(response => {
        return response.data
      })
  },
  updateLesson(lesson){
    console.log("Updating lesson")
    console.log(lesson)
    return axios.put("/lessons/" + lesson.id + "/update_content.json", {lesson}).then(response => {
        return response.data
      })
  },
  updateLessonLevel(id, options){
    return axios.put("/lesson_levels/" + id + ".json", {
        lesson_level: options, 
        week_plan_id: window.store.getters.weekPlan.id
      }).then(response => {
        return response.data
      })
  },
  updateStudentLevel(options){
    return axios.put("/student_levels/" + options.id + ".json", {student_level: {level_id: options.level_id}})
      .then(response => {
        return response.data
      })
  },
  updateGroup(options){
    return axios.put("/groups/" + options.id + ".json", {week_plan_id: options.week_plan_id, group: options.group})
      .then(response => {
        return response.data
      })
  },
  getGroupsForWeekPlan(id){
    return axios.get("/week_plans/" + id + "/groups.json")
      .then(response => {
        return response.data
      })
  },
  getPrintJobs(id){
    return axios.get("/week_plans/" + id + "/print_jobs.json")
      .then(response => {
        return response.data
      })
  },
  getWeekPlanPerCourse (id, clockless){
    return axios.get("/week_plans/" + id + `/per_course.json?clockless=${clockless}`)
      .then(response => {
        return response.data
      })
  },
  copyWeekPlanDay (options) {
    return axios.get(`/week_plan_days/${options.from_id}/copy.json?to_id=${options.to_id}`)
      .then(response => {
        console.log('done')
        return response.data
      })
  },
  // remove lessons from week plan day
  clearWeekPlanDay(weekPlanDayId) {
    return axios.get(`/week_plan_days/${weekPlanDayId}/remove_lessons.json`)
      .then(response => {
        console.log('done')
        return response.data
      })
  },
  getWeekPlanCourses (id) {
    return axios.get("/week_plans/" + id + "/plan.json")
      .then(response => {
        return response.data
      })
  },
  fetchLesonLevels (id) {
    return axios.get("/lessons/" + id + ".json")
      .then(response => {
        return response.data
      })
  },
  updateWeekPlan (id, options){
    return axios.put("/week_plans/" + id + ".json", {week_plan: options})
      .then(response => {
        return response.data
      })
    
  },
  updateWeekPlanDay (id, options){
    return axios.put("/week_plan_days/" + id + ".json", {week_plan_day: options})
      .then(response => {
        console.log("updated wpd")
        console.log(response.data)
        return response.data
      }).catch( error => { 
        console.log("fout bij opslaan...")
        console.log(error)
      })

  },
  createCourse (keyword) {
    return axios.post("/courses.json", {course: {name: keyword}})
      .then(response => {
        return response.data
      })
  },
  getCourses (id){
    return axios.get("/week_plans/" + id + "/courses.json")
      .then(response => {
        return response.data
      })
  },
  getLevels (id){
    return axios.get("/week_plans/" + id + "/levels.json")
      .then(response => {
        return response.data
      })
  },
  getStudents (id){
    return axios.get("/week_plans/" + id + "/students.json")
      .then(response => {
        return response.data
      })
  },
  getPictoWeekPlan (id){
    return axios.get("/week_plans/" + id + "/plan_pictos.json")
      .then(response => {
        return response.data
      })
  },
  saveLessonPicto(lesson_id, picto_id){
    return axios.put("/lessons/" + lesson_id + "/update_lesson_pictos.json", {lesson: {picto_id: picto_id}})
      .then(response => {
        return response.data
      })
  },
  saveLessonPictoTitle(lesson){
    return axios.put("/lessons/" + lesson.id + "/update_lesson_pictos.json", {lesson: {lesson_levels: lesson.lesson_levels}})
      .then(response => {
        return response.data
      })
  },
  getWeekPlanDays (id){
    return axios.get("/week_plans/" + id + ".json")
      .then(response => {
        return response.data
      })
  },
  getLessonsForDay (day){
    return axios.get("/week_plan_days/" + day + ".json")
      .then(response => {
        return response.data
      })
  },
  updateExtraTaskIndex(extraTaskId, newIndex){
    return axios.put("/extra_tasks/" + extraTaskId + ".json", {extra_task: {position: newIndex}})
      .then(response => {
        return response.data
      })
  },
  updateLessonIndex(weekPlanId, lessonId, newIndex, isClockless, lessonIds){
    // this.weekPlanId, 
    //     event.item.dataset.lessonId, 
    //     event.newIndex, 
    //     event.to.dataset.isClockless
    console.log(`Clockless: ${isClockless}`)
    return axios.put("/lessons/" + lessonId + ".json", {week_plan_id: weekPlanId, order: lessonIds, lesson: {position: newIndex, is_clockless: isClockless}})
      .then(response => {
        return response.data
      })
  },
  moveLessonToDayAndIndex(weekPlanId, lessonId, dayId, isClockless, newIndex){
    return axios.put("/lessons/" + lessonId + ".json", {week_plan_id: weekPlanId, lesson: {is_clockless: isClockless, position: newIndex, week_plan_day_id: dayId}})
      .then(response => {
        return response.data
      })
  },
  updateLessonDuration(weekPlanId, lessonId, duration){
    return axios.put("/lessons/" + lessonId + ".json", {week_plan_id: weekPlanId, lesson: {duration: duration}})
      .then(response => {
        return response.data
      })
  },
  updateLessonVisible(lessonId, isVisible, isVisibleForTeacher=true){
    return axios.put("/lessons/" + lessonId + ".json", {lesson: {visible_for_student: isVisible, visible_for_teacher: isVisibleForTeacher}})
      .then(response => {
        return response.data
      })
  },
  duplicateLesson(lessonId, groupId){
    return axios.get("/lessons/" + lessonId+ "/duplicate.json?group_id=" + groupId)
      .then(response => {
        return response.data
      })
  },
  addLessonToWeekPlan(week_plan_id, weekPlanDayId, courseId, position, isClockless){
    // console.log("adding to week_plan " + week_plan_id)
    return axios.post("/lessons.json", {week_plan_id: week_plan_id, lesson: {course_id: courseId, week_plan_day_id: weekPlanDayId, position: position, is_clockless: isClockless}})
      .then(response => {
        return response.data
      })
  },
  // deleteLesson(params.id, params.weekPlanId)
  deleteLesson(lessonId, weekPlanId){
    return axios.delete("/lessons/" + lessonId+ ".json?week_plan_id=" + weekPlanId)
      .then(response => {
        return response.data
      })
  },
  submitDayToWeekPlan (day, data){
    return true
    // return axios.put("/week_plan_days/" + day + ".json", {
    //   lessons: data
    // })
    // .then(response => {
    //   return response.data
    // })
  }
}