<template>
  <div >  
    <div class="container pt-3 mt-3 bg-white rounded shadow-sm">
      <div class="row">
        <div class="col-12 col-md-6 col-lg-5 mx-auto text-center">
          <h2>{{title}}</h2>
          <div class="text-center">
            <h6 class="p-0 m-0">Week {{weekNumber}}</h6>
            <span class="small">{{showDates}}</span>
          </div>
          <div class="card bg-light p-3 text-left" v-if="!extraTasks.length > 0">
            <p>Er is nog geen extra werk ingepland voor dit weekplan. De taken die je invoert als '{{title}}', worden getoond op de weektaken van de kinderen.</p>
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-12 col-md-10 col-lg-8 mx-auto">
          <div class="row">
            <div class="col pb-0 mb-0">
              <div class="form-group">
                <!-- <label for="lesson_method">Lesvorm</label> -->
                <select v-model="newExtraTask.course" class="form-control" id="extra_task_course">
                  <option selected disabled value="null">Kies een vak...</option>
                  <option selected value="nill">{{title}} zonder vak</option>
                  <option v-for="course in courses" :value="course.id">{{ course.name }}</option>
                </select>
              </div>
            </div>
            <div class="col pb-0 mb-0">
              <button @click="addExtraTask()" :disabled="newExtraTask.course === null" class="btn btn-block btn-success">Extra werk toevoegen <i class="fas fa-plus"></i></button>
            </div>
          </div>
        </div>
      </div>
    </div>



    <div class="container pt-3 mt-3 pb-4 bg-white rounded shadow-sm">

      <div class="row d-flex justify-content-center mt-3" v-if="extraTasks.length > 0">
        <div class="col-12 col-md-10 col-lg-8 mx-auto">
          
            <draggable  :options="{handle: '.drag-handle '}" @update="listChanged">
              <ExtraTask @refreshList="refreshPictoList" :lesson-methods="lessonMethods" :can_add_pictos="can_add_pictos" :pictos="pictos" :use_pictos="use_pictos" :groups="groups" :key="`task-${task.id}`" :task="task" v-for="task in extraTasks"></ExtraTask>
            </draggable>

        </div>
      </div>

    </div>
  </div>
</template>

<script>

import Vue from 'vue/dist/vue.esm'
import Weektaak from '../../packs/weektaak'
import ExtraTask from '../../components/settings/extraTask.vue'
import draggable from 'vuedraggable'

export default {
  components: {
    ExtraTask,
    draggable
  },
  props: ["id", "title", "groups", "use_pictos", "initial_pictos", "can_add_pictos", "lessonMethods", "weekNumber", "showDates"],

  data () {
    return {
      // extraTasks: [],
      pictos: [],
      newExtraTask: {course: null}
    }
  },
  computed: {
    extraTasks () {
      return window.store.getters.extraTasks
    },
    courses () {
      return window.store.getters.courses
    },
    weekPlan () {
      return window.store.getters.weekPlan
    },
  },
  methods: {
    refreshPictoList(list){
      // this.$emit('refreshList', list)
      this.pictos = list
    },
    listChanged(event) {
      Weektaak.updateExtraTaskIndex(event.item.dataset.extraTaskId, event.newIndex)

    },
    addExtraTask () {
      console.log("adding task")
      console.log(this.newExtraTask.course)
      window.store.dispatch('saveExtraTask', { week_plan_id: this.id, course_id: this.newExtraTask.course })  
    }
  },
  created () {
    this.pictos = this.initial_pictos
    window.store.dispatch('getExtraTasks', this.id)  
    window.store.dispatch('fetchCourses', { id: this.id })  
  },
  
}
</script>

<style scoped>
  .custom-select{
    width: auto;
    padding-top: 0px;
    padding-bottom: 0px;
    font-size: 1rem;
  }  
</style>
