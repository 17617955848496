<template>
  <div class="row">
    <div class="col-10 col-md-8 col-lg-6 mx-auto mt-3">
      <div class="card">
        <div class="card-header text-center mb-0">
          <h3 class="my-0">Inloggen</h3>
        </div>
        <div class="card-body text-center">
          <p class="lead">Laat je Weektaak sleutelhanger aan de camera zien.</p>
          <qrcode-stream :camera="camera"  @init="onInit" @decode="onDecode"></qrcode-stream>
          <img :src="image" width="200" style="display: none;" />
          <div class="alert alert-success text-center" v-if="success">
            {{ successMessage }}
          </div>              
          <div class="alert alert-danger text-center" v-if="showError">
            {{ errorMessage }}              </div>              

        </div>
      </div>
    </div>
  </div>

</template>

<script>

import axios from 'axios'
import { QrcodeStream, QrcodeDropZone, QrcodeCapture } from 'vue-qrcode-reader'

export default {
  components: {
    QrcodeStream
  },
  data () {
    return {
      isLoading: false,
      code: '',
      password: '',
      success: false,
      camera: 'front',
      showError: false,
      errorMessage: '',
      capabilities: {},
      successMessage: ''
    }
  },
  props: ["image"],
  mounted() {

    // if ('mediaDevices' in navigator && 'getUserMedia' in navigator.mediaDevices) {
    //   console.log("Let's get this party started")
    //   this.getDevices()
    // }
  },
  methods: {
    async getDevices() {
      this.capabilities = await navigator.mediaDevices.enumerateDevices()
    },
    async onInit (promise) {
      // show loading indicator

      try {
        // ...
        await promise
        console.log('ready')

        // successfully initialized
      } catch (error) {
        this.showError = true  
        if (error.name === 'NotAllowedError') {
          console.log("no permission")
          this.errorMessage = "Het gebruik van de camera is uitgeschakeld in deze browser voor Weektaak.com. "
          // user denied camera access permisson
        } else if (error.name === 'NotFoundError') {
          console.log("no camera")
          // no suitable camera device installed
          this.errorMessage = "Deze computer heeft geen camera om QR code's te scannen."
        } else if (error.name === 'NotSupportedError') {
          console.log("not supported")
          this.errorMessage = "Inloggen is via deze website op dit moment niet mogelijk"
          // page is not served over HTTPS (or localhost)
        } else if (error.name === 'NotReadableError') {
          console.log("not readable")
          // maybe camera is already in use
        } else if (error.name === 'OverconstrainedError') {
          console.log("front camera not found")
          this.camera = 'auto'
          this.showError = false
          // did you requested the front camera although there is none?
        } else if (error.name === 'StreamApiNotSupportedError') {
          console.log("browser doesn't support camera")
          // browser seems to be lacking features
        }
      } finally {
        // hide loading indicator
      }
    },
    onDecode(result) {
      this.code = result
      if (this.code.length == 5 || this.code.length == 8 || this.code.length == 6 || this.code.length == 12) {
        this.success = true  
        this.successMessage = "Even geduld..."
        this.signInStudent()
      } else {
        this.showError = true  
        this.errorMessage = "Deze QR-code hoort niet bij Weektaak.com"
        console.log(this.code)
        setTimeout(function () {
          this.showError = false
        }.bind(this), 4000)
      }
      
    },
    signInStudent() {
      return axios.post("/students/sign_in.json", {code: this.code})
        .then(response => {
          location.reload()
        }).catch( error => { 
          console.log("fout bij inloggen...")
          if (error.response.status == 401){
            this.success = false
            this.showError = true  
            this.errorMessage = "Ongeldige toegangscode"
            setTimeout(function () {
              this.showError = false
            }.bind(this), 4000)
          }
          console.log(error.response)
          console.log(error)
        })
    }
  }

}
</script>

<style lang="css" scoped>

.wrapper{
  
  width: 1px;
  height: 1px;
}

</style>