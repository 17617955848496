<template>
  <div class="row">
    <div class="col-12 col-md-8 col-lg-6 bg-white shadow-sm py-5 px-5 rounded login-form">
      <div v-show="step == 'username'">
        <div class="text-center mb-3">
          <h2>Wat is je gebruikersnaam?</h2>
        </div>
        <div class="input-group mb-3" :class="{'animated shake': showError}">
          <input v-on:keyup.enter="submitName" v-model="name" @input="onNameInput" maxlength="24" ref="nameInput" type="text" class="form-control name-input" placeholder="" data-lpignore="true" autocomplete="off" autofocus :name="randomNameInputName">
          <div class="input-group-append">
            <button @click="submitName" class="btn btn-success" type="button" v-bind:disabled="name == ''">Doorgaan</button>
          </div>
        </div>
        <div class="text-danger text-center" style="" v-if="showError">
          {{ errorMessage }}
        </div>
        <span class="form-text text-muted">Vul hier jouw gebruikersnaam in, bijvoorbeeld: <b>pietdeboer</b> of <b>anneke35</b></span>

      </div>
      <div v-if="step == 'animal'" class="mt-1">
        <button class="btn btn-lg btn-primary" @click="step = 'username'"><i class="fa fa-chevron-left"></i> Terug</button>
        <div class="text-center mb-3">
          <h2>Klik jouw dier aan</h2>
        </div>
        <div class="mx-auto d-flex justify-content-center">
          <i class="fa fa-spinner fa-spin" v-if="loadingAnimals"></i>
          <div v-if="!loadingAnimals">
            <div id="animal-grid">
              <div :id="`animal-${index}`" class="animal" v-for="(animal, index) in animals">
                <img @click="selectAnimal(animal)" class="w-100" :src="`/img/student-icons/PNG/256/${animal}.png`" style="cursor: pointer;" />
              </div>
            </div>
          </div>
        </div>

      </div>
      <div v-if="step == 'password'" class="mt-1">
        <button class="btn btn-lg btn-primary" @click="step = 'animal'"><i class="fa fa-chevron-left"></i> Terug</button>
        <div class="mx-auto d-flex justify-content-center" :class="{'animated shake': showError}">
          <div id="codeNumbers">
            <div></div>
            <div class="code-digit-outer">
              <div class="code-digit">
                <i class="fas fa-asterisk" v-if="password.length >= 1"></i>
              </div>
            </div>
            <div class="code-digit-outer">
              <div class="code-digit">
                <i class="fas fa-asterisk" v-if="password.length >= 2"></i>
              </div>
            </div>
            <div class="code-digit-outer">
              <div class="code-digit">
                <i class="fas fa-asterisk"  v-if="password.length >= 3"></i>
              </div>
            </div>
            <div class="code-digit-outer">
              <div class="code-digit">
                <i class="fas fa-asterisk" v-if="password.length >= 4"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="clearfix"></div>
        <div class="alert alert-danger mx-auto text-center" style="width: 360px;" v-if="showError">
          {{ errorMessage }}
        </div>
        <div v-if="success" class="text-center text-success">
          <img :src="`/img/student-icons/PNG/512/${selectedAnimal}.png`" width="128" />
          <br>
          inloggen gelukt!
        </div>
        <div id="keyboard" v-if="!showSpinner && !success">
          <keyboard @input="onInput" v-model="password" layouts="123|456|789|0" />
        </div>
        <div class="text-center" v-if="showSpinner">
          <i class="fas fa-spinner fa-spin fa-3x"></i>
        </div>

        <div class="clearfix"></div>
      </div>
    </div>    
  </div>
</template>

<script>
import axios from 'axios'
import keyboard from 'vue-keyboard'

export default {
  components: {

    keyboard
    // QrcodeDropZone,
    // QrcodeCapture
  },

  data () {
    return {
      nameEntered: false,
      passwordNumbers: [],
      name: '',
      isLoading: false,
      step: 'username',
      isLocked: true,
      animals: [],
      code: '',
      selectedAnimal: '',
      password: '',
      success: false,
      showError: false,
      errorMessage: '',
      successMessage: '',
      isFocused: false,
      showSubmitButton: false,
      loadingAnimals: true,
      showSpinner: false
    }
  },
  watch: {
    password: {
      handler: function(oldVal, newVal) {
        this.onInput()
      }
    }
  },
  computed: {
    completeUserName() {
      return `${this.name}-${this.selectedAnimal}@weektaak.com`
    },
    randomNameInputName() {
      return `student-name-${Date.now()}`
    },
    randomInputName() {
      return `student-pin-${Date.now()}`
    },
    randomAutocomplete() {
      return `off-${Date.now()}`
    }
  },
  methods: {
    signInStudent() {
      console.log('signing in')
      
      return axios.post("/students/sign_in.json", {
          student: {
            email: this.completeUserName.replace(/\s/g,''), 
            password: this.password
          }
        })
        .then(response => {
          console.log(response)
          this.success = true
          this.showSpinner = false
          // window.location = window.location
          // this.isLocked = false
          setTimeout(function () {
            // this.errorMessage = "Ongeldige toegangscode"
            this.success = true
            window.location = window.location
          }.bind(this), 600)
        }).catch( error => { 
          console.log("fout bij inloggen...")
          console.log(error.response.status)
          if (error.response.status == 401 || error.response.status == 500){
            this.success = false
            this.showError = true  
            this.errorMessage = "☹️ Wat je hebt ingevuld is niet juist. Probeer het opnieuw..."
            this.password = ''
            this.showSpinner = false
            this.name = ''
            this.step = 'username'
            this.$refs.nameInput.select()
            setTimeout(function () {
              // this.showError = 
              this.nameEntered = false
              
              this.showSpinner = false
            }.bind(this), 3000)
          }
          console.log(error.response)
          console.log(error)
        })
    },
    fetchAnimals() {
      this.loadingAnimals = true
      axios.post('/animals.json', {
        username: this.name
      }).then((response) => {
        this.loadingAnimals = false
        this.animals = response.data
        // TODO
      }).catch((error) => {
        console.error(error);
      }).finally(() => {
        // TODO
      });
    },
    submitName(){
      if (this.name != ''){
        this.nameEntered = true
        this.step = 'animal'
        this.showError = false
        this.fetchAnimals()
        // setTimeout(function () {
        //   this.$refs.passwordField.focus()
        //   // this.showReview = false
        // }.bind(this), 300)
        
      }
    },
    onNameInput(){
      return true
    },
    selectAnimal(animal) {
      this.selectedAnimal = animal
      this.showError = false
      this.step = 'password'
    },
    checkPassword() {
      if (this.password.length == 4) {
        this.submitPassword()
      }
    },
    submitPassword() {
      console.log("updating")  
      // this.showSubmitButton = false
      this.showSpinner = true
      // this.isFocused = false
      this.signInStudent()
    },
    onInput(res){
      this.checkPassword()
      console.log(res)
      if (this.password.length == 4){
        this.showSubmitButton = true
        // this.isLocked = false
        // this.isLoading = false
      } else if  (this.password.length > 4) {
        this.password = this.password.substring(0,4)
        this.showSubmitButton = true
      } else {
        this.showSubmitButton = false
        this.isLocked = true
      }
    },
    handleKeyDown(event){
      if (this.step == 'password') {
        if (event.key == "1") { this.password = this.password + '1' }
        if (event.key == "2") { this.password = this.password + '2' }
        if (event.key == "3") { this.password = this.password + '3' }
        if (event.key == "4") { this.password = this.password + '4' }
        if (event.key == "5") { this.password = this.password + '5' }
        if (event.key == "6") { this.password = this.password + '6' }
        if (event.key == "7") { this.password = this.password + '7' }
        if (event.key == "8") { this.password = this.password + '8' }
        if (event.key == "9") { this.password = this.password + '9' }
        if (event.key == "0") { this.password = this.password + '0' }
        this.checkPassword()
      }
      if (event.keyCode == 8){
        if (this.password.length) {
          this.password = this.password.substring(0, this.password.length-1)
        }
      } else if (event.keyCode == 38 || event.keyCode == 27){
        this.nameEntered = false
      }
    }
  },
  mounted() {
    window.addEventListener("keyup", this.handleKeyDown);
  },
  destroyed () {
    window.removeEventListener("keyup", this.handleKeyDown)
  }
}
</script>

<style lang="css">

.student-password-field{
  font-size: 30px;
  letter-spacing: 10px;
  height: 50px;
  text-align: center;
}

#keyboard button.vue-keyboard-key {
  font-size: 28pt;
  width: 90px;
  margin: 6px;
}
.vue-keyboard-key.action{
  background-color: #333;
}
.vue-keyboard-key:active {
  
}

.name-input{
  font-size: 24pt;
  text-align: center;
}

.lock-top{
  fill: none;
  stroke: #02ac1e;
  stroke-width: 5;
  stroke-linecap: round;
  stroke-miterlimit: 10;
  stroke-dasharray: 300;
  stroke-dashoffset: -30;
  transition: all 300ms ease-out;
}
.lock-top.loaded {
  stroke-dashoffset: 0;
  stroke: #fa113d
}
.lock-top.loaded ~ .lock-body {
  fill: #fa113d;
}
.lock-top.loading {
  stroke: #000;
  opacity: 0.3;
}

.lock-outline{
  fill: #fff;
}
.lock-body {
  fill: #02ac1e;
  opacity: 1;
  transition: all 300ms ease-out;
}
.lock-body.loading{
  fill: #000 !important;
  opacity: 0.3;
}

.lock-spinner {
  fill: none;
  stroke: #000;
  stroke-width: 5;
  stroke-linecap: round;
  stroke-miterlimit: 10;
  opacity: 0;
  transition: opacity 200ms ease;
}
.lock-spinner.loading{
  opacity: 1;
}
.login-form{
  position: absolute; top: 50%; left: 50%; transform: translate(-50%,-50%);
}

#animal-grid {
  height: 400px;
  width: 400px;
  display: grid;
  grid-template-columns: 100px 100px 100px 100px;
  grid-template-rows: 100px 100px 100px 100px;
}

#animal-grid .animal {
  padding: 5px;
}

#codeNumbers {
  display: grid;
  /*background: blue;*/
  width: 350px;
  height: 70px;
  grid-template-columns: 1fr 77px 77px 77px 77px 1fr;
  grid-template-rows: 1fr;
  margin-bottom: 10px;
}
.code-digit-outer {
  padding: 6px;
}
.code-digit{
  text-align: center;
  cursor: auto;
  background-image: linear-gradient(to bottom,#c6d89d,#b0c286);
  background-color: #c6d89d;
  box-shadow: inset 0 0 0.4rem rgba(0,0,0,.25);
  border: 1px solid #7f7f7f;
  padding-top: 8px;
  padding-bottom: 0px;
  font-family: monospace;
  font-size: 30px;
  font-weight: 900;
  white-space: nowrap;
  transition: opacity .2s;
  border-radius: .4rem;
  background-clip: padding-box;
  height: 60px;
  
}
</style>