<template>

  <div>
    <div class="modal-body py-0">
      <div v-show="!showFileUpload" class="w-100 chat-view py-2" v-bind:class="{'user-scope' : scope == 'user'}" v-chat-scroll="{always: false, smooth: false, scrollonremoved:true}">
        <div v-if="showLoading"></div>
        <chatMessage :scope="scope" :message="message" :key="`message-${message.id}`" v-for="message in weekPlanMessages" v-if="weekPlanMessages.length && !showFileUpload"></chatMessage>
        <center v-if="!weekPlanMessages.length && scope == 'student' && !showFileUpload"><em>Hier kun je een berichtje schrijven naar de juf of meester.</em></center>
        <center v-if="!weekPlanMessages.length && scope == 'user' && !showFileUpload"><em>Hier kun je een berichtje schrijven naar {{weekPlan.student.first_name}}.</em></center>

      </div>    
      <div v-if="showFileUpload && weekPlan" class="w-100 chat-view py-2">
        <fileUploader @upload="uploadedFile"  @cancel="showFileUpload = false" :url="`/message-attachment?student_week_plan_id=${weekPlan.id}&scope=${scope}`" />
      </div>
    </div>
    <div class="modal-footer bg-light d-block py-1" v-if="!showFileUpload">
      <div class="input-group w-100 mb-1">
        <textarea-autosize :min-height="32" :max-height="120" v-model="newMessage" ref="messageBox" rows="1" type="text" class="form-control" placeholder="Type hier jouw bericht"></textarea-autosize>
        <div class="input-group-append" v-if="use_fileupload">
          <button @click="showFileUpload = true" class="btn btn-success" type="button"><i class="fas fa-paperclip"></i></button>
        </div>
        <div class="input-group-append">
          <button @click="saveMessage" v-bind:disabled="newMessage == '' || this.isDisabled" class="btn btn-success" type="button">Verstuur</button>
        </div>
      </div>
      <emoji-picker @emoji="insert">
        <div slot="emoji-invoker" class="text-left" slot-scope="{ events: { click: clickEvent } }" @click.stop="clickEvent">
          <button class="btn btn-sm btn-outline-primary mr-auto">💬 Emoji toevoegen 🎉</button>
        </div>
        <div slot="emoji-picker" class="emoji-picker" slot-scope="{ emojis, insert, display }">
          <div class="sticky-top p-3" style="background: rgba(0,0,0,0.8);">
            <input v-model="newMessage" ref="messageBoxEmoji" type="text" class="form-control" placeholder="Type hier jouw bericht">
          </div>
          <div>
            <div>
              <div v-for="(emojiGroup, category) in emojis" :key="category">
                <hr>
                <div class="emojis">
                  <span
                    v-for="(emoji, emojiName) in emojiGroup"
                    :key="emojiName"
                    @click="insert(emoji)"
                    :title="emojiName"
                  >{{ emoji }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </emoji-picker>

    </div>
  </div>

</template>

<script>

import axios from 'axios'
import EmojiPicker from 'vue-emoji-picker'
import chatMessage from '../../components/digital/chatMessage.vue'
import fileUploader from '../../components/digital/fileUploader.vue'
export default {

  components: {
    chatMessage, 
    EmojiPicker,
    fileUploader
  },
  data () {
    return {
      showFileUpload: false,
      showLoading: true,
      isDisabled: false,
      previousWeekPlanMessages: [],
      newMessage: '',
      emojiExpanded: false
    }
  },
  props: ["uuid", "studentweekplanday", "scope", "loading", "use_fileupload"],
  watch: {
    'loading': function(val, oldVal) {
      this.initialize()
    }
  },
  computed: {
    weekPlan (){
      return window.store.getters.digitalWeekPlan
    },
    weekPlanMessages (){
      return window.store.getters.digitalWeekPlanMessages
    }
    // previousWeekPlanMessages (){
    //   return window.store.getters.previousDigitalWeekPlanMessages
    // }
  },
  mounted(){
    this.markAllAsRead()
    // return axios.get(`/student_week_plans/${this.weekPlan.id}/previous_messages.json`)
    //   .then(response => {
    //     console.log(response.data)
    //     this.previousWeekPlanMessages = response.data
    //     // window.store.dispatch('setDigitalWeekPlanMessages', response.data)
    //   })
    // window.store.dispatch('setPreviousDigitalWeekPlanMessages', this.studentweekplan.chat.previous_messages)
  },
  created(){
  },
  methods: {
    uploadedFile(data){
      this.showFileUpload = false
      window.store.dispatch('setDigitalWeekPlanMessages', data)
    },
    hideEmoji() {
      this.emojiExpanded = false
      this.$refs.messageBox.$el.focus()
    },
    insert(emoji) {
      this.newMessage += emoji
      this.$refs.messageBox.$el.focus()
    },
    initialize(){
      console.log("init chat")
      setTimeout(function () {
        this.showLoading = false
        setTimeout(function () {
          this.$refs.messageBox.$el.focus()
        }.bind(this), 200)
        setTimeout(function () {
          this.markAllAsRead()
        }.bind(this), 1200)
      }.bind(this), 300)
      
      // this.showLoading = false
    },
    markAllAsRead(){
      // console.log("mark messages as read")
      // console.log(this.weekPlan.student.id)
      // window.store.dispatch('fetchChatMessagesForUserChat', {uuid: this.uuid, student_id: this.weekPlan.student.id})
      return axios.post("/messages.json", {student_week_plan_id: this.weekPlan.id, mark_all_as_read: this.scope })
        .then(response => {
          // console.log("marked as read")
          window.store.dispatch('setDigitalWeekPlanMessages', response.data)
        })
        .catch(error => {
          console.log('error marking as read')
          console.log(error)
        })
    },
    saveMessage() {
      // console.log(`saving message: ${this.newMessage}`)
      if (this.isDisabled == false){
        this.isDisabled = true
        // window.store.dispatch('addDigitalWeekPlanMessage', this.newMessage)
        return axios.post("/messages.json", {message: this.newMessage, student_week_plan_id: this.weekPlan.id })
        .then(response => {
          this.newMessage = ''
          this.isDisabled = false
          this.$refs.messageBox.$el.focus()
          window.store.dispatch('setDigitalWeekPlanMessages', response.data)
        })
        .catch(error => {
          if (this.scope == 'user') {
            alert(`Chat kon niet verstuurd worden. Probeer het over enkele ogenblikken nog een keer. Melding: ${error}`)

          }
          this.isDisabled = false
          console.log('error saving')
          console.log(error)
        })
      }
    }
  }

}
</script>

<style lang="css" scoped>
.chat-view{
  max-height: calc(100vh - 275px);
  overflow-y: auto;
  overflow-x: hidden;
}
.chat-view.user-scope{
  max-height: calc(100vh - 375px);
}

.emoji-picker {
  width: 100%;
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100%;
  max-height: 50vh;
  overflow-y: auto;
  background: rgba(0,0,0,0.8);
  z-index: 999;
}

.emoji-picker__search {
  display: flex;
}
.emoji-picker__search > input {
  flex: 1;
  border-radius: 10rem;
  border: 1px solid #ccc;
  padding: 0.5rem 1rem;
  outline: none;
}
.emoji-picker h5 {
  margin-bottom: 0;
  color: #b1b1b1;
  text-transform: uppercase;
  font-size: 0.8rem;
  cursor: default;
}
.emoji-picker .emojis {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.emoji-picker .emojis:after {
  content: "";
  flex: auto;
}
.emoji-picker .emojis span {
  padding: 0.2rem;
  cursor: pointer;
  border-radius: 5px;
  font-size: 32px;
}
.emoji-picker .emojis span:hover {
  background: #ececec;
  cursor: pointer;
}
</style>
