<template>
  <tr>
    <th width="100">
      <time class="icon">
        <em>{{ event.week_day }}</em>
        <strong>{{ event.show_date }}</strong>
        <span>{{ event.day }}</span>
      </time>
    </th>
    <td align="left" style="vertical-align: middle;">
      <h5>{{ event.title }}</h5>
      <p class="small mb-0" style="font-weight: 700;">{{ event.date_string }}</p>
      <p v-if="event.description && event.description.length">{{ event.description }}</p>
    </td>
    <td align="right" style="vertical-align: middle;">
      <button @click="deleteEvent" class="btn btn-danger btn-sm"><i class="fa fa-trash"></i></button>
    </td>
    
  </tr>
</template>

<script>
export default {

  name: 'YearPlanningEvent',
  props: ['event'],
  data () {
    return {

    }
  },
  methods: {
    deleteEvent() {
      this.$emit('delete', this.event)
    }
  }
}
</script>

<style lang="css" scoped>
</style>