<template>
  <div class="col p-1" v-bind:class="{'col-6' : isSelected}">
    <div class="card mb-2" v-show="day.day != undefined">
      <div class="card-body">
        <div class="d-flex justify-content-between">
          <h5 class="card-title">{{day.day}} <span class="small text-muted">{{ day.short_show_date}}</span></h5>
          <toggle-button :value="day.is_active" :sync="true" data-toggle="tooltip" data-placement="top" :title="`Is er les op ${day.day}?`" @change="toggleState"/>
        </div>
        <div class="form-group">
          <textarea v-model="day.comment"  @change="saveChanges" :placeholder="`Opmerking voor ${day.day}.`" rows="4" class="form-control"></textarea>
          <small class="form-text text-muted">Zichtbaar voor leerkracht</small>
        </div>
        <div v-show="!day.is_active" class="form-group">
          <textarea  v-model="day.reason_for_inactive"  @change="saveChanges" :placeholder="`Waarom is er op ${day.day} geen les?`" rows="4" class="form-control"></textarea>
          <small class="form-text text-muted">Verschijnt op de weektaken</small>
        </div>
      </div>
    </div>
  </div>    
</template>

<script>

import Weektaak from '../../packs/weektaak'

export default {
  props: ["day"],

  data () {
    return {
      isSelected: false
    }
  },
  computed: {

  },
  methods: {
    toggleState() {
      this.day.is_active = !this.day.is_active
      this.saveChanges()
    },
    saveChanges() {
      Weektaak.updateWeekPlanDay(this.day.id, {
        is_active: this.day.is_active, 
        reason_for_inactive: this.day.reason_for_inactive, 
        comment: this.day.comment
      })
    }
  },
  created() {
    // document.querySelector('[data-toggle="tooltip"]').tooltip()
  }

  
}
</script>

<style scoped>
  
</style>
