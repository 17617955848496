<template>
  <div class="container-fluid" id="week-plan" :key="`week-plan-${weekPlanPerCourse.id}-${weekPlanPerCourse.updated_at}`">

    <button v-if="activeCourse && activeCourse.groups && activeCourse.groups.length > 1" class="btn" v-bind:class="{'btn-success': linkMode, 'btn-secondary': !linkMode}" @click="linkMode = !linkMode" style="position: fixed; bottom: 20px; right: 20px; z-index: 999; ">
      <span v-if="!linkMode"><i class="fas fa-link"></i> Lesstof koppelen</span>
      <span v-if="linkMode"><i class="fas fa-link"></i> Klaar met koppelen <i class="far fa-check-circle"></i></span>
    </button>
    <div class="row" v-if="!courses.length">
      <div class="col p-5 text-center text-muted">
        <i class="fa fa-spinner fa-spin"></i>
      </div>
    </div>
    <div class="row" v-if="courses.length">
      <div class="col-3 col-lg-2 pr-0 bg-light pb-2 px-0 pt-2" style="border-right: none; ">
        <div class="text-center">
          <h6 class="p-0 m-0">Week {{weekNumber}}</h6>
          <span class="small">{{showDates}}</span>
        </div>
        <div role="tablist" aria-orientation="vertical" class="pl-3 mt-1 pt-1">
          <button 
            :key="`course-btn-${course.id}`" 
            class="btn btn-sm btn-block text-left course-btn" 
            @click="selectCourse(course)"
            style="border-top-right-radius: 0; border-bottom-right-radius: 0;"
            v-bind:class="{'btn-white': isActiveCourse(course), 'shadow': isActiveCourse(course), 'btn-light': !isActiveCourse(course)}"
            v-for="course in courses">
            {{ course.name }}
          </button>
          <!-- <button 
            :key="`course-btn-clockless`" 
            class="btn btn-sm btn-block text-left course-btn" 
            @click="selectCourse(clocklessCourse)"
            style="border-top-right-radius: 0; border-bottom-right-radius: 0;"
            v-bind:class="{'btn-white': isActiveCourse(clocklessCourse), 'shadow': isActiveCourse(clocklessCourse), 'btn-light': !isActiveCourse(clocklessCourse)}"
            v-if="weekPlanPerCourse.has_clockless_lessons">
            {{ clocklessCourse.name }}
          </button>
           -->
        </div>
      </div>
      <div class="col p-0 bg-white shadow" style="-webkit-box-shadow: -3px 0px 28px 0px rgba(0,0,0,0.75);
-moz-box-shadow: -3px 0px 28px 0px rgba(0,0,0,0.75);
box-shadow: -3px 0px 28px 0px rgba(0,0,0,0.75);">
        <div class="p-3">
          <div class="row">
            <div class="col-12 text-center" v-if="selectedCourseId == null">
              <div v-if="courses.length > 1">
                <p class="mt-3 text-muted">Klik één van de vakken aan en vul vervolgens per vak de lesstof in.</p>
                <img :src="arrow" style="position
                : absolute; height: 200px; transform: rotate(209deg); top: -20px; left: -40px;"/>
              </div>
              <div v-if="!courses.length > 1">
                <p class="mt-4 text-muted">Maak eerst de <a :href="`/week_plans/${id}`">weekplanning</a>. Als je alle vakken ingepland hebt, kun je op deze plek eenvoudig de lesstof per vak invullen op de verschillende niveau's.</p>
              </div>
            </div>
            <div class="col-12" v-if="linkMode && activeCourse &&activeCourse.groups.length > 1">
              <div class="alert alert-success" role="alert">
                <span v-if="activeCourse.groups.length == 2">
                  Als de lesstof voor {{ activeCourse.name }} voor beide groepen precies hetzelfde is, kun je de lesstof van deze lessen aan elkaar koppelen. Op deze manier hoef je de stof maar één keer in te plannen.
                </span>
                <span v-if="activeCourse.groups.length > 2">
                  Als de lesstof voor {{ activeCourse.name }} voor meerdere groepen precies hetzelfde is, kun je de lesstof van deze lessen aan elkaar koppelen. Op deze manier hoef je de stof maar één keer in te plannen.
                </span>
              </div>
            </div>
            


<!--               <div class="col-12 col-md" :key="`group-clockless-${group.id}`" style="max-width: 480px;" v-for="group in weekPlanPerCourse.clockless_lessons.groups" v-if="activeCourse == clocklessCourse">
                <div class="text-center"><h5>{{ group.name }}</h5></div>
                <PerCourseLesson :lessonObject="lesson" :show_comment_field="show_comment_field" :course="activeCourse" :linkMode="linkMode" :key="`lesson-${lesson.id}-${lesson.updated_at}`" :lesson_methods="lesson_methods" :lesson_id="lesson.id" :day="lesson.day" v-for="lesson in group.lessons"></PerCourseLesson>
              </div>
 -->


              <div v-if="activeCourse" class="col-12 col-md" :key="`group-${group.id}`" style="max-width: 480px;" v-for="group in activeCourse.groups">
                <div class="text-center"><h5>{{ group.name }}</h5></div>
                <div :key="`group-${group.id}-${day.day}-${activeCourse.id}-${weekPlanPerCourse.updated_at}`" v-for="day in group.week_plan_days">
                  <PerCourseLesson :clockless="clockless" :show_comment_field="show_comment_field" :course="activeCourse" :linkMode="linkMode" :key="`lesson-${lesson.id}-${lesson.updated_at}`" :lesson_methods="lesson_methods" :lesson_id="lesson.id" :day="day" v-for="lesson in day.lessons"></PerCourseLesson>
                </div>
              </div>

          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>

import PerCourseLesson from '../../components/week_plans/perCourseLesson.vue'
export default {

  name: 'weekPlanPerCourse.vue',
  watch: {
    courses: {
      handler: function(val, oldVal) {
        // console.log(this.courses.length)
        if (this.courses.length == 1) {
          this.selectCourse(this.courses[0])
        }
      }
    }
  },
  components: {PerCourseLesson},
  props: ["id", "lesson_methods", "show_comment_field", "arrow", "weekNumber", "showDates", "clockless"],
  data () {
    return {
      selectedCourseId: null,
      linkMode: false,
      clocklessCourse: {id: -1, name: 'Lessen zonder tijd'}
    }
  },
  computed:{
    activeCourse(){
      if (this.selectedCourseId){
        return this.selectedCourse
      // } else if (this.courses.length){
      //   return this.courses[0]
      } else if (this.selectedCourseId == 0) {
        return this.clocklessCourse
      } else {
        return {name: ''}
      }
    },
    selectedCourse(){
      if (this.selectedCourseId){
        if (this.selectedCourseId == this.clocklessCourse.id) {
          return this.clocklessCourse
        }
        for (var i = this.courses.length - 1; i >= 0; i--) {
          let c = this.courses[i]
          if (c.id === this.selectedCourseId) {
            return c
          }
        }
      }
    },
    weekPlanPerCourse(){
      return window.store.getters.weekPlanPerCourse
    },
    courses(){
      return this.weekPlanPerCourse.courses
    }
  },
  methods: {
    selectCourse(course){
      this.selectedCourseId = course.id
    },
    isActiveCourse(course){
      if (this.selectedCourseId){
        return this.selectedCourseId == course.id
      // } else if (this.courses.length) {
      //   return this.courses[0] == course
      } else {
        return false
      }
      
    }
  },
  mounted() {

  },
  created(){

    window.store.dispatch('fetchWeekPlanPerCourse', {id: this.id, clockless: this.clockless})
      .then((result) => {
        // 
        
      }).catch(error => {
        // console.log("foutje")
        console.log(error)
      })
  }
}
</script>

<style lang="css" scoped>
.btn-white{
  z-index: 888;
  background-color: #fff !important;
  position: relative;
  box-shadow: -6px 0 6px rgba(0,0,0,.15)!important
}
.course-btn {
  background: #eaeaea;
}

.course-btn:hover {
  background-color: #e2e6ea;
  border-right: 0px;
  border-color: #dae0e5;
}
</style>