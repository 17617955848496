<template>
  <div class="col-sm day-column p-0" v-bind:class="{'bg-secondary': !day.is_active}">
    <div class="wpd-inner wpd-clock px-1" :class="{'showClockless' : showClockless}" v-show="!showClockless">
      <draggable 
        class="content"  
        style="" 
        data-is-clockless="false" 
        :data-week-plan-day-id="day.id" 
        handle='.draggable-lesson' 
        ghostClass='lesson-drag' 
        :group="{name: draggableGroupName(false)}" 
        @start="showTrash" 
        @end="hideTrash" 
        @update="listChanged" 
        @remove="removedFromList"
      >
        <div v-if="lessons.length == 0 && day.is_active && !draggingNewLesson && !showingTrash" class="text-center text-muted small">
          Er zijn nog geen lessen gepland voor {{day.day.toLowerCase()}}.
        </div>
        <lesson v-for="lesson in lessons"
          :is-loading="isLoading"
          :lesson="lesson"
          :key="`lesson-${lesson.id}`"
          :lessonMethods="lessonMethods"
          :usecolor="usecolor"
          :data-lesson-id="lesson.id"
          :weekPlanDay="day"
          :weekPlanId="weekPlanId"
          >  
        </lesson>
      </draggable>
    </div>
    <div class="wpd-inner wpd-clockless px-1" :class="{'showClockless' : showClockless}" v-show="showClockless">
      <draggable 
        class="content"
        style="" 
        :data-is-clockless="true" 
        :data-week-plan-day-id="day.id" 
        handle='.draggable-lesson' 
        ghostClass='lesson-drag' 
        :group="{name: draggableGroupName(true)}" 
        @start="showTrash" 
        @end="hideTrash" 
        @update="listChanged" 
        @remove="removedFromList"
      >
        <lesson v-for="lesson in clocklessLessons"
          v-show="showClockless"
          :is-loading="isLoading"
          :lesson="lesson"
          :key="`clockless-lesson-${lesson.id}`"
          :lessonMethods="lessonMethods"
          :usecolor="usecolor"
          :data-lesson-id="lesson.id"
          :weekPlanDay="day"
          :weekPlanId="weekPlanId"
          >  
        </lesson>
      </draggable>
    </div>
  </div>
</template>

<script>



import Weektaak from '../../packs/weektaak'
import draggable from 'vuedraggable'



export default {
  props: ["day", "lessonMethods", "weekPlanId", "usecolor", "isLoading", "showClockless"],
  components: {
    draggable
  },

  data () {
    return {
      lessonList: [],
      // showClockless: true
    }
  },
  // watch: {
  //   lessons: {
  //       handler: function(val, oldVal) {
  //         this.lessonsList = []
  //           // this.show = val;
  //           // console.log("lessons changed!")
  //         this.lessonList = this.lessons
  //       }
  //   }
  //   // 'lessons': (val, oldVal) => {
    
  //   // }
  // },
  methods: {
    draggableGroupName(extra){
      if (window.store.getters.draggingNewLesson){
        if (extra) {
          return "week_plans"
        } else {
          return "week_plans"
        }
      } else {
        if (extra) {
          return "week_plan_" + this.day.week_plan_id  
        } else {
          return "week_plan_" + this.day.week_plan_id  
        }
      }
      
    },
    showTrash (event){
      store.dispatch('showTrash')
    },
    hideTrash (event){
      store.dispatch('hideTrash')
    },
    listChanged (event) {
      // console.log('listChanged triggered')
      // .map(lesson => lesson.dataset.lessonId)
      let lessons = event.to.children
      lessons = Array.from(lessons).map(lesson => lesson.dataset.lessonId)
      // console.log(lessons)
      // console.log(event.to.dataset)
      Weektaak.updateLessonIndex(
        this.weekPlanId, 
        event.item.dataset.lessonId, 
        event.newIndex, 
        event.to.dataset.isClockless,
        lessons
      )
    },
    removedFromList (event) {

      if (event.to.dataset.weekPlanDayId == undefined) {
        // lesson will be deleted
      } else {
        // console.log('removedFromList triggered')
        Weektaak.moveLessonToDayAndIndex(
          this.weekPlanId,
          event.item.dataset.lessonId,      // lesson id from data attribute
          event.to.dataset.weekPlanDayId,   // week_plan_day_id from data attribute
          event.to.dataset.isClockless,
          event.newIndex
        )
      }
      
    },
    // lessonList () {
    //   return this.day.lessons //.sort((a, b) => (a.position > b.position) ? 1 : -1)
    // },
  },
  created(){
    // this.lessonList = this.day.lessons
  },
  computed: {
    showingTrash (){
      return window.store.getters.showWeekPlanTrash
    },
    lessons () {
      return this.day.lessons //.sort((a, b) => (a.position > b.position) ? 1 : -1)
    },
    clocklessLessons () {
      return this.day.clockless_lessons
    },
    draggingNewLesson () {
      return store.getters.draggingNewLesson
    },
    groupsToShow() {
      return store.getters.groupsToShow
    }
  },


}
</script>

<style scoped>

.content {
  padding-top: 6px;
  height: 100%;
}


.day-header{
  height: 40px;

}
.wpd-inner {
  min-height: 100%;
}

.wpd-inner .content {
  min-height: calc(100vh - 180px );
}
</style>
