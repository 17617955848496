<template>
  <div>
    <div class="row">
      <div class="col-12 mx-auto">
        <div class="list-group">

          <div class="list-group-item">
            <div class="d-flex justify-content-between">
              <span>Toon digitale weektaak functie</span>
              <span><toggle-button @change="updateUser" v-model="show_digital" :sync="true" /></span>
            </div>
          </div>


          <div class="list-group-item">
            <div class="d-flex justify-content-between">
              <span>Toon logboek functie</span>
              <span><toggle-button @change="updateUser" v-model="show_log" :sync="true" /></span>
            </div>
          </div>

          <div class="list-group-item">
            <div class="d-flex justify-content-between">
              <span>Toon zorgafspraken functie</span>
              <span><toggle-button @change="updateUser" v-model="show_planned_notes" :sync="true" /></span>
            </div>
          </div>

          <div class="list-group-item" v-if="show_planned_notes">
            <div class="row">
              <div class="col-7 my-auto">
                <span>Naamgebruik van leerlingen bij zorgafspraken</span>
              </div>
              <div class="col-5">
                <select @change="updateUser" v-model="student_naming" class="form-control">
                  <option value="full_name">Volledige voor en achternaam</option>
                  <option value="first_name_only">Alleen voornaam</option>
                  <option value="first_name_short_last_name">Volledige voornaam en initialen achternaam</option>
                  <option value="initials">Initialen</option>
                  
                </select>
              </div>
            </div>
          </div>

          <div class="list-group-item">
            <div class="d-flex justify-content-between">
              <span>Toon picto functie</span>
              <span><toggle-button @change="updateUser" v-model="show_older_picto" :sync="true" /></span>
            </div>
            <small class="mb-1" v-if="show_older_picto">
              Wanneer je deze optie hebt aangevinkt, kun je pictos beheren. Bij <a href="/settings/courses">Vakken</a> kun je nu ook een picto koppelen aan bepaald vak en daarbij per groep een afwijkende picto kiezen. <b>Tip:</b> upload je eigen pictos, zoals de voorkant van je methode. Eigen pictos kun je bij <a href="/setttings/pictos">Pictogrammen</a> beheren.
            </small>
          </div>

          <div class="list-group-item">
            <div class="d-flex justify-content-between">
              <span>Gebruik kleuterweektaken</span>
              <span><toggle-button @change="updateUser" v-model="show_picto" :sync="true" /></span>
            </div>
            <small class="mb-1" v-if="show_picto">
              Wanneer je deze optie hebt aangevinkt, zie je een extra optie je weekplanning: <kbd><i class="fas fa-shapes"></i> Pictoplan</kbd>. In het pictoplan kun je kleuterweektaken of dagritmekaarten maken.
            </small>
          </div>

          <div class="list-group-item">
            <div class="d-flex justify-content-between">
              <span>Sorteer leerlingen op achternaam</span>
              <span><toggle-button @change="updateUser" v-model="sort_by_last_name" :sync="true" /></span>
            </div>
          </div>

          <div class="list-group-item">
            <div class="d-flex justify-content-between">
              <span>Gebruik de verjaardagsfunctie</span>
              <span><toggle-button @change="updateUser" v-model="use_birthdays" :sync="true" /></span>
            </div>
            <small class="mb-1" v-if="use_birthdays">
              Wanneer je nu naar de <a href="/settings/groups">instellingen voor groepen</a> gaat en op 'bewerk' klikt bij een groep, kun je bij iedere leerling de verjaardag invullen. Op de weektaken krijgen de leerlingen dan automatisch een feestelijk versierde weektaak op de verjaardag.
            </small>
          </div>

          <div class="list-group-item">
            <div class="d-flex justify-content-between">
              <span>Toon kleuren bij de vakken</span>
              <span><toggle-button @change="updateUser" v-model="use_color" :sync="true" /></span>
            </div>
            <small class="mb-1" v-if="use_color">
              Wanneer je nu naar de <a href="/settings/courses">instellingen voor vakken</a> gaat, kun je bij ieder vak de kleur aanpassen. <b>Let op:</b> de ingestelde kleuren zijn voor de hele school hetzelfde.
            </small>
          </div>

          <div class="list-group-item">
            <div class="d-flex justify-content-between">
              <span>Toon extra opmerkingveld voor de leerkracht bij iedere les</span>
              <span><toggle-button @change="updateUser" v-model="use_teacher_comment" :sync="true" /></span>
            </div>
            <small class="mb-1" v-if="use_teacher_comment">
              Bij het inplannen van de lesstof krijg je naast 'taak' en 'doel' nog een derde veld te zien: 'opmerking'. Deze opmerking kun je gebruiken om aanvullende tekst bij de lesstof te schrijven, die alleen getoond wordt op het weekplan voor de leerkracht. 
            </small>
          </div>

          <div class="list-group-item">
            <div class="d-flex justify-content-between">
              <span>Plan ook lesstof zonder rooster</span>
              <span><toggle-button @change="updateUser" v-model="use_clockless" :sync="true" /></span>
            </div>
            <small class="mb-1" v-if="use_clockless">
              Op het normale lesrooster hebben alle lessen een begin- en eindtijd. Wil je daarnaast ook lessen inplannen die geen onderdeel zijn van je lesrooster? Als deze functie is ingeschakeld, kun je die lesstof ook toevoegen. Dat kan bij het inplannen van het rooster, door op de knop <kbd><i class="fas fa-chevron-up"></i> Lesstof zonder rooster</kbd> te drukken. Ze verschijnen dan wel op de weektaak en op het weekplan, maar staan los van de tijd.
            </small>
          </div>


        </div>
      </div>
    </div>

  </div>
</template>

<script>

import Weektaak from '../../packs/weektaak'
  
export default {
  props: ["user", "colors"],
  data () {
    return {
      use_color: false,
      student_naming: 'full_name',
      show_digital: false,
      show_log: false,
      show_older_picto: false,
      show_picto: false,
      use_birthdays: false,
      show_planned_notes: true,
      sort_by_last_name: false,
      use_teacher_comment: false,
      use_clockless: false
    }
  },
  methods: {
    updateUser(){

      if (this.show_planned_notes){
        $("#planned_notes_tab").removeClass("d-none")
      } else {
        $("#planned_notes_tab").addClass("d-none")
      }

      if (this.show_picto || this.show_older_picto){
        $("#picto-tab").removeClass("d-none")
      } else {
        $("#picto-tab").addClass("d-none")
      }
      
      Weektaak.saveUserPreference({ user: 
        {
          use_birthdays: this.use_birthdays, 
          use_colors: this.use_color, 
          show_digital: this.show_digital, 
          show_log: this.show_log, 
          sort_students_by_last_name: this.sort_by_last_name, 
          show_picto: this.show_picto,
          show_older_picto: this.show_older_picto,
          use_teacher_comment: this.use_teacher_comment,
          show_planned_notes: this.show_planned_notes,
          use_clockless: this.use_clockless,
          student_naming: this.student_naming
        }
      })  
    }
    
  },
  created(){
    this.use_color = this.colors
    this.show_planned_notes = this.user.show_planned_notes
    this.show_digital = this.user.show_digital
    this.show_log = this.user.show_log
    this.show_picto = this.user.show_picto
    this.use_birthdays = this.user.use_birthdays
    this.sort_by_last_name = this.user.sort_students_by_last_name
    this.use_teacher_comment = this.user.use_teacher_comment
    this.show_older_picto = this.user.show_older_picto
    this.use_clockless = this.user.use_clockless
    this.student_naming = this.user.student_naming
  }

}
</script>

<style lang="css" scoped>
</style>