<template>
  <div>
    <h4>Groep: {{group.name}}</h4>
    <table  class="small table table-striped table-hover">
      <thead>
        <tr>
          <th><input class="form-input-check" type="checkbox" v-model="selectAll" @change="setSelectAll" :indeterminate.prop="isIndeternminate"></th>
          <th>Voornaam</th>
          <th>Achternaam</th>
          <th>Groep</th>
          <th>Jaar</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="student in group.students">
          <td  class="py-0">
            <input class="form-input-check" type="checkbox" v-model="theSelectedStudents" :value="student.number" @change="emitChange">
          </td>
          <td class="py-0 ">{{ student.first_name }}</td>
          <td class="py-0 ">{{ student.last_name }}</td>
          <td class="py-0 ">{{ student.group_name }}</td>
          <td class="py-0 ">{{ student.group }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {

  name: 'StudentImportGroup',
  props: ['group', 'selectedStudents'],
  data () {
    return {
      theSelectedStudents: [],
      selectAll: true
    }
  },
  watch: {
    selectedStudents: {
      handler: function(newVal, oldVal) {
        // this.onInput()
        this.theSelectedStudents = newVal    
      }
    }
  },
  created() {
    this.theSelectedStudents = this.selectedStudents
  },
  computed: {
    isIndeternminate() {
      // return true
      if ((this.selectedGroupStudentIds.length == this.studentIds.length) || (this.selectedGroupStudentIds.length == 0)) {
        return false
      } else {
        return true
      }
    },
    selectedGroupStudentIds() {
      return this.studentIds.filter((studentId) => { return this.selectedStudents.includes(studentId) })
    },
    studentIds() {
      return this.group.students.map(s => s.number)
    }
  },
  methods: {
    setSelectAll() {
      if (this.selectAll) {
        for (var i = this.studentIds.length - 1; i >= 0; i--) {
          if (!this.theSelectedStudents.includes(this.studentIds[i])) {
            this.theSelectedStudents.push(this.studentIds[i])
          }
        }
      } else {
        for (var i = this.selectedGroupStudentIds.length - 1; i >= 0; i--) {
          const id = this.selectedGroupStudentIds[i]
          const index = this.selectedStudents.indexOf(id)
          if (index > -1) {
            this.selectedStudents.splice(index, 1)
          }
        }
      }
      this.emitChange()
    },
    emitChange() {
      console.log('emitting')
      this.$emit('update', this.theSelectedStudents)
    }
  }
}
</script>

<style lang="css" scoped>
</style>