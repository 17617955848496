<template>
  <div>
    <!-- ID: {{ dataimport.user_key }} -->
    
    <div class="row mb-5">
      <div class="col-10 col-md-6 mx-auto text-center">

          <p class="lead">Zodra je op de knop hieronder klikt, worden gegevens uit je oude account overgezet naar de nieuwe Weektaak.com. Dit proces duurt enige tijd.</p>
          <button @click="start" class="btn btn-success btn-lg" :disabled="started">Begin nu</button>

          <div class="list-group animated fadeInUp text-left mt-4" v-if="started">
            
            <!-- export -->
            <div class="list-group-item d-flex justify-content-between">
              <span>Bestaande account exporteren</span>
              <i class="fas fa-sync-alt fa-spin align-self-center" v-if="exportInProgress || exportStarted"></i>
              <i class="fas fa-check text-success align-self-center" v-if="exportFinished"></i>
            </div>

            <!-- import -->
            <div class="list-group-item">
              <div class="w-100 d-flex justify-content-between">
                <span>Gegevens importeren</span>
                <i class="fas fa-sync-alt fa-spin align-self-center" v-if="importInProgress"></i>
                <i class="fas fa-check text-success align-self-center" v-if="importFinished"></i>
              </div>

              <div class="progress mt-3 animated fadeInUp" v-if="importJob.parse_percentage > 0 && !importFinished">
                <div class="progress-bar" role="progressbar" :style="`width: ${importJob.parse_percentage}%;`">{{importJob.parse_percentage}}%</div>
              </div>

            </div>
              
          </div>


          <div class="animated fadeInUp text-center mt-4" v-if="importFinished">
            <p class="lead">🎉 De verhuizing is geslaagd! Alle gebruikers, groepen en weektaken zijn overgenomen. Klik op de knop hieronder om voor je eigen account een nieuw wachtwoord te kiezen.</p>
            <a :href="`/migration/${importJob.user_id}/password/${importJob.user_key}`" class="btn btn-success btn-lg" :disabled="started">Kies nu een nieuw wachtwoord</a>
          </div>

      </div>
    </div>
    

    <pre class="mt-4" v-if="false">
      {{ importJob }}  
    </pre>
    
  </div>
</template>

<script>
import axios from 'axios'
export default {

  props: ["dataimport"],
  data () {
    return {
      started: false,
    }
  },
  computed: {
    exportStarted (){
      return this.importJob.import_percentage == 0
    },
    exportFinished (){
      return this.importJob.import_percentage == 100
    },
    exportInProgress (){
      return this.importJob.import_percentage == 1
    },
    importStarted (){
      return this.importJob.parse_percentage == 0 && this.exportFinished
    },
    importFinished (){
      return this.importJob.parse_percentage == 100
    },
    importInProgress (){
      return this.importJob.parse_percentage > 0 && !this.importFinished
    },

    importJob (){
      return window.store.getters.importJob
    },
  },
  methods: {
    start(){
      this.started = true
      return axios.get("/data_imports/" + this.importJob.user_key + "/start.json")
      .then(response => {
        return response.data
      })
    }
  },
  created () {
    window.store.dispatch('setDataImport', this.dataimport)
  }
}
</script>

<style lang="css" scoped>
</style>