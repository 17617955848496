<template>
  <div class="container-fluid bg-light" id="week-plan">
    <div class="row">
      <div class="col-8 bg-light pt-3 full-height">
        <div class="bg-white rounded  p-3 shadow-sm mb-3 d-flex justify-content-between" v-if="pictoPlan.lists && pictoPlan.lists.length">

          <div class="form w-100">
            <div class="form-check">
              <input @change="submitChanges" class="form-check-input" v-model="pictoPlan.use_lesson_pictos" type="radio" name="exampleRadios" id="exampleRadios1" value="no">
              <div class="d-flex justify-content-between">
                <label class="form-check-label d-flex justify-content-between" for="exampleRadios1">
                  Gebruik alleen {{ list_names }}
                </label>
                <span v-show="from_plan_help" class="ml-5 text-muted font-italic"><span class='badge badge-secondary'><i class="fas fa-print"></i> kleuterweektaak</span></span>
              </div>
            </div>
            <div class="form-check" v-if="false">
              <input @change="submitChanges" class="form-check-input" v-model="pictoPlan.use_lesson_pictos" type="radio" name="exampleRadios" id="exampleRadios2" value="from_plan">
              <div class="d-flex justify-content-between">
                <label class="form-check-label" for="exampleRadios2">
                  Pictoweektaak met taken van het weekplan voor de dagen
                </label>
                <span>
                  <i v-if="!from_plan_help" class="text-muted fas fa-info-circle"  @click="showHelp"></i>
                  <span v-show="from_plan_help" class="ml-5 text-muted font-italic">gekoppeld aan weekplan <span class='badge badge-secondary'><i class="fas fa-print"></i> weektaak met plaatjes</span></span>
                </span>
              </div>
            </div>
            <div class="form-check">
              <input @change="submitChanges" class="form-check-input" v-model="pictoPlan.use_lesson_pictos" type="radio" name="exampleRadios" id="exampleRadios3" value="custom">
              <div class="d-flex justify-content-between">
                <label class="form-check-label d-flex justify-content-between" for="exampleRadios3">
                  Pictoweektaak met eigen taken voor de dagen 
                </label>
                <span>
                  <span class="text-muted font-italic" v-show="from_plan_help">zelf slepen, los van het weekplan <span class='badge badge-secondary'><i class="fas fa-print"></i> weektaak met plaatjes</span></span>
                </span>
              </div>
            </div>
          </div>
        </div>

        <div v-for="list in pictoPlan.custom_days" v-if="pictoPlan.use_lesson_pictos == 'custom'">
          <pictoList :placeholderImage="placeholderImage" :list="list" :students="students" :week_plan_id="id"></pictoList>
        </div>


        <div v-for="day in pictoPlan.days" v-if="pictoPlan.use_lesson_pictos == 'from_plan'">
          <div class="px-3 mb-2 mt-2">
            <div class="row bg-white rounded shadow-sm mb-3 pb-3 px-3">
              <div class="col-12 py-2 px-0" style="font-size: 16pt">{{ day.day }}</div>
                <div class="col-12" v-if="!day.lessons.length && day.is_active">
                  <div class="card text-center">
                    <div class="card-body text-muted">
                      Hier verschijnen automatisch de lessen van <span class='text-lowercase'>{{ day.day }}</span> van de <a :href="`/week_plans/${id}`">weekplanning</a>, waarvan je hebt aangegeven dat ze zichtbaar zijn voor de leerlingen.<br><b>Let op: </b> er passen maximaal 6 lessen per dag op de weektaak met plaatjes.
                    </div>
                  </div>
                </div>
                <div class="col-12" v-if="!day.is_active">
                  <div class="card text-center">
                    <div class="card-body text-muted">
                      Op <span class='text-lowercase'>{{ day.day }}</span> is er geen les.
                    </div>
                  </div>
                </div>
              <pictoLesson :lesson="lesson" :day="day" v-for="lesson in day.lessons.slice(0, 6)" v-if="day.is_active"></pictoLesson>
            </div>
          </div>
        </div>

        <div :key="list.id" v-for="list in pictoPlan.lists">
          <pictoList :list="list" :students="students" :week_plan_id="id"></pictoList>
        </div>

        <div class="bg-white p-3 shadow-sm rounded mb-5">
          <div class="row">
            <div class="col-8">
              <form>
                <div class="form-group">
                  <label for="titlr">Titel of thema</label>
                  <input type="text" v-model="pictoPlan.title" @change="submitChanges" class="form-control" id="titlr" placeholder="Kies een thema voor deze week. Bijv. 'Kerst'">
                  <small id="emailHelp" class="form-text text-muted">Niet verplicht. Deze titel verchijnt op de geprinte picto-weektaak.</small>
                </div>

                <div class="form-group">
                  <label for="comment">Opmerking</label>
                   <textarea v-model="pictoPlan.description" @change="submitChanges" class="form-control" id="comment" rows="3"></textarea>
                </div>
              </form>
            </div>            

            <div class="col-4">
              
              <div class="card" v-if="pictoPlan.image">
                <img :src="pictoPlan.image" class="card-img-top">
                <div class="card-footer text-muted small">
                  <div class="row">
                    <div class="col"><span>{{pictoPlan.image_file_name }}, {{pictoPlan.image_file_size }}</span></div>
                    <div class="col"><button @click="removeImage" class="btn btn-outline-danger btn-sm btn-block">verwijderen</button></div>
                  </div>
                </div>
              </div>
             

              <vue-clip :options="uploadOptions" :on-complete="uploadComplete" v-if="!pictoPlan.image">
                <template slot="clip-uploader-action">
                  <div class="row ">
                    <div class="dz-message col-8 mx-auto bg-light rounded p-4 text-center my-5" style="border-style: dashed; border-color: #ddd">Sleep een bestand (.jpg, .gif of .png) naar dit vak of <span style="cursor: pointer; text-decoration: underline">klik hier</span> om een afbeelding of kleurplaat te uploaden...
                      
                    </div>
                  </div>
                </template>

                <template slot="clip-uploader-body" slot-scope="props">
                </template>
              </vue-clip>
              <div v-if="!pictoPlan.image" class="text-center">
                <button @click="openKleurplaat" class="btn btn-outline-primary btn-sm">Kleurplaten downloaden?</button>
              </div>

              <div class="modal fade" id="kleurplaat-modal" tabindex="-1" role="dialog" aria-hidden="true">
                <div class="modal-dialog" role="document">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title">Kleurplaten</h5>
                      <button type="button" class="close" data-dismiss="modal" aria-label="Sluiten">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body">
                      <p>Op zoek naar leuke kleurplaten? Op de volgende websites zijn kleurplaten te downloaden voor allerlei thema's.</p>
                      <table class="table">
                        <tr>
                          <td><a href="https://www.jouwkleurplaten.nl/kleurplaten" target="_blank">www.jouwkleurplaten.nl</a></td>
                          <td>Duizenden kleurplaten van, onderverdeeld in thema's van <i>Alfabet</i> tot <i>Zomervakantie</i></td>
                        </tr>
                        <tr>
                          <td><a href="http://www.kleurplaten.eu" target="_blank">www.kleurplaten.eu</a></td>
                          <td>Kleurplaten onderverdeeld in handige categoriën, zoals beroepen, muziekinstrumenten, sporten en sinterklaas.</td>
                        </tr>
                        
                      </table>
                    </div>
                  </div>
                </div>
              </div>



            </div>
          </div>
        </div>

        <pre v-if="false">{{pictoPlan}}</pre>
      </div>

      <div class="col-4 pt-0 full-height" v-bind:class="{'bg-danger': showingTrash, 'bg-primary': !showingTrash}">
        <div id="trash-bin" v-show="showingTrash" class="bg-danger text-white">
          <i class="fas fa-trash-alt "></i>
          <draggable class="bin-content" :group="{put: true}" @add="trashedItem">
          </draggable>
        </div>

        <div v-show="!showingTrash">
          <div class="sticky-top bg-primary py-3">
            <input v-model="keyword" autofocus="true" class="form-control w-100" type="text" placeholder="Zoek picto" />
          </div>
          <draggable
            class="dragArea row px-3 pt-3"
            :list="pictoList"
            :group="{ name: 'pictos', pull: 'clone', put: false }"
          >
            <picto :size="'small'" :week_plan_id="id" :picto="{id: null, picto: pictoObject, title: pictoObject.name}" :key="`picto-${pictoObject.id}`" v-for="pictoObject in pictoList"></picto>
          </draggable>
        </div>

      </div>
    </div>
  </div>
</template>

<script>

import axios from 'axios'
import Weektaak from '../../packs/weektaak'
import pictoList from '../../components/pictos/pictoList.vue'
import pictoLesson from '../../components/pictos/pictoLesson.vue'
import Picto from '../../components/pictos/picto.vue'
import draggable from 'vuedraggable'

export default {

  name: 'pictoPlan',
  components: {Picto, draggable, pictoList, pictoLesson},
  props: ['pictos', 'id', 'students', 'placeholderImage'],
  data () {
    return {
      keyword: '',
      from_plan_help: false,
      custom_help: false,
      uploadOptions: {
        url: `/week_plans/${this.id}/image`,
        paramName: 'file',
        uploadMultiple: false,
        // acceptedFiles: {
        //   extensions: this.acceptedFileTypes(),
        //   message: 'Het gekozen bestand is geen geldig XML bestand.'
        // }
      }
    }
  },
  
  computed: {
    showingTrash (){
      return window.store.getters.showWeekPlanTrash
    },
    pictoPlan() {
      return window.store.getters.pictoPlan
    },
    pictoList() {
      return this.pictos.filter((picto) => {
        return picto.keywords.toLowerCase().includes(this.keyword.toLowerCase())
      })
    },
    list_names(){
      let list = []
      if (this.pictoPlan && this.pictoPlan.lists){
        for (var i = this.pictoPlan.lists.length - 1; i >= 0; i--) {
          list.push(this.pictoPlan.lists[i].title)
        }
      }
      return list.join(' en ')
    }
  },
  methods: {
    showHelp(){
      this.from_plan_help = !this.from_plan_help
      setTimeout(function () {
        this.from_plan_help = !this.from_plan_help
      }.bind(this), 5000)
    },
    openKleurplaat(){
      $("#kleurplaat-modal").modal('show')
    },
    removeImage(){
      return axios.delete("/week_plans/" + this.id + "/image.json")
    },
    uploadComplete(){
      // console.log("upload complete")
    },
    submitChanges(){
      return axios.put("/week_plans/" + this.id + ".json", {week_plan: {use_lesson_pictos: this.pictoPlan.use_lesson_pictos, picto_title: this.pictoPlan.title, picto_body: this.pictoPlan.description }})
    },
    trashedItem (event) {
      window.store.dispatch('addPictoToTrash', {id: event.item.dataset.id, weekPlanId: this.id})
    },
  },
  created () {
    window.store.dispatch('fetchPictoWeekPlan', this.id)
      .then((result) => {
        // 
      }).catch(error => {
        // console.log("foutje")
        console.log(error)
      })
  }
}
</script>

<style lang="css" scoped>
.full-height{
    height: calc(100vh - 110px);
    overflow: auto;
    /*padding-right: 10px;*/
  }
  .fa-info-circle{
    cursor: help;
  }
</style>