<template>
  <div :class="`col-4 col-md-2 mb-3 px-2 ${deleteClass}`" v-if="!isHidden">
    <div class="card" style="border: 1px solid #000;">

      <img :src="picto.image" class="card-img-top mb-2" />
      <button @click="destroyPicto" class="btn btn-danger btn-sm remove-btn" style="position: absolute; right: 5px; top: 5px;"><i class="fas fa-trash"></i></button>
      <div class="list-group list-group-flush">
        <div class="list-group-item border-0 px-1 pt-0 pb-2">
          <input @change="updatePicto" class="text-center border-0 form-control p-1" style="font-weight: 700; height: 16px; font-size: 12px;" type="text" v-model="picto.name" placeholder="Naam" />
        </div>
        <div class="list-group-item border-0 p-1" v-if="false">
          <textarea @change="updatePicto" class="form-control text-muted" type="text" v-model="picto.keywords" rows="3" placeholder="Zoekwoorden"></textarea>
        </div>
      </div>
      
    </div>
  </div>
</template>

<script>

import axios from 'axios'
export default {

  name: 'pictoCard',
  props: ["picto"],
  data () {
    return {
      isDeleting: false,
      isHidden: false
    }
  },
  computed: {

    deleteClass (){
      if (this.isDeleting){
        return 'animated zoomOutRight'
      } else {
        return ''  
      }
    },
  },
  methods: {
    destroyPicto(){
      let r = confirm("Weet je het zeker? Als je plaatjes verwijdert, verwijder je ze voor je hele school.");
      if (r){
        this.isDeleting = true
        setTimeout(function () {
          this.isHidden = true
        }.bind(this), 1000)
        return axios.delete(`/pictos/${this.picto.id}.json`)
          .then(response => {
            console.log("deleted...")
          })
        // Weektaak.deleteCourse(this.course.id)
      }
    },
    updatePicto(){
    return axios.put("/pictos/"+this.picto.id+".json", {picto: {name: this.picto.name, keywords: this.picto.keywords}})
      .then(response => {
        // window.store.dispatch('setCourseToSchoolCourses', response.data)
        // return response.data
      })
    }
  }
}
</script>

<style lang="css" scoped>
.card:hover .remove-btn{
  display: block;
}
.remove-btn{
  display: none;
}
</style>