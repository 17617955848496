<template>
  <div class="container-fluid p-0 bg-white" id="reg-container">
    <div id="reg-topleft" class="bg-primary"></div>
    <div id="reg-left" class="bg-primary">
      <div v-if="navMode == 'days'">
        <div v-for="day in days" :key="day.id">
          <b>{{ day.day }}</b>
        </div>
      </div>
    </div>
    <div id="reg-top" class="bg-light"></div>
    <div id="reg-data" class="bg-white">
      <h2>{{weekPlanId}}</h2>
      <pre>{{data}}</pre>
    </div>
    
  </div>
</template>

<script>
import axios from 'axios'
export default {

  name: 'registrationForm',
  props: ['weekPlanId'],
  data () {
    return {
      navMode: "days",
      data: {},
      days: [],
      courses: [],
      students: [],
      studentLessons: [],
      loading: false
    }
  },
  mounted() {
    this.loading = true
    return axios.get(`/week_plans/${this.weekPlanId}/registration.json`).
      then(response => {
        this.data = response.data
        this.courses = response.data.courses
        this.days = response.data.days
        this.students = response.data.students
        // console.log(response.data)
        // window.store.dispatch('setStudentWeekPlans', response.data)
        // this.resettingCredits = false
      }).finally(() => {
        this.loading = false
      })
  }
}
</script>

<style lang="css" scoped>
  #reg-container {
    display:  grid;
    grid-template-columns: 300px 1fr; 
    grid-template-rows: 200px 1fr; 
    gap: 0px 0px; 
    height:  calc(100vh - 109px);
    width:  100vw;
    background: teal;
  }
  #reg-topleft {
    grid-column: 1;
    grid-row: 1;
    display:  flex;
    background:  blue;
  }
  #reg-left {
    grid-column: 1;
    grid-row: 2;
    display:  flex;
    background: yellow;
  }
  #reg-top {
    grid-column:  2;
    grid-row: 1;
    background:  red;
    display:  flex;
  }
  #reg-data {
    background: green;
    grid-column: 2;
    grid-row: 2;
    overflow: auto;
  }
</style>