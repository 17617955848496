<template>
  <div class="row">
      <div class="col-10 mx-auto" v-for="group in groups">
        <groupListing :group="group" :birthdays="birthdays" :groups="groups"></groupListing>
      </div>
      <div class="col-10 mx-auto text-center mb-5">
        <button @click="newGroup()" class="btn btn-success">Groep toevoegen</button>
      </div>


      <div class="col-12" v-if="school.groups.length > 1">
        <combinationGroups :school="school" :combinations="combinations"></combinationGroups>
      </div>

    </div>
  </div>
</template>

<script>

import groupListing from '../../components/groups/groupListing.vue'
import CombinationGroups from '../../components/groups/combinationGroups.vue'

export default {
  components: {
    groupListing,
    CombinationGroups
  },
  props: ["school", "combinations", "birthdays"],

  data () {
    return {
      groups: []
    }
  },
  computed: {
    
  },
  methods: {
    newGroup() {
      console.log("adding new school")
      this.groups.push({
        name: "",
        name_with_group: "",
        students: [],
        new: true
      })
    }
  },
  created () {
    this.groups = this.school.groups
  },
  
}
</script>



<style scoped>

</style>
