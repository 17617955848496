<template>
  <div class="">
    <div v-if="lesson">
      <div class="modal-header text-center">
        <h5 class="pl-5 modal-title w-100" id="exampleModalLabel"><b>{{ lesson.course_name }}</b> <span class="ml-3 small text-muted" v-if="!lesson.is_clockless"><i class='fa fa-clock'></i> {{ lesson.time }}</span><br><span class="badge badge-secondary badge-pill small">{{ lesson.group_name_with_group }}</span>

        </h5>

        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
          
        <div v-if="lesson.lessons_of_course.length > 1" class="btn-group w-100" role="group" :aria-label="`Lessen van het vak %{lesson.course_name}`">
          <button v-for="lessonOfCourse in lesson.lessons_of_course" @click="clickLesson(lessonOfCourse)" type="button" :class="lessonOfCoursBtnClass(lesson.id, lessonOfCourse.id)">{{lessonOfCourse.day}} <span v-if="!lesson.is_clockless">{{lessonOfCourse.starts_at | moment("H:mm")}}</span></button>
        </div>
          
        <hr class="line" v-if="lesson.lessons_of_course.length > 1">
        <!-- content -->
        <div class="d-block">
          <div class="d-flex justify-content-between">
            <div v-if="!lesson.is_clockless">

              <toggle-button @change="saveLessonVisibility" v-model="lesson.visible_for_student" :sync="true" :labels="{checked: 'Ook zichtbaar op weektaak leerling', unchecked: 'Alleen zichtbaar voor leerkracht'}" :fontSize="12" :width="265" />
              
              <i v-if="lesson.visible_for_student" class="far fa-eye active"></i>
              <i v-if="!lesson.visible_for_student" class="far fa-eye-slash text-muted"></i>
            </div>
            <div v-if="lesson.is_clockless" class="">
              <label class="mb-1 mr-2">Zichtbaar voor:</label>
              <toggle-button @change="saveLessonVisibility" v-model="lesson.visible_for_student" :sync="true" :labels="{checked: 'Leerling', unchecked: 'Leerling'}" :fontSize="12" :width="101" />
              <toggle-button @change="saveLessonVisibility" v-model="lesson.visible_for_teacher" :sync="true" :labels="{checked: 'Leerkracht', unchecked: 'Leerkracht'}" :fontSize="12" :width="101" />
            </div>

            <div v-if="lessonLevels.length > 1 || (lessonLevels.length == 1 && lessonLevels[0].level.per_student )">
              <i v-if="isLocked" class="fas fa-link"></i>
              <i v-if="!isLocked" class="fas fa-unlink text-muted"></i>
              <toggle-button @change="toggleLock" v-model="isLocked" :sync="true" :labels="{checked: 'Vul overal hetzelfde in', unchecked: 'Vul niveaus afzonderlijk in'}" :fontSize="12" :width="195" />
            </div>
            
          </div>
          <div class="card bg-light py-1 px-3 mb-2 ml-4" v-for="lesson_level in lessonLevels">
            <!-- <h4>{{ lesson_level.level.name }}</h4> -->
            <WeekPlanItemLevel :show_comment_field="show_comment_field" ref="wpil" :locktask="'h'" @change="loadAllLessons"  @lockedUpdate="lockedUpdate" :islocked="isLocked" :visibleForStudent="lesson.visible_for_student" :key="`wpil_${item.id}`" :lesson="lesson" :lessonMethods="lessonMethods" :lessonLevel="item" v-for="item in lesson_level.lessons"></WeekPlanItemLevel>
          </div>  

          <!-- linked lessons -->
          <div v-if="lesson.possible_linked_lessons.length > 0" >
            <div class="card mb-2">
              <div class="card-body">
                <p class="small text-muted m-0" v-if="lesson.possible_linked_lessons.length == 1">Omdat {{ lesson.possible_linked_lessons[0].group_name_with_group }} tegelijkertijd {{ lesson.possible_linked_lessons[0].course_name }} heeft, kun je de lesstof van deze twee lessen aan elkaar koppelen. Handig, wanneer de lesstof voor beide groepen hetzelfde is. Op deze manier hoef je de stof maar één keer in te plannen.</p>
                <p class="small text-muted m-0" v-if="lesson.possible_linked_lessons.length > 1">Omdat andere groepen uit je combi-klas tegelijkertijd {{ lesson.possible_linked_lessons[0].course_name }} hebben, kun je de lesstof van deze lessen aan elkaar koppelen. Handig, wanneer de lesstof voor meerdere groepen hetzelfde is. Op deze manier hoef je de stof maar één keer in te plannen.</p>
                <p class="small text-muted m-0">Het koppelen en gelijktijdig invullen van de lesstof kan via de knop <a class="btn btn-outline-primary btn-sm small py-1" :href="`/week_plans/${weekPlanId}/courses`"><i class="fa fa-list"></i> Lijst</a></p>
              </div>
            </div>
          </div>
        </div>       

      </div>
      <div class="modal-footer w-100 d-flex justify-content-between">
        <button @click="clickLesson(previousLesson)" type="button" :class="previousLessonBtnClass"><i class="fas fa-chevron-left"></i> Vorige: {{ previousLesson.course_name }}</span></button>
        <button @click="clickLesson(nextLesson)" type="button" :class="nextLessonBtnClass">Volgende: {{ nextLesson.course_name }} <i class="fas fa-chevron-right"></i></button>
      </div>

    </div>

  
  </div>
</template>

<script>

import Weektaak from '../../packs/weektaak'
import WeekPlanItemLevel from '../../components/week_plans/weekPlanItemLevel.vue'


export default {
  components: {
    WeekPlanItemLevel
  },
  props: ["lessonMethods", "show_comment_field"],
  data () {
    return {
      isLocked: false,
      lesson: null,
      lessonLevels: [],
      nextLesson: false,
      previousLesson: false,
      theLinkedLessons: [1],
      loadingLessons: []
    }
  
  },
  methods: {
    toggleLock() {
      // toggle lock
    },
    // update from lock
    lockedUpdate (lessonLevel, field){
      for (let i = 0; i < this.lessonLevels.length; i++){
        for (let j = 0; j < this.lessonLevels[i].lessons.length; j++){
          // console.log(`checking if ${this.lessonLevels[i].lessons[j].id} == ${lessonLevel.id}`)
          if (this.lessonLevels[i].lessons[j].id != lessonLevel.id){
            // console.log('not the same')
            this.lessonLevels[i].lessons[j].islocked = false
            switch(field) {
              case 'task':
                this.lessonLevels[i].lessons[j].task = lessonLevel.task   
                break;
              case 'goal':
                this.lessonLevels[i].lessons[j].goal = lessonLevel.goal   
                break;
              case 'lesson_method_id':
                this.lessonLevels[i].lessons[j].lesson_method_id = lessonLevel.lesson_method_id   
                break;
              case 'correct':
                this.lessonLevels[i].lessons[j].correct = lessonLevel.correct   
                break;
              case 'show_time':
                this.lessonLevels[i].lessons[j].show_time = lessonLevel.show_time   
                break;
              default:
                // code block
            }
            
            
            // this.lessonLevels[i].lessons[j].lesson_method_id    = lessonLevel.lesson_method_id
            // this.lessonLevels[i].lessons[j].correct             = lessonLevel.correct
            // this.lessonLevels[i].lessons[j].show_time           = lessonLevel.show_time
          } else {
            this.lessonLevels[i].lessons[j].islocked = true
          }
          
        }
      }
    },
    isLoading (lesson_id) {
      return this.loadingLessons.includes(lesson_id) && this.theLinkedLessons.includes(lesson_id)
    },
    loadAllLessons(){
      this.loadingLessons = this.lesson.possible_linked_lessons.map((les) => les.id)
      setTimeout(function () {
        this.loadingLessons = []
      }.bind(this), 500)
    },
    toggleLessonLink (id){
      if (this.loadingLessons.includes(id)){
        // this.loadingLessons.includes(id)
        this.loadingLessons = Weektaak.arrayRemove(this.loadingLessons, id)
      } else {
        this.loadingLessons.push(id)
        setTimeout(function () {
          this.loadingLessons = []
        }.bind(this), 500)
      }
      Weektaak.saveLessonLinks(this.lesson.id, this.theLinkedLessons)
    },
    lessonIsLinked (id){
      return true
    },
    lessonOfCoursBtnClass (id_1, id_2) {
      if (id_1 == id_2) {
        return "btn btn-sm btn-outline-primary active"
      } else {
        return "btn btn-sm btn-outline-primary"
      }
    },
    saveLessonVisibility () {
      Weektaak.updateLessonVisible(this.lesson.id, this.lesson.visible_for_student, this.lesson.visible_for_teacher)
    },
    clickLesson(nextOrPrevious){
      if (nextOrPrevious) {
        window.store.dispatch('setModalLessonId', nextOrPrevious.id)  
        // var selector = "lesson-" + nextOrPrevious.id
        // document.getElement`ById(selector).click()
      }
      // const elem = this.$refs.myBtn
      // elem.click()
    },
    // setIsLocked(){

    // },
    fetchLessonLevels (){
      Weektaak.fetchLesonLevels(this.lessonModalId)
        .then((result) => {
          this.lesson         = result.lesson
          this.lessonLevels   = result.lesson_levels
          this.nextLesson     = result.next_lesson
          this.previousLesson = result.previous_lesson
          this.theLinkedLessons = result.lesson.linked_lesson_ids
          this.isLocked       = this.lesson.is_locked
          // this.setIsLocked()
        }).catch((error) => {
          console.log(error)
        })

      
    }
  },
  computed: {
    allLessonLevels(){
      return this.lessonLevels.map((ll) => ll.lessons).flat(5)
    },
    lessonModalId () {
      return window.store.getters.currentModalLessonId
    },
    weekPlanId() {
      return window.store.getters.weekPlan.id
    },
    previousLessonBtnClass () {
      if (this.previousLesson) {
        return "btn btn-outline-primary btn-sm"
      } else {
        return "btn btn-outline-primary disabled btn-sm"
      }
    },
    nextLessonBtnClass () {
      if (this.nextLesson) {
        return "btn btn-outline-primary btn-sm"
      } else {
        return "btn btn-outline-primary disabled btn-sm"
      }
    }
  },
  created () {

    // this.fetchLessonLevels()
  },
  watch: {

    lessonModalId: function (newLessonModalId, oldLessonModalId) {
      // console.log(newLessonModalId)
      this.fetchLessonLevels()
    }
  },
}
</script>

<style scoped>
  .card {
    position: relative;
  }
</style>
