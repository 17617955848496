<template>
  <div>
    <div class="container-fluid pb-5" id="week-planner" :data-id="id">
        <div class="row">
          <div class="col-3 pt-2">
            <div class="list-group">
              <div  v-for="weekPlanCourse in weekPlanCourses" 
                  v-bind:class="{'active': weekPlanCourse.course.id == selectedCourseToShow }" 
                  @click="showPane(weekPlanCourse.course.id)"
                  class="list-group-item" 
              >
                {{ weekPlanCourse.course.name }}
              </div>
            </div>
          </div>
          <div class="col-9 pt-2">
            <div v-for="weekPlanCourse in weekPlanCourses" v-show="weekPlanCourse.course.id == selectedCourseToShow">
              <div class="row">
                <center class="col">
                  <h3>{{ weekPlanCourse.course.name }}</h3>
                  <hr>
                </center>
              </div>
              <WeekPlanItem v-for="lesson in weekPlanCourse.lessons" :lesson="lesson" :lessonMethods="lesson_methods" :key="`wpi-${lesson.id}`"></WeekPlanItem>
            </div>
          </div>

        </div>

    </div>
  </div>
</template>

<script>

import WeekPlanItem from '../../components/week_plans/weekPlanItem.vue'



export default {

  props: ["id", "lesson_methods"],
  components: {
    WeekPlanItem
  },
  data () {
    return {
      loading: true,
      selectedCourse: 0
    }
  },
  methods: {
    showPane (lessonId){
      this.selectedCourse = lessonId
    }
  },
  computed: {  
    selectedCourseToShow () {
      if (this.selectedCourse > 0){
        return this.selectedCourse
      } else if (this.weekPlanCourses.length > 0){
        return this.weekPlanCourses[0].course.id
      } else {
        return 0
      }
    },
    weekPlan () {
      return window.store.getters.weekPlan
    },
    weekPlanCourses () {
      return window.store.getters.weekPlanCourses
    },
    days (){
      return this.weekPlan.weekPlanDays
    },
    courses (){
      return window.store.getters.courses
    }
  },

  created () {
    // window.store.dispatch('fetchWeekPlan', { id: this.id })  
    window.store.dispatch('fetchWeekPlanCourses', { id: this.id })
      .then((result) => {
        this.loading = false
      })
  },


}
</script>

<style scoped>

</style>
