<template>
  <div>
    <div class="row">
      <LessonMethod 
        :key="lesson_method.id" 
        :icons="icons" 
        :lesson-method="lesson_method" 
        v-for="lesson_method in theLessonMethods" 
        @delete="deleteLessonMethod"
      />
    </div>  
    <div class="row">
      <div class="col col-md-6 mx-auto bg-light rounded p-3" v-show="addMode">
        <h6>Hoe heet de nieuwe werkvorm?</h6>
        <div class="input-group">
          <input ref="name" class="form-control" @keyup.enter="submitNew" v-model="name" />
          <div class="input-group-append">
            <button class="btn btn-success" type="button" @click="submitNew" :disabled="name == ''"><i class="fa fa-plus"></i> Toevoegen</button>
          </div>
        </div>
      </div>
      <div class="py-3 text-center col-12" v-show="addMode">
        <button class="btn btn-link text-muted" @click="addMode = false">Annuleer</button>
      </div>
      <div class="col text-center" v-if="!addMode">
        <button @click="addNew" class="btn btn-success"><i class="fa fa-plus"></i> Werkvorm toevoegen</button>
      </div>
    </div>
  </div>
  
</template>

<script>
import axios from 'axios'
import LessonMethod from './LessonMethod.vue'
export default {

  name: 'LessonMethods',
  components: { LessonMethod },
  props: ['lessonMethods', 'icons'],
  data () {
    return {
      theLessonMethods: [],
      name: '',
      addMode: false
    }
  },
  mounted() {
    this.theLessonMethods = this.lessonMethods
  },
  methods: {
    submitNew() {
      this.addMode = false
      if (this.name != '') {
        axios.post(`/lesson_methods.json`, {
          lesson_method: { name: this.name }
        })
        .then(response => {
          this.name = ''
          this.theLessonMethods = response.data
        })  
      }
    },
    addNew() {
      this.addMode = true
      setTimeout(function () {
        this.$refs.name.focus()
      }.bind(this), 200)
      
    },
    deleteLessonMethod(id){
      return axios.delete(`/lesson_methods/${id}.json`)
      .then(response => {
        this.theLessonMethods = response.data
      })
    }
  }
}
</script>

<style lang="css" scoped>
</style>