<template>
  <div class="bg-white rounded shadow-sm mb-3 ">
    <div class="card-body text-center py-2 pl-2">
      <div class="row">
        <div class="col-12 col-md-8 col-xl-9">
          <div class="list-group small p-0 text-left">
            <div class="text-center list-group-item mt-0 ">
              <h5 class="card-title mb-0">{{ printJob.name }}</h5>
            </div>
            <a :href="`/print_jobs/${printJob.id}/file`" target="_blank" :class="`list-group-item list-group-item-action p-2 ${downloadBtnClass}`">
              <i class="fa fa-download"></i>
              <span>PDF Downloaden</span>
            </a>
            <a v-if="environment == 'development'" :href="printJob.preview_url" target="_blank" :class="`list-group-item list-group-item-action p-2`">
              <i class="fa fa-bug"></i>
              <span>Debug</span>
            </a>
            <a @click="setLoading" :href="`/print_jobs/${printJob.id}/regenerate`" data-remote="true" class="list-group-item list-group-item-action  p-2">
              <i :class="`fa fa-redo ${reloadBtnClass}`"></i>
              <span>Opnieuw</span>
            </a>
            <a :href="`/print_jobs/${printJob.id}`" data-remote="true" data-method="delete" class="list-group-item list-group-item-action  p-2">
              <i class="fa fa-trash"></i>
              <span>Verwijderen</span>  
            </a>
            <div class="list-group-item text-center bg-warning" v-if="printJob.has_overflow">
              <i class="fas fa-exclamation-triangle"></i>
              <b>Onvoldoende ruimte</b><br>
              Er is onvoldoende ruimte op het gekozen sjabloon om alle lesstof te printen. <a href="/handleiding/overvolle-weektaak" target="_blank">Lees hier</a> de mogelijke oorzaken en oplossingen. 
            </div>
          </div>
        </div>
        <div class="col-12 col-md-4 col-xl-3 text-center">
          <a v-if="printJob.finished"  :href="printJob.url" target="_blank" :class="`stacked-paper ${ printJob.orientation }`">
            <img :src="printJob.image" class="shadow p-1 mb-1 bg-white" width="100%"/>
            <img :src="printJob.image" class="shadow p-1 mb-1 bg-white" width="100%"/>
            <img :src="printJob.image" class="shadow p-1 mb-1 bg-white" width="100%"/>
          </a>
          <!-- <span v-if="printJob.finished" class="mt-0 bm-1 card-title text-muted" style="font-size: 0.7em">{{ printJob.updated_at | moment("DD-MM, H:mm")}}</span> -->
          <p class="card-text">
            <i class="fa fa-spin fa-spinner fa-2x" v-if="!printJob.finished"></i>
          </p>
        </div>

      </div>
    </div>
  </div>

</template>

<script>

import axios from 'axios'

export default {
  components: {
  },
  props: ["job", "environment"],
  data () {
    return {
      printJob: null
    }
  },
  created() {
    this.printJob = this.job
    if (!this.printJob.finished) {
      this.fetchStatus()
    }
    // this.loading = !this.job.finished
  },
  methods: {
    setLoading (){
      this.printJob.finished = false
      this.fetchStatus()
      // this.loading = true
    },
    getPrintJob() {
      axios.get(`/print_jobs/${this.printJob.id}.json`)
      .then((response) => {
        // console.log(response.data)
        if (response.data.finished) {
          this.printJob = response.data
        } else {
          this.fetchStatus()
        }
      }).catch((error) => {
        console.error(error);
      }).finally(() => {
        // TODO
      });
    },
    fetchStatus() {
      setTimeout(function() {
        this.getPrintJob()
      }.bind(this), 1000);
    }
  },
  computed: {
    reloadBtnClass () {
      if (this.printJob.finished) {
        return ''
      } else {
        return 'fa-spin'
      }
    },
    downloadBtnClass () {
      if (this.printJob.finished) {
        return ''
      } else {
        return 'disabled'
      }
    },
    style () {
      if (this.printJob.orientation == "portrait"){
        return "height: 100%;"
      } else {
        return "width: 100%;"
      }

    },
  
  }
}
</script>

<style scoped>
 .stacked-paper {
  position: absolute;
  left: 0px;
  right: 0px;
 }
 .stacked-paper img{
  position: absolute;
  left: 0px;
  top: 5px;
 }
.stacked-paper.portrait img{
  /*height: 120px;*/
  /*width: 100px*/
 }
 .stacked-paper img{

 }
 button, button:hover{ 
  cursor: pointer; 
}
  
@media screen and (min-width: 1400px) {
  .stacked-paper img{
    width: 75%;
    margin-left: 5%;
  }
} 
@media screen and (min-width: 1800px) {
  .stacked-paper img{
    width: 65%;
    margin-left: 15%;
  }
} 
.stacked-paper img:nth-child(1){ transform: rotate(12deg) }
.stacked-paper img:nth-child(2){ transform: rotate(6deg) }
.stacked-paper img:nth-child(3){ transform: rotate(3deg) }
.stacked-paper.landscape img:nth-child(1){ top: 20px; margin-left: -5px; }
 .stacked-paper.landscape img:nth-child(2){ top: 50px; }
 .stacked-paper.landscape img:nth-child(3){ top: 70px; margin-left: -10px; transform: rotate(357deg)}
 
 .stacked-paper:hover img{
  width: 105%;
  z-index: 999;
  position: absolute;
 }
 .stacked-paper:hover img:nth-child(1){ transform: rotate(15deg) }
 .stacked-paper:hover img:nth-child(2){ transform: rotate(0deg) }
 .stacked-paper:hover img:nth-child(3){ transform: rotate(350deg) }
</style>
