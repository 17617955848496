<template>
  <div>
    <h1>{{group.name}}</h1>
  </div>
</template>

<script>


export default {
  components: {
  },
  props: ["group", "students"],

  data () {
    return {
      
    }
  },
  computed: {
    
  },
  methods: {

  },
  created () {

    // window.store.dispatch('fetchWeekPlan', { id: this.id })
    //   .then(() => {
    //     // this.theYear = this.weekPlan.year
    //     // this.weekNumber = this.weekPlan.week_number
    //   })
  },
  
}
</script>



<style scoped>

</style>
