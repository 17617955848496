/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// import "@babel/polyfill"
// Core packages
import Vue from 'vue/dist/vue.esm'
import Vuex from 'vuex'


// External

import axios from 'axios' // for making API calls
// import TurbolinksAdapter from 'vue-turbolinks' // turbolinks
import ToggleButton from 'vue-js-toggle-button' // toggle
import moment from 'moment'

import VueClip from 'vue-clip'
Vue.use(VueClip)

import VueYoutube from 'vue-youtube'
Vue.use(VueYoutube)


// import ScrollSync from 'vue-scroll-sync' 
// Vue.use(ScrollSync)


import linkify from 'vue-linkify'
Vue.directive('linkified', linkify)

import Croppa from 'vue-croppa'
Vue.use(Croppa)   

import VTooltip from 'v-tooltip'
Vue.use(VTooltip)

import TextareaAutosize from 'vue-textarea-autosize'
Vue.use(TextareaAutosize)

import VueChatScroll from 'vue-chat-scroll'
Vue.use(VueChatScroll)

Object.defineProperty(Array.prototype, 'flat', {
    value: function(depth = 1) {
      return this.reduce(function (flat, toFlatten) {
        return flat.concat((Array.isArray(toFlatten) && (depth>1)) ? toFlatten.flat(depth-1) : toFlatten);
      }, []);
    }
});

// import VueTimepicker from 'vue2-timepicker'
// Vue.use(VueTimepicker)
// DragScroll
// import VueDragscroll from 'vue-dragscroll'
// Vue.use(VueDragscroll)

// import NotebookUi from 'vue-notebook-ui'
 
// Vue.use(NotebookUi)


// Bootstrap
// import BootstrapVue from 'bootstrap-vue'
// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'
// Vue.use(BootstrapVue)


// import VueSweetalert2 from 'vue-sweetalert2'; 
// Vue.use(VueSweetalert2);

// import Swal from 'sweetalert2'

import VueConfetti from 'vue-confetti'
Vue.use(VueConfetti)


// import PrettyCheckbox from 'pretty-checkbox-vue';
// Vue.use(PrettyCheckbox);
  
// import PrettyInput from 'pretty-checkbox-vue/input';
import PrettyCheck from 'pretty-checkbox-vue/check';
// import PrettyRadio from 'pretty-checkbox-vue/radio';

// Vue.component('p-input', PrettyInput);
Vue.component('p-check', PrettyCheck)
// Vue.component('p-radio', PrettyRadio);





// Internal
import youtubeWithTableOfContents from '../components/misc/youtubeWithTableOfContents.vue'

// Weekplans
import WeekPlan from '../components/week_plans/weekPlan.vue'
import WeekPlanPerCourse from '../components/week_plans/weekPlanPerCourse.vue'
import WeekPlanner from '../components/week_plans/weekPlanner.vue'
import WeekPlanDay from '../components/week_plans/weekPlanDay.vue'
import Lesson from '../components/week_plans/lesson.vue'
import LessonModal from '../components/week_plans/lessonModal.vue'
import CourseList from '../components/week_plans/courseList.vue'
import CourseListItem from '../components/week_plans/courseListItem.vue'
import WeekPlanOptions from '../components/week_plans/weekPlanOptions.vue'
import WeekPlanDayOption from '../components/week_plans/weekPlanDayOption.vue'
import WeekPlanNotes from '../components/week_plans/weekPlanNotes.vue'
import CombiWeekPlanNotes from '../components/week_plans/combiWeekPlanNotes.vue'
import weekPlanActiveDate from '../components/week_plans/weekPlanActiveDate.vue'
import GroupCopyOptions from '../components/week_plans/GroupCopyOptions.vue'


// Groups
import GroupPage from '../components/groups/groupPage.vue'
import GroupsPage from '../components/groups/groupsPage.vue'
import StudentName from '../components/groups/studentName.vue'
import ManualGroupImport from '../components/groups/manualGroupImport.vue'

// Print Jobs
import PrintJobList from '../components/print_jobs/printJobList.vue'
import PrintJob from '../components/print_jobs/printJob.vue'
import PrintOptionsForWeekPlan from '../components/print_jobs/printOptionsForWeekPlan.vue'
import PrintOptionsBack from '../components/print_jobs/printOptionsBack.vue'
import ContentTypePrintOption from '../components/print_jobs/contentTypePrintOption.vue'
import QrCodePrintOptionsForWeekPlan from '../components/print_jobs/qrCodePrintOptionsForWeekPlan.vue'

// Settings
import UserInvite from '../components/settings/userInvite.vue'
import StudentSelector from '../components/settings/studentSelector.vue'
import StudentSettings from '../components/settings/studentSettings.vue'
import AvgForm from '../components/settings/avgForm.vue'
import PlannedNotes from '../components/settings/plannedNotes.vue'
import LevelSettings from '../components/settings/levelSettings.vue'
import ExtraTasks from '../components/settings/extraTasks.vue'
import CourseIndex from '../components/settings/courseIndex.vue'
import CourseShow from '../components/settings/courseShow.vue'
import StudentImport from '../components/settings/studentImport.vue'
import FeedbackForm from '../components/settings/feedbackForm.vue'
import SchoolImport from '../components/settings/schoolImport.vue'
import UserPreferences from '../components/settings/userPreferences.vue'
import CustomWeekNumberSelector from '../components/settings/customWeekNumberSelector.vue'
import EvalutationItems from '../components/settings/evalutationItems.vue'
import UseGroupLevelsSettings from '../components/settings/useGroupLevelsSettings.vue'
import YearPlanning from '../components/settings/YearPlanning.vue'
import LessonMethods from '../components/settings/LessonMethods.vue'
import PlannedNoteCategories from '../components/settings/PlannedNoteCategories.vue'


// Digital week plan
import StudentWeekPlans from '../components/digital/studentWeekPlans.vue'
import DigitalWeekPlanFiles from '../components/digital/digitalWeekPlanFiles.vue'
import StudentWeekPlan from '../components/digital/studentWeekPlan.vue'
import DigitalWeekPlan from '../components/digital/digitalWeekPlan.vue'
import DigitalWeekPlanSettings from '../components/digital/digitalWeekPlanSettings.vue'
import StudentWeekPlanDay from '../components/digital/studentWeekPlanDay.vue'
import StudentWeekPlanItem from '../components/digital/studentWeekPlanItem.vue'
import StudentLogin from '../components/digital/studentLogin.vue'
import ChatWindow from '../components/digital/chatWindow.vue'
import UnreadMessagesCounter from '../components/digital/unreadMessagesCounter.vue'
import WeekPlanDashboard from '../components/digital/weekPlanDashboard.vue'
import RegistrationForm from '../components/digital/registrationForm.vue'


// Picto
import PictoPlan from '../components/pictos/pictoPlan.vue'
import PictoIndex from '../components/pictos/pictoIndex.vue'

// Misc.
// import InformationRequestForm from '../components/misc/informationRequestForm.vue'

// Books
// import BookDetails from '../components/books/bookDetails.vue'
// import BookBlock from '../components/books/bookBlock.vue'

moment.locale('nl')
window.moment = moment
// Object.definePrototype(Vue.prototype, '$moment', { value: moment })



// Data
import store from '../store/index'

// Vue.use(TurbolinksAdapter)
Vue.use(Vuex)
Vue.use(ToggleButton)


window.store = store

// Vue.component('informationrequestform', InformationRequestForm)

Vue.component('userinvite', UserInvite)
Vue.component('studentSettings', StudentSettings)
Vue.component('avgform', AvgForm)
Vue.component('weekplan', WeekPlan)
Vue.component('weekplanpercourse', WeekPlanPerCourse)
Vue.component('pictoplan', PictoPlan)
Vue.component('weekplanner', WeekPlanner)
Vue.component('digitalweekplanfiles', DigitalWeekPlanFiles)
Vue.component('groupcopyoptions', GroupCopyOptions)
Vue.component('studentselector', StudentSelector)
// Vue.component('weekplanday', WeekPlanDay)
Vue.component('lesson', Lesson)
Vue.component('lessonModal', LessonModal)
Vue.component('unreadmessagescounter', UnreadMessagesCounter)
// Vue.component('courselist', CourseList)
// Vue.component('courselistitem', CourseListItem)
Vue.component('weekplanoptions', WeekPlanOptions)
// Vue.component('weekplandayoption', WeekPlanDayOption)
Vue.component('grouppage', GroupPage)
Vue.component('student-name', StudentName)
Vue.component('groupspage', GroupsPage)
Vue.component('printjoblist', PrintJobList)
Vue.component('studentweekplans', StudentWeekPlans)
Vue.component('studentweekplan', StudentWeekPlan)
Vue.component('digitalweekplan', DigitalWeekPlan)
Vue.component('digitalweekplansettings', DigitalWeekPlanSettings)
Vue.component('studentweekplanday', StudentWeekPlanDay)
Vue.component('studentweekplanitem', StudentWeekPlanItem)
Vue.component('studentlogin', StudentLogin)
Vue.component('weekplannotes', WeekPlanNotes)
Vue.component('combiweekplannotes', CombiWeekPlanNotes)

Vue.component('chatwindow', ChatWindow)
Vue.component('userpreferences', UserPreferences)
Vue.component('yearplanning', YearPlanning)
Vue.component('lessonmethods', LessonMethods)
Vue.component('plannednotecategories', PlannedNoteCategories)



Vue.component('printoptionsforweekplan', PrintOptionsForWeekPlan)
Vue.component('contenttypeprintoption', ContentTypePrintOption)
Vue.component('qrcodeprintoptionsforweekplan', QrCodePrintOptionsForWeekPlan)

Vue.component('customweeknumberselector', CustomWeekNumberSelector)

// Vue.component('bookdetails', BookDetails)
Vue.component('levelsettings', LevelSettings)
Vue.component('extratasks', ExtraTasks)
Vue.component('courseindex', CourseIndex)
Vue.component('courseshow', CourseShow)
Vue.component('plannednotes', PlannedNotes)
Vue.component('studentimport', StudentImport)
Vue.component('feedbackform', FeedbackForm)
Vue.component('manualgroupimport', ManualGroupImport)
Vue.component('schoolimport', SchoolImport)
Vue.component('pictoindex', PictoIndex)
Vue.component('weekplanactivedate', weekPlanActiveDate)
Vue.component('printoptionsback', PrintOptionsBack)
Vue.component('evalutationitems', EvalutationItems)
Vue.component('usegrouplevelssettings', UseGroupLevelsSettings)
Vue.component('weekplandashboard', WeekPlanDashboard)
Vue.component('registrationform', RegistrationForm)


Vue.component('youtubewithtableofcontents', youtubeWithTableOfContents)

axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content')



document.addEventListener("turbolinks:load", function(){

  if (document.getElementById("main-wrapper").dataset.useVue == 'true') {

    const app = new Vue({
      el: '[data-behaviour="vue"]',
      mixin: [],
    })
  }
});