<template>
  <div class="list-group-item d-flex justify-content-between">
    <a :href="file.url" target="_blank" :class="{'disabled': loading}">
      <img :src="file.preview_url" width="80" class="mb-2 mr-2 align-self-center img-thumbnail" v-if="file.preview_url" />
      <div v-if="!file.preview_url" style="width: 80px;" class="text-center">
        <i class="fa fa-file" style="font-size: 60px;"></i>
        <br>
      </div>
    </a>
    <div class="text-center w-100">
      <div><strong>{{ file.name }}</strong></div>
      <div v-if="showConversionBtn">
        <div class="ml-2 alert alert-warning text-left small" v-if="showConversionText">
          <center><h6><i class="far fa-file-word"></i> Word Document</h6></center>
          Een Word Document kan alleen geopend worden door leerlingen die  Word hebben geïnstalleerd. Als het document niet door leerlingen ingevuld of veranderd hoeft te worden, maar alleen bekeken, dan is een PDF document handiger. Klik op onderstaande knop om het document automatisch te veranderen naar PDF formaat.
          <div v-if="converting" class="text-center mt-3"><i class="fa fa-spinner fa-spin"></i></div>
        </div>
        <div class="mt-2 d-flex justify-content-around align-items-center mx-auto">
          <div>
            <button v-if="!converting" @click="convertToPdf" class="btn btn-sm btn-warning mt-2 mr-2">Verander naar PDF</button>
            <i @click="showConversionText = !showConversionText" class="fa fa-info-circle"></i>
          </div>
        </div>
      </div>
      <div class="btn-group mt-3">
        <button @click="deleteFile" class="btn btn-sm btn-outline-danger" :disabled="loading">
          Verwijderen <i class="fa fa-trash"></i>
        </button>
      </div>
    </div>
  </div>

</template>

<script>
export default {

  name: 'digitalWeekPlanFile',
  props: ['file'],
  data () {
    return {
      loading: false,
      converting: false,
      showConversionText: false
    }
  },
  computed: {
    showConversionBtn() {
      if (this.file.content_type.includes('word')) {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    convertToPdf() {
      this.loading = true
      this.converting = true
      this.$emit('convert', this.file.id)
    },
    deleteFile() {
      this.loading = true
      this.$emit('delete', this.file.id)
    }
  }
}
</script>

<style lang="css" scoped>
</style>