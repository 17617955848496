<template>
  <div @click="emitClick" class="card visible-on-hover" :style="`height: ${plannedNote.duration*2}px; overflow: hidden; border-radius: 0; border: 0; border-left: 6px solid green; outline: 1px solid #000; background-color: transparent; cursor: pointer; position: absolute; top: ${top}px; left: -15px; width: 350px;`">
    <div class="card-body py-1">
      <div class="d-flex justify-content-between">
        <span>
          <strong style="font-size: 11pt;">{{ plannedNote.category.name }}</strong>
        </span>
        <small class="text-muted">
          {{plannedNote.starts_at_time}} - {{ plannedNote.ends_at_time }}
        </small>
      </div>

    </div>
  </div>
</template>

<script>
export default {

  name: 'dashboardPlannedNote',
  props: ['plannedNote', 'useColors', 'firstLessonTime'],
  data () {
    return {

    }
  },
  methods: {
    emitClick() {
      this.$emit('click')
    }
  },
  computed: {
    top() {
      return (this.plannedNote.seconds_since_midnight - this.firstLessonTime) * 2
    }
  }
}
</script>

<style lang="css" scoped>
  .visible-on-hover{
    opacity: 0.4;
    color:  transparent;
    background-color: white !important;
  }
  .visible-on-hover .text-muted{
    color:  transparent !important;
  }
  .visible-on-hover:hover{
    opacity: 1.0;
    color:  black;
  }
  .visible-on-hover:hover .text-muted{
    color: #6c757d!important;
  }
  
</style>