<template>
  <div>
    <div class="pt-5 mt-5 text-center" v-if="loading">
      <i class='fa fa-spinner fa-spin'></i>
    </div>  
    <div v-if="!loading" id="container">
      <div id="head" class="bg-white text-center pt-2">
        <div class="container">
          <div class="row">
            <div class="col text-center">
              <h3>{{ currentDay.day }} {{ currentDay.date }} <span v-if="false && viewMode == 'details_plus'" class="badge badge-primary">{{ timestampString }}</span></h3>
            </div>
          </div>
        </div>
      </div>
      <div id="mode-switch" class="btn-group-vertical animated slideInLeft">
        <button @click="setViewMode('time')" :class="{active: viewMode == 'time'}" class="btn btn-outline-primary"><i class="fa fa-clock"></i> Tijdlijn</button>
        <button @click="setViewMode('details')" :class="{active: viewMode == 'details'}" class="btn btn-outline-primary text-left"><i class="fa fa-list"></i> Details</button>
        <button @click="setViewMode('details_plus')" :class="{active: viewMode == 'details_plus'}" class="btn btn-outline-primary text-left"><i class="fa fa-step-forward"></i> Live</button>
      </div>  
      <div id="content" class="bg-white" v-if="viewMode == 'details'">
        <div class="container">
          <div class="row">
            <div style="height: 12px;" class="bg-white text-center" v-if="currentDay.week_plan_days.length == 1"></div>
            <div 
              class="mx-auto pb-5" 
              :class="{'col-7': (currentDay.week_plan_days.length == 1), 'col': (currentDay.week_plan_days.length > 1) }"  
              style="padding-top: 2px; overflow: auto;" 
              v-for="weekPlanDay in currentDay.week_plan_days"
              :key="weekPlanDay.id"
              >
              <div class="text-center sticky-top pt-2 bg-white" style="height: 30px; margin-bottom: 1px;" v-if="currentDay.week_plan_days.length > 1"> 
                <h4 class="h6">{{ weekPlanDay.group.name_with_group }}</h4>
              </div>
              <div class="card mb-3" v-if="weekPlanDay.comment && weekPlanDay.comment.length">
                <div class="card-body bg-light" style="white-space: pre;">{{ weekPlanDay.comment }}</div>
              </div>
              <div class="card mb-3" v-if="!weekPlanDay.is_active">
                <div class="card-body bg-warning">
                  <div v-if="weekPlanDay.reason_for_inactive">{{ weekPlanDay.reason_for_inactive }}</div>
                  <div v-if="!weekPlanDay.reason_for_inactive">Geen les vandaag</div>
                </div>
              </div>
              <div v-if="weekPlanDay.is_active">
              <DashboardLessonDetails 
                @update="setData"
                
                v-for="lesson in weekPlanDay.lessons" 
                :lesson="lesson" 
                :timestamp="timestamp"
                :use-timestamp="false"
                :large="false"
                :students="weekPlanDay.group.students"
                :week-plan-id="weekPlanId"
                :use-colors="useColors"
                :hide-day="true"
                :day="currentDay"
                :lesson_methods="lesson_methods"
                :show-comment-field="showCommentField"
                :key="`lesson-details-${lesson.id}`" 
              />
              </div>
              
            </div>
          </div>
        </div>
      </div>
      <div id="content" class="bg-white" v-if="viewMode == 'details_plus'">
        <div class="container">
          <div class="row">
            <div style="height: 12px;" class="bg-white text-center" v-if="currentDay.week_plan_days.length == 1"></div>
            
            <div 
              class="mx-auto pb-5" 
              :class="{'col-7': (currentDay.week_plan_days.length == 1), 'col': (currentDay.week_plan_days.length > 1) }"  
              style="padding-top: 2px; overflow: auto;" 
              v-for="weekPlanDay in currentDay.week_plan_days"
              :key="weekPlanDay.id"
              >
              <div class="text-center sticky-top pt-2 bg-white" style="height: 30px; margin-bottom: 1px;" v-if="currentDay.week_plan_days.length > 1"> 
                <h4 class="h6">{{ weekPlanDay.group.name_with_group }}</h4>
              </div>
              <div class="card mb-3" v-if="weekPlanDay.comment && weekPlanDay.comment.length">
                <div class="card-body bg-light">
                  {{ weekPlanDay.comment }}
                </div>
              </div>
              <div class="card mb-3" v-if="!weekPlanDay.is_active">
                <div class="card-body bg-warning">
                  <div v-if="weekPlanDay.reason_for_inactive">{{ weekPlanDay.reason_for_inactive }}</div>
                  <div v-if="!weekPlanDay.reason_for_inactive">Geen les vandaag</div>
                </div>
              </div>
              <div v-if="weekPlanDay.is_active">
              <DashboardLessonDetails 
                @update="setData"
                @click="openModal(lesson, weekPlanDay.group.students)"
                v-for="lesson in weekPlanDay.lessons" 
                :lesson="lesson" 
                :large="false"
                :students="weekPlanDay.group.students"
                :week-plan-id="weekPlanId"
                :use-colors="useColors"
                :hide-day="true"
                :timestamp="timestamp"
                :use-timestamp="true"
                :day="currentDay"
                :lesson_methods="lesson_methods"
                :show-comment-field="showCommentField"
                :key="`lesson-details-plus-${lesson.id}`" 
              />
              </div>
              
            </div>
          </div>
        </div>
      </div>
      <div id="content" class="bg-white" v-if="viewMode == 'time'">
        <div class="container">
          <div class="row">
            <div class="col-2" v-if="currentDay.week_plan_days.length == 1"></div>
            <div class="col-1">
              <div style="height: 18px;" class="bg-white sticky-top text-center" v-if="currentDay.week_plan_days.length > 1"></div>
              <div v-for="time in currentDay.times" class="d-flex align-items-center justify-content-center fifteen-minutes-block" style="height: 30px;">
                <span style="z-index: 1; background: #fff; padding: 2px; font-size: 12px; font-weight: 900;" v-if="time.full_hour">{{ time.time }}</span>
                <span style="z-index: 1; background: #fff; padding: 2px; font-size: 10px;" v-if="!time.full_hour">{{ time.time }}</span>
                <div v-if="!time.full_hour" style="z-index: 0; height: 1px; background: #dedede; width: 100vw; position: absolute; left: 0px; right: 0px;"></div>
                <div v-if="time.full_hour" style="z-index: 0; height: 1px; background: #007bff; width: 100vw; position: absolute; left: 0px; right: 0px;"></div>

              </div>
              <div v-if="showCurrentTimeLine" :style="`z-index: 0; height: 1px; background: red; width: 100vw; position: absolute; left: 0px; right: 0px; top: ${(minutesToday * 2) + firstTimeOffset}px;`"></div>
            </div>
            <div class="col pb-5" style="padding-top: 2px; max-width: 500px;" v-for="weekPlanDay in currentDay.week_plan_days">
              <div class="text-center sticky-top pt-2 bg-white" style="height: 30px; margin-bottom: 1px; " v-if="currentDay.week_plan_days.length > 1"> 
                <h4 class="h6">{{ weekPlanDay.group.name_with_group }}</h4>
              </div>
              <div style="height: 12px;" class="bg-white text-center" v-if="currentDay.week_plan_days.length == 1"></div>
              <div class="card mb-3 sticky-top" v-if="!weekPlanDay.is_active">
                <div class="card-body bg-warning">
                  <div v-if="weekPlanDay.reason_for_inactive">{{ weekPlanDay.reason_for_inactive }}</div>
                  <div v-if="!weekPlanDay.reason_for_inactive">Geen les vandaag</div>
                </div>
              </div>
              <div v-if="weekPlanDay.is_active" style="position: relative;">
                <DashboardLesson 
                  v-for="lesson in weekPlanDay.lessons" 
                  :use-colors="useColors" 
                  :lesson="lesson" 
                  :key="`lesson-${lesson.id}`" 
                  @click="openModal(lesson, weekPlanDay.group.students)"
                />
                <DashboardPlannedNote 
                  v-for="planned_note in weekPlanDay.planned_notes" 
                  :use-colors="useColors" 
                  :first-lesson-time="FirstLessonTime(weekPlanDay)"
                  :planned-note="planned_note" 
                  :key="`note-${planned_note.id}`" 
                  @click="openNoteModal(planned_note, weekPlanDay.group.students)"
                />
              </div>
              
            </div>
          </div>
        </div>
      </div>
      <div id="footer" class="bg-light shadow-sm py-1 border-top" v-if="viewMode != 'details_plus'">
        <div class="container">
          <div class="row animated slideInUp">
            
            <div class="col">
              <ul class="nav nav-pills nav-fill">
                <li class="nav-item" v-for="day in days">
                  <button :key="day.name" @click="selectDay(day)" class="btn nav-link btn-block " :class="{active: activeDay == day.day}">{{ day.day }}</button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="modal" tabindex="-1" role="dialog" aria-labelledby="modalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg modal-dialog-centered" role="document">

        <div class="modal-content m-0 p-0">
          <DashboardLessonDetails 
            v-if="selectedLesson"
            @update="setData"
            :lesson="selectedLesson" 
            :students="selectedStudents"
            :week-plan-id="weekPlanId"
            :large="true"
            :timestamp="timestamp"
            :use-timestamp="false"
            :day="currentDay"
            :hide-day="true"
            :lesson_methods="lesson_methods"
            :use-colors="useColors"
            :show-comment-field="showCommentField"
            :key="`lesson-modal-${selectedLesson.id}`"
            style="margin: 0px !important;"
          />
        </div>
      </div>
    </div>  


    <div class="modal fade" id="note-modal" tabindex="-1" role="dialog" aria-hidden="true" v-if="selectedPlannedNote">
      <div class="modal-dialog modal-md modal-dialog-centered" role="document">

        <div class="modal-content m-0 p-0">
          <div class="card pb-0" >
            <div class="card-header pb-0 pt-2">
              <div class="d-flex justify-content-between">
                <h4>{{ selectedPlannedNote.category.name }}</h4>
                <div>
                  <small class="text-muted">{{selectedPlannedNote.starts_at_time}} - {{ selectedPlannedNote.ends_at_time }}</small>
                </div>
                
              </div>

            </div>    
          </div>
          <div class="card-body">
            <div v-html="selectedPlannedNote.body" />
            <ul>
              <li v-for="student in selectedPlannedNote.students">{{ student.name }}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>  

  </div>
</template>

<script>
import axios from 'axios'
import DashboardLesson from './dashboardLesson.vue'
import DashboardLessonDetails from './dashboardLessonDetails.vue'
import DashboardPlannedNote from './dashboardPlannedNote.vue'

export default {

  name: 'weekPlanDashboard',
  props: ['weekPlanId', 'useColors', 'showCommentField', 'defaultView', 'lesson_methods'],
  components: {DashboardLesson, DashboardLessonDetails, DashboardPlannedNote},
  data () {
    return {
      days: [],
      selectedStudents: [],
      loading: true,
      timestampString: '',
      timestamp: 0,
      selectedLesson: null,
      selectedPlannedNote: null,
      viewMode: 'time',
      hours: 9,
      firstTimeOffset: 0,
      activeDay: 'Maandag',
      currentDay: null
    }
  },

  computed: {
    showCurrentTimeLine(){
      return this.hours > 7 && this.hours < 16
    },
    startMinutes(){
      for (var i = 0; i < this.currentDay.week_plan_days.length; i++) {
        let wpd = this.currentDay.week_plan_days[i]
        if (wpd.lessons.length > 0){
          return wpd.lessons[0].timestamp_start
        }
      }
      return 0;
    },
    minutesToday() {

      // console.log(this.startMinutes)
      // console.log(this.timestamp)
      return this.timestamp - this.startMinutes
    }
  },
  methods: {
    FirstLessonTime(wpd){
      if (wpd.lessons.length > 0){
        return wpd.lessons[0].seconds_since_midnight
      } else {
        return 510
      }
    },
    setViewMode(mode) {
      this.viewMode = mode
      axios.put('/usersetting.json', {
        key: 'default_digital_view',
        value: mode
      }).then((response) => {
        // TODO
      }).catch((error) => {
        console.error(error);
      }).finally(() => {
        // TODO
      });

      setTimeout(this.scrollToActiveLesson, 1000)
    },
    sorter(a,b){
      return parseInt(a.offsetTop) > parseInt(b.offsetTop)
    },
    scrollToActiveLesson(){
      if (this.viewMode == 'details_plus'){
      
        const items = document.getElementsByClassName('showing-progress');
        const visible = [...items].filter((el) => {
          return !!( el.offsetWidth || el.offsetHeight || el.getClientRects().length );
        });
        // console.log(visible)
        const sortedVisible = visible.sort(function (a,b) {
          return a.offsetTop - b.offsetTop
        })

        if (sortedVisible.length > 0) {
          // console.log(items[0].offsetTop)
          sortedVisible[0].scrollIntoView({ behavior: 'smooth' });
        }
        
      }
      
    },
    openNoteModal(planned_note, students){
      this.selectedPlannedNote = planned_note
      $("#note-modal").modal('show')
    },
    openModal(lesson, students) {
      this.selectedStudents = students
      this.selectedLesson = lesson
      $("#modal").modal('show')
    },
    setData(data) {
      this.days = data.days
    },
    selectDay(day) {
      this.activeDay = day.day
      this.currentDay = day
    },
    selectDayNumber(daynum) {
      var d = daynum
      if (daynum == 6 || daynum == 0){
        d = 1
      }
      var day = this.days[d-1]
      if (day != undefined){
        this.selectDay(day)  
      }
      
    },
    setTimeOffset(){
      if (this.viewMode == 'time'){
        const items = document.getElementsByClassName('fifteen-minutes-block');
        if (items.length > 0){
          this.firstTimeOffset = items[0].offsetTop + 15
        }
      }
    },
    getNow()  {
      if (this.viewMode == 'details_plus' || this.viewMode == 'time'){
        if (this.firstTimeOffset == 0){
          this.setTimeOffset()
        }
        const today = new Date()
        const hours = today.getHours();
        this.hours = hours
        const daynum = today.getDay()
        if (this.viewMode == 'details_plus'){
          this.selectDayNumber(daynum)
        }
        const date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
        let min = (today.getMinutes() < 10 ? '0' : '') + today.getMinutes()
        const time =  hours + ":" + min;
        const dateTime = time;

        this.timestampString = dateTime;
        this.timestamp = (hours * 60)+ today.getMinutes()
      }
    },
    fetchData() {
      axios.get(`/week_plans/${this.weekPlanId}/dashboard.json`)
      .then((response) => {
        this.loading = false
        this.days = response.data.days
        this.selectDay(this.days[0])
        for (var i = 0; i < this.days.length; i++) {
          if (this.days[i].is_today){
            this.selectDay(this.days[i])
          }
        }
      }).catch((error) => {
        console.error(error);
      }).finally(() => {
        // TODO
        this.setTimeOffset()
      });
    }
  },
  created() {
    this.viewMode = this.defaultView
    this.getNow()
    setInterval(this.getNow, 5000)
    setTimeout(this.scrollToActiveLesson, 1000)
    this.fetchData()
  }
}
</script>

<style lang="css" scoped>
  #container {
    display: grid;
    height: calc(100vh - 109px) /* Fallback for browsers that do not support Custom Properties */;
    width: 100vw;
    grid-template-columns: 1fr;
    grid-template-rows: 50px 1fr auto;
  }
  #head {
    grid-row-start: 1;
  }
  #content {
    grid-row-start: 2;
    overflow-x: hidden;
    width: 100vw;
    position: relative;
  }
  #footer {
    grid-row-start: 3;
  }
  #mode-switch {
    position: fixed;
    left: -1px;
    top: 186px;
    z-index: 800;
  }
  #mode-switch .btn {
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
  }
  
</style>