<template>
  <div>
    <youtube :video-id="youtubeId" ref="youtube" :player-vars="playerVars" @playing="playing"></youtube>
    <div class="row">
      <div class="col pt-2">
        <strong>Hoofdstukken Handleiding:</strong>
        <ol class="text-left links mx-auto" style="column-count: 3;">
          <li @click="seek(17)">Planning maken</li>
          <li @click="seek(187)">Instellingen</li>
          <li @click="seek(327)">Niveaus instellen</li>
          <li @click="seek(436)">Lesstof inplannen</li>
          <li @click="seek(764)">Extra opties</li>
          <li @click="seek(965)">Printen</li>
          <li @click="seek(1168)">Kopiëren</li>
          <li @click="seek(1230)">Digitale weektaak</li>
          <li @click="seek(1435)">Voorbeelden</li>
        </ol>    
      </div>
    </div>
  </div>
</template>

<script>
export default {

  name: 'youtubeWithTableOfContents',
  props: ['youtubeId'],
  data () {
    return {
      playerVars: {
        autoplay: 0,
        rel: 0
      }
    }
  },
  methods: {
    playVideo() {
      this.player.playVideo()
    },
    playing() {
      console.log('\o/ we are watching!!!')
    },
    seek(seconds) {
      this.player.seekTo(seconds, true)
    }
  },
  computed: {
    player() {
      return this.$refs.youtube.player
    }
  }
}
</script>

<style lang="css" scoped>
ol.links li {
  text-decoration: underline;
  cursor: pointer;
}
</style>