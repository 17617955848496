<template>
  <div class="row py-4 px-3">
    <a :href="file.url" target="_blank" style="position: relative;" class="d-block col-6 col-md-3 col-lg-2 text-center mx-auto p-2 rounded shadow-sm" :key="index" v-for="(file, index) in files">
      <img :src="file.preview_url" width="100%" class="mb-2" v-if="file.preview_url" />
      <div v-if="!file.preview_url">
        <i class="fa fa-file" style="font-size: 60px;"></i>
        <br>
      </div>

      <small><em>{{ file.name }}</em></small>
    </a>
  </div>
</template>

<script>
export default {

  name: 'filesView',
  props: ['files'],
  data () {
    return {

    }
  }
}
</script>

<style lang="css" scoped>
</style>