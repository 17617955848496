<template>
  <div class="card lesson" v-bind:class="{'draggable-lesson': !hover}" :style="lessonStyle" :data-position="lesson.position" :data-lesson-id="lesson.id" data-no-dragscroll @mouseleave="mouseLeave">
    <div class="card-body bg-white noselect" data-no-dragscroll v-on:dblclick="doubleClick" >

      <!-- icon top left -->
      <div class="icon left">
        <i @click="toggleVisible" v-if="lesson.visible_for_student" class="far fa-eye active"></i>
        <i @click="toggleVisible" v-if="!lesson.visible_for_student" class="far fa-eye-slash"></i>
      </div>      
      
      <!-- icon top right -->
      <div class="icon right" v-bind:class="{'completed': lesson.is_completed}">
        <i @click="showModal" class="fas fa-check"></i>
      </div>

      <!-- lesson name (title) -->
      <div @click="expand" class="lesson-name text-dark">
        <span v-if="isLoading" class="text-muted"><i class="fa fa-spinner fa-spin"></i> {{ lesson.course_name }}</span>
        <span v-if="!isLoading" :id="`lesson-${lesson.id}`" class="truncate" style="display: inline-block; max-width: 120px">{{ lesson.course_name }}</span>
      </div>

    </div>
    
    <div class="card-footer bg-white px-1 py-2 ">
        <div v-if="!lesson.is_clockless" @click="expand" class="lesson-time d-flex justify-content-between">
          <span class="badge badge-secondary">{{ this.fromTime | moment("HH:mm") }}-{{ this.untilTime | moment("HH:mm") }}</span>
          <span class='badge badge-secondary'>{{lesson.duration }} min.</span>
        </div>

        <div class="my-3" v-if="hover && !lesson.is_clockless">
          <number-input @change="sliderUpdated" v-model="duration" :min="0" :max="500" inline controls :step='5' size="small" :center="true"></number-input>
        </div>

        <div v-show="hover" class="btn-group w-100 fadeIn" data-no-dragscroll>
          <button @click="deleteLesson" class="btn btn-outline-danger btn-sm py-1 small" style="font-size: 10px">
            <i class="fas fa-trash"></i>
          </button>
          <button class="btn btn-outline-primary btn-sm py-1 small" @click="showModal">Lesstof</button>

          <button v-if="!isCombi" @click="duplicate(weekPlanDay.group_id)" class="btn btn-outline-success btn-sm py-1 small" style="font-size: 10px">
            <i class="far fa-copy"></i>
          </button>

          <button v-if="isCombi" class="btn btn-outline-success btn-sm py-1 small" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="far fa-copy"></i>
          </button>
          <div v-if="isCombi" class="dropdown-menu">
            <div @click="duplicate(group.group_id)" v-for="group in groups" class="dropdown-item">{{group.group}}</div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>

import Weektaak from '../../packs/weektaak'
import VueNumberInput from '@chenfengyuan/vue-number-input'


export default {
  components: {
    numberInput: VueNumberInput
  },
  props: ["lesson", "weekPlanDay", "trash", "timeString", "lessonMethods", "weekPlanId", "usecolor", "isLoading"],
  data () {
    return {
      hover: false,
      duration: 0,
      isExpanding: false,
    }
  
  },
  created() {
    this.duration = this.lesson.duration
  },
  methods: {
    doubleClick(){
      this.showModal
    },
    expand() {
      if (this.isExpanding == false) {
        this.isExpanding = true
        this.hover = !this.hover
        setTimeout(function() {
          this.isExpanding = false
        }.bind(this), 150)
      } else {

      }
      
    },
    mouseLeave() {
      this.hover = false
    },
    deleteLesson () {
      Weektaak.deleteLesson(this.lesson.id, this.weekPlanId)
    },
    duplicate (groupId) {
      Weektaak.duplicateLesson(this.lesson.id, groupId)
    },
    sliderUpdated () {
      this.lesson.duration = this.duration
      Weektaak.updateLessonDuration(this.weekPlanId, this.lesson.id, this.lesson.duration)
    },
    toggleVisible(){
      console.log('setting visibility')
      if (this.lesson.visible_for_student) {
        Weektaak.updateLessonVisible(this.lesson.id, false)
      } else {
        Weektaak.updateLessonVisible(this.lesson.id, true)
      }
    },
    saveLessonVisibility () {
      Weektaak.updateLessonVisible(this.lesson.id, this.lesson.visible_for_student)
    },
    clickLesson(nextOrPrevious){
      if (nextOrPrevious) {
        var selector = "lesson-" + nextOrPrevious.id
        document.getElementById(selector).click()
        this.$refs.lessonModal.hide()
      }
    },
    showModal (){
      $("#exampleModal").modal('show')
      window.store.dispatch('setModalLessonId', this.lesson.id)  
    },
    fetchLessonLevels (){
      Weektaak.fetchLesonLevels(this.lesson.id)
        .then((result) => {
          this.lessonLevels   = result.lesson_levels
          this.nextLesson     = result.next_lesson
          this.previousLesson = result.previous_lesson

        }).catch((error) => {
          console.log(error)
        })
    }
  },
  computed: {
    weekPlan () {
      return window.store.getters.weekPlan
    },
    groups () {
      return this.weekPlan.group_courses
    },
    isCombi(){
      return (this.groups.length > 1)
    },
    lessonStyle (){
      if (this.hover) {
        var padding = "box-shadow: 0 .125rem .25rem rgba(0,0,0,.25)!important; margin-bottom: 15px;"
      } else {
        var padding = ""
      }
      if (this.usecolor) {
        return "border: 2px solid " + this.lesson.course_color + ";"  + padding
      } else {
        return padding
      }
      
    },
    previousLessonBtnClass () {
      if (this.previousLesson) {
        return "btn btn-outline-primary btn-sm"
      } else {
        return "btn btn-outline-primary disabled btn-sm"
      }
    },
    nextLessonBtnClass () {
      if (this.nextLesson) {
        return "btn btn-outline-primary btn-sm"
      } else {
        return "btn btn-outline-primary disabled btn-sm"
      }
    },
    isLoaded () {
      return this.lessonLevels.length > 0
    },
    durationOfPreviousLessons () {
      let duration = 0
      for (var lesson of this.weekPlanDay.lessons) {
        if (lesson === this.lesson) {
          break
        } else {
          duration += lesson.duration
        }
      }
        
      return duration
    },
    lessonsForDay (){
      window.store.getters.lessonsForDay(this.weekPlanDay.day)
    },
    fromTime () {
      return this.weekPlanDay.starts_at + this.durationOfPreviousLessons*60
    },
    untilTime () {
      return this.fromTime + this.lesson.duration*60
    }
  }
}
</script>

<style scoped>
  .card {
    position: relative;
  }
  .lesson{
    cursor: move;
  }
  .card.lesson:hover{
    z-index:  9999;
    transform: scale(1.06);
    box-shadow: 0 1px 1px rgba(0,0,0,0.12), 
              0 2px 2px rgba(0,0,0,0.12), 
              0 4px 4px rgba(0,0,0,0.12), 
              0 8px 8px rgba(0,0,0,0.12),
              0 16px 16px rgba(0,0,0,0.12) !important;
  }
  .card-body .icon{
    position: absolute;
    top: 5px;
    font-size: 8px;
    color: rgba(0,0,0, 0.1);
  }
  .card-body .icon.right{
    right: 5px;
  }
  .card-body .icon.left{
    left: 5px;
  }
  .icon.left i, .icon.left i:hover, .icon.right i, .icon.right i:hover{
    cursor: pointer;
  }
  .card-body .icon.completed{
   color: #28a745; 
  }
  .icon .active{
    color: #333;
  }
  .card-footer {
    /*border-top: 1px solid rgba(0,0,0,.05);*/
    border-top: 0;
    padding-top: 2px !important;
    padding-bottom: 3px !important;
  }
  .badge{
    font-weight: 300;
    font-size: 90%;
  }
  .badge.badge-secondary{
    background-color: #fff;
    color: #333;
    border: 1px solid #dedede;
  }
  .lesson-drag {
    transform: scale(1.1) !important;
  }

</style>
