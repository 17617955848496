<template>
  <div class="pt-4">
    <vue-clip :options="options" :on-complete="complete" v-if="groups.length == 0">
      <template slot="clip-uploader-action">
        <div class="row ">
          <div class="dz-message col-8 mx-auto bg-light rounded p-4 text-center my-5">{{prompt}}</div>
        </div>
      </template>

      <template slot="clip-uploader-body" slot-scope="props">
      </template>

    </vue-clip>
    <div class="alert alert-warning" role="alert" v-if="errorMessage != ''">
      {{errorMessage}}
    </div>

    <div class="mt-4" :group="group" v-for="(group, i) in groups">
      <StudentImportGroup :group="group" :selectedStudents="selectedStudents" @update="setSelectedStudents" :key="`group-${i}`" />
    </div>

    <div v-if="!groups.length == 0">
      <p class="lead text-center">Klopt de lijst hierboven?<br>Klik dan op de knop hieronder om de import definitief door te voeren.</p>
      <button class="btn btn-success btn-block" :disabled="btnDisabled" @click="submitList" v-bind:class="{'disabled' : btnDisabled}">
        <span v-if="!btnDisabled">{{ selectedStudents.length }} leerlingen importeren</span>
        <span v-if="btnDisabled"><i class='fa fa-spinner fa-spin'></i> Even geduld... {{ selectedStudents.length }} leerlingen worden geïmporteerd.</span>

      </button>
    </div>

  </div>


</template>

<script>

import StudentImportGroup from '../../components/settings/studentImportGroup.vue'
import axios from 'axios'

export default {
  components: {
    StudentImportGroup
  },
  props: ["prompt", "exporttype"],

  data () {
    return {
      groups: [],
      selectedStudents: [],
      fileId: null,
      btnDisabled: false,
      errorMessage: '',
      options: {
        url: '/student_imports?import_type=' + this.exporttype,
        paramName: 'file',
        uploadMultiple: false,
        // acceptedFiles: {
        //   extensions: this.acceptedFileTypes(),
        //   message: 'Het gekozen bestand is geen geldig XML bestand.'
        // }
      }
    }
  },
  computed: {
  },
  methods: {
    submitList() {
      this.btnDisabled = true
      return axios.post(`/student_imports/${this.fileId}/sync_students.json`, {student_ids: this.selectedStudents })
      .then(response => {
        // this.btnDisabled = false
        window.location.href = response.data.url
      })
      .catch(error => {
        alert(`Er ging iets mis bij het verwerken van deze import. Neem contact op met info@weektaak.com.`)
        this.btnDisabled = false
      })

    },
    setSelectedStudents(list) {
      // console.log('update!')
      // console.log(list)
      this.selectedStudents = list
    },
    disableBtn () {
      this.btnDisabled = true
    },
    selectAllStudents() {
      this.selectedStudents = this.groups.map(g => g.students.map(s => s.number)).flat()
    },
    acceptedFileTypes () {
      // if (this.exporttype == 'edex') {
      //   return ['application/xml', 'text/xml']
      // } else if (this.exporttype == 'parnassys_xls') {
      //   return ['application/vnd.ms-excel', 'application/msexcel', 'application/x-msexcel', 'application/x-ms-excel', 'application/x-excel', 'application/x-dos_ms_excel', 'application/xls', 'application/x-xls', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/xml', 'text/xml']
      // }
    },

    complete (file, status, xhr) {
      console.log(xhr.response)
      var response = JSON.parse(xhr.response)
      if (response.error) {
        // alert("Het geüploadde bestand is onjuist.")
        this.errorMessage = response.message
      } else {
        this.errorMessage = ""
        this.groups = response.groups
        this.fileId = response.id  
        this.selectAllStudents()
      }  
    }
  }
  
}
</script>

<style scoped>
.dz-message{
  border: 2px dashed #dedede;
  cursor: pointer;
}

</style>
