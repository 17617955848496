<template>
  <div>
    <div class="row">
      <PlannedNoteCategory 
        :key="plannedNoteCategory.id" 
        :icons="icons" 
        :planned-note-category="plannedNoteCategory" 
        v-for="plannedNoteCategory in thePlannedNoteCategories" 
        @delete="deletePlannedNote"
      />
    </div>  
    <div class="row">
      <div class="col col-md-6 mx-auto bg-light rounded p-3" v-show="addMode">
        <h6>Hoe heet de nieuwe zorgcategorie?</h6>
        <div class="input-group">
          <input ref="name" class="form-control" @keyup.enter="submitNew" v-model="name" />
          <div class="input-group-append">
            <button class="btn btn-success" type="button" @click="submitNew" :disabled="name == ''"><i class="fa fa-plus"></i> Toevoegen</button>
          </div>
        </div>
      </div>
      <div class="py-3 text-center col-12" v-show="addMode">
        <button class="btn btn-link text-muted" @click="addMode = false">Annuleer</button>
      </div>
      <div class="col text-center" v-if="!addMode">
        <button @click="addNew" class="btn btn-success"><i class="fa fa-plus"></i> Zorgcategorie toevoegen</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import PlannedNoteCategory from './PlannedNoteCategory.vue'


export default {
  name: 'PlannedNoteCategories.vue',
  components: { PlannedNoteCategory },
  props: ['plannedNoteCategories', 'icons'],
  data () {
    return {
      thePlannedNoteCategories: [],
      name: '',
      addMode: false
    }
  },
  mounted() {
    this.thePlannedNoteCategories = this.plannedNoteCategories
  },
  methods: {
    submitNew() {
      this.addMode = false
      if (this.name != '') {
        axios.post(`/planned_note_categories.json`, {
          planned_note_category: { name: this.name }
        })
        .then(response => {
          this.name = ''
          this.thePlannedNoteCategories = response.data
        })  
      }
    },
    addNew() {
      this.addMode = true
      setTimeout(function () {
        this.$refs.name.focus()
      }.bind(this), 200)
      
    },
    deletePlannedNote(id){
      return axios.delete(`/planned_note_categories/${id}.json`)
      .then(response => {
        this.thePlannedNoteCategories = response.data
      })
    }
  }
}
</script>

<style lang="css" scoped>
</style>