<template>
  <div>
    <div class="level-label bg-light">{{lessonLevel.level_name}}</div>
    <div class="row mb-0 pt-3" style="min-height: 90px;">
      <div class="col-12 col-lg-4">
        <div v-if="lessonLevel.level_per_student">
          Taak voor {{ lessonLevel.student_name}}:
          <div class="clearfix"></div>
        </div>
        <div class="editor bg-white rounded p-2" style="border: 1px solid #ddd;">
          <label>Taak</label>
          <editor-content class="editor__content" v-model="lessonLevel.task" :editor="editor" />
        </div>

        <div class="editor mt-3 bg-white rounded p-2" style="border: 1px dashed #ddd;" v-if="show_comment_field">
          <label>Opmerking voor leerkracht</label>
          <editor-content class="editor__content" v-model="lessonLevel.comment" :editor="commentEditor" />
        </div>

      </div>
      <div class="col-12 col-lg-4">
        <div v-if="lessonLevel.level_per_student">
          Doel voor {{ lessonLevel.student_name}}:
          <div class="clearfix"></div>
        </div>
        <div class="editor bg-white rounded p-2" style="border: 1px solid #ddd;">
          <label>Doel</label>
          <editor-content class="editor__content" v-model="lessonLevel.goal" :editor="goalEditor" />
        </div>
      </div>

      <div class="col-12 col-lg-4">
        <div class="form-group mb-1">
          <select @change="submitChanges('lesson_method_id')" v-model="lessonLevel.lesson_method_id" class="form-control" id="lesson_method">
            <option selected value="">Selecteer werkvorm...</option>
            <option v-for="method in lessonMethods" :value="method.id">{{ method.name }}</option>
          </select>
        </div>
        <div class="form-check" v-if="visibleForStudent">
          <input class="form-check-input" v-model="lessonLevel.correct" @change="submitChangesWithDelay('correct')" type="checkbox" :id="`LessonLevelCorrectCheckBox${lessonLevel.id}`">
          <label class="form-check-label" :for="`LessonLevelCorrectCheckBox${lessonLevel.id}`">
            Zelf nakijken
          </label>
        </div>
        <div class="form-check" v-if="visibleForStudent">
          <input class="form-check-input" v-model="lessonLevel.show_time" @change="submitChangesWithDelay('show_time')" type="checkbox" :id="`LessonLevelTimeCheckBox${lessonLevel.id}`">
          <label class="form-check-label" :for="`LessonLevelTimeCheckBox${lessonLevel.id}`">
            Toon tijd op weektaak
          </label>
        </div>

        <div class="form-check" v-if="lessonLevel.show_time && !lesson.is_clockless">
          <input class="form-check-input" v-model="lessonLevel.use_custom_time" @change="submitChangesWithDelay('use_custom_time')" type="checkbox" :id="`LessonLevelTimeCheckBox-use_custom_time-${lessonLevel.id}`">
          <label class="form-check-label" :for="`LessonLevelTimeCheckBox-use_custom_time-${lessonLevel.id}`">
            Toon afwijkende tijd
          </label>
        </div>
        <div class="form-group" v-if="(lessonLevel.show_time && lessonLevel.use_custom_time) || (lessonLevel.show_time && lesson.is_clockless)">
          <!-- <masked-input @input="submitChangesWithDelay('time_to_show')" v-model="showTime" mask="11:11 uur" placeholder="Tijd" class="form-control"/> -->
          <input @input="submitChangesWithDelay('time_to_show')" v-model="showTime" placeholder="Tijd" class="form-control"/>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

// import VueTrix from 'vue-trix'
import Weektaak from '../../packs/weektaak'

import MaskedInput from 'vue-masked-input' 
import { Editor, EditorContent, EditorMenuBubble } from 'tiptap'
import {
  Bold,
  Italic,
  Strike,
  Underline,
  Placeholder,
  History,
} from 'tiptap-extensions'



export default {
  components:{
    EditorContent,
    EditorMenuBubble,
    MaskedInput
  },

  props: ["lessonLevel", "lessonMethods", "visibleForStudent", "islocked", "lockedcontent", "show_comment_field", "lesson"],

  watch: {
    lessonLevel: {
      handler: function(oldVal, newVal) {
        if (this.islocked && !this.lessonLevel.islocked) {
          // console.log("updating...")
          // this.saveChanges(null)
          this.editor.setContent(this.lessonLevel.task)  
          this.goalEditor.setContent(this.lessonLevel.goal)  
        }
      },
      deep: true
    }
  },

  data (){
    return {
      showTime: '08:45',
      keepInBounds: true,
      editor: new Editor({
        extensions: [
          new Placeholder({
            emptyClass: 'is-empty',
            emptyNodeText: "Taak",
            showOnlyWhenEditable: false,
          }),
          new Bold(),
          new Italic(),
          new Strike(),
          new Underline(),
          new History(),
        ],
        disableInputRules: true,
        content: this.lessonLevel.task,
        onUpdate: ({ getHTML }) => {
          this.lessonLevel.task = getHTML()
          if (this.islocked) {
            this.$emit('lockedUpdate', this.lessonLevel, 'task')
          }
        },
        onBlur: ({ event,  state, view }) => {
          this.submitChanges('task')
        }
      }),
      goalEditor: new Editor({
        extensions: [
          new Placeholder({
            emptyClass: 'is-empty',
            emptyNodeText: `Doel`,
            showOnlyWhenEditable: false,
          }),
          new Bold(),
          new Italic(),
          new Strike(),
          new Underline(),
          new History(),
        ],
        disableInputRules: true,
        content: this.lessonLevel.goal,
        onUpdate: ({ getHTML }) => {
          this.lessonLevel.goal = getHTML()
          if (this.islocked) {
            this.$emit('lockedUpdate', this.lessonLevel, 'goal')
          }
        },
        onBlur: ({ event,  state, view }) => {
          this.submitChanges('goal')
        }
      }),
      commentEditor: new Editor({
        extensions: [
          new Placeholder({
            emptyClass: 'is-empty',
            emptyNodeText: `Extra opmerking`,
            showOnlyWhenEditable: false,
          }),
          new Bold(),
          new Italic(),
          new Strike(),
          new Underline(),
          new History(),
        ],
        disableInputRules: true,
        content: this.lessonLevel.comment,
        onUpdate: ({ getHTML }) => {
          this.lessonLevel.comment = getHTML()
          if (this.islocked) {
            this.$emit('lockedUpdate', this.lessonLevel, 'comment')
          }
        },
        onBlur: ({ event,  state, view }) => {
          this.submitChanges('comment')
        }
      }),

    }
  },
  computed: {
  },
  methods: {
    submitTask(){
      console.log("task blurred")
    },
    submitChangesWithDelay(type) {
      setTimeout(() => this.submitChanges(type), 150)

    }, 
    submitChanges(type) {
      // console.log("submitting...")
      // console.log(this.lessonLevel.task)
      // console.log(this.lessonLevel.correct)
      // console.log(this.lessonLevel.show_time)
      // console.log(this.lessonLevel.lesson_method_id)
      // console.log(this.lessonLevel.goal)
      if (this.islocked) {
        this.$emit('lockedUpdate', this.lessonLevel, type)
      }
      this.$emit('change')

      this.saveChanges(type)
    },
    saveChanges(type){
      Weektaak.updateLessonLevel(this.lessonLevel.id, {
        task: this.lessonLevel.task,
        goal: this.lessonLevel.goal,
        comment: this.lessonLevel.comment,
        lesson_method_id: this.lessonLevel.lesson_method_id,
        show_time: this.lessonLevel.show_time,
        correct: this.lessonLevel.correct,
        locked: this.islocked,
        time_to_show: this.showTime,
        use_custom_time: this.lessonLevel.use_custom_time,
        locked_type: type
      })

    }
  },
  mounted(){
    if (this.lessonLevel.time_to_show && this.lessonLevel.time_to_show.length){
      this.showTime = this.lessonLevel.time_to_show
    } else {
      this.showTime = this.lessonLevel.lesson_time
    }
    
  },
  beforeDestroy() {
    this.editor.destroy()
  }
}
</script>

<style lang="scss">
.editor p{
  margin-bottom: 0px;
}
.editor p.is-empty:first-child::before {
  content: attr(data-empty-text);
  float: left;
  color: #aaa;
  pointer-events: none;
  height: 0;
  font-style: italic;
}
</style>

<style scoped>


  .level-label{
    position: absolute;
    left: -52px;
    background-color: #fff;
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
    width: 80px;
    height: 24px;
    top: 38px;
    font-size: 10px;
    padding-top: 4px;
    text-align: center;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-color: #dedede;
    border-bottom: none !important;
  }
  button.trix-button--icon-code{
    display: none;
  }
  select.form-control{
    /*height: calc(1.5rem + 2px);*/
  }
</style>
