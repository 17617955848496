<template>
  <tr :class="{'no-margin-on-p': large, 'no-margin-on-p-small': !large}">
    <th width="25%" :style="`font-size: ${fontSize(10,12)}pt;`"><span @click="showNames = false" style="cursor: pointer;">{{ lessonLevel.label }}</span></th>
    <td style="overflow-x: auto">
      <div class="bg-light rounded p-2" v-if="showNames">
        {{ lessonLevel }}
      </div>
      <div v-if="lessonLevel.task" v-html="lessonLevel.task" v-linkified></div>
      <div style="font-style: italic; border-top: 1px solid rgba(0,0,0,.125);" v-if="lessonLevel.goal" v-html="lessonLevel.goal"></div>
      <div class="bg-light rounded p-2 mt-1" v-if="showCommentField && lessonLevel.comment" v-html="lessonLevel.comment" v-linkified></div>
    </td>
    <td class="text-right" :style="`font-size: ${fontSize(10,11)}pt; width: ${fontSize(56,70)}px;`">
      <i v-tooltip.top-center="lessonLevel.lesson_method.name" v-if="lessonLevel.lesson_method" :class="`${lessonLevel.lesson_method.show_icon}`"></i>
      <i v-tooltip.top-center="`Zelf nakijken`" v-if="lessonLevel.correct" class="fas fa-pencil-alt"></i>
    </td>
  </tr>
</template>

<script>
export default {

  name: 'dashboardLessonLevelDetails',
  props: ['lesson', 'lessonLevel', 'showCommentField', 'large'],
  data () {
    return {
      showNames: false
    }
  },
  methods: {
    fontSize(s,l) {
      if (this.large) {
        return l
      } else {
        return s
      }
    },
  }
}
</script>

<style lang="css" scoped>
  td p {
    margin-bottom: 0px;
  }
</style>