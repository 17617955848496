<template>
  <div class="bg-light p-3 rounded mt-4">
    <h3>Groepsoverstijgend werken</h3>
    <p>Werken sommige kinderen bij sommige vakken in een andere groep? Bijvoorbeeld: een leerling uit groep 5 doet met rekenen mee met groep 6. Als dat zo is, kun je de functie 'groepsoverstijgend werken' inschakelen.</p>
    <toggle-button @change="toggleSetting" v-model="theSchool.use_group_for_levels" :sync="true" :labels="{checked: 'Groepsoverstijgend werken ingeschakeld', unchecked: 'Groepsoverstijgend werken uitgeschakeld'}" :fontSize="12" :width="325" />      
    <p v-if="theSchool.use_group_for_levels">Je hebt 'groepsoverstijgend werkend' op dit moment ingeschakeld. Bij het bewerken van een weektaak, kun je in het menu kiezen voor 'vakken en niveaus'. Op die plek kun je nu leerlingen niet alleen aan een niveau koppelen, maar ook aan een groep.</p>
  </div>
</template>

<script>
import axios from 'axios'
export default {

  name: 'useGroupLevelsSettings',
  props: ['school'],
  data () {
    return {
      theSchool: {}
    }
  },
  created() {
    this.theSchool = this.school
  },
  methods: {
    toggleSetting() {
      axios.put(`/schools/${this.school.id}.json`, {
        school: this.theSchool
      }).then((response) => {
        // TODO
      }).catch((error) => {
        console.error(error);
      }).finally(() => {
        // TODO
      });
    }
  }
}
</script>

<style lang="css" scoped>
</style>