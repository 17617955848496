<template>
  <div v-if="picto.picto" class="picto-card mb-2 px-1" :data-id="picto.id" :data-picto-id="picto.picto.id" v-bind:class="{'col-4' : size == 'small', 'col-2' : size == 'large' }">
    <div class="card" style="border: 1px solid #000">
      <img :src="picto.picto.image" class="card-img-top mb-2" />

      <div class="card-body px-1 py-0 text-center" style="height: 32px; line-height: 10px;">
        <span v-if="picto.id == null" :class="`${size} card-title`" style="font-size: 8px; font-weight: 700;">{{picto.title}}</span>
        <input 
          v-if="picto.id != null" 
          class="form-control form-control-sm text-center" 
          style="font-weight: 700; font-size: 9pt;"
          placeholder="Titel..." 
          v-model="picto.title"
          @change="submitChanges"
        />
        
      </div>
      <div class="list-group list-group-flush" v-if="size == 'large'">
        <div class="list-group-item small text-center px-1 py-1 border-0">
          <button @click="showModal" class="btn btn-sm btn-block btn-outline-primary">{{buttonTitle}}</button>
        </div>
        <div class="list-group-item p-1 border-0" v-if="!is_day">
          <div class="btn-group w-100" role="group" >
            <button @click="changeFrequency(1)" type="button" class="btn btn-sm btn-outline-primary" v-bind:class="{'active' : picto.number_of_times == 1}">1</button>
            <button @click="changeFrequency(2)" type="button" class="btn btn-sm btn-outline-primary" v-bind:class="{'active' : picto.number_of_times == 2}">2</button>
            <button @click="changeFrequency(3)" type="button" class="btn btn-sm btn-outline-primary" v-bind:class="{'active' : picto.number_of_times == 3}">3</button>
          </div>
        </div>
      </div>
      
    </div>
    
    <div class="modal fade" :id="`picto-modal-${picto.id}`" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Selecteer leerlingen</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Sluiten">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col">
      
                <div v-for="student in students">
                  <div class="form-check">
                    <input class="form-check-input" @change="submitStudentCheck" v-model="picto.student_ids" type="checkbox" :value="student.id" :id="`checkBox${student.id}`">
                    <label class="form-check-label" :for="`checkBox${student.id}`">
                      {{ student.first_name }} {{ student.last_name }}
                    </label>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
import { Editor, EditorContent } from 'tiptap'
import {
  Placeholder,
  History,
} from 'tiptap-extensions'

import axios from 'axios'
export default {

  components:{
    EditorContent
  },
  name: 'picto',
  props: ["picto", "size", "week_plan_id", "students", "is_day"],
  data () {
    return {
      theTitle: '',
      // editor: new Editor({
      //   extensions: [
      //     new Placeholder({
      //       emptyClass: 'is-empty',
      //       emptyNodeText: this.placeholder,
      //       showOnlyWhenEditable: false,
      //     }),
      //     new History(),
      //   ],
      //   content: this.picto.title,
      //   onUpdate: ({ getJSON }) => {
      //     var json = getJSON()
      //     // console.log([0].content[0].text)
      //     this.theTitle = json.content[0].content[0].text
      //     // this.lessonLevel.task = getHTML()
      //     // if (this.islocked) {
      //     //   this.$emit('lockedUpdate', this.lessonLevel, 'task')
      //     // }
      //   },
      //   onBlur: ({ event,  state, view }) => {
      //     this.submitChanges()
      //   }
      // }),

    }
  },
  methods: {
    showModal(){
      $(`#picto-modal-${this.picto.id}`).modal('show')
    },
    submitStudentCheck(){
    return axios.put("/week_plans/" + this.week_plan_id + "/plan_pictos/"+ this.picto.id +".json", {plan_picto: {student_ids: this.picto.student_ids}})
    },
    submitChanges(){
      // console.log(this.picto.title)
      return axios.put("/week_plans/" + this.week_plan_id + "/plan_pictos/"+ this.picto.id +".json", {plan_picto: {title: this.picto.title}})
    },
    changeFrequency(num){
      this.picto.number_of_times = num
      return axios.put("/week_plans/" + this.week_plan_id + "/plan_pictos/"+ this.picto.id +".json", {plan_picto: {number_of_times: num}})
    }
  },
  computed: {
    buttonTitle(){
      if (this.picto.student_ids.length == 0){
        return "Alle leerlingen"  
      } else if (this.picto.student_ids.length == 1) {
        return "1 leerling"  
      } else {
        return `${this.picto.student_ids.length} leerlingen`
      }
      
    },
    placeholder(){
      if (picto.picto){
        return picto.picto.name
      } else {
        return ''  
      }
      
    },
    showingTrash (){
      return window.store.getters.showWeekPlanTrash
    },
  }
}
</script>

<style lang="css" scoped>
.picto-card .editor{
  font-size: 12px;
  font-weight: 700;
}
.large.card-title{
  font-size: 12px !important;
}
.bin-content .col-2{
  /*width: 200px !important;*/
}
.bin-content .picto-card{
  width: 50% !important;
  max-height: 300px;
  background: none;
  margin-top: 40%;
  margin-left: auto;
  margin-right: auto;
  max-width: 50% !important;
  padding: 0px;
  overflow: hidden;
}
</style>