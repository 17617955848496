import { render, staticRenderFns } from "./extraTask.vue?vue&type=template&id=20cda201&scoped=true&"
import script from "./extraTask.vue?vue&type=script&lang=js&"
export * from "./extraTask.vue?vue&type=script&lang=js&"
import style0 from "./extraTask.vue?vue&type=style&index=0&id=20cda201&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20cda201",
  null
  
)

export default component.exports