<template>
  <div>
            <!-- select back -->
            <div class="form-group" v-if="options.includes('back_template')">
              <label for="back_template">Achterkant</label>
              <select class="form-control" name="print_job[options][back_template]" id="back_template">
                <option value="none">Geen achterkant</option>
                <option value="default-6">Achterkant met smileys, {{ klaaropdracht.toLowerCase() }} en doelen</option>
                <option value="default-1">Achterkant met smileys, feedback en 1 tekstveld</option>
                <option value="default-2">Achterkant met smileys, feedback en 2 velden</option>
                <option value="default-4">Achterkant met smileys en 1 tekstveld</option>
                <option value="default-5">Achterkant met smileys en 2 velden</option>
                <option value="default-7">Achterkant met doelen, {{ klaaropdracht.toLowerCase() }} en opmerkingen</option>
                <option value="default-3">Achterkant met doelevaluatie</option>
                <option v-if="evaluation && ['default_portrait', 'autonomous_1', 'large', 'picto_default'].includes(template)" value="evaluation">Achterkant met eigen leerlingevaluatie</option>
              </select>
            </div>
       
          
  
            <!-- select back -->
            <div class="form-group" v-if="options.includes('show_goals_for_student')">
              <label for="show_goals_for_student">Doelen</label>
              <select class="form-control" name="print_job[options][show_goals_for_student]" id="show_goals_for_student">
                <option value="inline">Toon doelen direct bij iedere les</option>
                <option value="block" v-if="template == 'multiple_pages'">Toon doelen verzameld op de achterkant</option>
                <option value="back"  v-if="template == 'per_day'">Toon doelen op de laatste pagina</option>
                <option value="block">Toon doelen verzameld op de voorkant</option>
                <option value="back">Toon doelen op de achterkant</option>
                <option value="none">Toon geen doelen</option>
                
              </select>
            </div>
          
          
            <div class="form-group" v-if="options.includes('extra_tasks_and_comments')">
              <label for="extra_tasks_and_comments">{{ klaaropdracht }}, doelen en opmerkingen</label>
              <select class="form-control" name="print_job[options][extra_tasks_and_comments]" id="extra_tasks_and_comments">
                <option value="every_page">Op elke pagina</option>
                <option value="extra_page">Op aparte pagina</option>
              </select>
            </div>
          
            <!-- select back -->
            <div class="form-group" v-if="options.includes('show_extra_lines')">
              <label for="show_extra_lines">Print extra lijnen bij iedere dag</label>
              <select class="form-control" name="print_job[options][show_extra_lines]" id="show_extra_lines">
                <option value="0">Toon geen extra lijnen</option>
                <option value="2">Toon 2 extra lijnen</option>
                <option value="3">Toon 3 extra lijnen</option>
                <option value="4">Toon 4 extra lijnen</option>
              </select>
            </div>
          
  </div>
</template>

<script>
export default {

  name: 'printOptionsBack',
  props: ['options', 'klaaropdracht', 'evaluation', 'template'],
  data () {
    return {

    }
  }
}
</script>

<style lang="css" scoped>
</style>