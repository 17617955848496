<template>
  <div class="card mb-3 shadow-sm">
    <div class="card-body">
      <div class="row">
        <div class="col-6">
          <div class="form-group">
            <label>Voornaam</label>
            <input type="text" ref="firstname" class="form-control" v-model="user.first_name">
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <label>Achternaam</label>
            <input type="text" class="form-control" v-model="user.last_name">
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <label>E-mail</label>
            <input @blur="validateUser" @keydown.enter="emitEnter" ref="email" type="email" :class="{'is-valid': isValid, 'is-invalid': isInvalid}" class="form-control" v-model="user.email">
            <div class="invalid-feedback" v-if="errorMessage.length">
              {{ errorMessage }}
            </div>
          </div>
          
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {

  name: 'newUser',
  props: ['user', 'index'],
  data () {
    return {
      errorMessage: '',
      isValid: null,
      isInvalid: null
    }
  },
  mounted() {
    this.$refs['firstname'].focus()
  },
  methods: {
    validateUser() {
      this.errorMessage = ''
      this.isValid = null
      this.isInvalid = null
      if (this.user.email.length) {
        return axios.post("/validate-user.json", {user: this.user })
        .then(response => {
          console.log(response.data)
          if (response.data.valid == false) {
            this.isValid = false
            this.isInvalid = true
          } else if (response.data.valid) {
            this.isValid = true
            this.isInvalid = false
          }
          this.errorMessage = response.data.messages
          
        })
        .catch(error => {
          // alert('')
          console.log('error: ')
          console.log(error)
        })
      }
    },
    emitEnter() {
      this.$refs.email.blur()
      this.$emit('enter')
    }
  }
}
</script>

<style lang="css" scoped>
</style>