<template>
  <span class="badge badge-danger" v-show="counter > 0">{{ counter }}</span>
</template>

<script>
export default {

  name: 'unreadMessagesCounter',
  props: ['unread', 'soundEnabled'],
  data () {
    return {

    }
  },
  watch: {
    'counter': function(val, oldVal) {
      // console.log('unread changed')
      if (this.soundEnabled && val > oldVal){
        // console.log('playing sound')
        // console.log(val)
        this.playSound()
      }
    }
  },
  methods: {
    playSound() {
      const audio = new Audio('/sounds/Pop.mp3')
      try {
        audio.play()
      }
      catch(err) {
        console.log('chat error')
        console.log(err)
      }
    }
  },
  computed: {
    counter() {
      // return parseInt(this.initial)
      if (store.getters.unreadChats == undefined) {
        return parseInt(this.unread)
      } else {
        return store.getters.unreadChats
      }
    }
  }
}
</script>

<style lang="css" scoped>
</style>