<template>
  <div>
    <div class="text-center">
      <h1>Combinatiegroepen</h1>
    </div>
    <div class="card pb-3 mt-3">
      <ul class="list-group list-group-flush" v-if="hasCombinations()">
        <li v-for="combination in listOfCombinations" class="list-group-item d-flex justify-content-between">
          <span>{{ combination.name }}</span>
          <button @click="deleteCombination(combination.id)" class="btn btn-outline-danger btn-sm"><i class='fa fa-trash'></i></button>
        </li>
      </ul>
      <div class="card-body">
        <p v-if="!hasCombinations()">
          Er zijn nog geen combinatiegroepen gemaakt voor {{ school.name }}. Door twee groepen (bijvoorbeeld '6A en 7A') te combineren, kun je eenvoudig gelijktijdig voor twee of meer groepen een weekplan maken.  
        </p>
        
        <button @click="showNewCombiBox" v-if="!showNewCombi" class="btn btn-block btn-success mt-4">Maak een combigroep <i class="fas fa-plus"></i></button>
        <div v-if="showNewCombi">
          <hr v-if="!hasCombinations()">
          <div v-for="group in school.groups">
            <div class="form-check">
              <input class="form-check-input" :disabled="groupAllreadyInCombination(group.id)" v-model="newCombi" type="checkbox" :value="{id: group.id, name: group.name}" :id="`checkBox${group.id}`">
              <label class="form-check-label" :for="`checkBox${group.id}`">
                {{ group.name_with_group }}
              </label>
            </div>
          </div>
          <div class="mt-3 d-flex justify-content-between">
            <button @click="saveCombination" :disabled="!selectedMultipleGroupsForCombi" v-bind:class="{'disabled': !selectedMultipleGroupsForCombi}" class="btn btn-primary btn-sm">{{ combinationName }} opslaan</button>          
            <button @click="hideNewCombiBox" class="btn btn-secondary btn-sm">Annuleer</button>
            
          </div>
        </div>
      </div>
    </div> 

  </div>
</template>

<script>

import Weektaak from '../../packs/weektaak'

export default {
  components: {
  },
  props: ["school", "combinations"],

  data () {
    return {
      showNewCombi: false,
      newCombi: [],
      listOfCombinations: [],
      listOfCombinedGroups: []
    }
  },
  computed: {
    selectedMultipleGroupsForCombi () {
      return this.newCombi.length > 1
    },
    combinationName () {
      if (this.selectedMultipleGroupsForCombi) {
        return this.newCombi.slice().sort(function(a, b) {
          return a.id - b.id
        }).map((group) => {
          return group.name
        }).sort().join(" / ")

        // return 'yes'
      } else {
        return ''
      }
    }
  },
  methods: {
    groupAllreadyInCombination(group_id){
      return this.listOfCombinedGroups.includes(group_id)
    },
    showNewCombiBox (){
      this.showNewCombi = true
    },
    hideNewCombiBox (){
      this.showNewCombi = false
    },
    saveCombination (){
      Weektaak.saveCombination(this.newCombi)
        .then((result) => {
          this.listOfCombinations = result.combinations
          this.listOfCombinedGroups = result.groups_in_combinations
          this.hideNewCombiBox()
          this.newCombi = []
        }).catch((error) => {
          console.log(error)
        })
    },
    deleteCombination(id){
      Weektaak.deleteCombination(id)
        .then((result) => {
          this.listOfCombinations = result.combinations
          this.listOfCombinedGroups = result.groups_in_combinations
        }).catch((error) => {
          console.log(error)
        })
    },
    hasCombinations () {
      if (this.listOfCombinations != undefined) {
        return this.listOfCombinations.length > 0  
      } else {
        return []
      }
      
    }
  },
  created () {
    this.listOfCombinations   = this.combinations.combinations
    this.listOfCombinedGroups = this.combinations.groups_in_combinations
    // console.log("used:")
    // console.log(this.listOfCombinedGroups)
    // console.log(this.combinations)
    // .map(function(key, index) { key *= 2 })
    // window.store.dispatch('fetchWeekPlan', { id: this.id })
    //   .then(() => {
    //     // this.theYear = this.weekPlan.year
    //     // this.weekNumber = this.weekPlan.week_number
    //   })
  },
  
}
</script>



<style scoped>


.form-check-input:disabled~.form-check-label{
  text-decoration: line-through;
}

</style>
