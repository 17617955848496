<template>
  <div style="padding: 10px;" class="row mb-3">

    <div class="col" v-if="item.input_type == 'custom_text'">
      <p class="lead text-center py-4" style="white-space: pre-line;">
        {{ item.body }}
      </p>
    </div>
    <div class="col" v-if="item.input_type == 'course'">
      <div class="text-center">
        <h4 class="regular">{{ item.course.name }}</h4>
      </div>
      <table style="width: 100%;">
        <thead>
          <tr style="">
            <th style="text-align: left;"></th>
            <th style="text-align: left;"></th>
            <th>{{ item.title_2 }}</th>
            <th style="text-align: right;">{{ item.title_1 }}</th>
          </tr>
        </thead>
        <tbody>
            <tr :key="`lesson-${lesson.id}-${item.answer.answer_object}`" v-for="lesson in item.lesson_levels" style="border-bottom: 1px solid #000; ">
              <th width="5%" style=" text-align: center; padding-top: 4px; padding-bottom: 4px; background: #c7eafb9c; color: #000;">{{ lesson.short_day }}</th>
              <td style="padding-top: 4px; padding-bottom: 4px; padding-left: 5px;">
                <span v-if="item.title_3 == 'task'" v-html="lesson.task"></span>
                <span v-if="item.title_3 == 'goal'" v-html="lesson.goal"></span>
              </td>
              <td width="40%">
                <input @change="submit" class='form-control' v-model="item.answer.answer_object[`lesson_level_${lesson.id}_answer`]" type="text" />
              </td>
              <td width="15%" style="text-align: right; padding-top: 4px; padding-bottom: 4px;">
                <span 
                  @click="markLessonFeedback(lesson, 'far fa-frown')" 
                  style="cursor: pointer; font-size: 24px; margin-left: 4px;" 
                  class="far fa-frown"
                  :class="{'text-danger': item.answer.answer_object[`lesson_level_${lesson.id}_smiley`] == 'far fa-frown'}"
                ></span>
                <span 
                  @click="markLessonFeedback(lesson, 'far fa-meh')" 
                  style="cursor: pointer; font-size: 24px; margin-left: 4px;" 
                  class="far fa-meh"
                  :class="{'text-warning': item.answer.answer_object[`lesson_level_${lesson.id}_smiley`] == 'far fa-meh'}"
                ></span>
                <span 
                  @click="markLessonFeedback(lesson, 'far fa-smile')" 
                  style="cursor: pointer; font-size: 24px; margin-left: 4px;" 
                  class="far fa-smile"  
                  :class="{'text-success': item.answer.answer_object[`lesson_level_${lesson.id}_smiley`] == 'far fa-smile'}"
                ></span>
              </td>
            </tr>

        </tbody>
      </table>
    </div>

    <div class="col text-center" v-if="item.title_1 && item.input_type != 'course'">
      <h4>{{ item.title_1 }}</h4>
      <div class="form-group">
        <textarea 
          @change="submit" 
          v-model="item.answer.answer_1" 
          class="form-control" 
          rows="3" 
          v-if="item.input_type.includes('text')"
        ></textarea>

        <div class="btn-group" v-if="item.input_type == 'number_rating'">
          <button 
            class="btn btn-outline-primary btn-lg" 
            :class="{active: parseInt(item.answer.answer_1) == num}"
            @click="setNum(num)"
            v-for="num in [1,2,3,4,5,6,7,8,9,10]"
          >{{ num }}</button>
        </div>

        <div class="btn-group" v-if="item.input_type == 'icons'">
          <button 
            class="btn btn-outline-primary btn-lg" 
            :class="{active: isActiveIcon(num, index)}"
            @click="setNum(num)"
            v-for="(num, index) in iconsToUse"
          ><i :class="num"></i></button>
        </div>

      </div>
    </div>
    <div class="col text-center" v-if="item.title_2 && !['icons', 'course'].includes(item.input_type) ">
      <h4>{{ item.title_2 }}</h4>
      <div class="form-group">
        <textarea @change="submit" v-model="item.answer.answer_2" class="form-control" rows="3"></textarea>
      </div>
    </div>
    <div class="col text-center" v-if="item.title_3 && !['icons', 'course'].includes(item.input_type)">
      <h4>{{ item.title_3 }}</h4>
      <div class="form-group">
        <textarea @change="submit" v-model="item.answer.answer_3" class="form-control" rows="3"></textarea>
      </div>
    </div>

    
  </div>
</template>

<script>
import axios from 'axios'
export default {

  name: 'evaluationItem',
  props: ['item', 'studentWeekPlanId', 'studentWeekPlanKey'],
  data () {
    return {

    }
  },
  computed: {
    iconsToUse() {
      if (this.item.title_2 == 'smiles') {
        return ['far fa-frown', 'far fa-meh', 'far fa-smile']
      } else if (this.item.title_2 == 'thumbs') {
        return ['far fa-thumbs-up', 'far fa-thumbs-down']
      } else if (this.item.title_2 == 'rock-paper') {
        return ['far fa-hand-rock', 'far fa-hand-paper', 'far fa-hand-scissors']
      } else if (this.item.title_2 == 'battery') {
        return ['fas fa-battery-empty', 'fas fa-battery-quarter', 'fas fa-battery-half', 'fas fa-battery-three-quarters','fas fa-battery-full']
      } else if (this.item.title_2 == 'stars') {
        return ['far fa-star star-1', 'far fa-star star-2', 'far fa-star star-3', 'far fa-star star-4', 'far fa-star star-5']
      } else if (this.item.title_2 == 'hearts') {
        return ['far fa-heart heart-1', 'far fa-heart heart-2', 'far fa-heart heart-3', 'far fa-heart heart-4', 'far fa-heart heart-5']
      }
    }
//     <% if item.title_2 == 'smiles' %>
//   <% icons = ['far fa-frown', 'far fa-meh', 'far fa-smile'] %>
// <% elsif item.title_2 == 'thumbs' %>
//   <% icons = ['far fa-thumbs-up', 'far fa-thumbs-down'] %>
// <% elsif item.title_2 == 'rock-paper' %>
//   <% icons = ['far fa-hand-rock', 'far fa-hand-paper', 'far fa-hand-scissors'] %>
// <% elsif item.title_2 == 'battery' %>
//   <% icons = ['fas fa-battery-empty', 'fas fa-battery-quarter', 'fas fa-battery-half', 'fas fa-battery-three-quarters',
// 'fas fa-battery-full'] %>
  },
  methods: {
    isActiveIcon(icon, index) {
      if (['hearts', 'stars'].includes(this.item.title_2)) {
        if (this.item.answer.answer_1 === null) {
          return false
        } else {
          let num = parseInt(this.item.answer.answer_1.slice(-1)) - 1
          return num >= index
        }
      } else {
        return this.item.answer.answer_1 == icon
      }
    },
    markLessonFeedback(lesson, val) {
      console.log('answer:')
      console.log(this.item.answer.answer_object[`lesson_level_${lesson.id}_smiley`])
      console.log(this.item.answer.answer_object)
      if (this.item.answer.answer_object[`lesson_level_${lesson.id}_smiley`] === undefined) {

      }
      this.item.answer.answer_object[`lesson_level_${lesson.id}_smiley`] = val
      this.submit()
    },
    setNum(num) {
      this.item.answer.answer_1 = num
      this.submit()
    },
    submit() {
      axios.put(`/student-evaluation/${this.studentWeekPlanId}/${this.studentWeekPlanKey}/${this.item.answer.id}/`, 
        this.item.answer
      ).then((response) => {
        // TODO
      }).catch((error) => {
        console.error(error);
      }).finally(() => {
        // TODO
      });
    }
  }
}
</script>

<style lang="css" scoped>
</style>