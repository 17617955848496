<template>
  <div class="col-12 col-lg-6">
      <div class="card mb-3">
        <div class="card-header text-center" v-show="deleteMode">
          <small>Weet je het zeker? Wil je de werkvorm '{{ theLessonMethod.name }}' voor de hele school verwijderen?</small>
          <div>
            <button @click="deleteNow" class="btn btn-sm btn-danger">Ja, verwijder</button>
            <button @click="deleteMode = false" class="btn btn-sm btn-secondary">Annuleren</button>
          </div>
        </div>
        <div class="card-header d-flex justify-content-between align-items-center" v-if="!deleteMode">
          <input @change="updateLessonMethod" class="form-control form-control-sm" style="font-weight: 700;" v-model="theLessonMethod.name" :disabled="deleting" />
          <div class="btn-group mx-2">
            <button type="button" class="btn btn-outline-primary btn-sm">
              <i :class="theLessonMethod.icon"></i>
            </button>
            
            <button type="button" class="btn btn-outline-primary dropdown-toggle dropdown-toggle-split btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <span class="sr-only">Kies icoon</span>
            </button>

            <div class="dropdown-menu text-center">
              <div :key="`group-${i}`" class="dropdown-item d-flex justify-content-between" v-for="(group,i) in groupArr(icons, 4)">
                <a href="javascript:void(0)" @click="setIcon(icon)" v-for="icon in group" class="icon-picker"><i :class="icon"></i></a>
              </div>
              <div class="dropdown-item">
                <a href="javascript:void(0)" @click="setIcon('no-icon')" data-icon="no-icon" class="btn btn-outline-primary btn-sm btn-block icon-picker" >geen icoon</a>
              </div>
            </div>
          </div>
          <button @click="deleteMode = true" class="btn btn-sm btn-outline-danger"><i class="fa fa-trash"></i></button>
        </div>
        <div v-if="deleting" class="card-body p-4 text-center">
          <i class="fa fa-spinner fa-spin"></i>
        </div>
        <div class="list-group list-group-flush" v-if="!deleting">
          <div class="list-group-item">
            <div class="d-flex justify-content-between">
              <span>Leerling kiest zelf dag</span>
              <span><toggle-button @change="updateLessonMethod" v-model="theLessonMethod.is_autonomous" :sync="true" v-tooltip="{delay: {show: 500},placement: 'right', content: `Bij lessen met de lesvorm '${theLessonMethod.name}', kunnen leerlingen zelf kiezen op welke dag ze deze les doen.`}" /></span>
            </div>
            
          </div>
          <div class="list-group-item" v-show="theLessonMethod.is_autonomous">
            <div class="d-flex justify-content-between">
              <span>Leerling mag dagkeuze achteraf wijzigen</span>
              <span><toggle-button @change="updateLessonMethod" v-model="theLessonMethod.has_unfixed_day_selection" :sync="true" v-tooltip="{delay: {show: 500},placement: 'right', content: `Als leerlingen eenmaal een dag hebben ingepland, kunnen ze deze keuze niet meer wijzigen.`}" /></span>
            </div>
            
          </div>
          <div class="list-group-item">
            <div class="d-flex justify-content-between align-items-center">
              <span>Leerling vult zelf lesstof in</span>
              <div>
                <span><toggle-button @change="updateLessonMethod" v-model="theLessonMethod.student_content" :sync="true" v-tooltip="{delay: {show: 500},placement: 'right', content: `Bij lessen met de lesvorm '${theLessonMethod.name}', krijgen leerlingen een invulveld te zien, waarin ze zelf iets kunnen invullen.`}" /></span>
              </div>
            </div>
          </div>

        </div>   
        
        <!-- <div class="card-footer text-muted">
          
        </div> -->
      </div>

  </div>
</template>

<script>
import axios from 'axios'

export default {

  name: 'LessonMethod',
  props: ['lessonMethod', 'icons'],
  data () {
    return {
      deleteMode: false,
      is_autonomous: false,
      theLessonMethod: {},
      deleting: false
    }
  },
  mounted() {
    this.is_autonomous = this.lessonMethod.is_autonomous
    this.theLessonMethod = JSON.parse(JSON.stringify(this.lessonMethod))
  },
  computed: {
    hasChanged () {
      return JSON.stringify(this.lessonMethod) != JSON.stringify(this.theLessonMethod)
    }
  },
  methods: {
    deleteNow() {
      this.deleteMode = false
      this.deleting = true
      this.$emit('delete', this.lessonMethod.id)
    },
    groupArr(data, n) {
      var group = [];
      for (var i = 0, j = 0; i < data.length; i++) {
          if (i >= n && i % n === 0)
              j++;
          group[j] = group[j] || [];
          group[j].push(data[i])
      }
      return group;
    },
    setIcon(icon) {
      // ...
      this.theLessonMethod.icon = icon
      console.log(icon)
      this.updateLessonMethod()
    },
    updateLessonMethod() {
      axios.put(`/lesson_methods/${this.lessonMethod.id}.json`, {
        lesson_method: this.theLessonMethod
      })
      .then(response => {
        // this.name = ''
        // this.theLessonMethods = response.data
      })  
      // return true
    }
  }
}
</script>

<style lang="css" scoped>
</style>