<template>
  <div>
    <div v-if="loading" class="text-center my-5">
      <i class="fa fa-spinner fa-spin" />
    </div>
    <div v-if="!loading" class="text-center mt-2 pb-3 text-white">
      <div v-if="totalCourses == 0" class="pt-5">
        Als je lesstof heb ingepland, kun je op deze plek statistieken vinden over je rooster. Per vak krijg je dan te zien hoeveel uren per week dit vak op het rooster staat.
      </div>
      <div v-for="group in groups" v-if="totalCourses > 0">
        <h4>{{ group.name }}</h4>
        <table class="table table-striped table-sm" style="table-layout: fixed;">
          <tbody>
            <tr v-for="course in group.courses">
              <td class="text-left truncate px-0">{{ course.name }}</td>
              <td class="px-0" width="24">{{ course.lessons}}&times;</td>
              <td class="px-0" width="64">{{ course.duration }}</td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <th colspan="2" class="text-left">Totaal</th>
              <th>{{ group.duration }}</th>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {

  name: 'courseStats',
  props: ['weekPlanId'],
  data () {
    return {
      loading: true,
      groups: [],
      totalCourses: 0
    }
  },
  mounted() {
    this.loadStats()
  },
  methods: {
    loadStats() {
      axios.get(`/week_plans/${this.weekPlanId}/stats.json`).then((response) => {
        this.loading = false
        this.groups = response.data.groups
        this.totalCourses = response.data.number_of_courses
        // TODO
      }).catch((error) => {
        console.error(error);
      }).finally(() => {
        // TODO
      });
    }
  }
}
</script>

<style lang="css" scoped>
</style>