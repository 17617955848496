<template>
  <div>
    <div class="row">
      <div class="col">
        <div class="form-check" v-for="student in students">
          <input @change="toggleStudent(student)" class="form-check-input" type="checkbox" :value="student.id" :id="`student-${student.id}-${extraTaskLevelId}`" :checked="studentIsChecked(student)">
          <label class="form-check-label" :for="`student-${student.id}-${extraTaskLevelId}`">
            {{ student.name }}
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {

  name: 'studentPicker',
  props: ['students', 'selected', 'extraTaskLevelId'],
  data () {
    return {

    }
  },
  methods: {
    toggleStudent(student) {
      axios.put(`/toggle-extra-task-student.json`, {
        student_id: student.id,
        extra_task_level_id: this.extraTaskLevelId
      }).then((response) => {
        // TODO
      }).catch((error) => {
        console.error(error);
      }).finally(() => {
        // TODO
      });
    },
    studentIsChecked(student) {
      if (this.selected.includes(student.id)) {
        return true
      }
    }
  }
}
</script>

<style lang="css" scoped>
</style>