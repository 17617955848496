<template>
  <div>
    
    <div class="input-group w-100 mb-1">
      <input  ref="messageBox" type="text" class="form-control" :value="label" readonly>
      <div class="input-group-append">
        <button @click="ShowModal" class="btn btn-primary">Selecteer leerlingen</button>
      </div>
    </div>
    <div class="modal fade" id="studentPickerModal" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-body" style="max-height: 70vh; overflow-y: auto;"> 
            <div v-for="group in groups" :key="`group-${group.id}-students`" class="bg-light rounded mb-3 p-3">
              <h5>{{ group.name_with_group }}</h5>
              <div class="row">
                <div :class="ColumnsClass" v-for="chunk in chunk(group.students, columns)">
                  <div v-for="student in chunk">
                    <div class="form-check d-flex">
                      <input class="form-check-input" v-model="selected_students" type="checkbox" :value="student" :id="`checkBox${student.id}`">
                      <label class="form-check-label" :for="`checkBox${student.id}`">
                        {{ student.name }}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="modal-footer bg-light d-block py-4">
            <div class="d-flex justify-content-between">
              <button @click="Cancel" class="btn btn-label" type="button">Annuleren</button>
              <button @click="Save" class="btn btn-primary" type="button">OK</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

  name: 'StudentSelector',
  props: ["students", "groups", "columns"],
  data () {
    return {
      originalStudents: [],
      selected_students: [],
      filter: ''
    }
  },
  computed: {
    label (){
      switch(this.selected_students.length) {
        case 0:
          return "Nog geen leerlingen geselecteerd"
        case 1:
          return this.selected_students[0].name
        case 2:
          return `${this.selected_students[0].name} en ${this.selected_students[1].name}`
        default:
          return `${this.selected_students.length} leerlingen`
        }

    },
    chunked() {
      return this.chunk(this.groups, this.columns)
    },
    ColumnsClass(){
      switch(this.columns) {
        case 1:
          return "col col-12"
        case 2:
          return "col col-6"
        case 3:
          return "col col-4"
        case 4:
          return "col col-3"
        case 6:
          return "col col-2"
        default:
          return "col"
        }
    }
  },
  methods:
  {
    Save() {
      this.originalStudents = this.selected_students;
      this.$emit('save', this.selected_students)
      this.HideModal();
    },
    Cancel() {
      this.selected_students = this.originalStudents ?? []
      this.HideModal();
    },
    ShowModal() {
      $("#studentPickerModal").modal({backdrop: 'static', keyboard: false},'show')
    },
    HideModal(){
      console.log('close')
      $("#studentPickerModal").modal('hide')
    },  
    chunk (arr, cols) {

      let chunks = []
      let i = 0
      let n = arr.length
      let len = Math.ceil(arr.length / cols)

      while (i < n) {
        chunks.push(arr.slice(i, i += len))
      }

      return chunks
    }
  },
  mounted(){
    this.originalStudents = this.students
    this.selected_students = this.students ?? []
  }
  
}
</script>

<style lang="css" scoped>
</style>