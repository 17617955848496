<template>
  <div>


    <div class="container-fluid pb-4 pt-5 mt-3 bg-light">
      <div class="container">
        <div class="row">
          <div class="col text-center">
            <h2>Lesdagen {{groupname}}</h2>
            <h5 class="text-muted">Op welke dagen is er les?</h5>
          </div>
        </div>
        <div class="row">
          

            <WeekPlanDayOption :key="`wpdo-${day.day}`" v-for="day in weekPlan.weekPlanDays" v-if="day.week_plan_id == id" :day="day"></WeekPlanDayOption>

        </div>
      </div>
    </div>
    
  </div>
</template>

<script>

import Vue from 'vue/dist/vue.esm'
import Weektaak from '../../packs/weektaak'
import StudentLevels from '../../components/settings/studentLevels.vue'
import WeekPlanDayOption from '../../components/week_plans/weekPlanDayOption.vue'
// import moment from 'vue-moment'
// require('moment/locale/nl')


// Vue.use(require('vue-moment'))
// console.log(Vue.moment.locale())

export default {
  components: {
    WeekPlanDayOption
  },
  props: ["id", "groupname"],

  data () {
    return {
      possibleWeeks: Array.from(Array(52)).map((e,i)=>i+1),
      possibleYears: [2018, 2019, 2020],
      courses: []
    }
  },
  computed: {
    year () {
      return window.moment().year(this.weekPlan.year)
    },
    weekPlan () {
      return window.store.getters.weekPlan
    },
    startDate (){
      return window.moment(this.year).day(this.weekPlan.weekPlanDays[0].day_number + 1).week(this.weekPlan.week_number)
    },
    formattedStartDate() {
      return window.moment(this.startDate).format('dddd D MMMM')
    },
    endDate () {
      let start = this.startDate
      if (this.weekPlan.weekPlanDays[0].day_number == 0){
        return window.moment(start).add(4, 'days')
      } else {
        return window.moment(start).add(6, 'days')
      }
      
      
    },
    formattedEndDate() {
      return window.moment(this.endDate).format('dddd D MMMM')
    },
  },
  methods: {
    saveChanges() {
      Weektaak.updateWeekPlan(this.id, {
        year: this.weekPlan.year, 
        week_number: this.weekPlan.week_number
      })
    }
  },
  created () {
    window.store.dispatch('fetchGroupsForWeekPlan', this.id)
      .then(() => {
        // 
      })
    window.store.dispatch('fetchWeekPlanCourses', { id: this.id })
      .then((result) => {
        this.loading = false
      })
    window.store.dispatch('fetchWeekPlan', { id: this.id })
      .then(() => {
        // this.theYear = this.weekPlan.year
        // this.weekNumber = this.weekPlan.week_number
      })
    window.store.dispatch('fetchCourses', { id: this.id })  
    window.store.dispatch('fetchStudents', { id: this.id })  
    window.store.dispatch('fetchLevels', { id: this.id })  
  },
  
}
</script>

<style scoped>
  .custom-select{
    width: auto;
    padding-top: 0px;
    padding-bottom: 0px;
    font-size: 1rem;
  }  
</style>
