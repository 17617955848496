<template>
  <div :class="`list-group-item ${deleteClass}`" v-if="!isHidden">
    
    <div class="input-group">
      <div class="input-group-prepend" v-if="show_picto">
        <div class="input-group-text">
          <div @click="togglePictoPicker()" class="picto-placeholder" :style="pictoStyle" v-bind:class="{'empty' : !(chosenPicto && chosenPicto.thumb)}"></div>
        </div>
      </div>
      <div class="input-group-prepend" v-if="usecolor">
        <div class="input-group-text">
          <div class="color-btn" @click="togglePicker()" :style="style"></div>
        </div>
      </div>
      <input @change="saveData" v-on:keyup.enter="submit" type="text" :class="inputClass" :placeholder="placeholder" v-model="courseName">
      <div class="input-group-append" v-if="course.new">
        <button class="btn btn-outline-success" :disabled="btnDisabled" @click="submit" type="button">{{course.name}}Vak toevoegen</button>
      </div>
      <div class="input-group-append" v-if="!course.new">
        <button class="btn btn-danger" @click="destroyCourse" type="button"><i class='fa fa-trash'></i></button>
      </div>
      <div v-if="course.new">        
      </div>
    </div>
    <em class="small" v-if="!course.new && !showPicker && !showPictoPicker && !course.used_in_current_schoolyear">Dit vak wordt in het huidige schooljaar niet gebruikt.</em>
    <small v-if="isDuplicate && course.new" class="form-text text-muted">Het vak {{courseName}} bestaat al.</small>

    <compact-picker @input="updateColorValue" class="mt-2" v-model="colors" v-if="showPicker"></compact-picker>
    <div  v-if="showPictoPicker">

      <div class="row">
        
        <div class="col">
          <div class="bg-light p-2 rounded mt-2">
            <div class="row">
              <div class="col-2" :key="`group-select-${group.id}-${groupPictos}`" v-for="group in allGroups">
                <button class="btn btn-sm w-100 group-select-btn" :class="{'btn-outline-secondary': selectedGroup && selectedGroup.id == group.id, 'btn-secondary': selectedGroup && selectedGroup.id == group.id}" @click="selectGroup(group)">
                  <div class="image-placeholder">
                    <img :src="imageForGroup(group)" class="img-fluid" v-if="imageForGroup(group)">
                    <img :src="chosenPicto.thumb" class="img-fluid" v-if="!imageForGroup(group) && chosenPicto" style="opacity: 0.3;">
                  </div>
                  {{group.name_with_group }}
                </button>
              </div>
            </div>
          </div>
        </div>      
      </div>

      <div class="row" v-if="selectedGroup">  
        <div class="col">
          <PictoPicker @refreshList="refreshList" :alwaysShowDelete="true" :current-picto="chosenPicto" :can_add_pictos="can_add_pictos" :pictos="pictos" @select="selectPicto"></PictoPicker>
        </div>      
      </div>
      <div class="row" v-if="selectedGroup">
        <div class="col">
          <button class="btn btn-sm btn-outline-secondary" @click="togglePicker" v-if="showPicker">annuleren</button>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

import Vue from 'vue/dist/vue.esm'
import Weektaak from '../../packs/weektaak'
import PictoPicker from '../../components/pictos/pictoPicker.vue'
import { Compact } from 'vue-color'

export default {
  components: {
    PictoPicker,
    // 'material-picker': material,
    'compact-picker': Compact,
    // 'swatches-picker': swatches,
    // 'slider-picker': slider,
    // 'sketch-picker': sketch,
    // 'chrome-picker': chrome,
    // 'photoshop-picker': photoshop

  },
  props: ["course", "usecolor", "show_picto", "pictos", "can_add_pictos", "groups"],

  data () {
    return {
      isDeleting: false,
      isHidden: false,
      showPicker: false,
      groupPictos: [],
      showPictoPicker: false,
      selectedGroup: null,
      chosenPicto: {},
      keyword: '',
      courseName: '',
      colors: {
        hex: '#dedede'
      }
    }
  },
  computed: {
    
    allGroups(){
      return [{id: null, name_with_group: "Standaard afbeelding"}, ...this.groups]
    },
    pictoStyle(){
      if (this.chosenPicto && this.chosenPicto.thumb){
        return `background-image: url(${this.chosenPicto.thumb});`
      } else {
        return ""
      }
    },

    deleteClass (){
      if (this.isDeleting){
        return 'animated zoomOutRight'
      } else {
        return ''  
      }
      
    },
    inputClass (){
      if (this.isDuplicate && this.course.new){
        return "form-control is-invalid string optional"
      } else {
        return "form-control string optional"
      }
    },
    placeholder () {
      if (this.course.new){
        return "Voeg een nieuw vak toe"
      } else {
        return "Naam van het vak"
      }
    },
    courseNames (){
      return this.allCourses.map(item => {return item.name.toLowerCase().trim()})
    },
    isDuplicate () {
      // returns true if courseName is already in state getter
      return this.courseNames.includes(this.courseName.toLowerCase().trim())
    },
    allCourses (){
      return window.store.getters.allCourses
    },
    bgc () {
      return this.colors.hex
    },
    style (){
      return "background-color: " + this.colors.hex + ";"
    },
    btnDisabled (){
      if (this.courseName == '' || this.isDuplicate) {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    imageForGroup(group){
      if (group.id != null){
        // loop through all the group_pictos in course.group_pictos.
        // if group_picto.group_id == group.id, return group_picto.image
        // else return null
        let group_picto = this.groupPictos.find(gp => gp.group_id == group.id)
        if (group_picto){
          return group_picto.thumb
        } else {
          return null
        }
      } else {
        if (this.chosenPicto && this.chosenPicto.thumb){
          return this.chosenPicto.thumb
        } else {
          return null
        }
      }
    },
    refreshList(list){
      this.$emit('refreshList', list)
      // this.pictos = list
    },
    togglePictoPicker(){
      if (this.showPictoPicker){
        this.selectedGroup = null
      }
      this.showPictoPicker = !this.showPictoPicker
    },
    selectPicto(picto){
      if (this.selectedGroup && this.selectedGroup.id == null){
          if (picto == null) {
          this.chosenPicto = {id: null, thumb: null}
          // this.showPictoPicker = false
        } else {
          this.chosenPicto = {id: picto.id, thumb: picto.image}
          // this.showPictoPicker = false
        }  
      } else {

      }
      
      if (this.course && this.course.id){
        Weektaak.updateCourseWithGroup(this.course.id, {picto_id: this.selectedGroup && this.selectedGroup.id == null ? this.chosenPicto.id : picto == null ? null : picto.id}, this.selectedGroup ? this.selectedGroup.id : null).then((response) => {
          this.groupPictos = response.group_pictos
        })
      }
    },
    destroyCourse(){
      let r = confirm("Weet je het zeker? Als je vakken verwijdert, verwijder je ze voor je hele school.");
      if (r){
        this.isDeleting = true
        setTimeout(function () {
          this.isHidden = true
        }.bind(this), 1000)
        Weektaak.deleteCourse(this.course.id)
      }
    },
    submit () {
      if (!this.btnDisabled) {
        
        if (this.chosenPicto){
          Weektaak.addSettingsCourse({name: this.courseName, color: this.colors.hex, picto_id: this.chosenPicto.id})
        } else {
          Weektaak.addSettingsCourse({name: this.courseName, color: this.colors.hex})
        }
        this.appendLesson()
      }
      
    },
    appendLesson (){
      
      this.courseName = ''
      this.chosenPicto = {}
    },
    saveData () {
      // send to weektaak
      if (!this.course.new){
        Weektaak.updateCourse(this.course.id, {name: this.courseName, color: this.colors.hex})  
      }
      
    },
    togglePicker () {
      this.showPicker = (this.showPicker == false)
    },
    onOk () {
      
    },
    onCancel () {
      
    },
    selectGroup(group){
      this.selectedGroup = group
    },
    updateColorValue (value) {
      
      this.togglePicker()
      this.colors = value
      this.saveData()
    }
  },
  created () {
    if (this.course.new){
      // this.course.name = "Nieuwe les toevoegen"
    }
    this.chosenPicto = this.course.picto
    this.courseName = this.course.name
    this.colors.hex = this.course.color
    this.groupPictos = this.course.group_pictos
  }
  
}
</script>

<style scoped>
  .color-btn{
    width: 20px;
    height: 20px;
    background-color: #dedede;
    border: 1px solid #333;
    cursor: pointer;
  }
  .picto-placeholder, .image-placeholder{
    background-color: #999; 
    background-size: cover;
    border: 1px solid #000; 
    width: 24px; 
    height: 24px;
    cursor: pointer;
  }

  .image-placeholder{
    width: 64px;
    background-color: #dedede; 
    height: 64px;
  }
  .picto-placeholder.empty, .image-placeholder.empty{
    border-style: dashed;
  }

  .group-select-btn{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  .group-select-btn.btn-outline-secondary.btn-secondary{
    color: white;
  }

</style>
