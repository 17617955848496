<template>
  <div class="col-12 mb-2" :data-extra-task-id="task.id">
    <div class="card">
      <div class="card-header bg-light d-flex justify-content-between">
        <div class="align-self-center">
          <i class="fas fa-sort drag-handle mr-2"></i>
          <span>{{ task.course_name }}</span>
        </div>
        <div class="btn-group">
          <button @click="deleteTask()" class="btn btn-outline-danger btn-sm">verwijderen</button>
          <div class="btn-group" role="group" v-if="groups.length > 1">
            <a class="btn btn-outline-success btn-sm dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              kopiëren
            </a>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
              <button v-for="group in groups" :key="`btn-${group.id}`" class="dropdown-item" @click="copyTaskToGroup(group.id)" href="#">{{group.name_with_group}} </button>
            </div>
          </div>

          <button v-if="groups.length == 1" @click="copyTaskToGroup(groups[0].id)" class="btn btn-outline-success btn-sm">kopiëren</button>
          
        </div>
      </div>
      <div class="list-group list-group-flush">
        <!-- {{ task }} -->
        <div class="list-group-item" v-for="taskLevel in task.extra_task_levels">
          <ExtraTaskLevel 
            @refreshList='refreshPictoList' 
            @change="submitChanges" 
            :can_add_pictos="can_add_pictos" 
            :pictos="pictos" 
            :use_pictos="use_pictos" 
            :task="task" 
            :lesson-methods="lessonMethods"
            :taskLevel="taskLevel"
            :taskLevelItem="taskLevelItem"
            :key="`extra-task-level-${taskLevelItem.id}`"
            v-for="taskLevelItem in taskLevel.tasks">  
          </ExtraTaskLevel>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Vue from 'vue/dist/vue.esm'
import Weektaak from '../../packs/weektaak'
import ExtraTaskLevel from '../../components/settings/extraTaskLevel.vue'

export default {
  components: {
    ExtraTaskLevel
  },
  props: ["task", "groups", "use_pictos", "pictos", "can_add_pictos", "lessonMethods"],

  data () {
    return {
      // extraTasks: [],
      // newExtraTask: {course: null}
    }
  },
  
  methods: {
    refreshPictoList(list){
      this.$emit('refreshList', list)
    },
    copyTaskToGroup(groupId) {
      window.store.dispatch('copyExtraTask', { 
        week_plan_id: this.task.week_plan_id, 
        group_id: groupId,
        id: this.task.id
      })  
    },
    deleteTask() {
      console.log("deleting...")
      window.store.dispatch('deleteExtraTask', this.task)
    },
    submitChanges(taskLevelItem) {
      console.log("submitting...")
      console.log(taskLevelItem)
      window.store.dispatch('updateExtraTaskLevel', { 
        week_plan_id: this.task.week_plan_id, 
        id: taskLevelItem.id, 
        extra_task_level: { 
          task: taskLevelItem.task,
          label: taskLevelItem.label,
          is_required: taskLevelItem.is_required,
          lesson_method_id: taskLevelItem.lesson_method_id,
          correct: taskLevelItem.correct,
          show_day_selector: taskLevelItem.show_day_selector,
          for_all_students: taskLevelItem.for_all_students
        }
      })  
    }
  },
  
}
</script>

<style scoped>
  .drag-handle{
    cursor: ns-resize;
  }
  .custom-select{
    width: auto;
    padding-top: 0px;
    padding-bottom: 0px;
    font-size: 1rem;
  }  

</style>
