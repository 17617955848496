<template>
  <div @click="emitClick" class="card" :style="`height: ${lesson.duration*2}px; overflow: hidden; border-radius: 0; border: 0; border-left: 6px solid ${color}; outline: 1px solid #000; background-color: rgb(248 249 250 / 68%); cursor: pointer;` ">
    <div class="card-body py-1">
      <div class="d-flex justify-content-between">
        <span>
          <strong style="font-size: 11pt;">{{ lesson.course }}</strong>
        </span>
        <small class="text-muted">
          <i v-if="lesson.notes.length" class="far fa-comments"></i>
          {{lesson.starts_at}} - {{ lesson.ends_at }}
        </small>
      </div>

    </div>
  </div>
</template>

<script>
export default {

  name: 'dashboardLesson',
  props: ['lesson', 'useColors'],
  data () {
    return {

    }
  },
  methods: {
    emitClick() {
      this.$emit('click')
    }
  },
  computed: {
    color() {
      if (this.useColors && this.lesson.course_color) {
        return this.lesson.course_color
      } else {
        return 'transparent'
      }
    }
  }
}
</script>

<style lang="css" scoped>
</style>