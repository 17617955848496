<template>
  <div class="col courses-column bg-primary text-light full-height" v-bind:class="{'bg-danger': showTrash}">
      <div id="trash-bin" v-show="showTrash" class="bg-danger">
        <i class="fas fa-trash-alt "></i>
        <draggable class="bin-content" :list="trashedItems" :group="{put: true}" @add="trashedItem">
        
        </draggable>
      </div>


      <div v-if="showStats">
        <CourseStats :week-plan-id="weekPlanId" />
        <button @click="openStatsModal" class="btn btn-outline-light btn-sm btn-block mt-3">Statistieken sluiten</button>
      </div>

      <div v-show="!showTrash && !showStats">
        <div class="sticky-top bg-primary pb-2" id="courses-head">
          <div class="mb-1" v-if="useClockless">
            

            <div class="btn-group btn-block toggle-btns">
              <button @click="$emit('toggleClockless')" class="btn btn-outline-light btn-sm py-0" v-bind:class="{'active' : !showClockless}"><i class="far fa-clock"></i> Met rooster</button>
              <button @click="$emit('toggleClockless')" class="btn btn-outline-light btn-sm py-0" v-bind:class="{'active' : showClockless}"><i class="fa fa-list"></i> Zonder rooster</button>
            </div>
          </div>
          <div v-show="!showTrash && showGroupName && !showStats">
            <div class="card bg-light p-2 mb-2 text-dark small">
              <div v-for="group in groups">
                <div class="form-check">
                  <input class="form-check-input" :disabled="isDisabled(group.id)" v-model="theGroupsToShow" @change="toggleGroupVisibility()" type="checkbox" :value="group.id" :id="`checkBox${group.id}`">
                  <label class="form-check-label" :for="`checkBox${group.id}`">
                    {{ group.name_with_group }}
                  </label>
                </div>
              </div>
            </div>
          </div>

          <h2 v-show="!showTrash">Vakken <i @click="openStatsModal" class="fa fa-info-circle" style="font-size: 20px; cursor: pointer;"></i></h2>
          <input v-model="keyword" autofocus="true" type="text" placeholder="Zoeken..." class="form-control mb-0">
        </div>
        <div class="list-group text-dark">
          <pre v-if="false">{{ filteredList }}</pre>
          <CourseListItem v-for="course in filteredList" :course="course" :key="course.id" :weekPlanIdParam="weekPlanId"></CourseListItem>
          <center v-show="showEmptySearch" class="text-light">
            <em>Geen vakken gevonden met de naam '{{ this.keyword }}'</em>
            <hr>
            <div v-if="!canaddcourse">
              <p>Je hebt geen rechten om vakken toe te voegen.</p>
            </div>
            <div v-if="canaddcourse">
              <button @click="createCourse" class="btn btn-sm btn-success btn-block" type="button">{{ this.keyword }} toevoegen <i class='fa fa-plus'></i></button>
            </div>
          </center>
        </div>
      </div>

  </div>
</template>

<script>
// import store from '../store/index'
import CourseListItem from '../../components/week_plans/courseListItem.vue'
import CourseStats from '../../components/week_plans/courseStats.vue'
import draggable from 'vuedraggable'
import Weektaak from '../../packs/weektaak'

export default {
  props: ["weekPlanId", "groups", "showGroupName", "canaddcourse", "courses", "is_admin", "useClockless", "showClockless"],
  components: {
    CourseListItem,
    CourseStats,
    draggable
  },

  data (){
    return {
      keyword: "",
      lessonsToDestroy: [],
      theGroupsToShow: [],
      coursesList: [],
      showStats: false,
      trashedItems: [],
      clockless: true,
      showListOption: true
    }
    
  },
  methods: {
    emitClockless() {
      this.$emit('toggleClockless')
    },
    openStatsModal() {
      this.showStats = !this.showStats
    },
    hideTrash (event){
      store.dispatch('hideTrash')
    },
    addedCourse (event){
      this.hideTrash()
      this.coursesList = this.courses
      // console.log(this.coursesList)
      store.dispatch('stopDraggingNewLesson')
      var position       = event.newIndex
      this.weekPlanDayId = event.to.dataset.weekPlanDayId
      jQuery(".day-column .new-lesson").remove()
      if (this.weekPlanDayId != null){
        // console.log(event.clone.dataset)
        // console.log("adding to week_plan " + this.weekPlanIdParam)
        Weektaak.addLessonToWeekPlan(this.weekPlanIdParam, this.weekPlanDayId, event.clone.dataset.courseId, position)  
      }
      
      // console.log(this.totalCourseDuration.map(day => day.lessons).flat().filter(lesson => lesson.course_id === 463).map(lesson => lesson.duration).reduce((a, b) => a + b, 0))
    },
    draggingCourse (event) {
      store.dispatch('startDraggingNewLesson')
      store.dispatch('showTrash')
    },
    isDisabled (groupId){
      return false // (this.groupsToShow.length <= 1 && this.groupsToShow.includes(groupId))
    },
    toggleGroupVisibility (event){
      window.store.dispatch('setGroupsToShow', this.theGroupsToShow)
    },
    trashedItem (event) {
      var lessonId = event.item.dataset.lessonId
      if (lessonId != undefined){
        window.store.dispatch('addToTrash', {id: lessonId, weekPlanId: this.weekPlanId})
      } else {
        // console.log("trashed")
        // console.log(this.trashedItems)
        jQuery(".bin-content .new-lesson").remove()
      }

      // console.log(event.item.dataset.lessonId)
    },
    createCourse (){
      window.store.dispatch('addCourse', this.keyword)
    }
  },
  computed: {
    groupsToShow () {
      return window.store.getters.groupsToShow
    },
    showEmptySearch (){
      return this.keyword != '' && this.noResults
    },
    showTrash (){
      return window.store.getters.showWeekPlanTrash
    },
    // courses () {
    //   return window.store.getters.courses
    // },
    draggingNewLesson() {
      return window.store.getters.draggingNewLesson
    },
    filteredList() {
      return window.store.getters.courses.filter((course) => {
        return course.name.toLowerCase().includes(this.keyword.toLowerCase())
      })
    },
    noResults() {
      return this.filteredList.length == 0
    }
  },
  created () {

    this.theGroupsToShow = this.groupsToShow
    this.coursesList     = this.courses
    // console.log("coursesList")
    // console.log(this.coursesList)
  }


}
</script>

<style>
#trash-bin{
  text-align: center;
  height: 100%;
  padding: 20px;
  background: green;
}
#trash-bin .fa-trash-alt {
  font-size: 50px;
  position: relative;
  top: calc(50% - 20px);

}
#trash-bin .bin-content{
  position: absolute;
  top: 20px;
  left: 20px;
  right: 20px;
  bottom: 20px;
  border: 2px dashed #fff;
  border-radius: 4px;
}
#trash-bin .bin-content{
  font-size: 80px;
}
#courses-head{
  /*position: absolute;*/
}
</style>
