<template>
  <div>
    <div class="row mx-0">
      <div class="col-4 pt-2">

        <div v-if="item.input_type == 'course'">
          <div class="form-group">
            <label>Vak</label>
            <select @change="saveChangeAndFetchCourse" class="custom-select" v-model="item.course_id">
              <option v-for="course in courses" :value="course.id">{{course.name}}</option>
              
            </select>
          </div>
          <div class="form-group">
            <label>Taak of Doel?</label>
            <select @change="saveChange" class="custom-select" v-model="item.title_3">
              <option value="task">Taak</option>
              <option value="goal">Doel</option>
            </select>
          </div>
          <div class="form-group">
            <label for="text2">Titel 1</label>
            <input @change="saveChange" type="text" class="form-control" id="text2" v-model="item.title_2" placeholder="Opmerking">
          </div>
          <div class="form-group">
            <label for="text3">Titel 2</label>
            <input @change="saveChange" type="text" class="form-control" id="text3" v-model="item.title_1" placeholder="Hoe ging het?">
          </div>
        </div>


        <div v-if="item.input_type == 'icons'">
          <div class="form-group">
            <label for="text1">Vraag</label>
            <input @change="saveChange" type="text" class="form-control" id="text1" v-model="item.title_1" placeholder="Doel van deze week">
          </div>
          <div class="form-group">
            <label for="text1">Iconen</label>
            <select @change="saveChange" class="custom-select" v-model="item.title_2">
              <option value="smiles">Smileys</option>
              <option value="thumbs">Duimpjes</option>
              <option value="stars">5 Sterren</option>
              <option value="hearts">5 Hartjes</option>
              <option value="battery">Batterij</option>
              <option value="rock-paper">Steen-Papier-Schaar</option>
            </select>
          </div>
          <div class="form-group">
            <label for="text2">Instructie</label>
            <input @change="saveChange" type="text" class="form-control" id="text2" v-model="item.title_3" placeholder="Kruis aan wat van toepassing is">
          </div>
        </div>

        <div v-if="item.input_type == 'custom_text'">

          <div class="form-group">
            <label for="text2">Tekst</label>
            <textarea @change="saveChange" type="text" rows="4" class="form-control" id="text2" v-model="item.body" placeholder="Mijn opmerking"></textarea>
            <small class="form-text text-muted">Max. 4 regels. Als je '[naam]' schrijft, wordt dat vervangen door de naam van de leerling.</small>
          </div>

        </div>

        <div v-if="item.input_type == 'single_text' || item.input_type == 'double_text' || item.input_type == 'tripple_text' || item.input_type == 'number_rating'">
          <div class="form-group">
            <label for="text1">Vraag</label>
            <input @change="saveChange" type="text" class="form-control" id="text1" v-model="item.title_1" placeholder="Doel van deze week">
          </div>
        </div>
        <div v-if="item.input_type == 'double_text' || item.input_type == 'tripple_text'">
          <div class="form-group">
            <label for="text2">Vraag 2</label>
            <input @change="saveChange" type="text" class="form-control" id="text2" v-model="item.title_2" placeholder="Doel van deze week">
          </div>
        </div>
        <div v-if="item.input_type == 'tripple_text'">
          <div class="form-group">
            <label for="text3">Vraag 3</label>
            <input @change="saveChange" type="text" class="form-control" id="text3" v-model="item.title_3" placeholder="Doel van deze week">
          </div>
        </div>

      </div>
      


      <div class="col-8 pt-2 bg-light">
        <i class="handle bg-light fa fa-arrows-alt"></i>
        <div class="row">
          <div class="col pb-2" v-if="item.input_type == 'course'">
            <div v-if="currentCourse && !lessons.length">Er is geen lesstof voor {{ currentCourse.name }} voor {{ currentStudent.name }}</div>
            <div class="text-muted" v-if="!currentCourse">Er is nog geen vak gekozen. Kies welk vak je hier wilt tonen...</div>
            <div v-if="lessons.length">
              <h6>{{ currentCourse.name }}</h6>
              <table class="table table-sm table-striped">
                <thead>
                  <tr>
                    <th>Dag</th>
                    <th></th>
                    <th>{{item.title_2}}</th>
                    <th class="text-right">{{item.title_1}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="lesson in lessons">
                    <td>{{ lesson.short_day }}</td>
                    <td>
                      <span v-html="lesson.task" v-if="item.title_3 == 'task'"></span>
                      <span v-html="lesson.goal" v-if="item.title_3 == 'goal'"></span>
                    </td>
                    <td></td>
                    <td class="text-right">
                      <span style="font-size: 24px; margin-right: 4px;" class="far fa-frown"></span>
                      <span style="font-size: 24px; margin-right: 4px;" class="far fa-meh"></span>
                      <span style="font-size: 24px; margin-right: 4px;" class="far fa-smile"></span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          
          </div>
          <div class="col-12" v-if="item.input_type == 'course' && currentCourse">
            <div class="row mt-5">
              <div class="col">
                <p class="small text-muted">Elke leerling krijgt dit onderdeel op het eigen niveau te zien. Dit voorbeeld is gepersonaliseerd voor {{ currentStudent.name }}. <a @click="showStudentSelector = 1" href="javascript:void(0);" v-if="!showStudentSelector">Kies een andere leerling.</a></p>
              </div>  
              <div class="col-6 col-md-4 col-lg-4 ml-auto" v-if="showStudentSelector">
                <select @change="setStudent" class="custom-select custom-select-sm" v-model="selectedStudentIndex">
                  <option v-for="(student, index) in students" :value="index">{{ student.name }}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="col pb-2" v-if="item.input_type == 'number_rating'">
            <h6>{{ item.title_1 }}</h6>
            <div class="rating-fields">
              <span>1</span>
              <span>2</span>
              <span>3</span>
              <span>4</span>
              <span>5</span>
              <span>6</span>
              <span>7</span>
              <span>8</span>
              <span>9</span>
              <span>10</span>
            </div>
          </div>

          <div class="col pb-2" v-if="item.input_type == 'icons'">
            <h6>{{ item.title_1 }}</h6>
            <div class="smile-feedback">
              <div v-if="item.title_2 == 'smiles'">
                <span class="far fa-frown"></span>
                <span class="far fa-meh"></span>
                <span class="far fa-smile"></span>
              </div>
              <div v-if="item.title_2 == 'thumbs'">
                <span class="far fa-thumbs-up"></span>
                <span class="far fa-thumbs-down"></span>
              </div>
              <div v-if="item.title_2 == 'stars'">
                <span class="far fa-star"></span>
                <span class="far fa-star"></span>
                <span class="far fa-star"></span>
                <span class="far fa-star"></span>
                <span class="far fa-star"></span>
              </div>
              <div v-if="item.title_2 == 'hearts'">
                <span class="far fa-heart"></span>
                <span class="far fa-heart"></span>
                <span class="far fa-heart"></span>
                <span class="far fa-heart"></span>
                <span class="far fa-heart"></span>
              </div>
              <div v-if="item.title_2 == 'rock-paper'">
                <span class="far fa-hand-rock"></span>
                <span class="far fa-hand-paper"></span>
                <span class="far fa-hand-scissors"></span>
              </div>
              <div v-if="item.title_2 == 'battery'">
                <span class="fas fa-battery-empty"></span>
                <span class="fas fa-battery-quarter"></span>
                <span class="fas fa-battery-half"></span>
                <span class="fas fa-battery-three-quarters"></span>
                <span class="fas fa-battery-full"></span>
              </div>
              <h5 class="text-muted" v-if="item.title_3 && item.title_3.length">{{ item.title_3 }}</h5>
            </div>

            
          </div>


          <div class="col pb-2" v-if="item.input_type == 'custom_text'">
            <div class="box-with-lines" style="min-height: 80px;">
              <div class="pattern handwriting">{{ item.body }}</div>
            </div>
          </div>

          <div class="col pb-2" v-if="item.input_type == 'single_text' || item.input_type == 'double_text' || item.input_type == 'tripple_text'">
            <h6>{{ item.title_1 }}</h6>
            <div class="box-with-lines" style="height: 120px;">
              <div class="pattern">
              </div>
            </div>
          </div>

          <div class="col pb-2" v-if="item.input_type == 'double_text' || item.input_type == 'tripple_text'">
            <h6>{{ item.title_2 }}</h6>
            <div class="box-with-lines" style="height: 120px;">
              <div class="pattern">
              </div>
            </div>
          </div>

          <div class="col pb-2" v-if="item.input_type == 'tripple_text'">
            <h6>{{ item.title_3 }}</h6>
            <div class="box-with-lines" style="height: 120px;">
              <div class="pattern">
              </div>
            </div>
          </div>


        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
import axios from 'axios'
export default {

  name: 'evaluationItem',
  props: ['item', 'weekPlanId', 'courses', 'students'],
  data () {
    return {
      isLoading: true,
      selectedStudentIndex: 0,
      showStudentSelector: false,
      lessons: []
    }
  },
  methods: {
    saveChangeAndFetchCourse() {
      this.saveChange()
    },
    setStudent() {
      this.fetchCourse()
    },
    saveChange() {
      console.log('saving changes')
      // console.log(this.item)
      axios.put(`/week_plans/${this.weekPlanId}/evaluation_items/${this.item.id}.json`, {
        evaluation_item: this.item
      }).then((response) => {
        // TODO
        console.log('saved')
      }).catch((error) => {
        console.error(error);
      }).finally(() => {
        // TODO
        this.fetchCourse()
      });
    },
    fetchCourse() {
      if (this.item.input_type == 'course') {
        this.lessons = []
        console.log(`fetching item: ${this.item.id}, ${this.selectedStudentIndex}`)
        // console.log(this.item.course_id)
        axios.get(`/week_plans/${this.weekPlanId}/evaluation_items/${this.item.id}/student.json`, {
          params: {
            student_index: this.selectedStudentIndex,
          },
        }).then((response) => {
          console.log(response)
          this.lessons = response.data
        }).catch((error) => {
          console.error(error);
        }).finally(() => {
          // TODO
        });
      }
    }
  },
  computed: {
    currentCourse() {
      for (var i = 0; i < this.courses.length; i++) {
        if (this.courses[i].id == this.item.course_id) {
          return this.courses[i]
        }
      }
      return null
    },
    currentStudent() {
      if (this.students.length) {
        return this.students[this.selectedStudentIndex]
      } else {
        return null
      }
    }
  },
  mounted() {
    if (this.item.input_type == 'course' && this.item.course_id !== null) {
      this.fetchCourse()
    }
  }
}
</script>

<style>
  .handle {
    cursor: move;
    display: block;
    position: absolute;
    right: -26px;
    top: calc(50% - 13px);
    padding: 4px;
    border: 2px dashed;
    border-color: rgba(0,0,0,.125);
    border-radius: 4px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-left: none;
  }
</style>