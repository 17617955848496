<template>
  <div class="card pb-0" :class="cardClass" :data-start="lesson.timestamp_start">
    <div class="card-header pb-0" :class="{'pt-1': !showProgress && useTimestamp, 'pt-2': showProgress || !useTimestamp }"  :style="headerStyle">
      <div class="d-flex justify-content-between">
        <h4 v-if="useTimestamp && !showProgress" @click="emitClick" :style="`font-size: 10pt; font-weight: 500; text-decoration: ${titleTextDecoration}; cursor: ${titleCursor}`">{{ lesson.course }}</h4>
        <h4 v-if="useTimestamp && showProgress" @click="emitClick" :style="`font-size: ${fontSize(12, 14)}pt; font-weight: 700; text-decoration: ${titleTextDecoration}; cursor: ${titleCursor}`">{{ lesson.course }}</h4>
        <h4 v-if="!useTimestamp" @click="emitClick" :style="`font-size: ${fontSize(12, 14)}pt; font-weight: 700; text-decoration: ${titleTextDecoration}; cursor: ${titleCursor}`">{{ lesson.course }}</h4>
        <div>
          <button class="btn btn-link small text-muted p-0" @click="editLesson" :style="`text-decoration: underline; font-size: ${fontSize(8, 10)}pt;`">{{ editLabel }}</button>
          <button class="btn btn-link small text-muted p-0" @click="toggleComments" :style="`text-decoration: underline; font-size: ${fontSize(8, 10)}pt;`">{{ notesLabel }}</button>
          <small class="text-muted"> | {{lesson.starts_at}} - {{ lesson.ends_at }}</small>
        </div>
        
      </div>

      <div class="progress mb-2" style="height: 8px;" v-if="showProgress">
        <div class="progress-bar bg-success" role="progressbar" :style="`width: ${progress}%;`"></div>
      </div>

    </div>
    <div class="p-2" v-if="editMode">
      <PerCourseLesson :hide-day="hideDay" :lesson-object="lesson" :show_comment_field="true" :course="lesson.course" :linkMode="false" :key="`lesson-${lesson.id}-${lesson.updated_at}`" :lesson_methods="lesson_methods" :lesson_id="lesson.id" :day="day" ></PerCourseLesson>
    </div>
    <table class="table m-0" v-if="!editMode">
      <DashboardLessonLevelDetails :large="large" :show-comment-field="showCommentField" :lesson="lesson" :lesson-level="lessonLevel" v-for="lessonLevel in lesson.lesson_levels" :key="`lesson-level-${lessonLevel.id}`"  v-if="showLarge" />

    </table>
    <div class="card-body border-top" style="border-top-style: dashed !important;" v-show="showComments">
      <div class="text-center" v-if="notes.length">
        <h5 class="m-0">Notities</h5>
      </div>
      <div class="mb-1" :key="note.id" v-for="note in notes">
        <div class="small text-muted d-flex justify-content-between">
          <span>{{ note.user }}</span>
          <span>{{ note.created_at }}</span>
        </div>
        <div class="bg-light rounded p-2">
          <p class="m-0" :style="`font-size: ${fontSize(11, 12)}pt;`">{{ note.body }}</p>
          <div v-if="!note.for_all_students">
            <span class="small text-muted">{{ note.students }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="card-body py-1" v-show="showComments">
      <div class="form-group">
        <textarea ref="newNote" class="form-control" rows="3" placeholder="Schrijf een nieuwe notitie..." v-model="newNote"></textarea>
        <div class="mt-1 d-flex justify-content-between">
          <div>
          <toggle-button v-model="newNoteForAllStudents" :sync="true" :labels="{checked: 'Voor alle leerlingen', unchecked: 'Voor geslecteerde leerlingen'}" :fontSize="fontSize(10, 11)" :width="fontSize(205, 215)" />
          </div>
          <div>
            <toggle-button v-model="newNoteShowTask" :sync="true" :labels="{checked: 'Toon taak', unchecked: 'Verberg taak'}" :fontSize="fontSize(10, 11)" :width="fontSize(130, 140)" />
            <toggle-button v-model="newNoteShowGoal" :sync="true" :labels="{checked: 'Toon doel', unchecked: 'Verberg doel'}" :fontSize="fontSize(10, 11)" :width="fontSize(130, 140)" />
          </div>
        </div>
        <div class="" v-show="!newNoteForAllStudents">
          <div class="form-check" v-for="student, key in students" :key="`student-${key}`">
             <input type="checkbox" :id="`student-${student.id}-${lesson.id}`" class="form-check-input" v-model="studentIds" :value="student.id"  > 
             <label class="form-check-label" :for="`student-${student.id}-${lesson.id}`">{{ student.name }}</label>
          </div>
        </div>
      </div>
      <button @click="saveNote" class="btn btn-primary btn-block" :disabled="newNote == ''">Notitie toevoegen</button>
      <button @click="toggleComments" class="btn btn-link btn-block text-muted small">Notities sluiten</button>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import DashboardLessonLevelDetails from './dashboardLessonLevelDetails.vue'
import PerCourseLesson from '../../components/week_plans/perCourseLesson.vue'
export default {
  components: {DashboardLessonLevelDetails, PerCourseLesson},
  name: 'dashboardLessonDetails',
  props: ['lesson', 'weekPlanId', 'students', 'showCommentField', 'useColors', 'large', 'lesson_methods', 'day', 'hideDay', 'timestamp', 'useTimestamp'],
  data () {
    return {
      notes: [],
      studentIds: [],
      editMode: false,
      showComments: false,
      newNote: '',
      newNoteForAllStudents: true,
      newNoteShowTask: false,
      newNoteShowGoal: false
    }
  },
  created() {
    if (this.lesson.notes && this.lesson.notes.length) {
      this.notes = this.lesson.notes
    }
  },
  computed: {
    showProgress() {
      return (this.useTimestamp && this.showLarge)
    },
    progress() {
      let duration = this.lesson.timestamp_end - this.lesson.timestamp_start
      let percPerMinute = 100 / duration
      let minutesSinceStart = this.timestamp - this.lesson.timestamp_start
      return minutesSinceStart * percPerMinute
      return null
    },
    cardClass(){
      if (this.useTimestamp && !this.showLarge) {
        return 'mb-1 mx-1'
      } else if (this.useTimestamp) {
        return 'my-2 showing-progress shadow'
      } else {
        return 'mb-3'
      }
    },
    titleTextDecoration() {
      if (this.useTimestamp && !this.showLarge) {
        return 'underline'
      } else {
        return 'none'
      }
    },
    titleCursor() {
      if (this.useTimestamp && !this.showLarge) {
        return 'pointer'
      } else {
        return ''
      }
    },
    
    showLarge(){
      const treshold = 10
      if (this.useTimestamp){
        return this.timestamp >= this.lesson.timestamp_start - treshold && this.timestamp < this.lesson.timestamp_end
      } else {
        return true;  
      }
      
    },
    editLabel() {
      if (this.editMode) {
        return "Sluit bewerkmodus"
      } else {
        return "Bewerk lesstof"
      }
    },
    headerStyle() {
      // :use-colors="useColors"
      if (this.useColors && this.lesson.course_color) {
        return `border-bottom: 3px solid ${this.lesson.course_color};`
      } else if (this.useTimestamp && !this.showLarge){
        return 'opacity: 0.3;'
      } else {
        return ''
      }
    },
    notesLabel() {
      if (this.notes.length == 0) {
        return 'maak notitie'
      } else if (this.notes.length == 1) {
        return `één notitie`
      } else {
        return `${this.notes.length} notities`
      }
    }
  },
  methods: {
    emitClick() {
      this.$emit('click')
    },
    fontSize(s,l) {
      if (this.large) {
        return l
      } else {
        return s
      }
    },
    saveNote() {
      var msg = this.newNote
      if (this.newNoteForAllStudents) {
        var student_ids = []
      } else {
        var student_ids = this.studentIds
      }
      this.newNote = ''
      axios.post(`/week_plans/${this.weekPlanId}/notes.json`, {
        note: {
          body: msg,
          lesson_id: this.lesson.id,
          student_ids: student_ids,
          for_all_students: this.newNoteForAllStudents,
          show_task: this.newNoteShowTask,
          show_goal: this.newNoteShowGoal
        },
        response_type: 'dashboard'
      }).then((response) => {
        // console.log(response.data)
        this.notes.push(response.data)
        // this.$emit('update', response.data)
        // TODO
      }).catch((error) => {
        // console.error(error);
      }).finally(() => {
        // TODO
      });
    },
    editLesson() {
      this.editMode = !this.editMode
    },
    toggleComments() {
      if (this.showComments) {
        this.showComments = false
      } else {
        this.showComments = true
        this.$refs.newNote.focus()
        setTimeout(function () {
          this.$refs.newNote.focus()
        }.bind(this), 200)
      }
    }
  }
}
</script>

<style lang="css" scoped>
</style>