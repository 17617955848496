
<template>
  <tr>
    <td>
      <img :src="`/img/student-icons/PNG/48/${studentweekplan.student.animal}.png`" height="24" width="24" v-tooltip.top-center="studentweekplan.student.username" />
    </td>
    <td>
      <a :href="`/weektaken/${studentweekplan.id}/${studentweekplan.key}`" target="_blank" class="btn btn-link btn-sm">{{studentweekplan.student.name}}</a>
    </td>
    <td>
      <studentWeekPlanProgress :studentweekplan="studentweekplan" />
    </td>
    <td class="text-center">
      <div>
        <button @click="showPasswordNow" class="btn btn-sm btn-secondary px-1" v-if="!showPassword"><i class="fas fa-key mr-3"></i> &middot; &middot; &middot; &middot;  </button>
        <span @click="showPassword = false" v-if="showPassword" class="show-password">{{ studentweekplan.student.password }}</span>
      </div>
    </td>
    <td class="text-center">
      <span v-if="studentweekplan.feedback.feeling.frown > 0" class="badge badge-danger"><i class='fa fa-frown'></i> {{studentweekplan.feedback.feeling.frown }}</span>
      <span v-if="studentweekplan.feedback.feeling.meh > 0" class="badge badge-warning text-light"><i class='fa fa-meh'></i> {{studentweekplan.feedback.feeling.meh }}</span>
      <span v-if="studentweekplan.feedback.feeling.smile > 0" class="badge badge-success"><i class='fa fa-smile'></i> {{studentweekplan.feedback.feeling.smile }}</span>
      <span v-if="studentweekplan.feedback.result.down > 0" class="badge badge-danger"><i class='fa fa-thumbs-down'></i> {{studentweekplan.feedback.result.down }}</span>
      <span v-if="studentweekplan.feedback.result.up > 0" class="badge badge-success"><i class='fa fa-thumbs-up'></i> {{studentweekplan.feedback.result.up }}</span>
    </td>
    <td v-if="weekPlanSettings.use_credits" class="text-center">
      <span class="small text-muted"> {{ studentweekplan.earned_credits }}/{{ studentweekplan.credits_to_earn }}</span>
    </td>
    <td v-if="weekPlanSettings.use_chat">
      <button class="btn btn-outline-primary btn-sm" @click="selectStudent">
        Chat <i v-if="studentweekplan.messages.length || unread > 0" class="fas fa-comments"></i>
        <span v-if="unread > 0" class="badge badge-danger">{{ unread }}</span>
      </button>
    </td>
  </tr>
</template>

<script>
import axios from 'axios'
import studentWeekPlanProgress from '../../components/digital/studentWeekPlanProgress.vue'
export default {
  props: ["studentweekplan"],
  data () {
    return {
      editPassword: false,
      showPassword: false,
      savingPassword: false
    }
  },
  components: {
    studentWeekPlanProgress
  },
  computed: {
    
    randomNumber(){
      return Math.floor(Math.random() * (10 - 1 + 1)) + 1
    },
    unreadMessages() {
      return this.studentweekplan.unread_messages_by_user
    },
    weekPlanSettings (){
      return window.store.getters.weekPlanSettings
    },
    studentChat () {
      // return window.store.getters.unreadChats
      let chats = window.store.getters.chats.filter((chat) => {
         return chat.student.id == this.studentweekplan.student.id
       }) 
      if (chats.length) {
        return chats[0]
      } else {
        return null
      }
    },
    unread() {
      if (this.studentChat != null){
        return this.studentChat.unread
      } else {
        return 0
      }
    }
  },
  methods: {
    focusPassword() {
      this.editPassword = true
      setTimeout(function() {
        this.$refs.passwordField.select()
      }.bind(this), 100)
    },
    // updatePassword() {
    //   this.savingPassword = true
    //   axios.put(`/students/${this.studentweekplan.student.id}.json`, {
    //     student: {
    //       pin: this.studentweekplan.student.password
    //     },
    //   }).then((response) => {
    //     this.savingPassword = false
    //     this.editPassword = false
    //     this.showPasswordNow()
    //   }).catch((error) => {
    //     this.savingPassword = false
    //     if (error.response.status == 422) {
    //       let r = confirm(error.response.data.pin)
    //       this.focusPassword()
    //     }
    //   }).finally(() => {
    //     // TODO
    //   });
      
    // },
    selectStudent(){
      this.$emit('selectStudent', this.studentweekplan)
    },
    showPasswordNow() {
      this.showPassword = true
      setTimeout(function () {
        this.showPassword = false
      }.bind(this), 5000)
    }
  }
}
</script>

<style lang="css" scoped>
.show-password{
  font-family: monospace;
  font-size: 12pt;
  font-weight: 900;
  text-align: center;
}
</style>