<template>
  <div class="bg-white rounded shadow-sm mb-3 p-3">
    <div class="noneditor title" v-if="isDay">
      {{ list.title }}
    </div>
    <div class="editor title" v-if="list.day_number === undefined">
      <editor-content class="editor__content" v-model="list.title" :editor="editor" />
    </div>
    <div v-if="false" class="small text-muted" style="margin-top: -10px;">klik op '{{list.title }}' om de titel aan te passen, sleep picto's in de lijst.</div>
    <div class="px-3 mb-2 mt-2">
      <div class="row">
        <div class="col" style="position: relative;">
          <draggable
            class="dragArea row"
            :data-list-id="list.id"
            style="min-height: 140px;"
            :list="list.plan_pictos"
            :group="{ name: `picto-list-${list.id}`, pull: true, put: true }"
            @add="setList"
            @change="changeList"
            @start="showTrash" 
            @end="hideTrash"
            
          >
            <picto :is_day="isDay" :size="'large'" :students="students" :week_plan_id="week_plan_id" :picto="picto" :key="`list-${list.id}-picto-${picto.id}`" v-for="picto in list.plan_pictos"></picto>
          </draggable>
          <div v-if="list.plan_pictos.length == 0" style="position: absolute; top: 0px; width: 100%;">
            <div class="row">

              <div class="picto-card mb-2 px-1 col-2" v-for="n in randomRange">
                <div class="card" style="border: 1px solid #dedede; color: #dedede; background: #efefef; text-align: center; padding-top: 40px; font-size: 70px; padding-bottom: 40px;">
                  <i class="fas fa-shapes"></i>
                </div>
              </div>
            </div>
            <div style="text-align: right; width: 100%; position: absolute; top: 60px; padding-right: 50px;"><em class="text-muted">Zoek en sleep picto's naar deze lijst</em></div>
          </div>
        </div>
      </div>
      <div class="row" v-if="list.plan_pictos.length > 6">
        <div class="col">
          <div class="text-muted small" style='font-size: 12px;'><b>Let op:</b> op de 'weektaaktaak met plaatjes' worden omwille van de vormgeving maximaal 6 pictos per dag per kind getoond.</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Weektaak from '../../packs/weektaak'
import { Editor, EditorContent } from 'tiptap'
import {
  Placeholder,
  History,
} from 'tiptap-extensions'
import Picto from '../../components/pictos/picto.vue'
import draggable from 'vuedraggable'
import axios from 'axios'

export default {
  components: {Picto, draggable, EditorContent},
  name: 'pictoList',
  props: ["list", "week_plan_id", "students"],
  data () {
    return {
      theTitle: '',
      randomRange: [],
      editor: new Editor({
        extensions: [
          new Placeholder({
            emptyClass: 'is-empty',
            emptyNodeText: this.list.title,
            showOnlyWhenEditable: false,
          }),
          new History(),
        ],
        content: this.list.title,
        onUpdate: ({ getJSON }) => {
          var json = getJSON()
          this.theTitle = json.content[0].content[0].text
        },
        onBlur: ({ event,  state, view }) => {
          this.submitChanges()
        }
      }),
    }
  },
  computed:{
    isDay(){
      return this.list.day_number == 0 || this.list.day_number
    },
    pictoPlan() {
      return window.store.getters.pictoPlan
    },
  },
  created(){
    const rand = Math.random()
    if (rand > 0.4){
      this.randomRange = [1,2]
    } else if (rand > 0.2){
      this.randomRange = [1,2,3]
    } else {
      this.randomRange = [1]
    }
  },
  methods: {
    submitChanges(){
      if (this.theTitle.length){
        return axios.put("/week_plans/" + this.week_plan_id + "/picto_lists/"+ this.list.id +".json", {picto_list: {title: this.theTitle}})  
      }
      
    },
    showTrash (event){
      store.dispatch('showTrash')
    },
    hideTrash (event){
      store.dispatch('hideTrash')
    },
    setList(evt){
      // console.log('set list')

      if (evt.item.dataset.id == null) {
        // console.log('createPlanPicto')
        Weektaak.createPlanPicto(this.week_plan_id, evt.to.dataset.listId, evt.item.dataset.pictoId, evt.newIndex)  
      }
      
    },
    changeList(evt){
      if (evt.added){
        // console.log('changeList add event')
      } else {
        Weektaak.updatePictoPlan(this.week_plan_id, {picto_plan: this.pictoPlan})
      }
      
    }
  },
}
</script>

<style lang="css" scoped>
  .editor.title, .noneditor.title{
    font-size: 16pt;
  }
</style>