<template>

  <div v-bind:class="{'birthday':isBirthday}" :class="`school-${weekPlan.school_id}`">
    <div class="container-fluid p-0 no-select">
      <nav class="navbar navbar-expand-md navbar-light bg-info shadow-sm">
        <a class="navbar-brand" href="javascript:void(0)" @click="selectedDay = {id: undefined}">Weektaak van {{student.first_name}}</a>
        <div v-if="is_previous_week" class="ml-auto badge badge-danger badge-pill" style="font-size: 18px; padding: 4px 8px;">Weektaak van vorige week</div>
        
        <div class="collapse navbar-collapse" id="navbarColor03">
          <ul class="navbar-nav ml-auto" v-if="!is_previous_week">

            <li v-if="false" class="nav-item" :key="weekplanday.name" :weekplanday="weekplanday" v-for="weekplanday in weekPlan.week_plan_days">
              <a href="javascript:void(0)" class="nav-link" @click="selectedDay = weekplanday" v-bind:class="{'active' : selectedDay.id == weekplanday.id}">
                {{ weekplanday.name }}
              </a>
            </li>

            <li class="nav-item" v-if="selectedDay.id != undefined">
              <button class="btn btn-outline-dark" @click="selectedDay = {id: undefined}"><i class="fas fa-home"></i> Week</button>
            </li>
            
            <li class="nav-item" v-if="files.length">
              <button class="btn btn-outline-dark ml-2" @click="loadFiles">
                <i class="fas fa-file-download"></i> Bestanden
              </button>
            </li>
            
            <li class="nav-item" v-if="chat">
              <button class="btn btn-outline-dark ml-2" @click="loadChat">
                <i class="fas fa-comments"></i> Chat
                <span v-if="unreadMessages > 0" class="badge badge-danger">{{ unreadMessages }}</span>
              </button>
            </li>

            <li class="nav-item">
              <a href="/students/sign_out" data-method="delete" class="btn btn-outline-dark ml-2"><i class="fas fa-sign-out-alt"></i> Uitloggen</a>
            </li>

            <li v-if="weekPlan.use_credits" class="ml-3 nav-item">
              <div class="nav-link align-self-center pt-1">
                <span class="align-self-center"><i class="text-warning fas fa-coins"></i> {{ weekPlan.group_credits }} / {{ weekPlan.credit_goal }}</span>
              </div>
            </li>


          </ul>
        </div>
      </nav>
    </div>

    <div :class="`container full-height school-${weekPlan.school_id}`" >
      
      <div class="d-flex h-100"  v-if="selectedDay.id == undefined">
        <div class="align-self-center w-100">

          <div class="row">
            <div class="col-12 col-lg mb-1 align-self-center" :key="weekplanday.id" :weekplanday="weekplanday" v-for="(weekplanday, index) in weekPlan.week_plan_days">
              <button :disabled="weekplanday.lesson_levels.length < 0" :class="`btn btn-lg btn-block py-4 py-lg-5 ${backgroundClass(index)} day-${index} ${weekplanday.lesson_levels.length < 0 ? 'strike' : ''}`" @click="selectedDay = weekplanday">
                {{ weekplanday.name }}
              </button>
            </div>
          </div>
          <div class="row mt-5" >
            <div class="col align-self-center"  v-if="weekPlan.extra_work.lesson_levels.length > 0">
              <button :class="`btn btn-lg btn-block py-4 btn-info`" @click="selectedDay = weekPlan.extra_work">
                <i class="ml-3 far fa-calendar-check"></i> {{ weekPlan.klaaropdracht }}
              </button>
            </div>

            <div class="col align-self-center" v-if="weekPlan.comments.comments.length > 0">
              <button :class="`btn btn-lg btn-block py-4 btn-warning`" @click="selectedDay = weekPlan.comments">
                <i class="ml-3 fas fa-comments"></i> Opmerkingen
              </button>
            </div>


            <div class="col align-self-center" style="position: relative;"  v-if="weekPlan.autonomous.lesson_levels.length > 0">
              <button :class="`btn btn-lg btn-block py-4 btn-success`" @click="selectedDay = weekPlan.autonomous">
                <i class="far fa-calendar-alt mr-3"></i> {{ weekPlan.autonomous.name }} 
              </button>
              <div v-if="hasUnplannedTasks" style="position: absolute; bottom: -60px; left: 0px; width: 100%;">
                <!-- <img src="/img/arrow-to-right.png" height="100"> -->
                <div class="text-center animated backInTop">
                  <i class="fas fa-hand-point-up fa-3x"></i><br>
                  <b>Dit werk mag je zelf inplannen.</b>
                </div>
              </div>

            </div>

            <div class="col align-self-center"  v-if="weekPlan.evaluation_items.items.length > 0">
              <button :class="`btn btn-lg btn-block py-4 btn-danger`" @click="selectedDay = weekPlan.evaluation_items">
                <i class="far fa-question-circle mr-3"></i> {{ weekPlan.evaluation_items.name }} 
              </button>
            </div>
            

          </div>
        </div>
      </div>
      
      <div class="h-100 w-100 d-flex" v-if="selectedDay.id != undefined">

        <studentweekplanday :on-line="onLine" :student-week-plan-key="studentweekplan.key" :student-week-plan-id="studentweekplan.id" :files="files" :use_fileupload="use_fileupload" :today="studentweekplan.today" :only_check_today="studentweekplan.only_check_today" :feedback-feeling="studentweekplan.feedback_feeling" :feedback-result="studentweekplan.feedback_result" :show-goals="studentweekplan.show_goals" :student="student" @escape="selectedDay = {id: undefined}" @clicked="arrowClick" class="align-self-center" :prevday="prevDay" :nextday="nextDay" :weekplanday="selectedDay" :scope="scope" v-if="selectedDay"></studentweekplanday>
      </div>


    </div>
    
    
    <div v-if="hasPreviousPlan && selectedDay.id == undefined" style="position: fixed; bottom: 10px; left: 10px;">
      <a :href="previousPlanUrl" target="_blank" class="btn btn-outline-info btn-sm"><i class="fa fa-chevron-left"></i> vorige week</a>
    </div>

  </div>
</template>

<script>



// Vue.use(VueConfetti)
// import chatModal from '../../components/digital/chatModal.vue'
export default {
  props: ["studentweekplan", "student", "scope", "chat", "show_confetti_on_startup", "use_fileupload", "files", "previous_plan_id", "previous_plan_key", "is_previous_week"],
  components: {},
  data () {
    return {
      chatIsLoading: true,
      selectedDay: {id: undefined},
      onLine: navigator.onLine,
      showBackOnline: false
    }
  },
  computed: {
    hasUnplannedTasks() {
      for (var i = this.weekPlan.autonomous.lesson_levels.length - 1; i >= 0; i--) {
        let ll = this.weekPlan.autonomous.lesson_levels[i]
        if (ll.student_lesson.week_plan_day_id === null) {
          return true
        }
      }
      return false
    },
    previousPlanUrl(){
      return "/weektaken/" + this.previous_plan_id + "/" + this.previous_plan_key
    },
    hasPreviousPlan(){
      return this.previous_plan_id != undefined && this.previous_plan_id != "" && this.previous_plan_key != undefined
    },
    isBirthday(){
      return this.selectedDay && this.selectedDay.birthday
    },
    shouldThrowConfetti(){
      return window.store.getters.throwConfetti
    },
    studentCredit(){
      return window.store.getters.digitalWeekPlan.student_credits
    },
    weekPlanMessages (){
      return window.store.getters.digitalWeekPlanMessages
    },
    weekPlan (){
      return window.store.getters.digitalWeekPlan
    },
    unreadMessages(){
      if (this.weekPlanMessages.length == 0) {
        return 0
      } else if (this.weekPlanMessages) {
        console.log('weekPlanMessages:')
        console.log(this.weekPlanMessages)
        return this.weekPlanMessages.filter((message) => {
          return !message.read_by_student
        }).length
      } else {
        return 0
      }
    },
    prevDay(){
      if (this.selectedDay.id == undefined || this.selectedDay.id == "extra" || this.selectedDay.id == "comments" || this.selectedDay.id == "autonomous") {
        return false  
      } else {
        const allIds = this.weekPlan.week_plan_days.map(wpd => wpd.id)
        const index = allIds.indexOf(this.selectedDay.id)
        return this.weekPlan.week_plan_days[index - 1]
      }
    },
    nextDay(){
      if (this.selectedDay.id == undefined || this.selectedDay.id == "extra" || this.selectedDay.id == "chat" || this.selectedDay.id == "autonomous" || this.selectedDay.id == "comments" || this.selectedDay.id == "evaluation" ) {
        return false  
      } else {
        const allIds = this.weekPlan.week_plan_days.map(wpd => wpd.id)
        const index = allIds.indexOf(this.selectedDay.id)
        return this.weekPlan.week_plan_days[index + 1]
      }
    }
  },
  methods: {
    updateOnlineStatus(e) {
      const { type } = e;
      this.onLine = type === 'online';
    },
    loadFiles(){
      this.selectedDay = {id: 'files', name: "Bestanden"}
    },
    loadChat(){
      this.chatIsLoading = false
      this.selectedDay = {id: 'chat', name: "Chat"}
    },
    showAlert(){
      this.$confetti.start();
    },
    backgroundClass(index){
      return ['btn-success', 'btn-danger', 'btn-info', 'btn-warning', 'btn-primary'][index]
    },
    arrowClick(direction){
      if (direction == "next"){
        this.selectedDay = this.nextDay
      } else {
        this.selectedDay = this.prevDay
      }
    }
  },
  created() {
    if (this.show_confetti_on_startup) {
      this.$confetti.start({});
      setTimeout(function () {
        this.$confetti.stop(); 
      }.bind(this), 5000)
    }
    
    window.store.dispatch('setDigitalWeekPlan', this.studentweekplan)
    window.store.dispatch('setDigitalWeekPlanMessages', this.studentweekplan.chat.messages)
  },
  mounted(){
    // this.loadChat()
    window.addEventListener('online', this.updateOnlineStatus);
    window.addEventListener('offline', this.updateOnlineStatus);

  },
  beforeDestroy() {
      window.removeEventListener('online', this.updateOnlineStatus);
      window.removeEventListener('offline', this.updateOnlineStatus);
  },


  watch: {
    onLine(v) {
      if (v) {
        this.showBackOnline = true;
          setTimeout(() => {
            this.showBackOnline = false;
        }, 1000);
      }
    },
    'shouldThrowConfetti': function(val, oldVal) {
      if (val == true){
        // this.$confetti.start();  
        this.$confetti.start({});  
      } else {
        this.$confetti.stop();  
      }
    }
  }

}
</script>

<style scoped lang="css">
.nav-link{
  font-size: 20px;
  padding: 0px;
}
.nav-link.active{
  color: #fff !important;
}
.navbar-brand{
  color: #000;
  font-size: 30px;
  padding: 0px;
}
.full-height{
  /*height: calc(100vh - 74px); */
  height: calc(100vh - 74px); 
  /*padding: 20px 0;*/
}
.lesson-container{
  height: 100vh;
  /*width: 100%;*/
  display: flex; 
  flex-direction: column; 
  flex-wrap: wrap;
}
.lesson-container{
  font-family: 'Comfortaa', cursive;
  font-size: 18px;
}
.btn-lg:nth-child(2) {
  background: red !important;
}
.alert-message{
  position: absolute;
  top: 100px;
  width: auto;
  margin-left: auto;
  margin-right: auto;
}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}
.birthday{
  background: url(/img/vector-birthday-party-seamless-pattern-with-balloons-and-confetti-hand-drawn-celebration-decorations-background.jpg) repeat;
}

</style>