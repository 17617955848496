<template>
  <div>

    <PictoPicker @cancel="addNew = false" v-if="addNew" @refreshList="refreshList" :can_add_pictos="true" :always_open="true" :pictos="pictos"></PictoPicker>


    <div class="row mb-3 mt-3">
      <div class="col text-center" v-if="!addNew">
        
        <button @click="showNewPictoModal" class="btn btn-outline-success btn-sm"><i class="fas fa-upload"></i> Nieuwe picto toevoegen</button>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-6 mx-auto">
        <input class="form-control" v-model="keyword" type="text" placeholder="Zoeken..." />
      </div>
    </div>
    <div class="row" :key="`pictos-${pictos.length}`">
      <PictoCard :key="`picto-${picto.id}`" :picto="picto" v-for="picto in filteredPictos"></PictoCard>
    </div>    

    <div class="row pt-3" style="border-top: 1px dashed #dedede;">
      <div class="col-8 mx-auto">
        <div class="list-group">

          <div class="list-group-item pb-0 pt-2">
            <div class="d-flex justify-content-between">
              <span>Gebruik ook picto's uit de standaardbibliotheek</span>
              <span><toggle-button @change="saveSettings" v-model="showPublic" :sync="true" /></span>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import axios from 'axios'
import PictoPicker from '../../components/pictos/pictoPicker.vue'
import PictoCard from '../../components/pictos/pictoCard.vue'
export default {

  name: 'pictoIndex',
  components: {PictoCard, PictoPicker},
  props: ["initial_pictos", "authtoken", "show_public"],
  data () {
    return {
      showPublic: false,
      addNew: false,
      keyword: '',
      pictos: []
    }
  },
  created(){
    this.pictos = this.initial_pictos
    this.showPublic = this.show_public
  },
  methods: {
    refreshList(list){
      // ...
      console.log("reloading list");
      console.log(list);
      this.pictos = list;
      // console.log(this.publicFilteredPictos())
    },
    showPublicPictos(){
      this.showPublic = true
      this.saveSettings()
    },
    hidePublicPictos(){
      this.showPublic = false
      this.saveSettings()
    },
    saveSettings(){
      return axios.put(`/picto-settings.json`, {show_public: this.showPublic})
      .then(response => {
        return response.data
      })
    },
    showNewPictoModal(){
      this.addNew = true
      // $(`#new-picto-modal`).modal('show');
    }
  },
  computed: {
    deleteClass (){
      if (this.isDeleting){
        return 'animated zoomOutRight'
      } else {
        return ''  
      }
      
    },
    publicFilteredPictos() {
      if (this.showPublic){
        return this.pictos
      } else {
        return this.pictos.filter((picto) => { return (picto.is_public == false) })  
      }
      
    },
    filteredPictos() {
      return this.publicFilteredPictos.filter((picto) => {
        return picto.keywords.toLowerCase().includes(this.keyword.toLowerCase())
      })
    }
  }
}
</script>

<style lang="css" scoped>
</style>