<template>
  <div>
    <Button @click="Print" :disabled="!canPrint" class="btn btn-outline-primary" v-if="!ready">
      <i v-if="printJob == null" class="fa fa-print"></i><i v-if="printJob != null" class="fa fa-spinner fa-spin"></i> Printen (PDF)
    </Button>
    <a @click="ready = false" v-if="ready" target="_blank" class="btn btn-success" :href="url">
      <i class="fa fa-download"></i> Klaar! Download PDF
    </a>
  </div>
</template>

<script>
import axios from 'axios'
export default {

  name: 'plannedNotesPrint',
  props: ["week", "planned_note_category_id", "has_notes"],
  data () {
    return {
      ready: false,
      printJob: null,
      url: null
      // selectedDate: null
    }
  },
  computed: {
    canPrint() {
      return this.week != null && this.printJob == null && this.has_notes
    }
  },
  methods: {
    Print(){
      return axios.post(`/print_jobs.json`, {
        planned_note_category_id: this.planned_note_category_id,
        print_job: {
          template_name: "planned_notes",
          options: {
            week: this.week
          }
        }
      }).
      then(response => {
        this.printJob = response.data
        this.CheckPrintStatus();
      }).finally(() => {
        // this.loading = false
      })
    },
    CheckPrintStatus(){
      if (this.printJob == null){
        return
      }

      axios.get(`/print_jobs/${this.printJob.id}.json`)
      .then(response => {
        
        this.printJob = response.data
        if (this.printJob.finished){
          this.url = this.printJob.url
          this.ready = true
          // window.open(this.printJob.url, "_blank")
          this.printJob = null
        }
      })

      if (this.printJob != null){
        setTimeout(this.CheckPrintStatus, 2000)
      }      
    }
  },
  mounted() {

  }
}
</script>

<style lang="css" scoped>
</style>