<template>

    <div class="form-group row mb-1">
      <div class="col-3">
        <label v-if="taskLevelItem.label" >{{taskLevelItem.field_label}}</label>
        <div v-if="use_pictos">
          <div @click="togglePictoPicker()" class="picto-placeholder" :style="pictoStyle" v-bind:class="{'empty' : !(chosenPicto && chosenPicto.thumb)}"></div>
        </div>
      </div>
      <div class="col-9">
        <div class="d-flex">
          <div class='text-left text-muted d-flex align-items-center pr-2' v-if="taskLevelItem.lesson_method_id">
            <i :class="lessonMethod(taskLevelItem).icon"></i>
          </div>
          <textarea @change="submitChanges(taskLevelItem)" v-model="taskLevelItem.task" :placeholder="taskLevelItem.placeholder" class="form-control mb-2"></textarea>
          <div class='text-right text-muted d-flex align-items-center pl-2'>
            <i v-tooltip.top-center="`Instellingen`" class="fa fa-cogs" @click="showSettings = !showSettings"></i>
          </div>
        </div>
        <div v-if="showSettings">

          <div class="list-group">
            <div class="list-group-item pb-1 pt-1 d-flex justify-content-between">
              <span>Werkvorm</span>
              <select @change="submitChanges(taskLevelItem)" v-model="taskLevelItem.lesson_method_id" class="form-control custom-select-sm w-50">
                <option :value="null">Geen werkvorm</option>
                <option :value="method.id" v-for="method in lessonMethods">{{ method.name }}</option>
              </select>
            </div>
            <div class="list-group-item pb-0 pt-1 d-flex justify-content-between">
              <span>Verplicht</span>
              <toggle-button @change="submitChanges(taskLevelItem)" v-model="taskLevelItem.is_required" :sync="true" />        
            </div>
            <div class="list-group-item pb-0 pt-1 d-flex justify-content-between">
              <span>Zelf nakijken</span>
              <toggle-button @change="submitChanges(taskLevelItem)" v-model="taskLevelItem.correct" :sync="true" />      
            </div>
            <div class="list-group-item pb-0 pt-1 d-flex justify-content-between">
              <span>Leerling mag zelf dag kiezen</span>
              <toggle-button @change="submitChanges(taskLevelItem)" v-model="taskLevelItem.show_day_selector" :sync="true" />      
            </div>
            
            <div class="list-group-item pb-0 pt-1 d-flex justify-content-between">
              <span>Voor alle leerlingen</span>
              <toggle-button v-if="!taskLevelItem.student_id" @change="submitChanges(taskLevelItem)" v-model="taskLevelItem.for_all_students" :sync="true" />        
            </div>
            <div class="list-group-item pb-0 pt-1" v-if="taskLevelItem.student_id == null" v-show="!taskLevelItem.for_all_students">
              <StudentPicker :extra-task-level-id="taskLevelItem.id" :students="taskLevelItem.all_students" :selected="taskLevelItem.student_ids" />
            </div>
          </div>

        </div>
        
      </div>
      <div class="" v-if="use_pictos">
        <PictoPicker @refreshList="refreshList" :pictos="pictos" :can_add_pictos="can_add_pictos" @select="selectPicto" v-if="showPictoPicker"></PictoPicker>
      </div>
    
    </div>
  
</template>

<script>
import axios from 'axios'

import StudentPicker from '../../components/settings/studentPicker.vue'
import PictoPicker from '../../components/pictos/pictoPicker.vue'
export default {

  name: 'extraTaskLevel',
  components: {PictoPicker, StudentPicker},
  props: ['taskLevel', 'task', 'use_pictos', 'pictos', 'can_add_pictos', 'lessonMethods', 'taskLevelItem'],
  data () {
    return {
      showPictoPicker: false,
      chosenPicto: null,
      showSettings: false
    }
  },
  created(){
    // this.pictos = this.initial_pictos
    if (this.taskLevelItem.picto){
      this.chosenPicto = this.taskLevelItem.picto
    }
  },
  computed: {
    pictoStyle(){
      if (this.chosenPicto && this.chosenPicto.thumb){
        return `background-image: url(${this.chosenPicto.thumb});`
      } else {
        return ""
      }
    }
  },
  methods: {
    lessonMethod(taskLevelItem) {
      for (var i = 0; i < this.lessonMethods.length; i++) {
        if (this.lessonMethods[i].id === taskLevelItem.lesson_method_id) {
          return this.lessonMethods[i]
        }
      }
      return null
    },
    refreshList(list){
      this.$emit('refreshList', list)
    },
    submitChanges(taskLevelItem){
      this.$emit('change', taskLevelItem)
    },
    selectPicto(picto){
      this.chosenPicto = {id: picto.id, thumb: picto.image}
      this.showPictoPicker = false
      if (this.task && this.task.id){

        console.log('updating task')
        // console.log(this.taskLevel)
        return axios.put("/set-extra-task-picto.json", {
          extra_task_level_id: this.taskLevelItem.id, 
          picto_id: this.chosenPicto.id 
        })
          .then(response => {
            // window.store.dispatch('setDigitalWeekPlanMessages', response.data)
            console.log('picto saved!')
          })
        // Weektaak.updateCourse(this.course.id, {picto_id: this.chosenPicto.id})  
      }
    },
    togglePictoPicker () {
      this.showPictoPicker = (this.showPictoPicker == false)
    }
  }
}
</script>

<style lang="css" scoped>
  .picto-placeholder{
    background-color: #999; 
    background-size: cover;
    border: 1px solid #000; 
    width: 32px; 
    height: 32px;
    cursor: pointer;
  }
  .picto-placeholder.empty{
    border-style: dashed;
  }
</style>