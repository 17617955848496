<template>
  <div class="mt-2 bg-light px-4 py-2 pt-2 rounded">
    <div class="" v-if="!editMode">
      <div class="row mb-3 pt-2" >
        <div class="col-6">
          <input class="form-control" v-model="keyword" type="text" placeholder="Zoeken..." ref="searchBar"/>
        </div>
        <div class="col-6 text-right" v-if="true">
          <button @click="enterEditMode" class="btn btn-outline-success"><i class="fa fa-upload"></i> Afbeelding toevoegen</button>
        </div>
      </div>
      <div class="row" style="max-height: 400px; overflow-y: auto;">
        <div v-if="hasImage || alwaysShowDelete" class="col-4 col-md-2 mb-3 px-2 picto-item">
          <div @click="selectPicto(null)" class="card" style="border: 1px dashed #333; background: #dedede; position: relative;">
            <img src="/img/wis2.png" class="card-img-top mb-2" style="opacity: 0;"/>
            <div style="position: absolute; top: 20%; left: 0px; width: 100%; font-size: 11px; text-align: center; padding: 10px;">
              Gekozen afbeelding wissen
            </div>
          </div>
        </div>
        <div class="col-4 col-md-2 mb-3 px-2 picto-item" :key="`picto-${picto.id}`" v-for="picto in filteredPictos">
          <div @click="selectPicto(picto)" class="card" style="border: 1px solid #000;">
            <img :src="picto.image" class="card-img-top mb-2" />
          </div>
        </div>
      </div>
      <div class="row" v-if="!filteredPictos.length">
        <div class="col text-center">
          Geen picto's gevonden met het zoekwoord '{{keyword}}'. Je kunt zelf nieuwe picto's toevoegen bij de <a href="/settings/pictos">instellingen voor pictogrammen</a>.
        </div>
      </div>
    </div>

    <div v-if="!can_add_pictos && editMode">
      <div class="col-6 col-xl-8 mx-auto">
        <div class="card text-center">
          <div class="card-body text-muted">
            Je bent niet bevoegd om afbeeldingen toe te voegen. Ga naar <a href="/settings/pictos">instellingen voor pictos</a> om te zien wie deze bevoegdheid wel heeft.
            <div class="text-center mt-3">
              <button class="btn btn-sm btn-outline-secondary" @click="cancelAdd">annuleren</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="editMode && can_add_pictos">
      <div class="row mb-2" v-show="showBtns && !loading">
        <div class="col-12 text-center">
          <p>Je kunt in- en uitzoomen door te scrollen</p>
          <div class="btn-group">
            <button class="btn btn-outline-primary btn-sm" @click="newPicto.rotate()"><i class="fas fa-undo"></i> draaien</button>
            <button class="btn btn-outline-primary btn-sm" @click="newPicto.flipX()"><i class="fas fa-arrows-alt-h"></i> horizontaal spiegelen</button>
            <button class="btn btn-outline-primary btn-sm" @click="newPicto.flipY()"><i class="fas fa-arrows-alt-v"></i> verticaal spiegelen</button>
          </div>
        </div>  
      </div>
      <div class="row">
        <div class="col">
          <div class="mx-auto" style="width: 200px;">
            <croppa v-model="newPicto" 
              placeholder="Kies een afbeelding van je computer" 
              :placeholder-font-size="10"
              :file-size-limit="5000000"
              :prevent-white-space="false"
              @file-type-mismatch="onFileTypeMismatch"
              @file-size-exceed="onFileSizeExceed"
              @new-image="handleCroppaFileChoose"
              @image-remove="handleCroppaFileRemove"
            >  
            </croppa>
          </div>
        </div>  
      </div>
      <div class="row" v-show="!showBtns && !loading">
        <div class="col text-center">
          <button class="btn btn-sm btn-outline-secondary" @click="cancelAdd">annuleren</button>
        </div>
      </div>
      <div class="row" v-show="showBtns">
        <div class="col-6 mx-auto">
          <div class="form-group" v-if="!loading">
            <label for="imgName">Naam van de afbeelding</label>
            <input ref="pictoName" type="email" class="form-control" id="imgName" v-model="name">
            <small class="form-text text-muted">Deze naam wordt gebruikt als zoekwoord</small>
          </div>
          <center v-if="loading"><i class="fa fa-spin fa-spinner my-4"></i></center>
          <button @click="createPicto" class="btn btn-success btn-block" v-bind:disabled="loading">Opslaan</button><br>
        </div> 
      </div>
      
    </div>
  </div>

</template>

<script>
import axios from 'axios'
export default {
  props: ['pictos', 'can_add_pictos', 'always_open', 'currentPicto', 'alwaysShowDelete'],
  name: 'pictoPicker',

  data () {
    return {
      keyword: '',
      editMode: false,
      showBtns: false,
      newPicto: {},
      hasImage: false,
      loading: false,
      name: ''
    }
  },
  mounted() {
    console.log('mounted')
    console.log(this.currentPicto)
    if (this.currentPicto && this.currentPicto.id != null) {
      this.hasImage = true
    }
  },
  created() {
    if (this.always_open){
      this.editMode = true
    }
    setTimeout(() => {  
      if (this.$refs.searchBar){
        this.$refs.searchBar.focus(); 
      }
    }, 200); 
  },
  methods: {
    cancelAdd(){
      if (this.always_open == true){
        this.$emit('cancel')
      } else {
        this.editMode = false
      }
    },
    createPicto(){
      const data = this.newPicto.generateDataUrl()
      console.log(data)
      this.loading = true
      return axios.post("/create-picto.json", { picto: data, name: this.name, filename: this.newPicto.chosenFile.name, return_all: this.always_open })
        .then(response => {
          console.log('success')
          console.log(response.data)
          // this.keyword = this.name
          if (this.always_open){
            this.editMode = true
            this.$emit('cancel')
          } else {
            this.editMode = false
          }
          this.loading = false
          this.newPicto = {}
          this.showBtns = false
          this.name     = ''
          this.$emit('refreshList', response.data)
          
          // window.store.dispatch('setDigitalWeekPlanMessages', response.data)
        })
    },
    handleCroppaFileChoose(){
      // name: "NOBODY-LOGO-DEF_PNG.png"
      // console.log(this.newPicto)
      // this.name = this.newPicto.chosenFile.name.toLowerCase().split(".jpg")[0].split(".png")[0]
      setTimeout(() => {  
        // this.$refs.pictoName.focus(); 
        if (this.newPicto.chosenFile){
          this.name = this.newPicto.chosenFile.name.toLowerCase().split(".jpg")[0].split(".png")[0];
        }
      }, 500); 
      
      this.showBtns = true
    },
    enterEditMode(){
      this.editMode = true
    },
    selectPicto(picto) {
      if (picto === null) {
        this.hasImage = false
      } else {
        this.hasImage = true
      }
      this.$emit('select', picto)
    },
    handleCroppaFileRemove(){
      this.showBtns = false
      this.name = ''
      // this.newPicto = {}
    },
    onFileTypeMismatch (file) {
      this.showBtns = false
      alert('Ongeldig bestandstype. Upload een .jpeg, een .jpg of .png bestand.')
     },
     onFileSizeExceed (file) {
       alert('Bestand is te groot. Upload een bestand kleinder dan 500kb.')
     }
  },
  computed: {
    filteredPictos() {
      return this.pictos.filter((picto) => {
        return picto.keywords.toLowerCase().includes(this.keyword.toLowerCase())
      })
    },
  }
}
</script>

<style lang="css" scoped>
  .picto-item .card{
    cursor: pointer;
  }
  .picto-item .card:hover{
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.75)!important;
    border-width: 0 !important;
  }
</style>