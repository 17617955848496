<template>
  <div>
    <div class="row" style="margin-bottom: -16px;">
      <div class="col mt-4 mx-auto text-center">
          <div class="btn-group">
            <button v-for="weekplan in weekplans" @click="selectedWeekPlan = weekplan.id" class="btn btn-outline-primary btn-sm" :class="{active: weekplan.id == selectedWeekPlan}">{{ weekplan.group.name }}</button>
          </div>
        </div>
    </div>
    
    <div class="row">
      <div v-show="weekplan.id == selectedWeekPlan" class="col-12" v-for="weekplan in weekplans" :key="`weekplan-notes-${weekplan.id}`">
        <WeekPlanNotes 
        :week_plans_for_year="weekplan.week_plans_for_year" 
        :levels="weekplan.levels"  
        :printurl="weekplan.printurl" 
        :courses="weekplan.courses" 
        :students="weekplan.students" 
        :lessons="weekplan.lessons" 
        :id="weekplan.id"
        :notes="weekplan.notes"></WeekPlanNotes>    
      </div>
    </div>


  </div>
</template>

<script>
import WeekPlanNotes from '../../components/week_plans/weekPlanNotes.vue'
export default {

  name: 'combiWeekPlanNotes',
  components: {WeekPlanNotes},
  props: ['weekplans', 'id'],
  data () {
    return {
      selectedWeekPlan: null
    }
  },
  mounted() {
    this.selectedWeekPlan = this.id
  }
}
</script>

<style lang="css" scoped>
</style>