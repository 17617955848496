<template>
  <div>
    <div v-if="selectedYear">
      <div class="text-center mb-1">
        <div class="d-flex justify-content-between px-3">
          <button @click="setYear(prevYear)" :disabled="!prevYear" class="btn btn-outline-secondary btn-sm"><i class="fa fa-chevron-left"></i></button>
          <h3>schooljaar {{ yearName }}</h3>
          <button @click="setYear(nextYear)" :disabled="!nextYear" class="btn btn-outline-secondary btn-sm"><i class="fa fa-chevron-right"></i></button>
        </div>
        <button class="btn btn-outline-primary my-3" @click="expand" v-if="!expanded">activiteit toevoegen <i class="fa fa-plus-square"></i></button>
        <button class="btn btn-outline-primary my-3" @click="expandHoliday" v-if="!expanded">vakanties toevoegen <i class="fa fa-plus-square"></i></button>
      </div>
      <div class="bg-light p-3 rounded my-3 shadow-sm" v-show="holidayExpanded">
        
        <div class="mb-3">
          <p class="note">Door hieronder een vakantieregio te kiezen en te klikken op 'vakanties importeren', voeg je in één keer alle schoolvakanties van de gekozen periode toe. Uiteraard kun je deze achteraf wijzigen.</p>
          <label for="region" class="form-label">Regio</label>
          <select v-model="region" @change="getHolidayPreview" name="region" class="form-control" id="region">
            <option :value="'nl_noord'">Nederland Regio Noord</option>
            <option :value="'nl_midden'">Nederland Regio Midden</option>
            <option :value="'nl_zuid'">Nederland Regio Zuid</option>
            <option :value="'be_vlaanderen'">Vlaanderen</option>

          </select>
          <div class="py-2 bg-white rounded my-3">
            <div v-if="loadingHolidays" class="mx-auto d-flex justify-content-center my-5">
              <i class="fa fa-spinner fa-spin"></i>
            </div>
            <div v-if="!loadingHolidays" v-html="calendarHtml">
              
            </div>
          </div>
        </div>
        <div class="mb-3">
          
            <button @click="addHoliday" class="btn btn-outline-primary">Importeer vakantieperiode</button>
            <button @click="holidayExpanded = false" class="btn btn-text">Annuleer</button>
        </div>
      </div>
      <div class="bg-light p-3 rounded my-3 shadow-sm" v-show="expanded">
        <div class="mb-3">
          <label for="title" class="form-label">Naam van de activiteit</label>
          <input @keydown.enter="saveActivity" ref="title" v-model="newEvent.title" type="text" class="form-control" id="title" placeholder="Studiedag">
        </div>
        <div class="mb-3">
          <label for="description" class="form-label">Omschrijving van de activiteit</label>
          <textarea ref="description" v-model="newEvent.description" type="text" class="form-control" id="description" placeholder="Waarom is vandaag een vrije dag? De leerlinge krijgen deze tekst op de weektaak te zien."></textarea>
        </div>
        <div class="mb-3">
          <div class="btn-group">
            <button @click="multipleDays = false" class="btn btn-outline-primary btn-sm" :class="{active: !multipleDays}">Eén dag</button>
            <button @click="multipleDays = true; newEvent.ends_on = null" class="btn btn-outline-primary btn-sm" :class="{active: multipleDays}">Meerdere dagen</button>
          </div>
        </div>
        <div class="mb-3 w-50">
          <div class="row">
            <div class="col-6">
              <label for="date" class="form-label" v-if="multipleDays">Van</label>
              <label for="date" class="form-label" v-if="!multipleDays">Datum</label>
              <input v-model="newEvent.starts_on" @keydown.enter="saveActivity" type="date" class="form-control" id="date" :min="`${selectedYear.year}-08-01`" :max="`${selectedYear.year+1}-07-31`">
            </div>
            <div class="col-6" v-if="multipleDays">
              <label for="date" class="form-label">Tot</label>
              <input v-model="newEvent.ends_on" @keydown.enter="saveActivity" type="date" class="form-control" id="end-date" :min="newEvent.starts_on" :max="`${selectedYear.year+1}-07-31`">
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-between">
          <button @click="saveActivity" :disabled="btnDisabled" class="btn btn-success">
            <i v-if="saving" class="fa fa-spinner fa-spin"></i> Activiteit toevoegen
          </button>
          <button @click="expanded = false" class="btn btn-text">Annuleer</button>
        </div>
      </div>

      <div v-if="events.length">
        <table class="table">
          <YearPlanningEvent 
            v-for="event in events" 
            :key="`event-${event.id}`" 
            :event="event" 
            @delete="deleteEvent"
          />
        </table>
      </div>
      <div v-if="!events.length && !loading">
        <div class="col-10 mx-auto">
          <div class="card text-center">
            <div class="card-body text-muted">
              <p>Er zijn nog geen activiteiten toegevoegd voor het schooljaar {{ yearName }}. Door activiteiten toe te voegen, kun je hele dagen uitroosteren, zodat je daar later in het schooljaar niet meer aan hoeft te denken. Handig om bijvoorbeeld studiedagen en het sinterklaasfeest van te voren als lesvrije dag in te roosteren.</p>
              <p><b>Let op:</b> alle activiteiten die je toevoegt, worden automatisch voor alle groepen van de school uitgeroosterd.</p>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>

import axios from 'axios'
import YearPlanningEvent from './YearPlanningEvent.vue'

export default {

  name: 'YearPlanning',
  components: {YearPlanningEvent},
  props: ['schoolId', 'years', 'currentYearId'],
  data () {
    return {
      region: 'nl_noord',
      selectedYear: null,
      expanded: false,
      holidayExpanded: false,
      loading: true,
      newEvent: null,
      saving: false,
      holidays: [],
      calendarHtml: '',
      multipleDays: false,
      loadingHolidays: false,
      events: []
    }
  },
  mounted() {
    this.selectedYear = this.years.filter((year) => { return year.id == this.currentYearId})[0]
    this.initializeNewEvent()
    this.fetchEvents()
  },
  computed: {
    selectedYearIndex() {
      return this.years.indexOf(this.selectedYear)
    },
    prevYear() {
      if (this.selectedYearIndex == 0) {
        return false
      } else {
        return this.years[this.selectedYearIndex-1]
      }
    },
    nextYear() {
      if (this.selectedYearIndex == (this.years.length - 1)) {
        return false
      } else {
        return this.years[this.selectedYearIndex+1]
      }
    },
    btnDisabled() {
      return !this.newEvent.title.length || !this.newEvent.starts_on.length || this.saving
    },
    yearName() {
      return `${this.selectedYear.year}-${this.selectedYear.year + 1}`
    }
  },
  methods: {
    getHolidayPreview(){
      this.loadingHolidays = true
      axios.get(`/school_years/${this.selectedYear.id}/holidays/${this.region}.html`)
      .then((response) => {
        this.calendarHtml = response.data
      }).catch((error) => {
        console.error(error);
      }).finally(() => {
        // TODO
        this.loadingHolidays = false
      });
    },
    addHoliday(){
      this.loadingHolidays = true
      this.saving = true
      axios.post(`/school_years/${this.selectedYear.id}/school_year_days.json?holiday_region=${this.region}`, {})
        .then((response) => {
          this.events = response.data
          // TODO
        }).catch((error) => {
          console.error(error);
        }).finally(() => {
          // TODO
          this.expanded = false
          this.holidayExpanded = false
          this.saving   = false
          this.loadingHolidays = false
        });

    },
    setYear(year) {
      this.selectedYear = year
      this.fetchEvents()
      if (this.holidayExpanded){
        this.getHolidayPreview()  
      }
    },
    expand() {
      this.holidayExpanded = false
      this.expanded = !this.expanded
      setTimeout(function() {
        this.$refs.title.select()
      }.bind(this), 100)
      
    },
    expandHoliday() {
      this.holidayExpanded = !this.holidayExpanded
      this.expanded = false
      this.getHolidayPreview()
      setTimeout(function() {
        this.$refs.title.select()
      }.bind(this), 100)
      
    },
    initializeNewEvent() {
      this.newEvent = {title: '', starts_on: '', description: ''}
    },
    saveActivity() {
      if (this.btnDisabled) {

      } else {
        this.saving = true
        axios.post(`/school_years/${this.selectedYear.id}/school_year_days.json`, {
          school_year_day: this.newEvent,
        }).then((response) => {
          this.events = response.data
          // TODO
        }).catch((error) => {
          console.error(error);
        }).finally(() => {
          // TODO
          this.expanded = false
          this.saving   = false
        });
      }
    },
    deleteEvent(event){
      this.loading = true
      axios.delete(`/school_years/${this.selectedYear.id}/school_year_days/${event.id}.json`)
      .then((response) => {
        this.events = response.data
      }).catch((error) => {
        console.error(error);
      }).finally(() => {
        // TODO
        // this.loading = false
      });
    },
    fetchEvents() {
      this.events = []
      this.loading = true
      axios.get(`/school_years/${this.selectedYear.id}/school_year_days.json`)
      .then((response) => {
        this.events = response.data
      }).catch((error) => {
        console.error(error);
      }).finally(() => {
        // TODO
        this.loading = false
      });
    }
  }
}
</script>

<style lang="css" scoped>
</style>