<template>
  <div class="">
  
    <div class="list-group">
      <courseshow @refreshList="refreshPictoList" :groups="groups" :can_add_pictos="can_add_pictos" :pictos="pictos" :show_picto="show_picto" :key="`course-${course.id}`" class="list-group-item" :usecolor="usingColor" :course="course" v-for="course in allCourses" />
    </div>

    <div class="list-group mt-5 shadow">
      <courseshow @refreshList="refreshPictoList" :can_add_pictos="can_add_pictos" :pictos="pictos" :show_picto="show_picto" :key="`course-new`" class="list-group-item" :usecolor="usingColor" :course="{'new': true, 'name': ''}" />
    </div>
  </div>


</template>

<script>

import Vue from 'vue/dist/vue.esm'
import courseShow from '../../components/settings/courseShow.vue'
import Weektaak from '../../packs/weektaak'

export default {
  components: {
  },
  props: ["courses", "colors", "groups", "show_picto", "initial_pictos", "can_add_pictos"],

  data () {
    return {
      usingColor: true,
      pictos: []
    }
  },
  computed: {
    allCourses (){
      return window.store.getters.allCourses
    }
  },
  methods: {
    refreshPictoList(list){
      console.log("setting pictos!")
      // this.$emit('refreshList', list)
      this.pictos = list
    },
    changeColor() {

      Weektaak.saveUserPreference({use_colors: this.usingColor})
    }
  },
  created () {
    this.pictos = this.initial_pictos
    this.usingColor = this.colors // == 'true'
    window.store.dispatch('setSchoolCourses', this.courses)
  }
  
}
</script>

<style scoped>

</style>
