<template>

    <div  class="list-group-item py-1 d-flex justify-content-between container" v-bind:class="{'bg-light': student.is_new}">
      <div v-if="edit" class="input-group">
        <div class="input-group-prepend" v-if="!student.is_new">
          <div class="input-group-text">
            <input ref="move_checkbox" @change="selectStudent" v-model="student.is_selected" type="checkbox" aria-label="Selecteer om leerling te selecteren voor verplaatsing">
          </div>
        </div>
        <input type="text" @change="submitNameChange" v-model="first_name" placeholder="Voornaam" class="form-control string optional py-0" ref="first_name" v-bind:class="{'mt-2': student.is_new }" >
        <input type="text" @change="submitNameChange" v-model="last_name" placeholder="Achternaam" v-on:keyup.enter="submit" class="form-control string optional py-0" v-bind:class="{'mt-2': student.is_new }">
        <div v-if="birthdays" class="input-group-prepend input-group-append" v-bind:class="{'mt-2': student.is_new, 'pb-3': student.is_new }">
          <span class="input-group-text">
            <i class="fas fa-birthday-cake"></i>
          </span>
        </div>
        <input v-if="birthdays" type="tel" @change="submitNameChange" v-model="birthday" placeholder="DD-MM-JJJJ" v-mask="'##-##-####'" v-on:keyup.enter="submit" class="form-control string optional py-0" v-bind:class="{'mt-2': student.is_new }">

        <div v-if="student.is_new" class="input-group-append">
          <button class="btn btn-success mb-3 mt-2" @click="submitStudent" :disabled="namesAreBlank">Toevoegen</button>
        </div>
      </div>

      <div v-if="!edit">        
        <a :href="`/students/${student.id}`" class="text-dark">{{ student.first_name }} {{ student.last_name }}</a>
        <span v-if="student.parnassys_key" class="parnassys-logo" title="Geïmporteerd uit leerlingvolgsysteem"></span>
      </div>

    </div>

</template>

<script>

import {mask} from 'vue-the-mask'

import axios from 'axios'
import Weektaak from '../../packs/weektaak'

export default {
  props: ["student", "edit", "groupid", "is_selected", "birthdays"],
  directives: {mask},
  data () {
    return {
      autofocus: false,
      first_name: '',
      last_name: '',
      birthday: ''
      // is_selected: false
    }
  },
  computed: {
    namesAreBlank(){
      return this.first_name == '' //|| this.last_name == ''
    }
  },
  methods: {
    selectStudent() {
      console.log('selecting student...')
      this.$emit('selectedStudent', this.$refs.move_checkbox.checked, this.student.id)
    },
    submit() {
      this.$refs.first_name.focus()
      if (this.student.is_new){
        this.submitStudent()
      } else {
        this.submitNameChange()
      }
    },
    submitNameChange (){
    // console.log("submitting name schange")
      if (!this.student.is_new){
        return axios.put("/students/"+ this.student.id +".json", {student: {first_name: this.first_name, last_name: this.last_name, birthday: this.birthday}})
          .then(response => {
            return response.data
          })
      }
    },
    submitStudent(){

      if (!this.namesAreBlank) {
        Weektaak.createStudent(this.groupid, {first_name: this.first_name, last_name: this.last_name, birthday: this.birthday})
          .then(data => {
            console.log("saved")
            console.log(data)
            this.$emit('appendNewStudent', data)
          })
        this.appendStudent()
        this.$refs.first_name.focus()
      }


    },
    appendStudent (){    
      this.first_name = ''
      this.last_name = ''
    },
  },
  created() {
    this.first_name = this.student.first_name
    this.last_name  = this.student.last_name
    if (this.birthdays){
      this.birthday   = this.student.show_birth_day
    }
  }

  
}
</script>

<style scoped>
  
</style>
