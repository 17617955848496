<template>
  <div id="chat-window">
    <!-- search bar -->
    <div id="search" class="bg-light border-bottom border-grey" :class="{'invisible-view' : selectedChat != null}">

      <!-- group selector -->
      <div class="p-2 border-bottom border-grey">
        <select v-model="selectedGroupId" class="custom-select custom-select-sm">
          <option value="my-chats">Mijn chats</option>
          <option value="unread">Ongelezen berichten</option>
          <option :value="null">Alle groepen</option>
          <option v-for="group in groups" :value="group.id">{{ group.name_with_group }}</option>
        </select>
      </div>

      <!-- search bar -->
      <div class="p-2">
        <div class="form-group mb-0">
          <input v-model="search" type="text" class="form-control mb-0" placeholder="Leerling zoeken...">
        </div>
      </div>
    </div>

    <!-- student list -->
    <div id="student-list" class="bg-light"  :class="{'invisible-view' : selectedChat != null}">
      <div class="list-group list-group-flush">
        <div class="list-group-item list-group-item-action disabled text-center" v-if="selectedGroupId == 'my-chats' && !filteredChats.length">Je hebt nog geen chatgeschiedenis.</div>
        <div class="list-group-item list-group-item-action disabled text-center" v-if="selectedGroupId == 'unread' && !filteredChats.length">Er zijn op dit moment geen ongelezen berichten 🎉</div>
        <div @click="selectChat(chat)" v-for="chat in filteredChats" :key="chat.id" class="list-group-item list-group-item-action" :class="{'active': selectedChat && selectedChat.id == chat.id}">
          <div class="d-flex w-100 justify-content-between align-items-end">
            <h5 class="mb-1">{{ chat.student.name }}</h5>
            <span class="badge badge-danger badge-pill" v-if="chat.unread > 0">{{ chat.unread }}</span>
          </div>
          <div class="d-flex w-100 justify-content-between">
            <small>{{ chat.last_interaction }}</small>
            <small>{{ chat.last_interaction_at }}</small>
          </div>
          <div class="d-flex w-100 justify-content-between">
            <small>{{ chat.group }}</small>
          </div>
        </div>
      
      </div>
    </div>
    
    <!-- chat header -->
    <div id="chat-head" class="bg-light shadow-sm"  :class="{'invisible-view' : selectedChat == null}">
      <div v-if="selectedChat" class="pt-3 d-flex justify-content-between align-items-center px-3">
        <i style="cursor: pointer;" @click="clearCurrentChat" class="fas fa-arrow-left"></i>
        <h4 style="font-weight: 500">{{ selectedChat.student.name }}</h4>
        <small>{{ selectedChat.group }}</small>
      </div>
    </div>

    <!-- chat messages -->
    <div id="chat" :data-id="dataAttribute" :class="{'invisible-view' : selectedChat == null}" v-chat-scroll="{always: false, smooth: false, scrollonremoved: true}">
      <div class="mt-5 w-50 rounded p-4 bg-white mx-auto shadow text-center" v-if="selectedChat == null">
        <p>Wil je liever op je mobiel chatten? Ga dan op je smartphone naar: <b>chat.weektaak.com</b></p>
        <p><b>Let op: </b> Dit chatvenster is alleen voor leerkrachten. Leerlingen chatten via hun digitale weektaak.</p>
      </div>
      
      <div v-if="showFileUpload && currentChat" class="w-100 chat-view py-2">
        <fileUploader @upload="uploadedFile"  @cancel="showFileUpload = false" :url="`/message-attachment?user_chat_id=${selectedChat.id}&scope=user`" />
      </div>

      <div v-if="selectedChat && currentChat && !currentChat.messages && !showFileUpload" class="text-center mt-5">
        <em>Chatgeschiedenis wordt geladen...</em><br>
        <i class='fa fa-spinner fa-spin'></i>
      </div>
      <div v-if="currentChat && currentChat.messages" class="container pt-3">
        <div v-show="!showFileUpload">
          <div v-if="currentChat.messages.length">
            <chatMessage scope="user" :message="message" :can_delete="false" :key="`message-${message.id}`" v-for="message in currentChat.messages"></chatMessage>
          </div>
          <center v-if="!currentChat.messages.length">
            <em>{{selectedChat.student.name }} heeft nog geen chats.</em>
          </center>
        </div>
      </div>
    </div>

    <!-- new message input -->
    <div id="new-message" class="bg-light py-2 px-3" v-if="selectedChat && !showFileUpload">
      <div class="input-group w-100 mb-1">
        <input v-model="newMessage" ref="messageBox" v-on:keyup.enter="saveMessage" type="text" class="form-control" placeholder="Type hier jouw bericht">
        <emoji-picker @emoji="insert">
          <div class="input-group-append" slot="emoji-invoker" slot-scope="{ events: { click: clickEvent } }" @click.stop="clickEvent">
            <button class="btn btn-secondary emoji-pick-btn">😀</button>
          </div>
          <div slot="emoji-picker" class="emoji-picker" slot-scope="{ emojis, insert, display }">
            <div class="sticky-top p-3" style="background: rgba(0,0,0,0.8);">
              <input v-model="newMessage" ref="messageBoxEmoji" type="text" class="form-control" placeholder="Type hier jouw bericht" readonly>
            </div>
            <div>
              <div>
                <div v-for="(emojiGroup, category) in emojis" :key="category">
                  <hr>
                  <div class="emojis">
                    <span
                      v-for="(emoji, emojiName) in emojiGroup"
                      :key="emojiName"
                      @click="insert(emoji)"
                      :title="emojiName"
                    >{{ emoji }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </emoji-picker>
        <div class="input-group-append" v-if="use_files">
          <button @click="showFileUpload = true" class="btn btn-success" type="button"><i class="fas fa-paperclip"></i></button>
        </div>
        <div class="input-group-append">
          <button @click="saveMessage" v-bind:disabled="newMessage == '' || this.isDisabled" class="btn btn-success" type="button">Verstuur</button>
        </div>
      </div>

    </div
    >
  </div>
</template>

<script>

import axios from 'axios'
import EmojiPicker from 'vue-emoji-picker'
import chatMessage from '../../components/digital/chatMessage.vue'
import fileUploader from '../../components/digital/fileUploader.vue'
  
export default {
  components: {chatMessage, EmojiPicker, fileUploader},
  props: ['uuid', 'groups', 'selected_group_id', 'use_files'],
  name: 'chatWindow',
  watch: {
    'chats': function(val, oldVal) {
      if (!this.isLoading){
        if (oldVal.length) {
          if (oldVal != val){
            if (oldVal[0].unread != val[0].unread) {
              console.log(oldVal[0])
              console.log(val[0])
              this.playPling()
            }
          }
        }
      }
    }
  },
  data () {
    return {
      showFileUpload: false,
      isLoading: true,
      selectedGroupId: 'my-chats',
      search: '',
      selectedChat: null,
      emojiExpanded: false,
      newMessage: '',
      isDisabled: false
    }
  },
  computed: {
    dataAttribute(){
      if (this.selectedChat) {
        return this.selectedChat.id
      } else {
        return 'none'
      }
    },
    chats() {
      return store.getters.chats
    },
    currentChat() {
      return store.getters.user_chat
    },
    filteredChats() {
      // is_relevant_for_user
      if (this.selectedGroupId == 'my-chats') {
        if (this.search == '') {
          return this.chats.filter((chat) => {
            return chat.is_relevant_for_user
          })  
        } else {
          return this.chats.filter((chat) => {
            return chat.student.name.toLowerCase().includes(this.search.toLowerCase())
          })  
        }
      } else if (this.selectedGroupId == 'unread') {
        if (this.search == '') {
          return this.chats.filter((chat) => {
            return chat.unread > 0
          })  
        } else {
          return this.chats.filter((chat) => {
            return chat.unread > 0 && chat.student.name.toLowerCase().includes(this.search.toLowerCase())
          })  
        }
      } else if (this.search == '') {
        if (this.selectedGroupId == null) {
          return this.chats
        } else {
          return this.chats.filter((chat) => {
            return chat.group_id == this.selectedGroupId
          })  
        }
      } else if (this.selectedGroupId == null) {
        return this.chats.filter((chat) => {
          return chat.student.name.toLowerCase().includes(this.search.toLowerCase())
        })  
      } else {
        return this.chats.filter((chat) => {
          return chat.student.name.toLowerCase().includes(this.search.toLowerCase()) && (chat.group_id == this.selectedGroupId)
        })   
      }
    }
  },
  methods: {
    clearCurrentChat() {
      this.selectedChat = null
      window.store.commit("setUserChat", {})
    },
    playPling() {
      var audio = new Audio('/pling.mp3')
      try {
        audio.play()
      } 
      catch(err) {
        console.log('error playing notification')
        console.log(err)
      }
    },  
    uploadedFile(data){
      this.showFileUpload = false
      window.store.commit('setUserChat', data)
    },
    saveMessage() {
      if (this.isDisabled == false){
        this.isDisabled = true
        return axios.post(`/user-chats/${this.uuid}/${this.selectedChat.id}.json`, {message: this.newMessage})
        .then(response => {
          this.newMessage = ''
          this.isDisabled = false
          // this.$refs.messageBox.focus()
          // window.store.dispatch('setUserChat', response.data)
          window.store.commit("setUserChat", response.data)
        })
        .catch(error => {
          alert(`Chat kon niet verstuurd worden. Probeer het over enkele ogenblikken nog een keer. Melding: ${error}`)
          this.isDisabled = false
          console.log('error saving')
          console.log(error)
        })
      }
    },
    hideEmoji() {
      this.emojiExpanded = false
      this.$refs.messageBox.focus()
    },
    insert(emoji) {
      this.newMessage += emoji
      this.$refs.messageBox.focus()
    },
    selectChat(chat){
      // this.playPling()
      this.isLoading = true
      this.selectedChat = chat
      
      document.querySelector("#user-uuid").dataset.student_id = chat.student_id

      window.store.commit("setUserChat", {})
      window.store.dispatch('fetchChatMessagesForUserChat', {uuid: this.uuid, user_chat_id: chat.id})
      .then(() => {
        // this.$refs.messageBox.focus()
        this.isLoading = false
      })
    },
    selectGroup(group){
      return true
    }
  },
  created () {
    // this.selectedGroupId = this.selected_group_id
    window.store.dispatch('fetchChatMessages', this.uuid)
    .then(() => {
      this.isLoading = false
      let vh = window.innerHeight * 0.01;
      // Then we set the value in the --vh custom property to the root of the document
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    })
  },
  

}
  window.addEventListener('resize', () => {
    // We execute the same script as before
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  });
</script>

<style lang="css" scoped>
@media (max-width: 560px){
  .invisible-view{
    display: none !important;
  }
  #chat-window {
    height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);
    width: 100vw;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 60px 42px 1fr 56px;
  }
  #student-list-head{
    grid-row: 1;
    grid-column-start: 1;
    grid-column-end: 2;
  }

  #chat-head{
    grid-row: 1;
    grid-column-start: 1;
    grid-column-end: 2;
  }
  #search {
    grid-row-start: 1;
    grid-row-end: 3;
    grid-column-start: 1;
    grid-column-end: 2;
    overflow-y: hidden;
    border-right: 1px solid #dee2e6!important;;
  }
  #student-list{
    grid-row-start: 3;
    grid-row-end: 5;
    grid-column-start: 1;
    grid-column-end: 2;
    overflow-y: auto;
    border-right: 1px solid #dee2e6!important
  }
  #chat{
    grid-row-start: 2;
    grid-row-end: 4;
    grid-column-start: 1;
    grid-column-end: 2;
    overflow-y: auto;
  }
  #new-message{
    border-top: 1px solid #dee2e6!important;;
    grid-row-start: 4;
    grid-row-end: 5;
    grid-column-start: 1;
    grid-column-end: 2;
  }
}

@media (min-width: 560px){
  .fa-arrow-left{
    display: none !important;
  }
  #chat-window {
    height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);
    width: 100vw;
    display: grid;
    grid-template-columns: 300px 1fr;
    grid-template-rows: 60px 42px 1fr 56px;
  }
  #student-list-head{
    grid-row: 1;
    grid-column-start: 1;
    grid-column-end: 2;

  }
  #chat-head{
    grid-row: 1;
    grid-column-start: 2;
    grid-column-end: 3;
  }
  #search {
    grid-row-start: 1;
    grid-row-end: 3;
    grid-column-start: 1;
    grid-column-end: 2;
    overflow-y: hidden;
    border-right: 1px solid #dee2e6!important;;
  }
  #student-list{
    grid-row-start: 3;
    grid-row-end: 5;
    grid-column-start: 1;
    grid-column-end: 2;
    overflow-y: auto;
    border-right: 1px solid #dee2e6!important
  }
  #chat{
    grid-row-start: 2;
    grid-row-end: 4;
    grid-column-start: 2;
    grid-column-end: 3;
    overflow-y: auto;
  }
  #new-message{
    border-top: 1px solid #dee2e6!important;;
    grid-row-start: 4;
    grid-row-end: 5;
    grid-column-start: 2;
    grid-column-end: 3;
  }
}

.list-group-item{
  cursor: pointer;
}

#search input{
  border-radius: 25px !important;
  padding-left: 14px;
}

.emoji-picker {
  width: 100%;
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100%;
  max-height: 50vh;
  overflow-y: auto;
  background: rgba(0,0,0,0.8);
  z-index: 999;
}

.emoji-picker__search {
  display: flex;
}
.emoji-picker__search > input {
  flex: 1;
  border-radius: 10rem;
  border: 1px solid #ccc;
  padding: 0.5rem 1rem;
  outline: none;
}
.emoji-picker h5 {
  margin-bottom: 0;
  color: #b1b1b1;
  text-transform: uppercase;
  font-size: 0.8rem;
  cursor: default;
}
.emoji-picker .emojis {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.emoji-picker .emojis:after {
  content: "";
  flex: auto;
}
.emoji-picker .emojis span {
  padding: 0.2rem;
  cursor: pointer;
  border-radius: 5px;
  font-size: 32px;
}
.emoji-picker .emojis span:hover {
  background: #ececec;
  cursor: pointer;
}
.emoji-pick-btn{
  border-radius: 0px;
}
</style>