<template>
  <tr class="py-2" style="background: none; border-left: 6px solid transparent;" v-bind:class="{'autonomous-tr' : lessonlevel.is_autonomous}">
    <td  v-if="weekplanday.id != 'autonomous'" style="width: 22px; vertical-align: middle; " class="pl-3 pr-0">
      <i class="fa fa-spinner fa-spin" v-if="loading"></i>
      <p-check v-model="isCompleted" class="p-icon p-round p-tada text-success" color="" v-if="possibleToCheckOff">
        <i slot="extra" class="icon fas fa-check"></i>
      </p-check>
    </td>
    <td style="vertical-align: middle; text-align: center;">
      <i class="fas fa-asterisk" v-if="lessonlevel.is_required" v-tooltip.top-center="`Verplicht`" ></i>
      <i class="fas fa-pencil-alt" v-if="lessonlevel.correct" v-tooltip.top-center="`Zelf nakijken`" ></i>
      <i :class="`${lessonlevel.icon}`" v-tooltip.top-center="lessonlevel.icon_tooltip"></i>
    </td>
    <td v-if="weekplanday.id != 'autonomous'" style="vertical-align: middle; text-align: center;">
      <span v-if="lessonlevel.show_time" style="font-size: 14px">
        <i class="far fa-clock"></i> {{ lessonlevel.time }}
      </span>
        
    </td>
    <td style="vertical-align: middle; text-align: center;">
      <img :src="image" v-if="showImage" class="image rounded shadow-sm" height="40"/>
    </td>
    <td width="25%" style="vertical-align: middle;" class="course" v-bind:class="{'completed strike' : isCompleted}"><p><b v-if="lessonlevel.course">{{ lessonlevel.course.name }}</b></p></td>
    <td v-if="!showReview" style="vertical-align: middle;" class="task" v-bind:class="{'completed strike' : isCompleted}">
      <div v-html="lessonlevel.task" v-linkified></div>
      <div v-if="lessonlevel.student_content">
        <form class="needs-validation" :class="{'was-validated': savedContent }" novalidate>
          <input @change="updateStudentContent" class="form-control" v-model="studentContent" placeholder="Vul hier in wat je gaat doen" />
          <div class="valid-feedback">
            Opgeslagen!
          </div>
        </form>
      </div>
    </td>
    <td colspan="2" v-if="showReview" style="vertical-align: middle; background: none;" class="feedback-btns task">

      <div class="row">
        <div class="col  text-center">
          {{feedbackFeeling}}<br>
          <div class="btn-group" role="group" aria-label="Gevoel">
            <button :disabled="loading" @click="setFeeling(0)" type="button" v-bind:class="{'active' : feeling == 0}" class="py-1 btn btn-outline-danger"><i class="far fa-frown"></i></button>
            <button :disabled="loading" @click="setFeeling(1)" type="button" v-bind:class="{'active' : feeling == 1}" class="py-1 btn btn-outline-warning"><i class="far fa-meh"></i></button>
            <button :disabled="loading" @click="setFeeling(2)" type="button" v-bind:class="{'active' : feeling == 2}" class="py-1 btn btn-outline-success"><i class="far fa-smile"></i></button>
          </div>
        </div>

        <div class='col text-center'>
          {{feedbackResult}}<br>
          <div class="btn-group" role="group" aria-label="Resultaat">
            <button :disabled="loading" @click="setResult(0)" type="button" v-bind:class="{'active' : result == 0}" class="py-1 btn btn-outline-danger"><i class="far fa-thumbs-down"></i></i></button>
            <button :disabled="loading" @click="setResult(1)" type="button" v-bind:class="{'active' : result == 1}" class="py-1 btn btn-outline-success"><i class="far fa-thumbs-up"></i></i></button>
          </div>
        </div>
      </div>
    </td>
    <td v-if="showGoals && !showReview && (weekplanday.id != 'extra')" style="vertical-align: middle;" class="goal" v-html="goalHtml" v-linkified></td>
    <td v-if="weekplanday.id != 'autonomous'" style="vertical-align: middle;" class=" text-right" v-bind:class="{'completed strike' : isCompleted}">
      <span v-if="scope == 'user' && liveLessonLevel.student_lesson && liveLessonLevel.student_lesson.number_of_credits_to_earn > 0 && liveLessonLevel.student_lesson.credits_earned == 0 ">
        <i class="text-muted fas fa-coins"></i>
      </span>
      <span v-if="liveLessonLevel.student_lesson && liveLessonLevel.student_lesson.credits_earned > 0">
        <i class="text-warning fas fa-coins"></i>
      </span>
      <span v-html="feedbackIconFeeling"></span>
      <span v-html="feedbackIconResult"></span>
    </td>

    <td v-if="lessonlevel.is_autonomous && weekplanday.id == 'autonomous'" style="vertical-align: middle;" class=" text-right">
      <studentWeekPlanDayPicker @pickDay="pickDay" :key="`autonomous-day-picker-${lessonlevel.id}`" :lessonlevel="lessonlevel"></studentWeekPlanDayPicker>
    </td>
  </tr>
</template>

<script>

import axios from 'axios'
import studentWeekPlanDayPicker from '../../components/digital/studentWeekPlanDayPicker.vue'

export default {

  props: ["lessonlevel", "hideplanner", "weekplanday", "only_check_today", "today", "scope", "showGoals", "feedbackResult", "feedbackFeeling"],
  components: {
    studentWeekPlanDayPicker
  },
  directives: {
  },
  watch: {

    isCompleted: function (values) {
      // console.log(newLessonModalId)
      // console.log("CHANGED")
      if (this.isWatching){

        if (this.isCompleted) {
          this.showReview = true
        } else {
          this.sendUpdates()
          this.showReview = false
          this.feeling = null
          this.result = null
        }
        
      }
    
    }
  },
  data () {
    return {
      isWatching: false,
      isCompleted: false,
      showCorrect: false,
      showReview: false,
      feeling: undefined,
      loading: false,
      savedContent: false,
      studentContent: '',
      result: undefined,
      week_plan_day_id: null
    }
  },
  computed: {
    image(){
      if (this.lessonlevel.picto) {
        return this.lessonlevel.picto;
      }
      else if (this.lessonlevel.course && this.lessonlevel.course.picto) {
        return this.lessonlevel.course.picto;
      }
      else {
        return null;

      }
    },
    showImage(){
      return this.image != null
    },
    possibleToCheckOff(){
      return this.loading === false
      // if (this.only_check_today && (this.weekplanday.name != this.today) && (this.weekplanday.id != 'extra')){
      //   return false
      // } else {
      //   return true
      // }
    },
    showAutonomous(){
      if (this.hideplanner){
        return false
      } else if (this.lessonlevel.is_autonomous){
        return true
      } else {
        false
      }
      
    },
    goalHtml(){
      if (this.lessonlevel.goal && this.lessonlevel.goal.length > 8 && this.isCompleted) {
        return `<div class="d-flex justify-content-start"><i class="mt-1 mr-1 d-block text-success fas fa-check-double"></i><div class="">${this.lessonlevel.goal}</div>`  
      } else {
        return this.lessonlevel.goal
      }
      
    },
    liveLessonLevel (){
      if (this.lessonlevel.is_extra && !this.lessonlevel.is_autonomous){
        return window.store.getters.digitalWeekPlan.extra_work.lesson_levels.filter(ll => ll.id == this.lessonlevel.id)[0]
      } else if (this.lessonlevel.is_autonomous) {
        return window.store.getters.digitalWeekPlan.autonomous.lesson_levels.filter(ll => ll.id == this.lessonlevel.id)[0]
      } else {
        return window.store.getters.digitalWeekPlan.week_plan_days.map(wpd => wpd.lesson_levels).flat().filter(ll => ll.id == this.lessonlevel.id)[0]  
      }
      
      // let wpds = window.store.getters.digitalWeekPlan.week_plan_days
      // for (var i = wpds.length - 1; i >= 0; i--) {
      //   console.log(wpds[i])

      //   for (var lesson_level in wpds[i].lesson_levels) {
      //     console.log(lesson_level[0])
      //   }
      // }
    },
    weekPlan (){
      return window.store.getters.digitalWeekPlan
    },
    feedbackIconFeeling(){
      switch(this.feeling) {
        case 0:
          return "<i class='text-danger far fa-frown'></i>"
        case 1:
          return "<i class='text-warning far fa-meh'></i>"
        case 2:
          return "<i class='text-success far fa-smile'></i>"
        default:
          return ""
      }
      
    },
    feedbackIconResult(){
      switch(this.result) {
        case 0:
          return "<i class='text-danger far fa-thumbs-down'></i>"
        case 1:
          return "<i class='text-success far fa-thumbs-up'></i>"
        default:
          return ""
      }
      
    }
  },
  methods: {
    showCorrectHelp() {
      this.showCorrect = true
      setTimeout( function()
        { 
          this.showCorrect = false
        }.bind(this), 3000
      )
    },
    setFeeling(num) {
      this.feeling = num
      this.submitFeedback()
    },
    setResult(num) {
      this.result = num
      this.submitFeedback()
    },
    submitFeedback(){
      if (this.result != undefined && this.feeling != undefined){
        // console.log("Sending feedback")
        // console.log(this)
        this.sendUpdates()
        // setTimeout(function () {
        //   this.showReview = false
        // }.bind(this), 300)
      }
    },
    pickDay(day){
      console.log("Picked a date!")
      console.log(day)
      this.week_plan_day_id = day ? day.id : null
      this.sendUpdates()
      return
    },
    updateStudentContent() {
      this.sendUpdates()
      this.savedContent = true
      setTimeout(function () {
        this.savedContent = false
      }.bind(this), 2500)
    },
    sendUpdates() {
      this.loading = true
      return axios.put(`/student_week_plans/${this.weekPlan.id}/student_lessons/${this.lessonlevel.student_lesson.id}.json`, {
          key: this.weekPlan.key, 
          student_lesson: {
            completed: this.isCompleted,
            feedback_feeling: this.feeling,
            feedback_result: this.result,
            week_plan_day_id: this.week_plan_day_id,
            student_content: this.studentContent
          }
        })
        .catch(error => {
          console.log(error);
          alert("Er ging iets mis bij het opslaan. Ververs de pagina en probeer het opnieuw.");
        })
        .then(response => {
          this.loading = false
          this.showReview = false
          window.store.dispatch('setDigitalWeekPlan', response.data)
          return response.data

        })
      return true  
      
    },
    setWatching(){
      // this.isWatching = true
      setTimeout( function()
      { 
        this.isWatching = true
      }.bind(this), 50
      );
      
    }
  },
  beforeMount() {
    
    
    // this.showReview  = false
  },
  mounted() {
    // this.showReview  = false
    this.isCompleted = this.lessonlevel.student_lesson.completed
    this.feeling = this.lessonlevel.student_lesson.feedback_feeling
    this.result = this.lessonlevel.student_lesson.feedback_result
    this.week_plan_day_id = this.lessonlevel.student_lesson.week_plan_day_id
    this.studentContent = this.lessonlevel.student_lesson.student_content
    this.setWatching()

    // setTimeout(() => this.watchting = true, 500);
    // this.watching = true
  }
}
</script>

<style lang="css" scoped>
.feedback-btns i{
  align-self: center;
  font-size: 26px;
}
.autonomous{
  background-color: #efefef !important;
}
.autonomous-tr{
  border-left: 6px solid #6CC3D5 !important;
}

.completed .course >>> p, .completed .task >>> p {
  text-decoration: line-through;
}

.p-icon .icon{

}

.task >>> p, .goal >>> p{
  margin-bottom: 0px !important;
}
.completed .goal >>> p{
  color: green;
}

table, body{
  color: #000;
}
table td{
  padding-top: 10px;
  padding-bottom: 10px !important;
  margin-bottom: -1rem;
  font-size: 18px;
  border-top: 1px solid rgba(0,0,0,0.1);
}
p {
  margin-bottom: 0 !important;
}
.pretty{
  margin-right: 0px;
}

@keyframes strike{
  0%   { width : 0; }
  100% { width: 100%; }
}


</style>