<template>
  <div>
    <div class="container-fluid p-0 bg-white" id="week-plan" :data-id="id">

      <div id="planner">
        <div v-if="hasSelectedGroups()" class="row row-flex flex-nowrap">
          <div class="col-sm planner-day p-0" v-bind:class="{'clockless' : showClockless}" :key="`day-${day.id}`" v-if="day.id && showDay(day)" v-for="day in weekPlan.weekPlanDays">
            <div class="day-header bg-light sticky-top shadow-sm" v-if="day.lessons">
              <h6 v-if="!day.lessons.length" data-dragscroll class="m-0 pt-1 mb-0 ">{{ day.day }} {{day.short_show_date}}</h6>
              <div class="dropdown" v-if="day.lessons.length">
                <span class="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <h6 class="m-0 pt-1 mb-0 d-inline">{{ day.day }} {{day.short_show_date}}</h6>
                </span>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">

                  <button @click="copyDay(day, wday)" class="dropdown-item py-2" :disabled="day == wday" v-for="wday in weekPlan.weekPlanDays">
                    Rooster kopiëren naar {{ wday.day.toLowerCase().trim() }} <span v-if="showGroupName">{{ wday.group_name }}</span>
                  </button>
                  <div class="dropdown-divider"></div>
                  <button @click="removeLessons(day)" class="dropdown-item py-2">Alle lessen van {{ day.day.toLowerCase().trim() }} verwijderen</button>
                </div>
              </div>
              <span data-dragscroll v-if="showGroupName" class="m-0 group-name small muted">{{ day.group_name }}</span>
            </div>

            <weekPlanDay style="" :showClockless="showClockless" :is-loading="false" :usecolor="usecolor" class="" v-if="showDay(day)" :key="`wpd-day-${day.id}`" :day="day" :weekPlanId="id"></weekPlanDay>
          </div>
        </div>
      </div>
      <div id="courses">
        <CourseList :courses="courses" :is_admin="is_admin" :use-clockless="useClockless" :canaddcourse="canaddcourse" :showGroupName="showGroupName" :groups="this.groups" :weekPlanId="this.id" :show-clockless="showClockless" @toggleClockless="showClockless = !showClockless" v-if="courses.length"></CourseList>
      </div>

    </div>

    <div class="modal fade" id="clocklessHelp" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-md modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <h4>Lessen zonder rooster</h4>
            <p v-if="true">Op het normale lesrooster hebben alle lessen een begin- en eindtijd. Wil je daarnaast ook lessen inplannen die geen onderdeel zijn van je lesrooster? Dan kun je die lessen hier inslepen. Ze verschijnen dan wel op de weektaak en op het weekplan, maar staan los van de tijd.</p>
            <p v-if="true">Enkele voorbeelden hiervan:
              <ul>
                <li>'Technisch lezen' bestaat eigenlijk uit vier onderdelen voor de leerling, maar moeten qua tijd als één vak ingepland worden voor de leerkracht.</li>
                <li>Een aantal leerlingen gaat tijdens de rekenles naar het RT-lokaal.</li>
              </ul>
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
          <lessonModal :show_comment_field="show_comment_field" :lessonMethods="lesson_methods"></lessonModal>  
        </div>
      </div>
    </div>
    
  </div>



</template>

<script>
import WeekPlanDay from '../../components/week_plans/weekPlanDay.vue'
import CourseList from '../../components/week_plans/courseList.vue'
import lessonModal from '../../components/week_plans/lessonModal.vue'


import { dragscroll } from 'vue-dragscroll'
import ScrollSync from 'vue-scroll-sync'
export default {
  directives: {
    dragscroll
  },
  components: { WeekPlanDay, CourseList, ScrollSync, lessonModal},
  props: ["id", "lesson_methods", "groups_to_show_ids", "groups", "usecolor", "canaddcourse", "arrow", "is_admin", "show_comment_field", "useClockless"],

  data () {
    return {
      lessonForModal: false,
      showClockless: false,
      copyingDay: []
    }
  },
  methods: {
    showClocklessHelp() {
      console.log('ok')
      $("#clocklessHelp").modal('show')
      
    },
    removeLessons(day) {
      const answer = confirm("Weet je het zeker? Alle lessen van deze dag worden verwijderd, inclusief lesstof.")
      if (answer) {
        window.store.dispatch('deleteLessonsFromWeekPlanDay', day.id)  
      } else {
        // do nothing
      }
    },  
    copyDay(day, dayToCopyTo) {

      // console.log(day)
      // console.log(dayToCopyTo)
      window.store.dispatch('copyWeekPlanDay', { from_id: day.id, to_id: dayToCopyTo.id })
      .then((result) => {
        console.log('ready!')
        // this.copyingDay = []
      }).catch(error => {
        console.log("foutje")
        console.log(error)
      })
      .finally((result) => {
        console.log('really ready!')
      })
    },
    showDay (day) {
      if (window.store.getters.weekPlan.days && window.store.getters.weekPlan.days.length == 5){
        return true
      }  else if (this.groupsToShow != undefined) {
        return this.groupsToShow.includes(day.group_id)
      }        
    },
    doSomething () {
      // console.log('ok')
    },
    hasSelectedGroups (){
      return this.groupsToShow.length > 0
    }
  },
  computed: {  
    isChrome() {
      return this.browserDetect.isChrome
    },
    hasMultipleGroups() {
      return this.groupsToShow.length > 1
    },
    hasOneOfMultiple() {
      return this.groupsToShow.length == 1 && this.groups.length > 1
    },
    groupsToShow() {
      return store.getters.groupsToShow
    },
    courses(){
      return window.store.getters.courses
    },
    days (){
      return window.store.getters.weekPlan.days
    },
    weekPlan () {
      return window.store.getters.weekPlan
    },
    isEmpty(){
      return this.weekPlan.weekPlanDays.map(wpd => wpd.lessons).flat().length == 0
    },
    loading (){
      return false // window.store.getters.isLoading
    },
    showGroupName (){
      return true // window.store.getters.weekPlan.weekPlanDays.length > 5
    },
    handleScroll() {
      let scroll = window.scrollX
      console.log(scroll)
      // console.log()
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
  },
  created () {
    window.store.dispatch('setGroupsToShow', this.groups_to_show_ids)
    window.store.dispatch('fetchWeekPlan', { id: this.id })
      .then((result) => {
        // 
      }).catch(error => {
        // console.log("foutje")
        console.log(error)
      })
    window.store.dispatch('fetchCourses', { id: this.id })  
  },


}
</script>



<style scoped>
  .full-height{
    height: calc(100vh - 109px);
    overflow: auto;
    /*padding-right: 10px;*/
  }

 body.modal-open .full-height{
  overflow: hidden;
 }
.fade-enter-active {
  transition: all .3s ease;
}
.fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.fade-enter, .fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.week-plan-day{
  border-right: 1px solid #dedede;
}

.grab-bing {
  cursor : -webkit-grab;
  cursor : -moz-grab;
  cursor : -o-grab;
  cursor : grab;
}


.grab-bing:active {
  cursor : -webkit-grabbing;
  cursor : -moz-grabbing;
  cursor : -o-grabbing;
  cursor : grabbing;
}
.day-header{
  text-align: center;
  padding-top: 4px;
  min-width: 150px;  
  background-color: red;
  border-bottom: 1px solid #dedede;
  line-height: 6pt;
  padding-bottom: 4px;
  border-left: 1px solid #dedede !important;
  border-right: 1px solid #dedede !important;
}
.day-header .dropdown{
  cursor: pointer;
}
.day-header:active{
  cursor: grabbing;
}
.group-name{
  font-size: 7pt;
  line-height: 7pt;
}
#arrow-right{
  position: absolute;
  top: 10px;
  opacity: 0.2;
  right: 20px;
}

.wpd-container.multi-group, .wpd-container.multi-group.NotShowClockless, .wpd-container.multi-group.ShowClockless{
  min-width: 680px; 
  height: calc(100% - 43px) !important;
  min-height: calc(100% - 43px) !important;
}

.wpd-container.one-of-multi-group{
  min-width: 680px; 
  height: calc(100% - 36px);
}
.wpd-container.NotShowClockless{
  min-width: 680px; 
  min-height: calc(100% - 34px);
  overflow: auto;
}
.wpd-container.ShowClockless{
  min-width: 680px; 
  height: calc(100% - 34px);
}

h6{
  font-size: 10pt;
}

#empty{
  position: fixed;
  top: 275px;
  right: 250px;
  z-index: 9999;

}
#empty p{
  transform: rotate(20deg);
}

#empty_arrow{
  position: absolute;
  top: -120px;
  right: 10px;
  width: 200px;
  transform: rotate(210deg);
}
.halfway {
  bottom:  calc(50% - 17px) !important;
}

.halfway.multi-group {
 bottom:  calc(50% - 22px) !important; 
}
.no-focus {
  border:  1px solid #dedede !important;
  border-bottom-width: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
.no-focus:focus {

  outline:  0;
  box-shadow: none;
}

.no-focus {
  transition: background-color 0.2s ease-out;
}

.halfway .no-focus {
  background-color: #b7c5ce !important;  
}

#week-plan {
  display: grid;
  grid-template-columns: 1fr 300px; 
  grid-template-rows: 1fr; 
  gap: 0px 0px; 
  height:  calc(100vh - 109px);
  width:  100vw;
}
#days {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 2;
  background-color: purple;
  overflow:  auto;
}
#planner {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 2;
  overflow:  auto;
  display: flex;
  
}

#planner .row  {
  height:  100%;
  min-width:  100%;
  padding:  0px !important;
}

#courses {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 2;
}

.planner-day:nth-child(odd) {
  background-color:  #f7fafc;
}

.planner-day {
  display: grid;
  grid-template-columns: 1fr; 
  grid-template-rows: 40px 1fr;
  gap: 0px 0px; 
  min-height: 100%;
  height:  fit-content;
  background-color: transparent;
  padding-bottom:  20px !important;
  background-color: #fff;
  border-right:  1px solid #dedede;
  
}

.planner-day.clockless {
  background-color: #7b8992c2;
}

.planner-day.clockless:nth-child(odd) {
  background-color: #7b8992;
}
.col-sm.planner-day:first-child {
  padding-left:  15px !important;
}
</style>
