<template>
  <div>
    <div v-if="loading" class="text-center">
      <i class="fa fa-spinner fa-spin"></i>
    </div>
    <vue-clip :options="options" :on-complete="complete" :on-sending="setLoading" v-if="!loading">
      <template slot="clip-uploader-action">
        <div class="row ">
          <div class="dz-message col-8 mx-auto bg-light rounded p-4 text-center my-2">
            Klik hier, of sleep hier bestanden heen. Kinderen krijgen deze dan op de digitale weektaak te zien onder de knop <kbd>bestanden</kbd>.
          </div>
        </div>
      </template>

      <template slot="clip-uploader-body" slot-scope="props">
      </template>

    </vue-clip>

    <div class="px-3" v-if="errorMessage.length">
      <div class="alert alert-danger">
        {{ errorMessage }}
      </div>
    </div>

    <div class="list-group-flush mt-2" v-if="fileList.length">
      <digitalWeekPlanFile @convert="convertFile" @delete="deleteFile" :file="file" :key="file.id" v-for="file in fileList" />
    </div>

  </div>
</template>

<script>
import axios from 'axios'
import digitalWeekPlanFile from './digitalWeekPlanFile.vue'
export default {

  name: 'digitalWeekPlanFiles',
  props: ['files', 'week_plan_id'],
  components: { digitalWeekPlanFile },
  data () {
    return {
      fileList: [],
      fileId: null,
      loading: false,
      btnDisabled: false,
      errorMessage: '',
      options: {
        url: `/week_plans/${this.week_plan_id}/files`,
        paramName: 'file',
        uploadMultiple: true,
        maxFilesize: {
          limit: 25,
          message: 'Dit bestand is te groot. Je kunt bestanden van maximaal 25MB uploaden.'
        },
      }
    }
  },
  created() {
    this.fileList = this.files
  },
  methods: {
    deleteFile(id) {
      this.setLoading()
      return axios.delete(`/week_plans/${this.week_plan_id}/files/${id}.json`)
        .then(response => {
          this.loading = false
          this.errorMessage = ""
          this.fileList = response.data  
        })
    },
    convertFile(id) {
      this.setLoading()
      return axios.get(`/week_plans/${this.week_plan_id}/files/${id}/convert.json`)
        .then(response => {
          this.loading = false
          this.errorMessage = ""
          this.fileList = response.data  
        })
    },
    setLoading() {
      this.loading = true
    },
    complete (file, status, xhr) {
      this.loading = false
      this.errorMessage = ""
      // console.log(xhr.response)
      
      if (status == 'success') {
        // if xhr.response
        // console.log(xhr.response)
        if (xhr.response && xhr.response.length) {
          var response = JSON.parse(xhr.response)
          // this.$emit('upload', JSON.parse(xhr.response) )
          this.fileList = response
        }
      } else {
        console.log(file)
        console.log(status)
        if (file.errorMessage.length > 200){
          this.errorMessage = "Er ging iets fout bij het uploaden van dit bestand. Probeer het later nog een keer, of probeer een ander bestand te uploaden."
        } else {
          this.errorMessage = file.errorMessage
        }
        // alert(this)
      }
    }
  }
}
</script>

<style lang="css" scoped>
.dz-message{
  border: 2px dashed #dedede;
  cursor: pointer;
}
</style>