<template>
  <div>
    <button v-if="!showDatePicker" @click="showDatePicker = true" class="btn small btn-sm btn-outline-success"><i class="fas fa-calendar-day"></i> actief op {{ theDateString }}</span></button>
    <div v-if="showDatePicker">
      <div class="form-row text-left" v-if="!loading">
        <div class="form-group col-auto">
          <label for="date">Datum</label>
          <input type="date" class="form-control" id="date" v-model="theDate">
        </div>
        <div class="form-group col-auto">
          <label for="time">Tijd</label>
          <input type="time" class="form-control" id="time" v-model="theTime">
        </div>
      </div>
      <div class="row" v-if="loading">
        <div class="col text-center">
          <i class="fa fa-spinner fa-spin"></i>
        </div>
      </div>
      <div class="row" v-if="!loading">
        <div class="col">
          <button @click="save" class="btn small btn-sm btn-outline-success" :disabled="!changed">opslaan</button>
          <button @click="showDatePicker = false" class="btn small btn-sm btn-outline-secondary">annuleren</button>
        </div>
      </div>
      
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {

  name: 'weekPlanActiveDate.vue',
  props: ['weekPlanId', 'dateTime', 'date', 'time', 'dateString'],
  data () {
    return {
      theDateString: '',
      showDatePicker: false,
      theDate: null,
      theTime: null,
      loading: false
    }
  },
  computed: {
    changed() {
      return (this.theDate != this.date) || (this.theTime != this.time)
    }
  },
  methods: {
    save() {
      this.loading = true
      const dateString = `${this.theDate} ${this.theTime}`
      return axios.put("/week_plans/"+ this.weekPlanId +"/update-activate-at.json", {week_plan: {auto_active_at: dateString}})
        .then(response => {
          this.loading = false
          this.showDatePicker = false
          this.theDateString = response.data
          // return t
        })
    }
  },
  created() {
    this.theDateString = this.dateString
    this.theTime = this.time
    this.theDate = this.date
  }
}
</script>

<style lang="css" scoped>
</style>