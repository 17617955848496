<template>
  <div class="pl-5">
    <div v-if="combinations.length && isCombi">
      <div class="btn-group w-100 mb-2 ">
        <button class="btn btn-outline-primary btn-sm" :class="{active: groupMode == 'single'}" @click="setGroupMode($event, 'single')">Enkele groep</button>
        <button class="btn btn-outline-primary btn-sm" :class="{active: groupMode == 'combi'}" @click="setGroupMode($event, 'combi')">Combinatiegroep</button>
      </div>
    </div>
    <input name="group_mode" type="hidden" :value="groupMode"/>
    <div v-if="groupMode == 'single'">
      <select v-model="selectedGroup" name="copy_group_id" id="copy_group_id" class="form-control">
        <option v-for="group in groups" :value="group.id">{{ group.name_with_group }}</option> 
      </select>
    </div>
    <div v-if="groupMode == 'combi'">
      <select v-model="selectedCombi" name="combination_id" id="combination_id" class="form-control">
        <option v-for="combination in combinations" :value="combination.id">{{ combination.name }}</option> 
      </select>
      <div v-if="selectedCombi == currentCombination.id">
        <input :name="`combination_targets[group_${group.id}]`" type="hidden" v-for="(group, gi) in selectedCombination.groups" :value="group.id" />
      </div>
      <table class="table table-sm mt-1" v-if="selectedCombi != currentCombination.id">
        <thead><tr> 
          <td>Van</td>
          <td></td>
          <td>Naar</td>
        </tr></thead>
        <tbody>
          <tr v-for="(group, i) in currentCombination.groups">
            <td>{{ group.name }}</td>
            <td><i class="fas fa-arrow-right"></i>
            <td>
              <select :name="`combination_targets[group_${group.id}]`" class="form-control">
                <option v-for="(group, gi) in selectedCombination.groups" :value="group.id" :selected="gi == i">{{ group.name }}</option> 
              </select>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

  </div>
</template>

<script>

  export default {
    props: ['groups', 'combinations', 'isCombi', 'currentGroupId', 'currentCombination'],
    data() {
      return {
      groupMode: 'single',
      selectedGroup: null,
      selectedCombi: null
      }
    },
    mounted() {
      this.selectedGroup = this.currentGroupId
      if (this.currentCombination) {
          this.selectedCombi = this.currentCombination.id
      }
      if (this.isCombi) {
        this.groupMode = 'combi'
      }
    },
    computed: {
      selectedCombination() {
        if (this.selectedCombi) {
          for (let index = 0; index < this.combinations.length; index++) {
            const comb = this.combinations[index]
            if (comb.id == this.selectedCombi) {
              return comb
            }
          }
        } else {
          return []
        }
      }
    },
    methods: {
      setGroupMode (event, mode) {
        event.preventDefault();
        this.groupMode = mode
      }
    }
  }
</script>

<style scoped>

</style>