<template>
  <div class="col">
    {{courseId}}
  </div>
</template>

<script>


import Weektaak from '../../packs/weektaak'

export default {
  data () {
    return {
      //
    }
    
  },
  props: ["courseId", "courseId"],
  methods: {
  },

  computed: {
    groupCourses (){
      return this.weekPlan.group_courses
    },
    group () {
      return window.store.getters.groups
    },
    weekPlanCourses () {
      return window.store.getters.weekPlanCourses
    }
  }
  
}
</script>

<style scoped>

</style>
