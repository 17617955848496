<template>
  <div>  
    <div class="container pt-5">
      <div class="row">
        <div class="col text-center">
          <h2>Vakken en niveaus {{groupname}}</h2>
          <h5 class="text-muted">Welke vakken wil je op verschillende niveaus invullen?</h5>

        </div>
      </div>
    </div>

    <StudentLevels 
      @showHelp="showHelp"
      v-if="weekPlan.group_courses != []" 
      :weekplanid="id" 
      :group="group"
      :groups="groups"
      :courses="courses"
      :show-group-select="showGroupSelect"
      :groupid="groupid" />

    <div class="container" v-if="!showGroupSelect">
      <div class="row">
        <div class="col">
          <div class="alert alert-info">Wil je sommige leerlingen van {{group.name_with_group }} bij sommige vakken laten werken in een andere groep? Schakel dan de functie 'groepsoverstijgend werken' in. Dat kan bij <a href="/settings/levels">Instellingen <i class="fas fa-arrow-right"></i> Niveaus</a>.</div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

import Vue from 'vue/dist/vue.esm'
import Weektaak from '../../packs/weektaak'
import StudentLevels from '../../components/settings/studentLevels.vue'

export default {
  components: {
    StudentLevels
  },
  props: ["id", "groupname", "groupid", "group", "groups", "showGroupSelect", "courses"],

  data () {
    return {
      // courses: [],
      loading: true

    }
  },
  computed: {
    weekPlan () {
      return window.store.getters.weekPlan
    },
  },
  methods: {
    showHelp() {
      $("#helpModal").modal('show')
    }
  },
  created () {
    window.store.dispatch('fetchGroupsForWeekPlan', this.id)
      .then(() => {
        // 
      })
    window.store.dispatch('fetchWeekPlanCourses', { id: this.id })
      .then((result) => {
        // this.loading = false
      })


    window.store.dispatch('fetchWeekPlan', { id: this.id })
      .then(() => {
        this.loading = false
      })
    window.store.dispatch('fetchCourses', { id: this.id })  
    window.store.dispatch('fetchStudents', { id: this.id })  
    window.store.dispatch('fetchLevels', { id: this.id })  
  },
  
}
</script>

<style scoped>
  .custom-select{
    width: auto;
    padding-top: 0px;
    padding-bottom: 0px;
    font-size: 1rem;
  }  
</style>
