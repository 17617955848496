<template>
  <draggable :data-course-id="course.id" emptyInsertThreshold="120"  :group="{'name': 'week_plans', 'put': false, 'pull': 'clone'}" @end="addedCourse" @start="draggingCourse">
    <div v-if="weekPlanDayId == null" class="card lesson new-lesson">
      <div class="card-body d-flex justify-content-between">
        <div class="lesson-name text-left">
          {{ course.name }}
        </div>
        <span v-if="totalCourseDuration > 0" class="duration">{{totalCourseDurationString}}</span>
      </div>
    </div>


          
    
  </draggable>
</template>

<script>
import Weektaak from '../../packs/weektaak'
import draggable from 'vuedraggable'
export default {
  props: ["course", "weekPlanIdParam"],
  components: {
    draggable
  },

  data (){
    return {
      weekPlanDayId: null,
      isClockless: null
    }
    
  },
  methods: {
    addedCourse (event){
      store.dispatch('hideTrash')
      store.dispatch('stopDraggingNewLesson')
      var position       = event.newIndex
      console.log("Dataset:")
      console.log(event.to.dataset)
      this.weekPlanDayId = event.to.dataset.weekPlanDayId
      this.isClockless = event.to.dataset.isClockless

      if (this.weekPlanDayId != null){
        // console.log("adding to week_plan " + this.weekPlanIdParam)
        Weektaak.addLessonToWeekPlan(this.weekPlanIdParam, this.weekPlanDayId, this.course.id, position, this.isClockless) 
      }
      jQuery(".day-column .new-lesson").remove()
      // console.log(this.totalCourseDuration.map(day => day.lessons).flat().filter(lesson => lesson.course_id === 463).map(lesson => lesson.duration).reduce((a, b) => a + b, 0))
    },
    draggingCourse (event) {
      store.dispatch('startDraggingNewLesson')
      store.dispatch('showTrash')
    }
  },
  computed: {
    totalCourseDuration (){
      return 0
      // if (this.course){
      //   return window.store.getters.totalCourseDurationFor(this.course)  
      // } else {
      //   return 0
      // }
      
    },
    totalCourseDurationString (){
      return ''
      // return this.totalCourseDuration + " min."
    }
  },
  create () {

  },


}
</script>

<style scoped>
  .card.lesson{
    height: 34px;
    padding: 5px;
    margin-bottom: 5px;
    cursor: grab;
  }
  .card.lesson.sortable-drag{
    box-shadow: 0px 20px #888888;
  }
  .card.lesson:active, .card.lesson:focus{
    cursor: grabbing;
  }

  .duration{
    line-height: 11px;
    font-size: 10px;
    color: #666;
  }

</style>
