<template>
  <div class="col-12 col-lg-6">
      <div class="card mb-3">
        <div class="card-header text-center" v-show="deleteMode">
          <small>Weet je het zeker? Wil je de zorgacategorie '{{ thePlannedNoteCategory.name }}' voor de hele school verwijderen?</small>
          <div>
            <button @click="deleteNow" class="btn btn-sm btn-danger">Ja, verwijder</button>
            <button @click="deleteMode = false" class="btn btn-sm btn-secondary">Annuleren</button>
          </div>
        </div>
        <div class="card-header d-flex justify-content-between align-items-center" v-if="!deleteMode">
          <input @change="updatePlannedNoteCategory" class="form-control form-control-sm" style="font-weight: 700;" v-model="thePlannedNoteCategory.name" :disabled="deleting" />
          <div class="btn-group mx-2">
            <button type="button" class="btn btn-outline-primary btn-sm">
              <i :class="thePlannedNoteCategory.icon"></i>
            </button>
            
            <button type="button" class="btn btn-outline-primary dropdown-toggle dropdown-toggle-split btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <span class="sr-only">Kies icoon</span>
            </button>

            <div class="dropdown-menu text-center">
              <div :key="`group-${i}`" class="dropdown-item d-flex justify-content-between" v-for="(group,i) in groupArr(icons, 4)">
                <a href="javascript:void(0)" @click="setIcon(icon)" v-for="icon in group" class="icon-picker"><i :class="icon"></i></a>
              </div>
              <div class="dropdown-item">
                <a href="javascript:void(0)" @click="setIcon('no-icon')" data-icon="no-icon" class="btn btn-outline-primary btn-sm btn-block icon-picker" >geen icoon</a>
              </div>
            </div>
          </div>
          <button @click="deleteMode = true" class="btn btn-sm btn-outline-danger"><i class="fa fa-trash"></i></button>
        </div>
        <div v-if="deleting" class="card-body p-4 text-center">
          <i class="fa fa-spinner fa-spin"></i>
        </div>
        <div class="card-body" v-if="false && !deleting">
          <div class="form-group">
            <label>Afgekorte naam</label>
            <input @change="updatePlannedNoteCategory" class="form-control" maxlength="8" v-model="thePlannedNoteCategory.short_name" :disabled="deleting" />
            <small class="form-text text-muted">Niet verplicht. Wordt gebruikt in de geprintte weekplanning om ruimte te besparen.</small>
          </div>
        </div>
        <div class="list-group list-group-flush" v-if="false">
          
<!--           <a :href="printUrl" class="list-group-item list-group-item-action"><i class="fa fa-print" /> Planning afdrukken</a> 
          <a :href="calendarUrl" class="list-group-item list-group-item-action"><i class="far fa-calendar" /> Digitale agenda</a> --> 
        </div>   
        
        <!-- <div class="card-footer text-muted">
          
        </div> -->
      </div>

  </div>
</template>

<script>
import axios from 'axios'

export default {

  name: 'PlannedNoteCategory',
  props: ['plannedNoteCategory', 'icons'],
  data () {
    return {
      deleteMode: false,
      is_autonomous: false,
      thePlannedNoteCategory: {},
      deleting: false
    }
  },
  mounted() {
    // this.is_autonomous = this.plannedNoteCategory.is_autonomous
    this.thePlannedNoteCategory = JSON.parse(JSON.stringify(this.plannedNoteCategory))
  },
  computed: {
    printUrl(){
      return `/settings/planned_note_categories/${this.thePlannedNoteCategory.id}/print`
    },
    calendarUrl(){
      return `/settings/planned_note_categories/${this.thePlannedNoteCategory.id}/agenda`
    },
    hasChanged () {
      return JSON.stringify(this.plannedNoteCategory) != JSON.stringify(this.thePlannedNoteCategory)
    }
  },
  methods: {
    deleteNow() {
      this.deleteMode = false
      this.deleting = true
      this.$emit('delete', this.plannedNoteCategory.id)
    },
    groupArr(data, n) {
      var group = [];
      for (var i = 0, j = 0; i < data.length; i++) {
          if (i >= n && i % n === 0)
              j++;
          group[j] = group[j] || [];
          group[j].push(data[i])
      }
      return group;
    },
    setIcon(icon) {
      // ...
      this.thePlannedNoteCategory.icon = icon
      console.log(icon)
      this.updatePlannedNoteCategory()
    },
    updatePlannedNoteCategory() {
      axios.put(`/planned_note_categories/${this.plannedNoteCategory.id}.json`, {
        planned_note_category: this.thePlannedNoteCategory
      })
      .then(response => {
        // this.name = ''
        // this.thePlannedNoteCategorys = response.data
      })  
      // return true
    }
  }
}
</script>

<style lang="css" scoped>
</style>