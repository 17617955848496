import { render, staticRenderFns } from "./courseShow.vue?vue&type=template&id=773e5412&scoped=true&"
import script from "./courseShow.vue?vue&type=script&lang=js&"
export * from "./courseShow.vue?vue&type=script&lang=js&"
import style0 from "./courseShow.vue?vue&type=style&index=0&id=773e5412&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "773e5412",
  null
  
)

export default component.exports