  <template>
  <div>
    <div v-if="viewMode">
      <div class="card mb-3">

        <div class="card-body py-2">
          <div @click="toggleSticky" class="thumbtack" :class="{sticky: isSticky}" v-tooltip="{content: 'Kopieer deze notitie ook naar volgende weektaak', placement: 'top-center', delay: 500}"><i class="fa fa-thumbtack" /></div>
          
          <div v-if="note.show_task || note.show_goal">
            <table class="table table-sm small-table">
              <thead>
                <tr>
                  <th></th>
                  <th></th>
                  <th v-if="note.show_task">Taak</th>
                  <th v-if="note.show_goal">Doel</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="ll in note.lesson.levels">
                  <th>{{ll.label}}</th>
                  <td><i :class="ll.icon"/></td>
                  <td v-if="note.show_task" v-html="ll.task"></td>
                  <td v-if="note.show_goal" v-html="ll.goal"></td>
                </tr>
              </tbody>
            </table>
            
          </div>
          <p class="mb-0" style="white-space-collapse: preserve;">{{ note.body }}</p>
          <span class="badge badge-primary mr-1" v-if="!hideOtherStudents" v-for="student in note.students">{{ student }}</span>
        </div>
        <div v-if="showDetails" class="card-footer text-muted text-center small py-1 d-flex justify-content-between">
          <span v-if="note.course_name">
            <strong>{{ note.course_name }}</strong> 
            <span class="ml-2 text-muted">{{ note.lesson.day }} <span v-if="!note.lesson.is_clockless">{{ note.lesson.time }}</span></span>
          </span>
          <span v-if="!note.course_name" class="text-muted">Algemene notitie</span>

          <span>
            {{ note.created_at }} door {{ note.user_name }}
            <span class="link" @click="toggleEdit">bewerk</span>
          </span>
        </div>
        
      </div>
    </div>
    <div v-if="!viewMode" class="mb-3">
      <div class="form-group">
        <label>Waar gaat deze notitie over?</label>
        <select @change="setLessonOrWeekPlanDayId" v-model="day" class="form-control">
          <option :value="null">Algemene notitie</option>
          <option :value="day" :day="day" v-for="day in lessons">{{ day.name }}</option>
        </select>
      </div>

      <div class="form-group" v-if="day !== null">
      <label>{{ day.name }}</label>
        <select v-model="note.lesson_or_week_plan_day_id" class="form-control">
          <option :value="`day_${day.id}`">Algemene notitie {{day.name}}</option>
          <option v-for="lesson in day.lessons" :value="lesson.id"><span v-if="!lesson.is_clockless">{{lesson.time}} </span>{{lesson.course}}</option>
        </select>
      </div>
      
      <div class="bg-light p-2 rounded mb-2 shadow-sm" style="max-height: 200px; overflow: auto;" v-if="selectedLesson">
        <div v-for="lesson_level in selectedLesson.lesson_levels">
          <b>{{lesson_level.name}}:</b><br>
          <div> 
            <div class="lesson-content" v-html="lesson_level.task"></div>
            <div class="lesson-content font-italic" v-html="lesson_level.goal"></div>
          </div>
        </div>

      </div>


      <div class="form-group" v-if="selectedLesson">
        <div class="row">
          <div class="col-6">
        <toggle-button class="mx-auto " :width=110 v-model="note.show_task" :sync="true" :labels="{checked: 'Toon taak', unchecked: 'Verberg taak'}"  />         
          </div>
          <div class="col-6">
        <toggle-button class="mx-auto" :width=110 v-model="note.show_goal" :sync="true" :labels="{checked: 'Toon doel', unchecked: 'Verberg doel'}"  />    
          </div>
        </div>
      </div>


      <div class="form-group">
        <label>Notitie</label>
        <textarea v-model="note.body" class="form-control" placeholder="Schrijf hier een nieuwe notitie" rows="5"></textarea>
      </div>

      <div class="row mb-4">
        <div class="col">
          <label class="form-check-label">
            Deze notitie gaat over
          </label>
          <div class="btn-group-vertical w-100">
            <button class="btn btn-outline-primary btn-sm" @click="note.for_all_students = !note.for_all_students" :class="{active: note.for_all_students}">Alle leerlingen</button>
            <button class="btn btn-outline-primary btn-sm" @click="note.for_all_students = !note.for_all_students" :class="{active: !note.for_all_students}">Specifieke leerlingen</button>
            <button v-if="!note.for_all_students" :disabled="!note.student_ids.length" class="btn btn-outline-primary btn-sm" @click="clearStudentIds">Selectie wissen</button>
          </div>

        </div>
      </div>
      <div class="row mb-2" v-if="!note.for_all_students">
        <div class="col-12 mb-2" v-if="currentLevels">
          <div class="btn-group-vertical w-100">
            <button class="btn btn-outline-primary btn-sm" @click="selectStudentIds(level.student_ids)" v-for="level in currentLevels" v-if="level.student_ids.length">{{ level.level }}</button>
            
          </div>
        </div>
        <div class="col">
          <div v-for="student in students">
            <div class="form-check">
              <input class="form-check-input" v-model="note.student_ids" type="checkbox" :value="student.id" :id="`checkBox${student.id}`">
              <label class="form-check-label" :for="`checkBox${student.id}`">
                {{ student.name }}
              </label>
            </div>
          </div>

        </div>
      </div>

      <div class="row">
        <div class="col">
          <button @click="submit" class="btn btn-success" v-bind:disabled="isDisabled">Notitie opslaan</button>
          <button @click="cancel" v-if="note.id" class="btn btn-outline-secondary">Annuleer</button>
          <hr v-if="note.id">
          <button @click="deleteNote" v-if="note.id" class="btn btn-sm btn-outline-danger btn-block">Deze notitie verwijderen</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["note", "lessons", "students", "hideOtherStudents", "showDetails", "levels"],
  data () {
    return {
      for_all_students: true,
      // studentIds: [],
      isSticky: false,
      day: null,
      viewMode: true,
      // showDetails: false
    }
  },
  mounted(){
    
    // console.log(`lesson/weekplan id: ${this.note.lesson_or_week_plan_day_id}`)
    if (this.note) {
      this.isSticky = this.note.is_sticky
      
    }
    if (this.note.created_at != undefined){
      this.viewMode = true
    } else {
      this.viewMode = false
    }
  },
  updated(){
    // if (this.note.for_all_students == false){
    //   setTimeout(function () {
    //     this.for_all_students = false
    //     this.studentIds = this.note.student_ids
    //   }.bind(this), 500)
      
    // }
  },
  methods: {
    toggleSticky() {
      // 
      this.isSticky = !this.isSticky
      this.note.is_sticky = this.isSticky
      this.submit()
      console.log('sticky')
    },
    setLessonOrWeekPlanDayId() {
      if (this.day === null) {
        this.note.lesson_or_week_plan_day_id = null
      } else {
        this.note.lesson_or_week_plan_day_id = `day_${this.day.id}`
      }
    },
    cancel(){
      this.$emit('cancel', this.note)      
    },
    setDay(day) {
      if (day === null) {
        this.day = null
      } else if (String(day).includes('day')) {
        for (var i = 0; i < this.lessons.length; i++) {
          var d = this.lessons[i]
          if (day.includes(d.id)) {
            this.day = d
          }
        }
      } else {
        for (var i = 0; i < this.lessons.length; i++) {
          var d = this.lessons[i]
          for (var j = 0; j < d.lessons.length; j++) {
            var l = d.lessons[j]
            console.log('checking l')
            console.log(l)
            if (l.id == day) {
              console.log('day found')
              this.day = d
            }
          }
        }
      }
    },
    clearStudentIds() {
      this.note.student_ids = []
    },
    selectStudentIds(studentIds) {
      let sIds = this.note.student_ids.concat(studentIds)
      this.note.student_ids = sIds
    },
    setValues(){
      this.setDay(this.note.lesson_or_week_plan_day_id)
      this.for_all_students = this.note.for_all_students
      console.log(this.note.id)
      if (this.note.id == undefined){
        
        this.note.student_ids = []
        console.log("cleared students")
      } else {
        // this.studentIds = this.note.student_ids  
      }
      
    },
    toggleEdit(){
      // this.viewMode = !this.viewMode
      this.$emit('editNote', this.note)
    },
    submit(){
      // this.note.studentIds = this.studentIds
      // this.note.for_all_students = this.for_all_students      
      this.$emit('submit', this.note)
    },
    deleteNote(){
      this.$emit('deleteNote', this.note) 
      // delete the note
    }
  },
  computed: {
    currentLevels() {
      if (this.selectedLesson) {
        console.log(this.selectedLesson)
        return this.levels[`course_${this.selectedLesson.course_id}`]
      } else {
        return null
      }
    },
    flatLessons(){
      return this.lessons.map((day) => day.lessons).flat()
    },
    selectedLesson(){
      if (this.note.lesson_or_week_plan_day_id && parseInt(this.note.lesson_or_week_plan_day_id) > 0) {
        // for (var i = this.lessons.length - 1; i >= 0; i--) {
        //   console.log("check if " + this.lessons[i].id + " == " + this.note.lesson_id)
        //   if (this.lessons[i].id == this.note.lesson_id){
        //     return this.lessons[i]
        //   }
        // }
        return this.flatLessons.filter((lesson) => {
          return parseInt(lesson.id) == parseInt(this.note.lesson_or_week_plan_day_id)
        })[0]
      } else {
        return null
      }
    },
    isDisabled(){
      if (this.note && this.note.body == null || this.note.body == '') {
        return true
      } else {
        return false
      }
    },
    checkboxColStyle(){
      var h = Math.ceil(this.students.length / 2) * 30
      return `max-height: ${h}px;`
    }
  }
}
</script>

<style lang="css" scoped>
  .two-cols{
   display: flex;
   align-items: left;
   justify-content: left;
   flex-flow: column wrap;
   align-content: stretch;
  }
  .two-cols div{
    height: 30px;
  }
  .link{
    text-decoration: underline;
    cursor: pointer;
  }
  .lesson-content p {
    margin-bottom: 3px !important;
  }
  .thumbtack {
    position: absolute;
    top:  10px;
    right:  10px;
    cursor: pointer;
    color:  #dedede;
    transition-duration: 100ms;
    transform:  rotate(35deg);
  }
  .thumbtack.sticky{
    color:  #FFB300;
    transition-duration: 100ms;
transform:  rotate(0deg);
  }
</style>