<template>
  <div class="row">
    <div class="col">
      <div class="card mb-2">
        <div class="card-header d-flex justify-content-between py-1">
          <span>{{lesson.day.day}}</span>
          <span><i class='fa fa-clock'></i> {{lesson.starts_at | moment("HH:mm")}}</span>
        </div>
        <div class="card-body">
          <WeekPlanItemLevel :key="`wpil-${lesson_level.id}`" :lesson="lesson" :lessonMethods="lessonMethods" :lessonLevel="lesson_level" v-for="lesson_level in lesson.lesson_levels"></WeekPlanItemLevel>
        </div>
      </div>
        
    </div>
  </div>
</template>

<script>
import WeekPlanItemLevel from '../../components/week_plans/weekPlanItemLevel.vue'
export default {
  components: {
    WeekPlanItemLevel
  },
  props: ["lesson", "lessonMethods"],
  data (){
    return {
    }
  }

}
</script>

<style scoped>

</style>
