<template>
  <div>
    <div class="btn-group">
      <button v-for="day in weekPlan.week_plan_days" class="btn btn-outline-primary" v-bind:class="{'blocked-day': !day.is_active, 'active': isActive(day), 'day-ma': day.short_name == 'ma', 'day-di': day.short_name == 'di', 'day-wo': day.short_name == 'wo', 'day-do': day.short_name == 'do', 'day-vr': day.short_name == 'vr'}" @click="selectDay(day)">
        {{ day.short_name }}
      </button>
    </div>
    <div style="font-size:  12px" v-if="!lessonlevel.has_unfixed_day_selection && isDisabled">
      Je mag geen andere dag meer kiezen.
    </div>
    <div style="font-size:  12px" v-if="!lessonlevel.has_unfixed_day_selection && !isDisabled">
      Je mag je keuze achteraf niet meer wijzigen.
    </div>
  </div>
</template>

<script>
export default {
  props: ["lessonlevel"],
  data () {
    return {
      markAsActive: false,
      day_id: 0
    }
  },
  computed: {
    weekPlan (){
      return window.store.getters.digitalWeekPlan
    },
    isDisabled () {
      return !this.lessonlevel.has_unfixed_day_selection && (this.markAsActive != false || this.lessonlevel.student_lesson.week_plan_day_id != null)
    }
  },
  mounted(){
    this.day_id = this.lessonlevel.student_lesson.week_plan_day_id
    console.log("day:", this.day_id)
  },
  methods: {
    selectDay (day){
      if (this.isDisabled) {
        alert("Voor deze les mag je geen andere dag meer kiezen.")
      } else if (!day.is_active) {
        if (day.reason_for_inactive.length) {
          alert(`Op ${day.name} is er geen les: ${day.reason_for_inactive}`)  
        } else {
          alert(`Op ${day.name} is er geen les.`)  
        }
        
      } else {
        if (this.isActive(day)){
          
          this.day_id = 0
          this.$emit('pickDay', null)    
        } else {
          this.day_id = day.id
          this.markAsActive = day.id
          this.$emit('pickDay', day)    
        }
        
      }
      
    },
    isActive (day){
      if (this.day_id == 0){
        return false;
      } else if (this.markAsActive){
        return (this.markAsActive == day.id)
      } else if (!day.is_active) {
        console.log('is inactive') 
        return false
      } else {
        return (this.lessonlevel.student_lesson.week_plan_day_id == day.id)
      }
      
    }
  }
}
</script>

<style lang="css" scoped>

  .blocked-day:before, .blocked-day:after {
    position: absolute;
    content: '';
    background: red;
    display: block;
    width: 3px;
    height: 30px;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
}

.blocked-day:after {
    -webkit-transform: rotate(45deg);    
    transform: rotate(45deg);
}

</style>