<template>
  <div class="text-center px-2">
    <button type="button" class="btn btn-sm p-1 pt-0 dropdown-toggle" :class="{'btn-outline-primary': (selectedGroup.id == group.id), 'btn-primary': (selectedGroup.id != group.id)}" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" v-if="showGroupSelect && selectedGroup && groups.length > 0">
      {{ selectedGroup.name.substring(0,3) }}
    </button>
    <div class="dropdown-menu">
      <button @click="selectGroupAndSave(gr.id)" class="dropdown-item" v-for="gr in groups">{{ gr.name_with_group }}</button>
      <div class="dropdown-divider"></div>
      <button @click="showHelp" class="dropdown-item"><i class='fa fa-question-circle'></i> Uitleg</button>
    </div>
    <div class="btn-group">
      <button @click="saveLevel(level.id)" v-for="level in levels"  v-tooltip.top-center="level.name" :key="`${studentLevelId}-${level.id}`" :value="level.id" :class="`btn btn-sm p-1 pt-0 btn-outline-primary ${activeClass(level.id)}`">{{level.initials}}</button>
    </div>

  </div>
</template>

<script>
import axios from 'axios'
import Weektaak from '../../packs/weektaak'
import StudentCourseLevelList from './studentCourseLevelList.vue'
import StudentCourseLevelPicker from './studentLevelPicker.vue'

export default {
  props: ["studentLevelId", "levelId", "student", "course", "group", "groups", "studentCourse", "showGroupSelect"],
  data (){
    return {
      selectedLevel: this.course.level_id,
      selectedGroup: null
    }
  },
  mounted() {
    if (this.studentCourse.group_id === null) {
      this.selectedGroup = this.group  
    } else {
      this.selectGroup(this.studentCourse.group_id)
    }
    
  },
  computed: {
    // selectedLevelId (){
    //   return this.course.level_id
    // },
    selected (){
      return this.course.level_id
    },
    levels (){
      return window.store.getters.levels
    }
  },
  // watch: {
  //   // whenever question changes, this function will run
  //   selected: function (oldId, newId) {
  //     this.selectedLevel = oldId
  //   },
  //   selectedLevel: function (oldSelectedLevel, newSelectedLevel) {
  //     // this.answer = 'Waiting for you to stop typing...'
  //     // this.debouncedGetAnswer()
  //     this.saveLevel(oldSelectedLevel)
  //     // console.log("Old: " + oldSelectedLevel)
  //     // console.log("New: " + newSelectedLevel)
  //   }
  // },
  methods: {
    showHelp() {
      this.$emit("showHelp")
    },
    selectGroupAndSave(groupId) {
      axios.put(`/student_levels/${this.studentCourse.id}.json`, {
        student_level: {
          group_id: groupId
        }
      }).then((response) => {
        this.selectGroup(groupId)
        // TODO
      }).catch((error) => {
        console.error(error);
      }).finally(() => {
        // TODO
      });
      
    },
    selectGroup(groupId) {
      console.log(`selecting group ${groupId}`)
      for (var i = 0; i < this.groups.length; i++) {
        if (this.groups[i].id == groupId) {
          this.selectedGroup = this.groups[i]
          return this.selectedGroup
        }
      }
    },
    activeClass(levelId){
      if (levelId == this.selected){
        return 'active'
      } else {
        return ''
      }
    },
    saveLevel (selectedLevelId) {
      console.log("saving")
      console.log(this.levels)
      console.log(this.selectedLevel)
      window.store.dispatch('updateStudentLevel', {id: this.studentLevelId, level_id: selectedLevelId})
      // console.log("saving")
      // console.log(this.selectedLevel)
      // console.log(this.studentLevelId)
    }
  }

  
}
</script>

<style scoped>
  .form-group{
    margin: 0px;
    text-align: center;
  }
  .student-level .custom-select{
    padding-top: 0px;
    padding-bottom: 0px;
    height: 28px;
  }
  .custom-control{
    padding-left: 1.1rem;
  }
  .radio-btn{
    display: inline;
  }
  .btn-sm{
    padding-top: 1px !important;
    padding-bottom: 1px !important;
  }

</style>
