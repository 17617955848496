<template>
  <div class="row">
    <div class="col-10 col-lg-8 mx-auto">
      <div v-for="(group, i) in groups" class="card mb-4 shadow-sm">
        <div class="list-group list-group-flush" :id="`group-${i}`">
          <div class="list-group-item bg-light">
            <input style="font-size: 1.3em" type="text" v-model="group.name" class="form-control string optional py-0 text-center" placeholder="Naam van de groep" autofocus>
          </div>
        
          <div class="list-group-item" v-for="student in group.students">
            <input :ref="student.ref" type="text" v-model="student.name" class="student form-control string optional py-0" placeholder="Naam van de leerling">
          </div> 
          <div class="list-group-item bg-light">
            <button @click="addStudent(group)" class="btn btn-sm btn-outline-primary btn-block" :disabled="!canAddStudent(group)">Leerling toevoegen</button>
          </div>
        </div>
      </div>
      <center class="my-3">
        <button @click="addGroup" class="mr-3 btn btn-sm btn-outline-primary"><i class="fas fa-users"></i> Groep toevoegen <i class="fas fa-plus"></i></button>
        <button @click="submitGroups" data-disable-with="Even geduld..." class="ml-3 btn-sm btn btn-success" :disabled="btnDisabled">Klaar? Opslaan en verder gaan!</button>
      </center>
    </div>

  </div>
</template>

<script>

import axios from 'axios'

export default {

  data () {
    return {
      btnDisabled: false,
      groups: [{name: '', students: [{name: ''},{name: ''},{name: ''}]}]
    }
  },
  methods: {
    submitGroups(e){

      // e.preventDefault()
      this.btnDisabled = true
      return axios.post("/manual-groups.json", {groups: this.groups})
        .then(response => {
          // console.log(response.data)
          if (response.data.status == 'success'){
            window.location.href = response.data.redirect_to
          }
          
        })
    },
    addGroup(){
      this.groups.push({name: '', students: [{name: ''},{name: ''},{name: ''}]})
    },
    canAddStudent(group){
      // const names = group.students.map((stud) => stud.name).flat(5)
      return group.students.length && group.students[group.students.length - 1].name != ''
    },
    addStudent(group){
      if (this.canAddStudent(group)){
        var index = this.groups.indexOf(group)
        group.students.push({name: '', ref: 'new-student'})
        setTimeout(() => $(`#group-${index} .student`).last().focus(), 50)  
      }
    },
    blurred(group, student) {
      console.log('new student?')
      if (group.students[group.students.length - 1] == student){  
        console.log('yes ')
        console.log(this.canAddStudent(group))
        if (this.canAddStudent(group)){
          console.log('adding')
          var index = this.groups.indexOf(group)
          group.students.push({name: '', ref: 'new-student'})
          setTimeout(() => $(`#group-${index} .student`).last().focus(), 50)  
        }
      }      
    }
  }
}
</script>

<style lang="css" scoped>
</style>