<template>
  <div>
    <div class="form-check">
      <input class="form-check-input" type="radio" v-model="login_type" name="print_job[options][login_type]" id="login_type_pin" value="pin" checked>
      <label class="form-check-label" for="login_type_pin">
        PIN code
      </label>
    </div>
    <div class="form-check">
      <input class="form-check-input" type="radio" v-model="login_type" name="print_job[options][login_type]" id="login_type_qr" value="qr" checked>
      <label class="form-check-label" for="login_type_qr">
        QR Code
      </label>
    </div>

    <div class="form-group mb-2" v-if="login_type == 'pin'">
      <label for="student">Kies leerling</label>
      <select class="form-control" name="print_job[options][student]" id="student">
        <optgroup label="Om te printen">
          <option value="all">Overzicht</option>
          <option value="all_single_page">1 A4 per leerling</option>
        </optgroup>
        <optgroup label="Om te e-mailen">
            <option v-for="student in students" :value="student.id">{{ student.first_name }} {{ student.last_name }}</option>
        </optgroup>

      </select>
    </div>

    <div class="row pt-4" v-if="login_type == 'qr'">
      <div class="col-11 mx-auto bg-light p-2 mb-2 rounded">
        <img :src="image" width="150px;" style="float: left; margin-right: 20px;">
        Deze printoptie maakt QR code's voor alle leerlingen. Deze QR code's kunnen in een sleutelhanger worden geplaatst en worden gebruikt om in te loggen. Op die manier hoeven de kinderen geen code te onthouden. Neem contact op met Weektaak.com als je sleutelhangers wilt bestellen.
      </div>
      <div class="col">
        <div class="form-group">
          <label for="exampleInputPassword1">Breedte per kaartje</label>
          <div class="input-group mb-3">
            <input name="print_job[options][width]" type="text" class="form-control" placeholder="30" v-model="width">
            <div class="input-group-append">
              <span class="input-group-text">mm</span>
            </div>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="form-group">
          <label for="exampleInputPassword1">Hoogte per kaartje</label>
          <div class="input-group mb-3">
            <input name="print_job[options][height]" type="text" class="form-control" placeholder="45" v-model="height">
            <div class="input-group-append">
              <span class="input-group-text">mm</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

  name: 'qrCodePrintOptionsForWeekPlan.vue',
  props: ["image", "students"],
  data () {
    return {
     login_type: 'pin',
     width: 30,
     height: 45
    }
  }
}
</script>

<style lang="css" scoped>
</style>