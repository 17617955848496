<template>
  <div>
    <div class="row">
      <div class="col-5">
        <div class="form-group">
          <label>Voornaam</label>
          <input class="form-control" v-model="theStudent.first_name" />
        </div>
      </div>
      <div class="col-7">
        <div class="form-group">
          <label>Achternaam</label>
          <input class="form-control" v-model="theStudent.last_name" />
        </div>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col">
        <button 
          :disabled="savingName" 
          v-if="nameHasChanged" 
          class="btn btn-success btn-block" 
          @click="saveNameChanges"
        >
            <i class="far fa-save" v-if="!savingName"></i><i class="fa fa-spinner fa-spin" v-if="savingName"></i> Wijzigingen opslaan
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col bg-light rounded p-3">
        <div class="text-center">
          <h6>Inloggegevens mijnweektaak.com</h6>
        </div>
        <hr>
        <div class="form-group">
          <label>Gebruikersnaam</label>
          <div class="input-group">
            <input v-on:keyup.enter="savePinChanges" type="text" ref="username" class="form-control" v-model="theStudent.username" :readonly="!editPin" />
            <div class="input-group-append" v-if="!editPin">
              <span class="input-group-text"><i class="fas fa-lock"></i></span>
            </div>
          </div>

          
        </div>
        <div class="row mb-2" v-if="editPin">
          <div class="col">
            <img 
              :src="`/img/student-icons/PNG/48/${animal}.png`" 
              v-for="animal in animals" 
              width="32" 
              style="margin: 4px; cursor: pointer;"
              @click="selectAnimal(animal)" 
            />
          </div>

        </div>
        <div class="row mb-3">
          <div class="col-4">
            <div class="form-group">
              <label>PIN</label>
              <div class="input-group">
                <input ref="pin" class="form-control" v-on:keyup.enter="savePinChanges" v-model="theStudent.pin" :readonly="!editPin" />
                <div class="input-group-append" v-if="!editPin">
                  <span class="input-group-text"><i class="fas fa-lock"></i></span>
                </div>
              </div>
            </div>    
          </div>
          <div class="col-4">
            <div class="form-group">
              <img :src="`/img/student-icons/PNG/256/${theStudent.animal}.png`" width="110"/>
            </div>    
          </div>
        </div>
        <div class="alert alert-warning" v-if="editPin">
          <b>Let op:</b> als je de inloggegevens hebt veranderd, moet je er wel voor zorgen dat {{ student.first_name }} de nieuwe gegevens ook krijgt, anders kan {{ student.first_name }} niet meer inloggen in mijnweektaak.com.
        </div>
        <button v-if="!editPin" class="btn btn-secondary btn-block" @click="editPinMode"><i class="fas fa-pencil-alt"></i> Inloggegevens veranderen</button>       
        <button 
          :disabled="!pinHasChanged || savingPin" 
          v-if="editPin" 
          class="btn btn-success btn-block" 
          @click="savePinChanges"
        >
            <i class="far fa-save" v-if="!savingPin"></i><i class="fa fa-spinner fa-spin" v-if="savingPin"></i> Wijzigingen opslaan
        </button>
        <div class="text-center" v-if="editPin">
          <button class="btn btn-link text-muted" @click="cancelPin">Annuleer</button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-4">
        
      </div>
      
    </div>

  </div>
</template>

<script>

import axios from 'axios'

export default {

  name: 'studentSettings',
  props: ['student'],
  data () {
    return {
      theStudent: {},
      theOriginalStudent: {},
      savingName: false,
      editPin: false,
      savingPin: false,
      animals: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31", "32", "33", "34", "35", "36", "37", "38", "39", "40", "41", "42", "43", "44", "45", "46", "47", "48"]
    }
  },
  created() {
    this.theStudent = JSON.parse(JSON.stringify(this.student))
    this.theOriginalStudent = JSON.parse(JSON.stringify(this.student))
  },
  computed: {
    nameHasChanged() {
      return ((this.theStudent.first_name != this.theOriginalStudent.first_name) || (this.theStudent.last_name != this.theOriginalStudent.last_name))
    },
    pinHasChanged() {
      return ((this.theStudent.pin != this.theOriginalStudent.pin) || (this.theStudent.animal != this.theOriginalStudent.animal) || (this.theStudent.username != this.theOriginalStudent.username))
    }
  },
  methods: {
    cancelPin() {
      this.editPin = false
      this.theStudent.animal = this.student.animal
      this.theStudent.username = this.student.username
      this.theStudent.pin = this.student.pin
    },
    saveNameChanges() {
      this.savingName = true
      axios.put(`/students/${this.student.id}.json`, {
        student: {
          first_name: this.theStudent.first_name,
          last_name: this.theStudent.last_name
        },
        response_type: 'json_object'
      })
      .then((response) => {
        this.savingName = false
        console.log(response.data)
          // this.theStudent = JSON.parse(JSON.stringify(response.data))
          this.theOriginalStudent = JSON.parse(JSON.stringify(response.data))
          // this.editPin = false
          // this.succe
      }).catch((error) => {
        console.error(error);
      }).finally(() => {
        // TODO
      });
    },
    savePinChanges(){
      if (this.pinHasChanged) {
        this.savingPin = true
        axios.put(`/students/${this.student.id}/update_pin.json`, this.theStudent)
        .then((response) => {
          this.savingPin = false
          console.log(response.data)
          if (response.data.error) {
            alert(response.data.error)
            if (response.data.field && response.data.field == 'pin') {
              this.$refs.pin.focus()
            } else if (response.data.field && response.data.field == 'username') {
              this.$refs.username.focus()
            }

          } else {
            this.theOriginalStudent = JSON.parse(JSON.stringify(response.data))
            this.editPin = false
            // this.succe
          }
        }).catch((error) => {
          console.error(error);
        }).finally(() => {
          // TODO
        });
      }
    },
    selectAnimal(animal) {
      console.log('selecting animal')
      console.log(animal)
      this.theStudent.animal = animal
    },
    editPinMode() {
      this.editPin = true
      this.$refs.username.focus()
    }
  }
}
</script>

<style lang="css" scoped>
</style>