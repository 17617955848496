<template>
  <div id="week-plan" :data-id="weekPlanId" class="text-center">
    <h4>Printopdrachten</h4>
    <div>
      <small><em v-if="printJobs.length == 0" class="text-muted">Er zijn nog geen printopdrachten<br>voor deze weekplanning.</em></small>
      <PrintJob :key="`print-job-${job.id}`" :environment="environment" :job="job" v-for="job in printJobs"></PrintJob>
    </div>
  </div>
</template>

<script>

import PrintJob from '../../components/print_jobs/printJob.vue'

export default {
  components: {
    PrintJob
  },
  props: ["weekPlanId", "environment"],
  computed: {
    printJobs () {
      return window.store.getters.printJobs
    },
  },

  created () {
    window.store.dispatch('fetchPrintJobs', { id: this.weekPlanId })  
  }

}
</script>

<style scoped>

</style>
