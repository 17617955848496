<template>
  <div class="col-4 px-1 col-md-3 col-lg-2">
    <div class="card" style="border: 1px solid #000">
      
      <draggable 
        :list="pictos" 
        class="bg-light dropzone square rounded" 
        v-bind:style="[{backgroundImage : theBackgroundImage()}]" 
        :group="{name: `day-${day.id}`, put: true, pull: false}"
        @change="changePicto"
        />
      <div class="top card-body p-0 py-1 text-center">
        <h5 class="card-title m-0" style="font-size: 12px; font-weight: 700;">{{ lesson.course }}</h5>
      </div>
      <div class="bottom card-body p-1 text-center">
        <button @click="showModal" class="btn btn-outline-primary btn-sm btn-block">Lesstof</button>
      </div>
    </div>

    <div class="modal fade" :id="`picto-lesson-modal-${lesson.id}`" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ lesson.course }} - {{ day.day }}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Sluiten">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div v-if="lesson.lesson_levels.length > 1">
              <i v-if="isLocked" class="fas fa-link"></i>
              <i v-if="!isLocked" class="fas fa-unlink text-muted"></i>
              <toggle-button v-model="isLocked" :sync="true" :labels="{checked: 'Vul overal hetzelfde in', unchecked: 'Vul niveaus afzonderlijk in'}" :fontSize="12" :width="195" />
            </div>

            <div class="bg-light rounded px-3 pt-1 pb-1 mb-2" v-for="lesson_level in lesson.lesson_levels">
              <div class="form-group">
                <label>{{ lesson_level.label }}</label>
                <input type="text" @change="saveChanges" @keyup="inputChanged(lesson_level)" v-model="lesson_level.picto_title" class="form-control" :placeholder="lesson_level.stripped_task" :disabled="!lesson_level.has_task">
                <small v-if="!lesson_level.has_task" class="form-text text-muted">Niet zichtbaar voor de leerling, omdat er in de weekplanning geen lesstof is ingevuld.</small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>

import Weektaak from '../../packs/weektaak'
import draggable from 'vuedraggable'

export default {

  name: 'pictoLesson',
  components: {draggable},
  props: ["lesson", "day"],
  data () {
    return {
      pictos: [],
      isLocked: false
    }
  },
  computed:{
  },
  methods: {
    inputChanged(lesson_level){
      if (this.isLocked){
        for (var i = this.lesson.lesson_levels.length - 1; i >= 0; i--) {
          if (this.lesson.lesson_levels[i].id != lesson_level.id){
            this.lesson.lesson_levels[i].picto_title = lesson_level.picto_title
          }
        }
      }
    },
    showModal(){
      $(`#picto-lesson-modal-${this.lesson.id}`).modal('show')
    },
    theBackgroundImage(){
      if (this.lesson.picto) {
        return `url(${this.lesson.picto})`
      } else {
        return 'none'
      }
    },
    saveChanges(){
      Weektaak.saveLessonPictoTitle(this.lesson)
    },
    changePicto(evt){
      if (evt.added){
        var picto_id = evt.added.element.id
      } else {
      }
      this.lesson.picto = evt.added.element.image
      Weektaak.saveLessonPicto(this.lesson.id, picto_id)
      
    }
  }
}
</script>

<style lang="css" scoped>
.card {
  display: grid;
  grid-template-rows: 1fr 30px 40px;
  grid-template-columns: 1fr; 
  grid-template-areas:
    "middle"
    "top"
    "bottom"
    ;
  }

  .square{
    background-size: cover;
  }

  #card .top{
    grid-area: top;
  }

  #card .dropzone{
    grid-area: middle;
  } 
  #card .bottom{
    grid-area: bottom;
  }
</style>