<template>
  <div>
    <div class="form-group">
      <label for="content_type">Wat wil je afvinken?</label>
      <select v-model="contentType" class="form-control" name="print_job[options][content_type]" id="content_type">
        <option value="tasks">Lessen zonder lesstof</option>
        <option value="tasks_per_student">Lessen met lesstof - blok per leerling</option>
        <option value="tasks_old" v-if="showOld">Lessen (oude stijl)</option>
        <option value="goals_compact">Doelen</option>
        <option value="extra">Klaaropdrachten - blok per leerling</option>
        <option value="extra_compact">Klaaropdrachten zonder lesstof</option>
      </select>
    </div>
    
    <div class="form-group" v-show="showTaskType" v-if="contentType == 'tasks' || contentType == 'tasks_per_student' || contentType == 'tasks_old'">
      <label for="content_type">Welk soort lessen?</label>
      <div class="form-group">
        <div class="form-check">
          <input v-model="taskType" value="clock" class="form-check-input" type="radio" name="print_job[options][task_type]" id="tasktype_clock">
          <label class="form-check-label" for="tasktype_clock">
            Lessen met rooster
          </label>
        </div>
        <div class="form-check">
          <input v-model="taskType" value="clockless" class="form-check-input" type="radio" name="print_job[options][task_type]" id="tasktype_clockless">
          <label class="form-check-label" for="tasktype_clockless">
            Lessen zonder rooster
          </label>
        </div>
        <div class="form-check">
          <input v-model="taskType" value="all" class="form-check-input" type="radio" name="print_job[options][task_type]" id="tasktype_all">
          <label class="form-check-label" for="tasktype_all">
            Beiden
          </label>
        </div>
      </div>
    </div>

    <div class="form-group" v-if="contentType == 'goals_compact' || contentType == 'tasks' || contentType == 'tasks_old' || contentType == 'tasks_per_student' ">
      <label for="content_type">Welke vakken wil je afvinken?</label>                    
      <div class="form-group">
        <div class="form-check" v-for="course in courses">
          <input v-model="selectedCourses" type="checkbox" name="print_job[options][course_ids][]" :id="`course-check-${course.id}`" :value="course.id" class="form-check-input">
          <label :for="`course-check-${course.id}`" class="form-check-label">{{ course.name }}</label>
        </div>
      </div>
    </div>
    <div class="text-center" v-if="contentType == 'goals_compact' || contentType == 'tasks' || contentType == 'tasks_old' || contentType == 'tasks_per_student' ">
      <div class="btn-group mx-auto">
        <button class="btn btn-sm btn-outline-primary" @click="selectAll">Selecteer alle vakken</button>
        <button class="btn btn-sm btn-outline-primary" @click="deselectAll">Deselecteer alle vakken</button>
      </div>
    </div>
    <hr>
  </div>
</template>

<script>
export default {

  name: 'contentTypePrintOption',
  props: ['courses', 'courseIds', 'showOld', 'showTaskType'],
  data () {
    return {
      contentType: 'tasks',
      taskType: 'all',
      selectedCourses: []
    }
  },
  methods: {
    selectAll(event) {
      this.selectedCourses = this.courseIds
      event.preventDefault()
      return false
    },
    deselectAll() {
      this.selectedCourses = []
      event.preventDefault()
      return false
    }
  },
  created() {
    this.selectedCourses = this.courseIds
  }
}
</script>

<style lang="css" scoped>
</style>