<template>

  <div class="card mb-5" v-bind:class="{'shadow': edit, 'animated': deleting, 'fadeOut': deleting}" v-show="isVisible">
    
    <div v-if="edit && !group.new" class="card-header d-flex justify-content-between">
      <button @click="showDeleteModal"  class="btn btn-sm btn-outline-danger" style="padding-top: 7px; cursor: pointer">Groep verwijderen</button>
      <button class="btn btn-sm btn-outline-secondary" @click="toggleEdit">Sluiten</button>
    </div>

    <div class="card-header mb-0 d-flex justify-content-between">
      <h5 v-if="!edit" class="mb-0">{{ group.name_with_group }}</h5>  
      <button v-if="!edit" class="btn btn-sm my-0 btn-outline-primary" @click="toggleEdit">bewerk</button>
      <div v-if="edit"  class="input-group">
        <input type="text" v-model="group.name" placeholder="Naam van de groep (bijv. 6A)" class="form-control string optional py-0" :autofocus="group.new">
        <div class="input-group-append" v-if="nameChanged">
          <button @click="submitNameChange" class="btn btn-success" type="button">Opslaan <i class="fas fa-check"></i></button>
        </div>
      </div>
    </div>
    
    <div class="list-group list-group-flush">
      <div class="list-item py-3 text-muted text-center" v-if="listOfStudents.length == 0">
        Deze groep heeft nog geen leerlingen. <span v-if="edit && !group.new">Voeg hieronder de namen van leerlingen toe. <i class="fas fa-arrow-down"></i></span>
        <span v-if="group.name == ''">Kies eerst een naam voor deze groep.</span>
      </div>

      <div v-show="!group.new">
        <div  class="list-group-item py-1 d-flex justify-content-between" v-if="edit">
          <button v-if="!selectedStudents.length && listOfStudents.length > 2" @click="selectAllStudents" class="btn btn-outline-primary btn-sm btn-block"><i class="far fa-check-square"></i> Selecteer alle leerlingen</button>
          <button v-if="selectedStudents.length" @click="deselectAllStudents" class="btn btn-outline-primary btn-sm btn-block"><i class="far fa-square"></i> Deselecteer alle leerlingen</button>
        </div>
        <student-name :ref="`student-name-${student.id}`" :birthdays="birthdays" v-for="student in listOfStudents" :key="student.id" :groupid="group.id" :student="student" :edit="edit" @selectedStudent="selectedStudent"></student-name>
        <student-name v-if="edit && !selectedStudents.length" :groupid="group.id" :key="'new-student'" :student="{is_new: true, first_name: '', last_name: ''}" :edit="edit" v-on:appendNewStudent="addNewStudent"></student-name>
      </div>

      <div class="list-group-item py-1 d-flex justify-content-between container bg-light" v-if="selectedStudents.length">
        <div class="row w-100">
          <div class="col">
            <select v-model="selectedGroup" ref="groupSelector" class="form-control w-100">
              <option value="">Verplaatsen of verwijderen...</option>
              <option :value="sgroup.id" :key="`group-${sgroup.id}`" :disabled="sgroup.id == group.id" v-for="sgroup in groups">{{ sgroup.name_with_group}}</option>
              <option value="remove">Verwijderen</option>
            </select>
          </div>
          <div class="col">
            <button v-if="selectedGroup && (selectedGroup != 'remove')" @click="moveSelectedStudents" class="btn btn-outline-primary btn-block">Verplaatsen</button>
            <button v-if="selectedGroup == 'remove'" @click="moveSelectedStudents" class="btn btn-outline-danger btn-block">Verwijderen</button>
          </div>
      </div>
      </div>




    </div>
    <div class="modal fade" :id="`delete-modal-${group.id}`" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog " role="document">
        <div class="modal-content">
          <div class="model-body p-3">
            <p class="lead">Weet je zeker dat je {{group.name_with_group}} wilt verwijderen? Alle <b>weektaken én leerlingen</b> gekoppeld aan deze groep, worden dan <b>ook verwijderd</b>.</p>
            <hr>
            <p>Als het je bedoeling is leerlingen te verplaatsen of groepen anders in te delen, dan kun je dat doen door leerlingen te selecteren en te verplaatsen. Als het echt je bedoeling is om deze groep te verwijderen, type hieronder dan eerst '{{ group.name_with_group}}'.
            </p>
            <input class="form-control my-2" type="text" v-model="deleteConfirmation" :placeholder="group.name_with_group" autofocus />
            <div class="text-center">
              <button @click="deleteGroup" :disabled="deleteConfirmation.replace(' ', '').toLowerCase() != group.name_with_group.replace(' ', '').toLowerCase()" class="btn btn-danger">Ja, verwijder {{ group.name_with_group }}</button>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  </div>


</template>

<script>

import axios from 'axios'

export default {
  props: ["group", "new_student", "groups", "birthdays"],

  data () {
    return {
      selectedGroup: "",
      groupName: "",
      listOfStudents: [],
      selectedStudents: [],
      deleteConfirmation: '',
      newStudent: {firstName: '', lastName: ''},
      editMode: false,
      deleting: false,
      isVisible: true
    }
  },
  computed: {
    nameChanged() {
      return this.groupName != this.group.name
    },
    edit () {
      return this.editMode || this.group.new
    },
  },
  mounted() {
    this.groupName = this.group.name
  },
  methods: {
    deleteGroup() {
      $(`#delete-modal-${this.group.id}`).modal('hide')
      this.deleting = true
      axios.delete(`/groups/${this.group.id}.json`).then((response) => {
        // TODO
      }).catch((error) => {
        console.error(error);
      }).finally(() => {
        // TODO
        setTimeout(function() {
          this.isVisible = false
        }.bind(this), 600);
      });
      

    },
    showDeleteModal(){
      $(`#delete-modal-${this.group.id}`).modal('show')
    },
    deselectAllStudents (){
      this.selectedStudents = []
      var i = 0
      for (i = 0; i < this.listOfStudents.length; i++) { 
        this.listOfStudents[i].is_selected = false
      }
    },
    selectAllStudents (){ 
      this.selectedStudents = []
      var i = 0
      for (i = 0; i < this.listOfStudents.length; i++) { 
        this.listOfStudents[i].is_selected = true
        this.selectedStudents.push(this.listOfStudents[i].id)
      }
    },
    moveSelectedStudents (){
      return axios.get("/groups/"+ this.selectedGroup +"/move.json?student_ids=" + this.selectedStudents)
        .then(response => {
          location.reload()
          // return response.data
        })

    },
    // adds selected student id to the list of selectedStudents
    // this.$emit('appendNewStudent', this.is_selected, this.student.id)
    selectedStudent (isSelected, studentId){
      console.log("selected")
      console.log(isSelected)
      console.log(studentId)
      if (isSelected){
        this.selectedStudents.push(studentId)
      } else {
        for( var i = 0; i < this.selectedStudents.length; i++){ 
           if ( this.selectedStudents[i] === studentId) {
             this.selectedStudents.splice(i, 1)
           }
        }
      }
      // console.log(this.selectedStudents)
    },
    addNewStudent (student){
      console.log('new student')
      console.log(student)
      this.listOfStudents.push(student)
    },
    submitNameChange (){
      if (this.group.new){
       return axios.post("/groups.json", {group: {name: this.group.name}})
          .then(response => {
            // console.log("Data:")
            console.log(response.data)
            this.group.name_with_group = response.data.name_with_group
            this.group.name = response.data.name
            this.group.id = response.data.id
            this.group.new = false
            this.groupName = this.group.name
            this.editMode = false
            return response.data
          })
      } else {

        // console.log("submitting name schange")
        return axios.put("/groups/"+ this.group.id +".json", {group: {name: this.group.name}})
          .then(response => {
            this.groupName = this.group.name
            this.group.name_with_group = response.data.name_with_group
            this.group.name = response.data.name
            this.editMode = false
            return response.data

          })
      }
    },
    toggleEdit () {
      if (this.editMode){
        this.editMode = false
      } else {
        this.editMode = true  
      }
      
    }
  },
  created() {
    this.listOfStudents = this.group.students
    if (this.group.new){
      this.editMode = true  
    }
    
  }

  
}
</script>

<style scoped>
  
</style>
