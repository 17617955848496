<template>
  <div class="px-3">
    <div class="d-flex justify-content-between">
      <div class="d-flex flex-row">
        <toggle-button @change="toggleLive" v-model="isLive" :sync="true" />      
        <label class="ml-2">Deze weektaak is actief</label>
      </div>
      <i @click="showInfo1 = !showInfo1" id="credit-popover" class="fas fa-info-circle pt-1"></i>
    </div>
    <div v-if="showInfo1">
      <div class="bg-light p-2 rounded">
        Wanneer leerlingen inloggen in hun digitale weektaak, krijgen ze de op dat moment actieve weektaak te zien. Gebruik deze optie om deze weektaak nu actief/inactief te maken.
      </div>
    </div>

    <hr>

    <div class="form-group">
      <label for="distribution">Hoe wordt de voortgang berekend?</label>
      <select v-model="progressType" class="form-control">
        <option value="tasks">Taken</option>
        <option value="extra">{{ extrawork }}</option>
        <option value="tasks_and_extra">Taken én {{ extrawork }}</option>
      </select>
    </div>


    <div class="d-flex justify-content-between">
      <div class="d-flex flex-row">
        <toggle-button v-model="showGoals" :sync="true" />      
        <label class="ml-2">Toon leerdoelen</label>
      </div>
      
    </div>
    <hr>

    <div class="d-flex flex-row">
        <toggle-button v-model="useChat" :sync="true" />      
        <label class="ml-2">Gebruik de chatfunctie</label>
    </div>

  
    <div v-if="useChat">
      <div class="form-group mt-3">
        <label for="chat_name">Jouw naam in de chat</label>
        <input v-model="userChatName" type="text" class="form-control" id="chat_name" :placeholder="username">
        <small class="form-text text-muted">Deze naam krijgen de kinderen te zien als afzender van jouw chatberichten.</small>
      </div>
    </div>

    <hr v-if="picto">
    <div class="form-group" v-if="picto">
      <label>Picto's bij de vakken</label>
      <select v-model="pictoPref" class="form-control">
        <option value="hide">Toon geen picto's</option>
        <option value="group">Toon picto voor {{ group_name }}, anders niets</option>
        <option value="show">Toon picto voor {{ group_name }}, anders standaardpicto</option>
      </select>
    </div>

    <hr>


      <div class="d-flex flex-row">
        <toggle-button v-model="showPreviousWeek" :sync="true" />      
        <label class="ml-2">Leerlingen mogen vorige weektaak terugzien</label>
    </div>

    <hr>

    <div class="form-group mt-3">
      <label>Leerlingfeedback</label>
      <small class="form-text text-muted">Bij het afvinken van de taken, geven de leerlingen feedback op de taak door smiley's en duimpjes. Welke vragen moeten daar worden gebruikt?</small>
      <div class="row">
        <div class="col-12 col-md-6 text-center">
          <label for="feedback_feeling" class="w-100">
            <i class="far fa-frown"></i>
            <i class="far fa-meh"></i>
            <i class="far fa-smile"></i>
          </label>
          <input v-model="feedback_feeling" type="text" class="form-control text-center" id="feedback_feeling" placeholder="Hoe vond je het?" maxlength="24">
        </div>

        <div class="col-12 col-md-6 text-center">
          <label for="feedback_result" class=" w-100">
            <i class="fa fa-thumbs-down"></i>
            <i class="fa fa-thumbs-up"></i>
          </label>
          <input v-model="feedback_result" type="text" class="form-control text-center" id="feedback_result" placeholder="Hoe ging het?" maxlength="24">
        </div>
      </div>
      
    </div>

    <hr>
    <div class="d-flex justify-content-between">
      <div class="d-flex flex-row">
        <toggle-button v-model="useCredits" :sync="true"  />      
        <label class="ml-2">Laat kinderen punten verdienen</label>
      </div>
      <i @click="showInfo2 = !showInfo2" class="fas fa-info-circle pt-1"></i>
    </div>
    <div v-if="showInfo2">
      <div class="bg-light p-2 rounded">
        <h5>Spaarpunten</h5>
        Het is mogelijk om kinderen punten te laten verdienen wanneer ze hun taken afvinken. Hoe je dit gebruikt, mag je helemaal zelf bepalen.<br><br>Je kunt bijvoorbeeld afspreken: <em>"Er zitten 100 punten 'verstopt' in de weektaak. Hebben jullie er vrijdag meer dan 80? Dan gaan we extra lang buitenspelen."</em><br><br>Zodra het doel gehaald is, zal er een confetti-feestje zichtbaar zijn op de schermen van de kinderen. 🎉<br><br><b>Let op:</b> de punten worden niet individueel gespaard, maar als groep.
        Wanneer leerlingen inloggen in hun digitale weektaak, krijgen ze de op dat moment actieve weektaak te zien. Gebruik deze optie om deze weektaak nu actief/inactief te maken.  
      </div>
      
    </div>



    <div v-if="useCredits">
            
      
      <div class="form-group mt-3" v-if="false">
        <label for="credit_name">Naam van de munt</label>
        <input v-model="theCreditName" type="text" class="form-control" id="credit_name" placeholder="Naam van de munt, bijv. 'speelpunten'">
        <small class="form-text text-muted">De naam van de munt is zichtbaar voor de kinderen.</small>
      </div>
      <div class="row mt-3">
        <div class="col">  
          <div class="form-group mb-1">
            <label for="total">Totaal</label>
            <input @input="checkNumbers" type="number" class="form-control" id="total" placeholder="120" v-model="theTotal">
          </div>
        </div>
        <div class="col">
          <div class="form-group mb-1">
            <label for="goal">Spaardoel</label>
            <input @input="checkNumbers" type="number" class="form-control" id="goal" placeholder="100" v-model="theGoal">
          </div>    
        </div>
      </div>
      <div class="alert alert-danger" role="alert" v-if="errorMessage != '' ">
        {{ errorMessage }}
      </div>
      <small class="form-text text-muted">Het <b>totaal</b> geeft aan hoeveel punten er in totaal worden verdeeld over de hele klas. Zodra het <b>spaardoel</b> gehaald is, krijgen de kinderen een feestje op het scherm te zien.</small>

      <div class="form-group" v-if="false">
        <label for="distribution">Welke lessen kunnen punten verdienen?</label>
        <select v-model="onlyCheckToday" class="form-control">
          <option :value="false">Lessen van alle dagen</option>
          <option :value="true">Alleen lessen van dagen die aan de beurt zijn</option>
        </select>
      </div>

      <div class="form-group mt-2">
        <label for="distribution">Hoe worden de punten verdeeld?</label>
        <select v-model="theDistribution" name="theDistribution" class="form-control" id="distribution">
          <option :value="'tasks'">Bij alle taken</option>
          <!-- <option :value="'tasks-for-day'">Bij alle taken van de huidige dag</option> -->
          <option :value="'tasks-and-extra'">Bij alle taken en bij {{extrawork}}</option>
          <!-- <option :value="'tasks-for-day-and-extra'">Bij alle taken van de huidige dag én bij {{extrawork}}</option> -->
          <option :value="'extra'">Alleen bij {{extrawork}}</option>
        </select>
      </div>


      <div class="text-center">
        <b>verdiend:</b> <i class="text-warning fas fa-coins"></i> {{ earned }} / {{ theGoal }}
      </div>
      
    </div>
    <button @click="submitForm()" v-bind:disabled="errorMessage != '' || saving" class="mt-4 btn btn-success btn-block">{{saveTextToShow}}</button>
    <div class="w-100 mt-4">
      <div v-if="useCredits" class="d-flex justify-content-between">
        <button @click="testConfetti" class="btn btn-sm btn-outline-primary">🎉 Test de confetti</button>
        <button @click="redistributeCredits" class="btn btn-sm btn-outline-primary" :disabled="resettingCredits">Punten herverdelen</button>
      </div>
    </div>
  </div>
</template>

<script>

import axios from 'axios'

export default {

  props: ["picto","group_name", "live", "earned","distribution", "total", "goal", "chat", "extrawork", "creditname", "usecredits", "weekplanid", "username", "chatname", "only_check_today", "typeOfProgress", "show_goals", "feedbackResult", "feedbackFeeling", "show_previous_week", "picto_preference"],
  data () {
    return {
      isLive: false,
      useCredits: true,
      showInfo1: false,
      resettingCredits: false,
      showInfo2: false,
      pictoPref: 'hide',
      useChat: false,
      onlyCheckToday: false,
      showGoals: false,
      errorMessage: '',
      feedback_result: '',
      feedback_feeling: '',
      theDistribution: 'tasks',
      showPreviousWeek: false,
      theCreditName: '',
      theGoal: '',
      progressType: 'tasks',
      theTotal: '',
      originalDistribution: '',
      originalTotal: '',
      saving: false,
      saveText: 'Opslaan'
    }
  },
  computed: {
    saveTextToShow(){
      if (this.saveText == 'Opslaan' && this.creditSettingsHaveChanged){
        return "Opslaan en punten willekeurig verstoppen"
      } else {
        return this.saveText
      }
    },
    creditSettingsHaveChanged() {
      // this.originalTotal = this.theTotal
      // this.originalDistribution = this.theDistribution
      if (
          (this.theTotal != this.originalTotal) || 
          (this.theDistribution != this.originalDistribution) || 
          (!this.usecredits && this.useCredits)
          ) { 
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    redistributeCredits() {
      this.resettingCredits = true
      return axios.get(`/week_plans/${this.weekplanid}/redistribute_credits.json`).
      then(response => {
        console.log(response.data)
        window.store.dispatch('setStudentWeekPlans', response.data)
        this.resettingCredits = false
      })
    },
    toggleLive(){
      console.log("Live " + this.isLive)
      return axios.get(`/week_plans/${this.weekplanid}/toggle-active.json?active=${this.isLive}`).
      then(response => {
        // done
      })
    },
    testConfetti(){
      return axios.get(`/week_plans/${this.weekplanid}/test-confetti.json`).
      then(response => {
        // done
      })
    },
    submitForm(){
      let redistribute_credits = this.creditSettingsHaveChanged
      this.originalTotal = this.theTotal
      this.originalDistribution = this.theDistribution
      const weekplanObject = {
            total_credits: this.theTotal,
            goal_credits: this.theGoal,
            picto_preference: this.pictoPref,
            use_chat: this.useChat,
            credit_distribution: this.theDistribution,
            feedback_result: this.feedback_result,
            feedback_feeling: this.feedback_feeling,
            credit_name: this.theCreditName,
            use_credits: this.useCredits,
            only_check_today: this.onlyCheckToday,
            show_previous_week: this.showPreviousWeek,
            show_goals: this.showGoals,
            progress_type: this.progressType
          }
      // save settings in Vuex
      this.saving = true
      window.store.dispatch('setWeekPlanSettings', weekplanObject)

      return axios.put(`/week_plans/${this.weekplanid}.json`, {
          // total_credits
          // goal_credits
          // credit_distribution
          // credit_name
          // credit_icon
          // use_credits
          user: {
            chat_name: this.userChatName
          },
          redistribute_credits: redistribute_credits,
          week_plan: weekplanObject
        })
        .then(response => {
          // window.store.dispatch('setDigitalWeekPlan', response.data)
          this.saveText = 'Opgeslagen!'
          this.saving = false
          setTimeout(function () {
            this.saveText = 'Opslaan'
          }.bind(this), 2000)
          return response.data

        })
      return true  
    },
    checkNumbers(){
      if (parseInt(this.theGoal) > parseInt(this.theTotal)) {
        this.errorMessage = "Het spaardoel kan niet hoger zijn dan het totaal te verdienen punten"
      } else {
        this.errorMessage = ''
      }
    }
  },
  created() {
    this.originalTotal = this.total
    this.originalDistribution = this.distribution  
    this.theTotal = this.total
    this.theGoal = this.goal
    this.isLive = this.live
    this.useChat = this.chat

    this.showGoals = this.show_goals
    this.onlyCheckToday = this.only_check_today
    this.userChatName = this.chatname
    this.showPreviousWeek = this.show_previous_week
    this.theCreditName = this.creditname
    this.progressType = this.typeOfProgress
    this.pictoPref = this.picto_preference ?? "hide"
    this.feedback_result = this.feedbackResult
    this.feedback_feeling = this.feedbackFeeling
    if (this.distribution != '' && this.distribution != undefined) {
      this.theDistribution = this.distribution  
    }
    this.useCredits = this.usecredits
  }
}
</script>

<style lang="css" scoped>
</style>