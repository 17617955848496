<template>
  <div class="row">
    <div class="col-12 text-center">
      <p class="lead">Nodig collega's uit. Je werkt samen in hetzelfde account, maar kunt ieder in je eigen planningen werken.</p>
    </div>
    <div class="col-10 col-md-8 mx-auto">

      <div class="card mb-3 shadow-sm">
        <div class="card-header">
          <h6 class="mb-0">Beheerder</h6>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-6">
              <div class="form-group">
                <label>Voornaam</label>
                <input type="text" class="form-control" :value="admin.first_name" readonly>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label>Achternaam</label>
                <input type="text" class="form-control" :value="admin.last_name" readonly>
              </div>
            </div>
            <div class="col-12">
              <div class="form-group">
                <label>E-mail</label>
                <input type="email" class="form-control" :value="admin.email" readonly>
              </div>
              
            </div>
            <div class="col-12">
              <p class="small text-muted">Jij bent zelf beheerder met de meeste rechten. Gebruikers die je nu uitnodigt, zullen minder rechten hebben. Hun rechten kun je later op elk moment aanpassen.</p>
            </div>
          </div>
        </div>
      </div>

      <newUser @enter="addIfCan" :index="index" :user="user" v-for="(user, index) in users" />
    </div>
    <div class="col-10 col-md-8 mx-auto text-center">
      <button :disabled="!canAdd" class="btn btn-success btn-sm" @click="addUser">Nog een gebruiker toevoegen</button>
    </div>
    <div class="col-12 text-right mt-4 border-top py-2">
      <a v-if="hasNoUsers" href="/onboarding/done?skip_users=true" class="btn btn-outline-secondary">Deze stap nu overslaan, ik doe dit later</a>
      <button @click="createUsers" :disabled="hasNoUsers || inviting" class="btn btn-success">Klaar? Gebruikers uitnodigen <i class="ml-2 fa fa-paper-plane"></i></button>
      <div v-if="message.length" class="text-success text-right my-2">
        {{ message }} <i class='fa fa-spinner fa-spin'></i>
      </div>
      
    </div>
  </div>
</template>

<script>
import newUser from './newUser.vue'
import axios from 'axios'
export default {

  name: 'userInvite',
  props: ['admin'],
  components: { newUser},
  data () {
    return {
      users: [],
      message: '',
      inviting: false
    }
  },
  created() {
    this.addUser()
  },
  computed: {
    hasNoUsers(){
      return this.users.length == 1 && !this.canAdd
    },
    canAdd(){
      // const names = group.students.map((stud) => stud.name).flat(5)
      return this.users && this.users[this.users.length - 1].email != ''
    },
  },
  methods: {
    createUsers(){
      this.inviting = true
      return axios.post("/user-invites.json", {users: this.users })
      .then(response => {
        console.log(response.data)
        this.message = response.data.message
        setTimeout(function () {
          window.location = response.data.url
        }.bind(this), 3000)
        
      })


    },
    addIfCan(){
      console.log('enter pressed')
      if (this.canAdd) {
        this.addUser()
      }
    },
    addUser() {
      let users = [...this.users]
      let newUsers = users.map(function(user) { 
        user.focus = false
        return user
      })
      newUsers.push({first_name: '', last_name: '', email: '', focus: true})
      this.users = newUsers
      // console.log(this.$refs)
      // setTimeout(function () {
      //   this.$refs[`name_${users.length -1}`].focus()
      // }.bind(this), 100)
      
    }
  }
}
</script>

<style lang="css" scoped>
</style>