<template>

    <div class="container">
      <passwordLogin v-if="usepassword"></passwordLogin>
      <qrcodeLogin :image="image" v-if="useqrcode"></qrcodeLogin>
    </div>   

</template>

<script>

import qrcodeLogin from '../../components/digital/qrcodeLogin.vue'
import passwordLogin from '../../components/digital/passwordLogin.vue'


import axios from 'axios'


export default {
  components: {
    passwordLogin,
    qrcodeLogin

  },
  props: ["image", "usepassword", "useqrcode"],
  data () {
    return {
    }
  }
}
</script>

<style lang="css">

</style>