<template>
  <div>
    <div class="row">
      <div v-if="true" class="" style="position: absolute; bottom: 0px; font-size: 9pt; right: 10px; color: #dedede;">{{lesson_level.label}}</div>
      <div class="col-12 px-0">
        <table class="table table-borderless mb-1">
          <tbody>
            
            <tr v-show="tab == 'task'">
              <td class="pt-2">
                <div class="editor">
                  <editor-menu-bubble :editor="editor" :keep-in-bounds="keepInBounds" v-slot="{ commands, isActive, menu }">
                    <div
                      class="menububble"
                      :class="{ 'is-active': menu.isActive }"
                      :style="`left: ${menu.left}px; bottom: ${menu.bottom}px;`"
                    >
                      <button
                        class="menububble__button"
                        :class="{ 'is-active': isActive.bold() }"
                        @click="commands.bold"
                      >
                        <i class="fas fa-bold"></i>
                      </button>
                      <button
                        class="menububble__button"
                        :class="{ 'is-active': isActive.italic() }"
                        @click="commands.italic"
                      >
                        <i class="fas fa-italic"></i>
                      </button>
                      <button
                        class="menububble__button"
                        :class="{ 'is-active': isActive.underline() }"
                        @click="commands.underline"
                      >
                        <i class="fas fa-underline"></i>
                      </button>
                    </div>
                  </editor-menu-bubble>

                  <editor-content class="editor__content" v-model="lesson_level.task" :editor="editor" />
                </div>
              </td>
            </tr>
            <tr v-show="tab == 'comment'">
              <td>
                <div class="editor">
                  <editor-menu-bubble :editor="commentEditor" :keep-in-bounds="keepInBounds" v-slot="{ commands, isActive, menu }">
                    <div
                      class="menububble"
                      :class="{ 'is-active': menu.isActive }"
                      :style="`left: ${menu.left}px; bottom: ${menu.bottom}px;`"
                    >
                      <button
                        class="menububble__button"
                        :class="{ 'is-active': isActive.bold() }"
                        @click="commands.bold"
                      >
                        <i class="fas fa-bold"></i>
                      </button>
                      <button
                        class="menububble__button"
                        :class="{ 'is-active': isActive.italic() }"
                        @click="commands.italic"
                      >
                        <i class="fas fa-italic"></i>
                      </button>
                      <button
                        class="menububble__button"
                        :class="{ 'is-active': isActive.underline() }"
                        @click="commands.underline"
                      >
                        <i class="fas fa-underline"></i>
                      </button>
                    </div>
                  </editor-menu-bubble>

                  <editor-content class="editor__content" v-model="lesson_level.comment" :editor="commentEditor" />
                </div>
              </td>
            </tr>
            <tr v-show="tab == 'goal'">
              <td>
                <div class="editor">

                  <editor-menu-bubble :editor="goalEditor" :keep-in-bounds="keepInBounds" v-slot="{ commands, isActive, menu }">
                    <div
                      class="menububble"
                      :class="{ 'is-active': menu.isActive }"
                      :style="`left: ${menu.left}px; bottom: ${menu.bottom}px;`"
                    >
                      <button
                        class="menububble__button"
                        :class="{ 'is-active': isActive.bold() }"
                        @click="commands.bold"
                      >
                        <i class="fas fa-bold"></i>
                      </button>
                      <button
                        class="menububble__button"
                        :class="{ 'is-active': isActive.italic() }"
                        @click="commands.italic"
                      >
                        <i class="fas fa-italic"></i>
                      </button>
                      <button
                        class="menububble__button"
                        :class="{ 'is-active': isActive.underline() }"
                        @click="commands.underline"
                      >
                        <i class="fas fa-underline"></i>
                      </button>
                    </div>
                  </editor-menu-bubble>

                  <editor-content class="editor__content" v-model="lesson_level.goal" :editor="goalEditor" />
                </div>
              </td>
            </tr>

            <tr v-show="tab == 'more'" class="more">
              <div class="pt-2 pb-3">
                <div class="d-flex justify-content-between">
                  <span>Werkvorm</span>
                  <div class="dropup">
                    <button class="btn btn-outline-dark btn-sm dropdown-toggle py-0 mb-1" href="#" role="button" data-toggle="dropdown" v-html="currentLessonMethodLabel"></button>
                    <div class="dropdown-menu bg-white">
                      <button v-if="lesson_level.lesson_method_id != null" @click="selectMethod(null)" class="dropdown-item">
                        Kies geen werkvorm
                      </button>
                      <div class="dropdown-divider" v-if="lesson_level.lesson_method_id != null"></div>
                      <button v-bind:class="{'active': lesson_level.lesson_method_id == method.id }" class="dropdown-item d-flex align-items-center" @click="selectMethod(method)" v-for="method in lesson_methods" :value="method.id">
                        <i :class="method.icon" style="width: 30px;"></i> 
                        <span class="">{{ method.name }}</span>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="d-flex justify-content-between">
                  <span>Leerling mag zelf nakijken</span>
                  <span><toggle-button @change="submitChanges('correct')" v-model="lesson_level.correct" :height="20" :sync="true" /></span>
                </div>
                <div class="d-flex justify-content-between">
                  <span>Toon tijd op de weektaak</span>
                  <span><toggle-button @change="submitChanges('show_time')" v-model="lesson_level.show_time" :height="20" :sync="true" /></span>
                </div>
                <div class="d-flex justify-content-between" v-if="lesson_level.show_time">
                  <span>Toon afwijkende tijd op weektaak</span>
                  <span><toggle-button @change="submitChanges('use_custom_time')" v-model="lesson_level.use_custom_time" :height="20" :sync="true" /></span>
                </div>
                <div class="d-flex justify-content-between" v-if="lesson_level.show_time && lesson_level.use_custom_time">
                  <span>Tijd op de weektaak</span>
                  <span>
                    <input @input="submitChanges('time_to_show')" v-model="lesson_level.time_to_show" placeholder="Tijd" class="form-control small py-0" style="font-size: 11pt; height: 14pt; max-width: 90px;"/>
                  </span>
                </div>
              </div>
          

            </tr>

          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import Weektaak from '../../packs/weektaak'
import MaskedInput from 'vue-masked-input' 
import { Editor, EditorContent, EditorMenuBubble } from 'tiptap'
import {
  Bold,
  Italic,
  Strike,
  Underline,
  Placeholder,
  History,
} from 'tiptap-extensions'
export default {

  name: 'weekPlanItemLevelPerCourse',
  components:{
    EditorContent,
    EditorMenuBubble,
    MaskedInput
  },
  props: ["lesson_level", "lesson", "lesson_methods", "tab", "isLocked", "show_comment_field"],
  watch: {
    lesson_level: {
      handler: function(oldVal, newVal) {
        if (this.isLocked && !this.lesson_level.isLocked) {
          // console.log(`receiving isLocked changes for level ${this.lesson_level.label}`)
          // console.log("is locked")
          this.editor.setContent(this.lesson_level.task)  
          this.goalEditor.setContent(this.lesson_level.goal)  
          this.commentEditor.setContent(this.lesson_level.comment)  
          // this.submitChanges('task')
        } else if (this.lesson_level.trigger_update_once) {
          // console.log(`receiving changes for level ${this.lesson_level.label}`)
          this.editor.setContent(this.lesson_level.task)  
          this.goalEditor.setContent(this.lesson_level.goal)  
          this.commentEditor.setContent(this.lesson_level.comment)  
          this.lesson_level.trigger_update_once = false
        }
        // else if (this.lesson_level.updatedOnServer && this.lesson_level.updated_at + 3 > (Date.now() / 1000)){
        //   this.taskBeforeChange = this.lesson_level.task
        //   this.goalBeforeChange = this.lesson_level.goal
        //   console.log("updating!")
        //   this.editor.setContent(this.lesson_level.task)  
        //   this.goalEditor.setContent(this.lesson_level.goal)  
        // }
      },
      deep: true
    }
  },
  computed: {
    currentLessonMethodLabel(){
      if (this.lesson_level.lesson_method_id != null){
        for (var i = this.lesson_methods.length - 1; i >= 0; i--) {
          let lm = this.lesson_methods[i]
          if (lm.id == this.lesson_level.lesson_method_id){
            return `<i class='${lm.icon}'></i> ${lm.name}`
          }
        }
      } else {
        return 'Kies een werkvorm...'
      }
    },
    weekPlan (){
      return window.store.getters.weekPlanPerCourse
    },
    linkedLessonLevels (){
      if (this.lesson.linked_lessons.length){
        var arr = []
        for (var a = this.weekPlan.courses.length - 1; a >= 0; a--) {
          var course = this.weekPlan.courses[a]
          if (course.id == this.lesson.course_id){
            for (var b = course.groups.length - 1; b >= 0; b--) {
              var group = course.groups[b]
              // console.log(group.name)
              // console.log(`checking course ${course.name} for ${group.name}`)
              for (var c = group.week_plan_days.length - 1; c >= 0; c--) {
                var wpd = group.week_plan_days[c]
                for (var d = wpd.lessons.length - 1; d >= 0; d--) {
                  var lesson = wpd.lessons[d]
                  // arr.push(lesson)
                  for (var e = this.lesson.linked_lessons.length - 1; e >= 0; e--) {
                    var linkedLesson = this.lesson.linked_lessons[e]
                    if (lesson.id == linkedLesson.id) {
                      // console.log(linkedLesson)
                      for (var f = lesson.lesson_levels.length - 1; f >= 0; f--) {
                        var lessonLevel = lesson.lesson_levels[f]
                        // console.log(lessonLevel)
                        // console.log(`checking if ${lessonLevel.student_id} == ${this.lesson_level.student_id}`)
                        // console.log(`and checking if ${lessonLevel.level_id} == ${this.lesson_level.level_id}`)
                        if (
                              (lessonLevel.student_id == this.lesson_level.student_id) && 
                              (lessonLevel.level_id == this.lesson_level.level_id) 
                          ) {
                            arr.push(lessonLevel)
                        } else if (this.isLocked && lessonLevel.student_id == null){
                          // console.log("adding, because is locked")
                          arr.push(lessonLevel)
                        }
                      }
                    }
                  }
                }
              }
            }
            return arr
          }
        }

        return arr
      } else {
        return []
      }
    }
  },
  data () {
    return {
      keepInBounds: true,
      taskBeforeChange: null,
      goalBeforeChange: null,
      commentBeforeChange: null,
      copy: {},
      editor: new Editor({
        extensions: [
          new Placeholder({
            emptyClass: 'is-empty',
            emptyNodeText: this.lesson_level.placeholder,
            showOnlyWhenEditable: false,
          }),
          new Bold(),
          new Italic(),
          new Strike(),
          new Underline(),
          new History(),
        ],
        disableInputRules: true,
        content: this.lesson_level.task,
        onUpdate: ({ getHTML }) => {
          // console.log('onUpdate triggered')
          this.copy = {...this.lesson_level}
          this.copy.task = getHTML()
          this.triggerUpdate('task')
          if (this.isLocked) {
            // console.log('triggered onUpdate#lockedUpdate')
            this.$emit('lockedUpdate', this.copy, 'task')
          }
        },
        onBlur: ({ event,  state, view }) => {
          if (this.copy && this.copy.task) {


            this.lesson_level.task = this.copy.task
           
             // console.log(`lesson_level:`)
             // console.log(this.lesson_level)
             // console.log(`copy:`)
             // console.log(this.copy)
             // console.log(`taskBeforeChange: ${this.taskBeforeChange}`)
            if ((this.lesson_level.task == this.taskBeforeChange)) {
              console.log("not updating, is unchanged")
            } else {
              // console.log('submitting: yes')
              this.submitChanges('task')
            }
          }
        }
      }),
      goalEditor: new Editor({
        extensions: [
          new Placeholder({
            emptyClass: 'is-empty',
            emptyNodeText: `Doel voor ${this.lesson_level.label}`,
            showOnlyWhenEditable: false,
          }),
          new Bold(),
          new Italic(),
          new Strike(),
          new Underline(),
          new History(),
        ],
        disableInputRules: true,
        content: this.lesson_level.goal,
        onUpdate: ({ getHTML }) => {
          this.copy = {...this.lesson_level}
          this.copy.goal = getHTML()

          // this.lesson_level.goal = getHTML()
          this.triggerUpdate('goal')
          if (this.isLocked) {
            this.$emit('lockedUpdate', this.copy, 'goal')
          }
        },
        onBlur: ({ event,  state, view }) => {
          if (this.copy && this.copy.goal) {

            this.lesson_level.goal = this.copy.goal
            console.log('blurred goal')
            // || this.isLocked
            if ((this.lesson_level.goal != this.goalBeforeChange)) {
              console.log('submitting changes')
              this.submitChanges('goal')
            }
          }
        }
      }),
      commentEditor: new Editor({
        extensions: [
          new Placeholder({
            emptyClass: 'is-empty',
            emptyNodeText: `Opmerking voor ${this.lesson_level.label}`,
            showOnlyWhenEditable: false,
          }),
          new Bold(),
          new Italic(),
          new Strike(),
          new Underline(),
          new History(),
        ],
        disableInputRules: true,
        content: this.lesson_level.comment,
        onUpdate: ({ getHTML }) => {
          this.copy = {...this.lesson_level}
          this.copy.comment = getHTML()
          // this.lesson_level.comment = getHTML()
          this.triggerUpdate('comment')
          if (this.isLocked) {
            this.$emit('lockedUpdate', this.copy, 'comment')
          }
        },
        onBlur: ({ event,  state, view }) => {
          // || this.isLocked
          if (this.copy && this.copy.comment) {
            this.lesson_level.comment = this.copy.comment
            if ((this.lesson_level.comment != this.commentBeforeChange)) {

              this.submitChanges('comment')
            }
          }
        }
      })

    }
  },
  methods: {
    selectMethod(method){
      if (method === null){
        this.lesson_level.lesson_method_id = null
      } else {
        this.lesson_level.lesson_method_id = method.id
      }
      this.submitChanges('lesson_method_id')
    },
    triggerUpdate(type){
      if (this.lesson.linked_lessons.length){
        for (var i = this.linkedLessonLevels.length - 1; i >= 0; i--) {
          let linked_lesson_level = this.linkedLessonLevels[i]
          // console.log(`updating for ${linked_lesson_level.label}. Locked: ${this.isLocked}`)
          linked_lesson_level.task                = this.lesson_level.task
          linked_lesson_level.goal                = this.lesson_level.goal
          linked_lesson_level.comment             = this.lesson_level.comment
          // linked_lesson_level.show_time           = this.lesson_level.show_time
          // linked_lesson_level.use_custom_time     = this.lesson_level.use_custom_time
          // linked_lesson_level.correct             = this.lesson_level.correct
          // linked_lesson_level.time_to_show        = this.lesson_level.time_to_show
          linked_lesson_level.lesson_method_id    = this.lesson_level.lesson_method_id
          linked_lesson_level.trigger_update_once = true
        }
      }
    },
    submitChanges(type) {
      // console.log("submitting...")

      // if (type == 'lesson_method_id'){
      if (type != 'time_to_show') {
        this.triggerUpdate(type)
      }

      // console.log(this.lesson_level.task)
      // console.log(this.lesson_level.correct)
      // console.log(this.lesson_level.show_time)
      // console.log(this.lesson_level.lesson_method_id)
      // console.log(this.lesson_level.goal)
      if (this.isLocked) {
        if (type != 'time_to_show') {
          this.$emit('lockedUpdate', this.lesson_level, type)
        }
      }
      this.$emit('change')

      // Weektaak.updateLessonLevel(this.lesson_level.id, {
      //   task: this.lesson_level.task,
      //   goal: this.lesson_level.goal,
      //   lesson_method_id: this.lesson_level.lesson_method_id,
      //   show_time: this.lesson_level.show_time,
      //   correct: this.lesson_level.correct,
      //   locked: this.islocked,
      //   time_to_show: this.lesson_level.time_to_show,
      //   use_custom_time: this.lesson_level.use_custom_time,
      //   locked_type: type,
      //   response_type: 'per_course'
      // })

    }
  },
  mounted(){
    this.taskBeforeChange = this.lesson_level.task
    this.goalBeforeChange = this.lesson_level.goal
    this.commentBeforeChange = this.lesson_level.comment
  }
}
</script>

<style lang="css" scoped>
  .level-label{
    position: absolute;
    left: -62px;
    background-color: #fff;
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
    width: 100px;
    height: 24px;
    top: 38px;
    font-size: 10px;
    padding-top: 4px;
    text-align: center;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-color: #dedede;
    border-bottom: none !important;
  }

  table.table th, table.table td{
    padding: 5px;
  }
  table.table th{
    font-size: 11pt;
    vertical-align: middle;
  }
  .editor{
    font-size: 11pt;
  }
  .more {
    font-size: 11pt;
  }
  .menububble{
    position: absolute;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    z-index: 20;
    background: #000;
    border-radius: 5px;
    padding: .3rem;
    margin-bottom: .5rem;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    visibility: hidden;
    opacity: 0;
    -webkit-transition: opacity .2s,visibility .2s;
    transition: opacity .2s,visibility .2s;
  } 
  .menububble.is-active{
    opacity: 1;
    visibility: visible;
  }

  .menububble__button {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    background: transparent;
    border: 0;
    color: #fff;
    padding: .2rem .5rem;
    margin-right: .2rem;
    border-radius: 3px;
    cursor: pointer;
}
</style>