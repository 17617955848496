<template>
  <div class="row">
    <div class="col-9" v-bind:class="{'incomming' : inGoing, 'outgoing': !inGoing, 'deleting' : deleting}">
      <div class="d-inline-block rounded p-2 msg mb-1">
        <div class="arrow"></div>
        <p class="small my-0" v-if="scope == 'student' && message.sender == 'student'"></p>
        <p class="my-0 font-italic" style="font-size: 0.8rem" v-else>{{ message.user_name }}:</p>
        <div v-if="message.is_file">
          <a v-if="message.preview_url" target="_blank" :href="message.download_url"><img :src="message.preview_url" width="150" class="rounded" /></a>
          <a v-if="!message.preview_url" target="_blank" :href="message.download_url" class="btn btn-primary">
            <i class="fas fa-download"></i> {{ message.filename }}
          </a>
        </div>
        <p class="my-0 chat-message" :class="{'omji-only': onlyEmoji}" v-html="message.body" v-linkified></p>
        <p class="small my-0">
          {{ message.time_string }}
          <span class="text-white" v-if="scope == 'user' && message.read_by_student && message.sender == 'user'"><i class="fas fa-check-double"></i></span>
        </p>
        <button @click="deleteMessage" class="btn btn-sm btn-link text-danger delete-btn" v-if="scope == 'user' && !deleting && canDelete"><i class="fas fa-trash"></i></button>
      </div>

    </div>

  </div>
</template>

<script>

import axios from 'axios'
export default {

  name: 'chatMessage',
  props: ["message", "scope", "can_delete"],
  data () {
    return {
      deleting: false,
      canDelete: true
    }
  },
  methods: {
    deleteMessage() {
      this.deleting = true
      return axios.delete(`/messages/${this.message.id}.json`)
        .then(response => {
          window.store.dispatch('setDigitalWeekPlanMessages', response.data)
        })
    },
  },
  computed: {
    inGoing (){
      return this.scope == this.message.sender
    },
    onlyEmoji (){
      if (this.message && this.message.body && this.message.body.length < 10) {
        let str = this.message.body
        const ranges = [
          '\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff]',
          ' ', // Also allow spaces
        ].join('|');
        const removeEmoji = str => str.replace(new RegExp(ranges, 'g'), '');
        const hasOnlyEmoji = str => !removeEmoji(str).length;
        return hasOnlyEmoji(str)
      } else {
        return false
      }
    }
  },
  created() {
    if (this.can_delete != null) {
      this.canDelete = this.can_delete 
    }
  }
}
</script>

<style lang="css" scoped>
  .msg{
    position: relative;
  }

.outgoing .arrow {
  position: absolute;
  right: -6px;
  top: 4px;
  width: 0; 
  height: 0; 
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent; 
  border-left: 10px solid #FFCE67;
}

.incomming .arrow {
  position: absolute;
  left: -6px;
  top: 4px;
  width: 0; 
  height: 0; 
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent; 
  border-right: 10px solid #6CC3D5;
}

/*  .arrow{
    background-color: red;
    height: 10px;
    width: 10px;
    position: absolute;
    left: -10px;
    top: 0px;
  }*/
  .incomming {
    text-align: left;
    margin-right: auto;
  }
  .incomming .msg{
    background-color: #6CC3D5;
    margin-left: 20px;
    min-height: 30px;
  }
  .outgoing {
    text-align: right;
    margin-left: auto;
    
  }
  .outgoing .msg{
    background-color: #FFCE67;
    margin-right: 20px;
  }
  .msg .small{
    font-size: 9px;
  }
  .incomming .delete-btn{
    position: absolute;
    right: -25px;
    font-size: 12px;
    top: 0px;
  }
  .outgoing .delete-btn{
    position: absolute;
    left: -25px;
    font-size: 12px;
    top: 0px;
  }
  .deleting p{
    text-decoration: line-through;
  }
  .omji-only {
    font-size: 50px;
  }
  .chat-message {
    white-space: pre-line; 
    /*word-wrap: break-word;
    font-family: inherit;*/
  }
  
</style>