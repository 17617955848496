<template>
  <div>
    <div class="container-fluid mt-4 pb-3 px-3">
      <div class="rounded bg-white container-fluid rounded shadow-sm">
        <div class="row">
          <div class="col pt-3 mb-3">
            <h3>Gekozen onderdelen</h3>
            <div v-if="items.length == 0" class="bg-light blank-slate rounded p-3 text-muted">
              <p class="lead">Met 'evaluatie voor leerlingen', kun je een lijst met vragen samenstellen die je aan de leerlingen kunt stellen.</p>
              <p class="lead">Welke vragen wil je aan de leerlingen stellen? Sleep beschikbare sjablonen naar deze plek. Zet ze in de gewenste volgorde en pas instellingen aan.</p>
            </div>
            <draggable
          class="dragArea list-group"
          :list="items"
          group="items"
          handle=".handle"
          direction="vertical"
          @start="checkMove"
          @end="stopMove"
          @change="dropItem"
          :class="{'h-100' : !items.length}"
            >
              <div
                class="list-group-item p-0"
                style="border-width: 2px; border-style: dashed;"
                v-for="(item, index) in items"
                :key="`${item.id}-${index}`"
                :data-item-id="item.id"
              >
                <evaluationItem :courses="courses" :students="students" :item="item" :week-plan-id="weekPlanId" />
              </div>
            </draggable>
            <div class="mt-3 bg-light p-3 rounded shadow-sm" v-if="items.length">
              <i class='fa fa-print'></i> Wanneer je dit formulier uitprint, past het op op <strong>{{ numPagesText }}</strong> per leerling.
            </div>

            <div v-if="reachedLimit" class="alert alert-warning mt-4">
              Let op: je kunt maximaal 3 onderdelen selecteren
            </div>
          </div>
          <div class="col col-md-3 py-3">
            <div id="trash-bin" v-show="moving" class="bg-danger pt-5">
              <i class="fas fa-trash-alt text-white mt-5"></i>
              <draggable class="bin-content" style="font-size: 10px !important;" :list="trashedItems" :group="{put: true}" @add="trashedItem">            
              </draggable>
            </div>

            <div v-show="!moving" class="sticky-top">
              <h3>Sjablonen</h3>
              <draggable
                class="dragArea list-group"
                :list="defaultOptions"
                :group="{ name: 'items', pull: pullOption, put: false }"
              >
                <div v-for="(option, index) in defaultOptions" class="list-group-item py-1" style="cursor: grab;" @click="addToTop(option)">
                  <h5 class="mb-0">{{ option.name }}</h5>
                  <small class="text-muted" v-if="option.hint">{{ option.hint }}</small>
                </div>
              </draggable>
            </div>
          </div>


        </div>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import axios from 'axios'
import evaluationItem from './evaluationItem.vue'

export default {

  name: 'evalutationItems',
  components: { draggable, evaluationItem},
  props: ['defaultOptions', 'weekPlanId', 'initialItems', 'courses', 'students'],
  data () {
    return {
      items: [],
      trashedItems: [],
      moving: false
    }
  },
  methods: {
    stopMove() {
      this.moving = false
    },
    checkMove() {
      this.moving = true
    },
    trashedItem(event) {
      var itemId = event.item.dataset.itemId
      if (itemId != undefined){
        axios.delete(`/week_plans/${this.weekPlanId}/evaluation_items/${itemId}`).then((response) => {
          this.moving = false
          this.items = response.data
        }).catch((error) => {
          console.error(error);
        }).finally(() => {
          // TODO
        });
      }
    },
    addToTop(option){
      let item = { added: { newIndex: 1, element: option }}
      this.dropItem(item)
    },
    dropItem(item) {
      this.moving = false
      console.log(item)
      if (item.moved) {
        axios.get(`/week_plans/${this.weekPlanId}/evaluation_items/${item.moved.element.id}/move.json?position=${item.moved.newIndex}`).then((response) => {
          console.log(response)
          this.items = response.data
        }).catch((error) => {
          console.error(error);
        }).finally(() => {
          // TODO
        });
      } else if (item.added) {
        axios.post(`/week_plans/${this.weekPlanId}/evaluation_items.json?position=${item.added.newIndex}`, {
          title_1: item.added.element.title_1,
          title_2: item.added.element.title_2,
          title_3: item.added.element.title_3,
          body: item.added.element.body,
          input_type: item.added.element.input_type
        }).then((response) => {
          console.log(response)
          this.items = response.data
        }).catch((error) => {
          console.error(error);
        }).finally(() => {
          // TODO
        });
      }
    }
  },
  created() {
    if (this.initialItems && this.initialItems.length) {
      this.items = this.initialItems
    }
  },
  computed: {
    pagesNeeded() {
      var pages = 1
      var height = 0
      const limit = 270
      for (var i = 0; i < this.items.length; i++) {
        let item = this.items[i]
        if (item.height + height > limit) {
          pages ++
          height = item.height
        } else {
          height += item.height
        }
      }
      return pages
    },
    numPagesText() {
      if (this.pagesNeeded == 1) {
        return `${this.pagesNeeded} pagina (A4)`
      } else {
        return `${this.pagesNeeded} pagina's (A4)`
      }
    },
    reachedLimit() {
      return false // this.items.length == 12
    },
    pullOption() {
      if (this.reachedLimit) {
        return false
      } else {
        return 'clone'
      }
    }
  }
}
</script>

<style lang="css" scoped>
</style>