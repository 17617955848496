<template>
  <div>
    <div v-if="errorMessage" class="text-center">
      <div class="alert alert-danger">
        {{ errorMessage }}
      </div>
      <div class="btn-group my-4">
        <button @click="errorMessage = null" class="btn btn-outline-primary">Nog een keer proberen</button>
        <button @click="cancel" class="btn btn-outline-primary">Annuleren</button>
      </div>
    </div>
    <div v-if="loading" class="text-center">
      <i class="fa fa-spinner fa-spin"></i>
    </div>
    <vue-clip :options="options" :on-sending="setLoading" :on-complete="uploadComplete" v-if="!loading && !errorMessage">
      <template slot="clip-uploader-action">
        <div class="row py-5">
          <div class="dz-message col-8 mx-auto bg-light rounded p-4 text-center my-5">
            Klik hier of sleep hierheen om een bestand te uploaden.
          </div>
          <div class="col-12 text-center">
            <button class="btn btn-outline-secondary" @click="cancel">Annuleer</button>
          </div>
        </div>
      </template>

      <template slot="clip-uploader-body" slot-scope="props">
      </template>
    </vue-clip>
  </div>
</template>

<script>
export default {

  name: 'fileUploader',
  props: ['url'],
  data () {
    return {
      errorMessage: null,
      loading: false,
      options: {
        url: this.uploadUrl(),
        maxFilesize: {
          limit: 5,
          message: 'Dit bestand is te groot. Je kunt bestanden van maximaal 5MB uploaden.'
        },
        paramName: 'file',
        uploadMultiple: false,
        acceptedFiles: {
          extensions: ['image/*', 'application/pdf'],
          message: 'Het gekozen bestand kan niet worden geupload. Je kunt alleen afbeeldingen (.png, .jpg) of PDF bestanden uploaden.'
        }
      }
    }
  },
  methods: {
    setLoading() {
      this.loading = true
    },
    cancel() {
      this.$emit('cancel')
    },
    uploadUrl() {
      // return `/message-attachment`
      return this.url
    },
    uploadComplete(file, status, xhr) {
      // this.errorMessage = null
      // this.loading = true
      console.log(status)
      if (status == 'success') {
        // if xhr.response
        // console.log(xhr.response)
        if (xhr.response && xhr.response.length) {
          this.$emit('upload', JSON.parse(xhr.response) )
        }
      } else {
        console.log(file)
        this.loading = false
        console.log(status)
        if (file.errorMessage.length > 200){
          this.errorMessage = "Er ging iets fout bij het uploaden van dit bestand. Probeer het later nog een keer, of probeer een ander bestand te uploaden."
        } else {
          this.errorMessage = file.errorMessage
        }
        // alert(this)
      }
      // console.log(xhr.response)
      // console.log("upload complete")
    },
  }
}
</script>

<style lang="css" scoped>
.dz-message{
  cursor: pointer;
  border: 2px dashed #dedede;
}
</style>